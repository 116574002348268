import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Rating from './Rating'

import { usePhotoGallery } from '../../../modules/inventory/admin/upload/usePhotoGallery.ts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLeft, faCheck, faCircleCheck, faCircleRight, faX } from '@fortawesome/free-solid-svg-icons';

export default function Item({entry, count, handleFunctions}) {

    var {updateChecklist, showTasks, setShowTasks, taskCountNum, module, submitItem, setSubmitItem} = handleFunctions

    var entryStatus = (entry.excused) ? 'excused' :
                      (!entry.complete && !entry.completeAdmin && entry.inProgress) ? 'inProgress' :
                      (entry.complete && !entry.completeAdmin) ? 'pending' :
                      (entry.completeAdmin) ? 'complete' : 'active'
          
    if(entry.complete) {
      // console.log(entryStatus,'entryStatus',entry)
    }
    // var bgColor = 'bg-secondary'

    // console.log(entry,'entry')
    
    function handleTasks() {
      setShowTasks(prev => {return !prev})
    }

    var bgColor = entry.type === 'Test' ? 'bg-black' : 
                  entry.type === 'Activity' ? 'bg-purple' : 
                  entry.type === 'Design Brief' ? 'bg-primary' : 'bg-secondary'

    var bgStatus = 'text-secondary bg-light'
    var stringStatus = 'To Be Completed'

    if(entryStatus === 'inProgress') {
      bgStatus = 'bg-secondary text-white border border-secondary border-2'
      stringStatus = 'In Progress'
    } else if(entryStatus === 'pending') { 
        // bgColor = 'bg-info'
        // bgStatus = 'text-white border bg-light border-success border-3' //stripes successAttendance
        bgStatus = 'text-white pending stripes'
        stringStatus = 'Marked Complete'
    } else if(entryStatus === 'complete') {
        // bgColor = 'bg-success'
        bgStatus = 'bg-success border border-success border-2'
        stringStatus = 'Complete'
    } else if(entryStatus === 'excused') {
        bgStatus = 'bg-black border-black border-2'
        stringStatus = 'Excused'
    }

    var typeBg = entry.type === 'Test' ? 'bg-black' :
                 entry.type === 'Activity' ? 'bg-purple' :
                 entry.type === 'Design Brief' ? 'bg-primary' : 'bg-secondary'

    if(entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview' && !showTasks) return
    var showTasksBadge = (entry.type !== 'Design Brief' || entry.designBriefTask !== 'Overview') ? false : true
    var showBadges = (entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview') ? false : true

    const importVariables = {bgStatus, entryStatus, stringStatus, entry, updateChecklist, typeBg, submitItem, setSubmitItem, count}

    // var tasksString = entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview'

    var modifiedLink = ''
    if(entry.link && entry.link.includes('=project&parent=')) {
      var linkStem = `${entry.link.split('=project&parent=')[0]}=project&parent=`
      var linkEnd = entry.link.split('=project&parent=')[1].match(/([^,]*),(.*)/)[2]
      modifiedLink = linkStem + '4C5E7Y70,' + linkEnd

      // console.log(modifiedLink,'modifiedLink', entry.link.split('=project&parent=')[1])
    }
    // console.log(entry,'entry')

    return (
    <>
    {/* && submitItem.itemId === entry.itemId */}
    {(submitItem ) ? <CompleteCard key={entry.itemId} importVariables={importVariables}/> : <div className="mt-3 mb-3 d-flex align-items-stretch">
        <div className={`d-flex align-items-stretch`}>
            {(entry.type !== 'Design Brief' || entry.designBriefTask === 'Overview') && <div className={`card p-3 ${bgColor} text-white me-3`} style={{minWidth:"70px"}}>
              <div className="d-flex align-items-center justify-content-center" style={{height:"100%"}}>
                  {entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview' ? entry.designBriefTask : <h2 className="m-0">{count}</h2>}
              </div>
            </div>}
        </div>
        <div className="card ms-1 flex-grow-1">
          <div className="card-body">
            <div className="d-flex align-items-stretch justify-content-between">
              <span>
                <div>
                  {entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview' ? <TaskCard entry={entry} /> :
                  entry.link ? <a href={entry.link} target="_blank">{entry.item}</a> : `${entry.item}`}
                  {entry.action && entry.action.length > 0 && <span className="text fst-italic fw-lighter ms-1">
                            - {entry.action}
                  </span>}  
                </div>
                {showBadges && <span>
                  <Link className="badge mt-2 bg-secondary" to={entry.link ? entry.link : "https://lms.paxpat.com/"} target="_blank">PaxPat - {entry.day}</Link>
                  <Link className={`badge mt-2 ms-2 me-2 ${typeBg}`} to={entry.link ? entry.link : "https://lms.paxpat.com/"} target="_blank">{entry.type}</Link>
                  {/* <div className={`ms-2 me-2 badge ${typeBg}`}>{entry.type}</div> */}
                  {showTasksBadge && <div className={`badge bg-light text-primary me-2`}>{taskCountNum} Tasks</div>}
                  {/* {entry.action && entry.action.length > 0 && <Link className={`badge mt-2 bg-secondary  d-flex align-items-center`} to={entry.link ? entry.link : "https://lms.paxpat.com/"} target="_blank">{entry.action}</Link>} */}
                </span>}
              </span>
              {showTasksBadge && <div className={`ms-3 badge bg-primary text-white d-flex align-items-center justify-content-center`} role="button"
              style={{minWidth: '110px'}} 
              onClick={handleTasks}>{showTasks ? 'Hide Tasks' : `Show ${taskCountNum} Tasks`}</div>}
              {(entry.type !== 'Design Brief' || entry.designBriefTask !== 'Overview') && <UpdateChecklist importVariables={importVariables} />}
            </div>
          </div>
        </div>
    </div>} 
    {showTasksBadge && showTasks && module && module.designBriefTask && !submitItem && <TaskDescriptionCard task={module.designBriefTask}/>}
    </>
    )
}

function TaskCard({entry}) {
  return (
    <div className="d-flex justify-content-start align-items-center">
      <div>
        <div className="d-flex align-items-center">
        <Link className="badge text-white bg-primary d-block" to={entry.link ? entry.link : "https://lms.paxpat.com/"} style={{width:"70px"}} target="_blank">{entry.designBriefTask}</Link>
        {entry.action && entry.action.length > 0 && <Link className={`badge ms-2 bg-light`} to={entry.link ? entry.link : "https://lms.paxpat.com/"} target="_blank">{entry.action}</Link>}
        </div>
        {/* <div className={`me-3 badge text-white bg-primary d-block flex-fill`} style={{width:"70px"}}>{entry.designBriefTask}</div> */}
        <div className={`me-3 d-block flex-fill mt-2`} style={{minWidth:"50px"}}>{entry.item}</div>
        </div>
        {/* {entry.link ? <a href={entry.link} target="_blank">{entry.item}</a> : `${entry.item}`} */}
    </div>
  )
}

function TaskDescriptionCard({task}) {
  return (
    <div className="card ms-1 flex-grow-1">
      <div className="card-body">
        <div className="d-flex align-items-stretch justify-content-between">
          <span>
            <div>
              <div className={`me-3 badge text-white bg-primary d-inline`}>Summary</div>
            </div>
            <div className="mt-2" style={{whiteSpace:"pre-wrap"}}>{task}</div>
          </span>
        </div>
      </div>
    </div>
  )
}

function CompleteCard({importVariables}) {

  const {submitItem, entry, setSubmitItem, typeBg, count, updateChecklist} = importVariables

  const { takePhoto } = usePhotoGallery();
  const [localActivity, setLocalActivity] = useState(entry.completeEvent && entry.completeEvent.activity)

  // console.log(entry,'entry')

  useEffect(() => {

    let interval;

    interval = setInterval(async () => {
      if(entry.completeEvent && localActivity === entry.completeEvent.activity) return
      updateChecklist(false, entry.itemId, 'completeEvent', 'activity', localActivity)
    }, 3000);

    return () => clearInterval(interval);

  }, [localActivity])

  async function handleAddImage(e) {
    e.preventDefault()

    if(e.target.classList.contains('delete')) {
      console.log('here')
      updateChecklist(e, entry.itemId, 'completeEvent', 'url', 'delete')
      return
    }
    const timeMilli = new Date().getTime()
    var url = `https://ccr-items.s3.us-west-1.amazonaws.com/${timeMilli}.jpeg`
    await takePhoto(timeMilli, 100)
    updateChecklist(e, entry.itemId, 'completeEvent', 'url', url)
    // handleChange(e, 'image', '', url)
  }

  if(submitItem.itemId !== entry.itemId) return

  function handleClose() {
    setSubmitItem(false)
  }

  function localUpdate(e) {
    e.preventDefault()
    setLocalActivity(e.target.value)
  }

  function updateChecklistLocal(e) {
    updateChecklist(e, entry.itemId, 'inProgress')
    setSubmitItem(false)
  }

  const questions = [
    {question: 'In a couple sentences, what did you do in this activity?', type: 'text'},
    {question: 'How much did you enjoy the activity? (On a scale of 1 to 5)', type: 'rating'},
  ]

  var countQ = 0

  return (
    <div className="mt-3 mb-3 d-flex align-items-stretch">
      <div className="card flex-grow-1 border border-3 border-success">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex">
            <h2 className={`${typeBg} text-white d-flex align-items-center me-3 mb-0 p-3 border rounded`}>{count}</h2>
            <div>
              <div className="">
                <h2 className="fsize-1 mb-0">Confirm Completion:</h2>
                <h3 className="fsize-2 fw-bold">{entry.item}</h3>
              </div>
              {/* {entry.action && entry.action.length > 0 &&  */}
              <div className="d-flex flex-wrap mt-2">
                {/* <div className="badge bg-success m-1 ms-0">{entry.item}</div>
                <div className="badge bg-success m-1 ms-0">{entry.item}</div> */}
                  <Link className="badge bg-secondary" to={entry.link ? entry.link : "https://lms.paxpat.com/"} target="_blank">PaxPat - {entry.day}</Link>
                  <Link className={`badge ms-2 me-2 ${typeBg}`} to={entry.link ? entry.link : "https://lms.paxpat.com/"} target="_blank">{entry.type}</Link>
                  {entry.action && entry.action.length > 0 && <span className="text fst-italic fw-lighter">
                    {entry.action}
                  </span>}
                  {/* <Link className="badge bg-success" to={entry.link ? entry.link : "https://lms.paxpat.com/"} target="_blank">{entry.item}</Link> */}
              </div>
            </div>
            </div>
            <FontAwesomeIcon className={`m-2`} style={{fontSize: "1rem"}} role="button" icon={faX} onClick={handleClose} />
          </div>
        </div>
        <div className="card-body">
          <div className="mb-2">To submit evidence of your work & learning, please complete the following:</div>
          <div className="d-flex justify-content-start align-items-start">
            <div className="me-2">1.</div>
            <div>Answer the following questions.</div>
          </div>
          <div className="d-flex justify-content-start align-items-start">
            <div className="me-2">2.</div>
            <div>Submit an artifact. Either by taking a photo of your work or uploading a photo.</div>
          </div>
        </div>
        <div className="card-body">
          {/* <div className="card bg-secondary border border-3 border-success">
            <div className="card-body"> */}
              {questions.map(item => {
                countQ++  
                return (
                  <div key={countQ} className="card bg-secondary border border-3 border-success mb-3">
                    <div className="card-body">
                      <div className="text-white fw-bold">{countQ}. {item.question}</div>
                      <div className="mt-2 p-1">
                      {item.type === 'text' ?          
                        <textarea className="form-control" onChange={e => localUpdate(e)} cols={40} rows={2} 
                          id="textAreaSpace" placeholder={'Type here...'} defaultValue={!localActivity ? '' : localActivity} /> : item.type === 'rating' ? <Rating updateChecklist={updateChecklist} itemId={entry.itemId} /> : <></>}
                       </div>
                      {item.type === 'text' && localActivity && localActivity.length < 40 && localActivity.length > 10 && <div className="d-flex justify-content-end text-light p-1">
                        {40-localActivity.length} Characters More Required
                      </div>}
                    </div>
                  </div>
                )
              })}
            {/* </div>
          </div> */}
          <div className="card bg-black text-success mb-3" role="button" onClick={async (e) => handleAddImage(e)}>
            <div className="card-body fw-bold d-flex justify-content-between flex-wrap">
              <span>Click here to upload artifact. Either take or upload photo.</span>
              <div className="d-flex align-items-center">
              <div className={`badge ${entry.completeEvent && entry.completeEvent.url ? 'bg-success text-white' : 'bg-light text-black'} d-flex align-items-center`}>{!entry.completeEvent || !entry.completeEvent.url ? 'No File Uploaded' : (typeof entry.completeEvent.url === 'string' || entry.completeEvent && entry.completeEvent.url && entry.completeEvent.url.length === 1) ? 'Image Uploaded' : `${entry.completeEvent && entry.completeEvent.url && entry.completeEvent.url.length} Images Uploaded ${(entry.completeEvent && entry.completeEvent.url && entry.completeEvent.url.length > 1) ? `(Max 5)` : ''}`}</div>
              {entry.completeEvent && entry.completeEvent.url && <div className={`badge ms-2 bg-light text-black d-flex align-items-center`}>+ Image</div>}
              {entry.completeEvent && entry.completeEvent.url && entry.completeEvent.url.length > 0 && <div className={`badge ms-2 bg-light text-black d-flex align-items-center delete`}>
                <FontAwesomeIcon className={`delete`} icon={faX} />
              </div>}
              {/* border border-2 border-success */}
              </div>
            </div>
          </div>
          {entry.completeEvent && entry.completeEvent.url && entry.completeEvent.rating && localActivity && localActivity.length > 39 && <div className="d-flex justify-content-end">
            <div className="badge bg-success p-3" style={{fontSize:"1rem"}} 
              role="button" onClick={e => updateChecklistLocal(e)}>
              Submit
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}

function UpdateChecklist({importVariables}) {

  const [show, setShow] = useState(false);
  const [initial, setInitial] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const {bgStatus, entryStatus, stringStatus, entry, updateChecklist, typeBg, setSubmitItem} = importVariables
  const exportVariables = {handleClose, handleShow, show, updateChecklist, entry, typeBg}

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

    const newDate = new Date(d.setDate(diff));
    newDate.setMinutes(0)
    newDate.setHours(0)
    newDate.setSeconds(0)

    return newDate.getTime()
  }

  function handleShowSubmit() {
    setSubmitItem(entry)
  }

  function handleInitialSubmit() {
    setInitial(true)
  }

  return (
    <>
    {/* <CompleteModal exportVariables={exportVariables} /> */}
    <div className="d-flex align-items-center justify-content-center h-auto">
    {!entry.completeAdmin && (entry.inProgress || entry.complete) && <div className={`badge bg-light d-flex align-items-center m-1`} 
      role={'button'} 
      style={{width: '20px', height:"40px"}}
      onClick={e => {
        updateChecklist(e, entry.itemId, 'pending')
        setInitial(false)
      }}
      >
          
    </div>}
    {!entry.completeAdmin && !entry.inProgress && (entry.inProgress || entry.complete) && <div className={`badge bg-secondary d-flex align-items-center m-1`} 
      role={'button'} 
      style={{width: '20px', height:"40px"}}
      onClick={e => {
        updateChecklist(e, entry.itemId, 'active')
        setInitial(false)
      }}
    >
    </div>}
    {entry.completeAdmin && entry.completeEvent ? <SCompleteEvent item={entry} stringStatus={stringStatus} entryStatus={entryStatus} event={entry.completeEvent} bgStatus={bgStatus} /> : 
    <div className={`badge ${bgStatus} d-flex align-items-center justify-content-center m-1`} 
      style={{minWidth: (entryStatus !== 'complete' && entryStatus !== 'excused') ? '140px' : '110px', height:"40px"}}
    >
          <span>{stringStatus}</span>
    </div>}
    {!entry.completeAdmin && !entry.inProgress && !entry.complete && <div className={`badge bg-secondary d-flex align-items-center m-1`} 
      role={'button'} 
      style={{width: '20px', height:"40px"}}
      onClick={e => {
        updateChecklist(e, entry.itemId, 'active')
        setInitial(false)
      }}
    >
    </div>}
    {!entry.completeAdmin && !entry.complete && !initial && <div className={`badge bg-success border border-success border-2 d-flex align-items-center m-1`} 
      role={'button'} 
      style={{width: '20px', height:"40px"}}
      // onClick={handleShow}
      onClick={handleInitialSubmit}
      // onClick={e => updateChecklist(e, entry.itemId, 'inProgress')}
    >
          
    </div>}
    {!entry.completeAdmin && !entry.complete && initial && <div className={`badge bg-success border border-success border-2 d-flex align-items-center m-1 text-wrap`} 
      role={'button'} 
      style={{width: '80px', height:"40px"}}
      // onClick={handleShow}
      onClick={handleShowSubmit}
      // onClick={e => updateChecklist(e, entry.itemId, 'inProgress')}
    >
          Click to Confirm
    </div> }
    </div>
    </>
  )
}


function SCompleteEvent({event, item, stringStatus, entryStatus, bgStatus}) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // console.log(event,'event', item)

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

    const newDate = new Date(d.setDate(diff));
    newDate.setMinutes(0)
    newDate.setHours(0)
    newDate.setSeconds(0)

    return newDate.getTime()
  }

  function prettyDate(dateInput) {
    var date = new Date(dateInput)
    // console.log(date, 'inside---')
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var hours = ((date.getHours() + 11) % 12 + 1);
    var minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    
    return `${days[date.getDay()]} ${months[date.getMonth()]} ${date.getDate()}`
    // ${hours}:${minutes}`

  }

  var count = 0
  // console.log('ehllo')
  return (
    <>
      <div className={`badge ${bgStatus} d-flex align-items-center justify-content-center m-1`} onClick={handleShow} role="button"
      style={{minWidth: (entryStatus !== 'complete' && entryStatus !== 'excused') ? '140px' : '110px', height:"40px"}}>
          <span>{stringStatus}</span>
          {item.completeEvent && item.completeAdmin && item.completeEvent.date > getMonday(Date.now()) && <FontAwesomeIcon className={`text-white m-1 me-0 ms-2`} style={{fontSize: "1rem"}} icon={faCircleCheck} />}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
              <span className="fw-bold">{item.day} - {item.type}</span>
              <div style={{fontSize:"1rem"}}>{item.item}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <div className="d-flex mb-2 align-items-center">
              <div>{event.name} - {event.period} - {prettyDate(event.date)}</div>
           </div>
           {event && typeof event.url === 'string' ? <img src={event.url} className="m-0 viewModalImage"/> :
           <>
           {event && event.url && typeof event.url !== 'string' && event.url.map(entry => {
             count++
             return <img key={count} src={entry} className="m-0 mb-2 viewModalImage"/>
           })}
           </>}
           <div className="d-flex align-items-center mt-2">
              <div className="badge bg-secondary text-white me-2">{event.rating}</div>
              <div className="">{event.activity}</div>
           </div>
           
            {/* <img src={event.url}/> */}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
import React, {useState, useContext, useEffect} from 'react'
import { SiteContext } from '../../App';

import CalendarPicker from 'react-calendar';
import { differenceInCalendarDays } from 'date-fns';

import 'react-calendar/dist/Calendar.css';
import './schedules.css'
// const datesToAddClassTo = [];

export default function Calendar() {

    const {colorMatch, schedules, calendarDays, loginState, focusAgreements, classsInformationArray, studentDataFunctions: {setCalendarDays, createMongo, updateMongo}} = useContext(SiteContext)

    const [value, setValue] = useState(new Date());

    if(!calendarDays || calendarDays.length === 0) return
    const calendarEvents = calendarDays[0].events

    function onChange(nextValue){
        setValue(nextValue);

        const editCalendarEvents = [...calendarEvents]
        var index = editCalendarEvents.findIndex(entry => entry.milli == new Date(nextValue).getTime())

        if(index === -1) {
            editCalendarEvents.push({milli: new Date(nextValue).getTime(), date: nextValue, bgClass: 'styledTile bg-alt', index: 0})
        } else {

            if(editCalendarEvents[index].index < schedules.length - 1) {
                editCalendarEvents[index].bgClass = `styledTile bg-${colorMatch[editCalendarEvents[index].index + 3].variant}`
                editCalendarEvents[index].index = editCalendarEvents[index].index + 1
            } else if(editCalendarEvents[index].index < schedules.length) {
                editCalendarEvents[index].bgClass = `styledTile bg-black`
                editCalendarEvents[index].index = editCalendarEvents[index].index + 1
            } else {
                editCalendarEvents[index].bgClass = `styledTile bg-alt`
                editCalendarEvents[index].index = 0
            }
        }

        const editCalendarDays = [...calendarDays]
        editCalendarDays[0].events = [...editCalendarEvents]

        setCalendarDays(editCalendarDays)
        updateMongo({events: editCalendarEvents}, editCalendarDays[0]._id, 'calendarDays')
    }

    function tileClassName({ date, view }) {
        // Add class to tiles in month view only
        if (view === 'month') {
          var index = calendarEvents.findIndex(entry => entry.milli === new Date(date).getTime())
          if(index === -1) return 'noBackground'
          return calendarEvents[index].bgClass
        }
      }

    return (
     <div className="card-body d-flex justify-content-between align-items-start flex-wrap">
      <div className="m-2">
        <CalendarPicker
            onChange={loginState.admin ? onChange : () => {}}
            value={value}
            tileClassName={tileClassName}
            next2Label={null}
            prev2Label={null}
            maxDetail={'month'}
            maxDate={new Date(2030, 0, 1)}
            minDate={new Date(2020, 0, 1)}
        />
      </div>
      <div>
        <div className="m-2 flex-wrap d-flex justify-content-between">
            <span className="me-2">Key:</span>
            <div>
            {schedules.map(entry => {
                return <div key={entry._id} className={`badge me-2 bg-${colorMatch[2 + entry.index].variant}`}>{entry.item}</div>
            })}
            <div className={`badge bg-black`}>No School</div>
            </div>
        </div>
        <div className="m-2 d-flex justify-content-between align-items-center mt-3">
            <span className="me-2">First Day of School:</span>
            <DateComponent/>
        </div>
      </div>

      </div>
    );
}

function DateComponent({}) {

    const {focusAgreements, classInformationArray, studentDataFunctions: {setFocusAgreements, updateMongo}} = useContext(SiteContext)

    const [month, setMonth] = useState(() => {
        const date = new Date(focusAgreements.firstDayMilli)
          return date.getMonth() + 1
      })
      const [day, setDay] = useState(() => {
        const date = new Date(focusAgreements.firstDayMilli)
          return date.getDate()
      })
      const [year, setYear] = useState(() => {
        const date = new Date(focusAgreements.firstDayMilli)
          return date.getFullYear()
      })

      useEffect(() => {
          if(!focusAgreements) return
          var exportDate = new Date()
          exportDate.setHours(0)
          exportDate.setMinutes(0)
          exportDate.setSeconds(0)
          exportDate.setMilliseconds(0)
          exportDate.setYear(year)
          exportDate.setMonth(month-1)
          exportDate.setDate(day)

          var exportMilli = exportDate.getTime()
          console.log(exportDate,'exportDate', exportMilli, 'exportMilli', focusAgreements.firstDayMilli-exportMilli)
          if(focusAgreements.firstDayMilli && exportMilli == focusAgreements.firstDayMilli) return

          classInformationArray.map(entry => {
            updateMongo({firstDayMilli: exportMilli}, entry.focusAgreementId, 'focusAgreements')
          })
          console.log(exportMilli,'exportMilli')
          setFocusAgreements({...focusAgreements, firstDayMilli: exportMilli})

      }, [month, day, year, classInformationArray, focusAgreements.firstDayMilli])

      const date = new Date()
      date.setMonth(month - 1)
      date.setDate(day)

      const weekDayNum = date.getDay()
      const weekDays = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday', 'Saturday']
      const weekDay = weekDays[weekDayNum]

    return (
      <div className="d-inline d-flex justify-content-between align-items-center bg-light ps-2 pe-2 rounded">
        <span className="ms-2 me-1">Month:</span>
        <input 
          type="number" name="month" min="1" max="12" className="rounded border border-light border-1 me-2 mt-2 mb-2"
          value={month}
          onChange={e => {
            setMonth(e.target.value)
          }}
          // value={}
        />
        <span className="me-1">Day:</span>
        <input 
          type="number" name="day" min="1" max="31" className="rounded border border-light border-1 mt-2 mb-2"
          value={day}
          onChange={e => {
            setDay(e.target.value)
          }}
          // value={}
        />
        <span className="me-1 ms-1">Year:</span>
        <input 
          type="number" name="year" min="1900" max="2100" className="rounded border border-light border-1 mt-2 mb-2"
          value={year}
          style={{minWidth:"60px"}}
          onChange={e => {
            setYear(e.target.value)
          }}
          // value={}
        />
        <span className="ms-3 me-2 fw-bold">{weekDay} {month}/{day}, {year}</span>
      </div>       
    )
}
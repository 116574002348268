import React from 'react'
import { v4 as uuidv4 } from 'uuid'

export default function AttendanceString({attendanceString, student}) {

    if(!attendanceString) return
    const filter = attendanceString.filter(entry => entry.letter === "A" || entry.letter === "T")
    if(filter.length !== 0) {
      var bgVariant = 'light'
    } else {var bgVariant = 'secondary'}

    // console.log(attendanceString,'attendanceString')

    var count = 0

        
    const attendanceColors = {
      'P': 'success',
      'T': 'warning',
      'A': 'danger',
    } 

    var showToday = false
    var letterToday = student.attendance ? student.attendance.substring(0, 1).toUpperCase() : 'P'
    var letterVariant = attendanceColors[letterToday]
    if(student.loggedIn || student.attendance !== 'Present') {
      showToday = true
    } 

    return (
     <button
      type="button"
      style={{color:"white", fontSize:"10px"}}
      className={`mb-2 me-2 btn-pill btn btn-outline-2x btn-outline-${bgVariant} active`}
      onClick={(e) => e.preventDefault()}
     >
        {attendanceString.length === 0 ? <span>Perfect Attendance</span> : attendanceString.map(entry => {
            count++
            return <span key={count} className={`attendanceString ${entry.variant} ${bgVariant}`} key={uuidv4()}>{entry.letter ? entry.letter : ''}</span>
        })}
        {/* {showToday && <span key={count} className={`attendanceString ${letterVariant} ${bgVariant}`}>{letterToday ? letterToday : ''}</span>} */}
     </button>
    )
}


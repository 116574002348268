import React, { useContext, useState } from 'react'

import { SiteContext } from '../../../App'
import { AdminContext } from '../../../pages/AdminContainer'

export default function PassEntry({pass}) {

    const {studentDataFunctions: {
        handleDateString
    }} = useContext(SiteContext)

    const {updateHallPassEvent, deleteHallPassEvent} = useContext(AdminContext)

    const [time, setTime] = useState({
        minutes: pass.timeComplete && pass.timeComplete.split(':')[0],
        seconds: pass.timeComplete && pass.timeComplete.split(':')[1]
    })

    if(!pass.timeComplete) return

    function updateTime(value) {
        return setTime((prev) => {
          return { ...prev, ...value };
        });
    }

    // console.log(pass, 'pass')

    var differenceString = `${parseInt(pass.timeComplete.split(':')[0]) - parseInt(pass.time)}:${pass.timeComplete.split(':')[1]}`
    var difference = (parseInt(pass.timeComplete.split(':')[0]) + pass.timeComplete.split(':')[1]/60) - parseInt(pass.time)

    // console.log(pass.timeComplete.split(':')[1],'pass.timeComplete.split()[1]', difference)
    var differenceBg = difference > 2.5 ? 'danger' : difference > 1.5 ? 'flag' : difference > 1 ? 'warning' : 'light'

    return (
        <tr className={`${""}`}>
            <td className="">
                {pass.name}
            </td>
            <td className="">
                {handleDateString(pass.timeStamp)}
            </td>
            <td className="">
                {pass.period}
            </td>
            <td className="" style={{width:"30%"}}>
                <span style={{whiteSpace:"initial"}}>
                {pass.purpose}
                {pass.explain.length > 0 && ` - ${pass.explain}`}
                </span> 
            </td>
            <td className="">
                {pass.time}
            </td>
            <td className="">
                {pass.timeComplete}
            </td>
            <td className="">
                <div className={`mb-2 me-2 badge bg-${differenceBg}`}>{differenceString}</div>
            </td>
            <td className="d-flex">
                <input
                type="text" className={`form-control bg-light completedWatch2`}  min={0}  max={60}
                value={time.minutes} style={{width:"60px"}}
                onChange={e => updateTime({ minutes: e.target.value })}
                />
                <span className="m-1">:</span>
                <input
                type="text" className={`form-control bg-light completedWatch2`} min={0}  max={60}
                value={time.seconds} style={{width:"60px"}}
                onChange={e => updateTime({ seconds: e.target.value })}
                />
            {/* <div role="group" className="btn-group btn-group-sm" style={{}}>   
                <button 
                onClick={(e) => updateHallPassEvent(e, time, pass)} 
                className={`btn-shadow btn btn-secondary ${``}`}>
                🔁
                </button>
            </div>   */}
            </td>
            <td className="">  
                <button
                   className="btn btn-primary text-white"
                   onClick={(e) => updateHallPassEvent(e, time, pass)} 
                 >
                     Update
                </button>
            </td>
            <td className="">  
                <button
                   className="btn btn-danger text-white"
                   onClick={(e) => deleteHallPassEvent(e, pass)} 
                 >
                     Delete
                </button>
            </td>
        </tr>
    )
}

// text-center
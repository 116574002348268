import React, {useContext} from 'react'
import { SiteContext } from '../../../App'

export default function Form({hpForm, updateForm}) {
    
    const {studentList, classInformation} = useContext(SiteContext)

    return (
    <form>
        <div className="form-group">
            <label htmlFor="position" className="mb-1">Student:</label>
            <select
            className="form-control"
            className="pa3 bb br3 grow b--none bg-lightest-blue ma3 mb-3"
            style={{display:"block"}}
            value={hpForm.studentId}
            onChange={(e) => {
                if(e.target.value === '-- Select Student --') {return}
                updateForm(e, { studentId: e.target.value })
            }}
            >
            <option>-- Select Student --</option>
            {studentList.map(entry => {
                if(entry.period !== classInformation.period) return
                return <option key={entry.studentId} value={entry.studentId}>{entry.name}</option>
            })}
            </select>
            <label htmlFor="position" className="mb-1">Hall Pass Purpose:</label>
            <select
            className="form-control"
            className="pa3 bb br3 grow b--none bg-lightest-blue ma3 mb-3"
            style={{display:"block"}}
            value={hpForm.purposeOption}
            onChange={(e) => {
                if(e.target.value === '-- Select Class Role --') {return}
                updateForm(e, { purposeOption: e.target.value })
            }}
            >
            <option>-- Select Hall Pass Purpose --</option>
            <option>Bathroom</option>
            <option>Locker</option>
            <option>Front Office</option>
            <option>Library</option>
            <option>Counselor</option>
            <option>Talk to Adult</option>
            <option>Another Class</option>
            <option>Water</option>
            <option>Other</option>
            </select>

            {hpForm.purposeOption === 'Other' && <div className="form-group edit-row" style={{marginTop:"10px"}}>
                <label htmlFor="name">Other:</label>
                <input
                type="text"
                className="form-control"
                // id="name"
                value={hpForm.purpose}
                onChange={(e) => updateForm(e, { purpose: e.target.value })}
                />
            </div>} 
        </div>
        <label htmlFor="position" style={{marginBottom:"5px"}}>How much time do you need?</label><br/>

        <TimeOptions hpForm={hpForm} updateForm={updateForm} />
        {hpForm.moreTime === true && <div className="form-group edit-row" style={{display:"inline"}}>
                <label htmlFor="name" style={{paddingRight:"5px"}}>Time Needed:</label>
                <input
                type="number"
                className="form-control"
                min={4}
                max={50}
                style={{width:"15%",display:"inline"}}
                // id="name"
                value={hpForm.time}
                onChange={(e) => updateForm(e, { time: parseInt(e.target.value) })}
                />
        </div>}
        {hpForm.moreTime === true && <div className="form-group" style={{marginTop:"10px"}}>
                <label htmlFor="name" style={{marginBottom:"5px"}}>Reason for {hpForm.time} minutes:</label>
                <input
                type="text"
                className="form-control"
                value={hpForm.explain}
                // onChange={(e) => updateFormExplain({ explain: e.target.value })}
                onChange={(e) => updateForm(e, { explain: e.target.value })}
                />
            </div>} 
        <div className="form-group mt-2">
            <label htmlFor="name" className="d-block mb-1">Start Immediately?</label>
            <div className={`mb-2 me-2 badge ${hpForm.approved ? 'bg-secondary text-white' : 'bg-white'}`} style={{border:"2px solid #6c757d", color:"#6c757d"}} role="button"
              onClick={e => updateForm(e, { approved: true })}
            >Yes</div>
            <div className={`mb-2 me-2 badge ${!hpForm.approved ? 'bg-secondary text-white' : 'bg-white'}`} style={{border:"2px solid #6c757d", color:"#6c757d"}} role="button"
              onClick={e => updateForm(e, { approved: false })}
            >No</div>
         </div>
         <div className="form-group mt-2">
            <label htmlFor="name" className="d-block mb-1">Allow Simultaneous?</label>
            <div className={`mb-2 me-2 badge ${hpForm.allowSimultaneous ? 'bg-secondary text-white' : 'bg-white'}`} style={{border:"2px solid #6c757d", color:"#6c757d"}} role="button"
              onClick={e => updateForm(e, { allowSimultaneous: true })}
            >Yes</div>
            <div className={`mb-2 me-2 badge ${!hpForm.allowSimultaneous ? 'bg-secondary text-white' : 'bg-white'}`} style={{border:"2px solid #6c757d", color:"#6c757d"}} role="button"
              onClick={e => updateForm(e, { allowSimultaneous: false })}
            >No</div>
         </div>
        </form>

    )
}


function TimeOptions({hpForm, updateForm}){

    const bgColors = [
      '', '', '', ''
    ]
  
    var index = hpForm.time - 1
    if(hpForm.time > 4) {
      index = 3
    }
    bgColors[index] = 'bg-secondary text-white'
  
      return (
        <>
          <div className={`mb-2 me-2 badge ${bgColors[0]}`} style={{border:"2px solid #6c757d", color:"#6c757d"}} role="button"
            onClick={e => updateForm(e, { moreTime: false, time: 1 })}
          >1 Min</div>
          <div className={`mb-2 me-2 badge ${bgColors[1]}`} style={{border:"2px solid #6c757d", color:"#6c757d"}} role="button"
            onClick={e => updateForm(e, { moreTime: false, time: 2 })}
          >2 Min</div>
            <div className={`mb-2 me-2 badge ${bgColors[2]}`} style={{border:"2px solid #6c757d", color:"#6c757d"}} role="button"
            onClick={e => updateForm(e, { moreTime: false, time: 3 })}
          >3 Min</div>
          <div className={`mb-2 me-2 badge ${bgColors[3]}`} style={{border:"2px solid #6c757d", color:"#6c757d"}} role="button"
            onClick={e => updateForm(e, { moreTime: true, time: 4 })}
          >Over 3 Min</div>
          </>
      )
  }
import React, {useContext, useState, useEffect } from 'react'
import { useStopwatchLocal } from '../Stopwatch'
import { SiteContext } from '../../App'
import { StudentContext } from '../../pages/StudentContainer'

import {useTenMinRule} from '../TenMinRule'

import ActiveModal from './modal/ActiveModal'

export default function ActiveHP({event}) {
    
    const [show, setShow] = useState(() => {
        if(event.approved && event.active) return true
        else return false
    })

    useEffect(() => {
        if(event.approved && event.active) {
            setShow(true)
        } else {
            setShow(false)
        }
    }, [event])
    
    var minutesInt = event.timeComplete ? parseInt(event.timeComplete.split(':')[0]) : 0
    var bgBadge = minutesInt > event.time + 1 ? 'danger' : 
                  minutesInt > event.time ? 'flag' :
                  minutesInt == event.time ? 'warning' : 'secondary'

    return (<>
    {event.approved && event.active ?
    <ActivePassInformation event={event} show={show} /> :
    event.active && !event.approved ?
    <PendingPassInformation event={event} show={show} /> :
    !event.active && !event.closed ? 
    <CompletePassInformation event={event} bgBadge={bgBadge}/> : <></>}
    </>)
}

function PassInformation({event, bgBadge}) {
    return (
        <>
        <div className={`mb-2 me-2 badge bg-${bgBadge}`} style={{marginTop:"5px", marginLeft:"20px"}}>
            <span>{event.purpose}</span>
        </div>
        <div className={`mb-2 me-2 badge bg-${bgBadge}`} style={{marginTop:"5px"}}>
            <span>Time: {event.time}:00</span>
        </div>  
        </>      
    )
}

function PendingPassInformation({event}) {

    const {
        socket, room, classInformation, student,
        studentDataFunctions: {
        deleteMongo
    }} = useContext(SiteContext)
    const {activePass, setHpEvents, updateExistingEvent} = useContext(StudentContext)

    const tenMinRule = useTenMinRule()

    function handleCancel(e) {
        e.preventDefault()
        console.log('canceling')
        deleteMongo(event._id, 'hallPassEvents')
        console.log('canceling')
        socket.emit("send_hallPass_update", { updatedObj: event, type: 'remove', changeValue: {}, room });
        updateExistingEvent(event, 'remove', {})
    }

    if(!activePass && !tenMinRule) {
        return (
            <>
            <PassInformation event={event} bgBadge={'light'} />
            <div className={`mb-2 me-2 badge bg-info`} style={{marginTop:"5px"}}
            onClick={e => handleCancel(e)} role="button"
            >
                X
            </div>
            </>
        )
    } else {
        return (
            <div className={`mb-2 me-2 badge bg-secondary`} style={{marginTop:"5px"}}
            onClick={e => handleCancel(e)} role="button"
            >
                X
            </div>
        )
    }
}



function ActivePassInformation ({event, show}) {

    const {socket, room, studentDataFunctions: {updateMongo}} = useContext(SiteContext)
    const {setHpEvents, updateExistingEvent} = useContext(StudentContext)

    const [running, setRunning] = useState(false);

    useEffect(() => {
        if(event.active && event.approved) {
            setRunning(true)
        }
    }, [])

    const {minutes, seconds} = useStopwatchLocal(event.approvedTimeStamp, running)
    var minutesInt = parseInt(minutes)

    if(minutesInt > event.time + 1) {
        var bgBadge = 'danger'
    }else if(minutesInt > event.time) {
        var bgBadge = 'flag'
    } else if(minutesInt == event.time) {
        var bgBadge = 'warning'
    } else { var bgBadge = 'secondary'}

    function triggerEnd(e) {
        e.preventDefault()
        endHallPassEvent()
    }

    function endHallPassEvent() {
        // e.preventDefault()

        console.log('running')
        const timeComplete = `${minutes}:${seconds}`

        const updatedObj = {...event, active: false, timeComplete} 
            delete updatedObj["_id"];

            console.log(updatedObj,'updatedObj',event._id,'id', event,'event')

        updateMongo(updatedObj, event._id, 'hallPassEvents')
        updateExistingEvent(updatedObj, 'update', {active: false, timeComplete})

        socket.emit("send_hallPass_update", { updatedObj, type: 'update', changeValue: {active: false, timeComplete}, room });

        setRunning(false)
    }

    return (
        <>
        <ActiveModal event={event} time={`${minutes}:${seconds}`} bgBadge={bgBadge} show={show} endHallPassEvent={endHallPassEvent} />
        <div className={`mb-2 me-2 badge bg-${bgBadge}`} style={{marginTop:"5px"}}>
            <div className="numbers">
                {/* <span>Time: </span> */}
                <span>{minutes}:</span>
                <span>{seconds}</span>
            </div>
        </div>
        <div 
          onClick={e => triggerEnd(e)}
          className={`mb-2 me-2 badge bg-info`} style={{marginTop:"5px"}}
        >
            <span>End</span>
        </div>
        </>
    )
}

function CompletePassInformation({event, bgBadge}) {

    const {socket, room, studentDataFunctions: {updateMongo}} = useContext(SiteContext)
    const {setHpEvents, updateExistingEvent} = useContext(StudentContext)

    function hideHallPassEvent(e) {
        e.preventDefault()

        const id = event._id

        const updatedObj = {...event, closed: true} 
            delete updatedObj["_id"];

        updateMongo(updatedObj, id, 'hallPassEvents')
        updateExistingEvent(event, 'update', {closed: true})
        updateExistingEvent(event, 'remove', {})
    }

    return (
        <>
        <div className={`mb-2 me-2 badge bg-${bgBadge}`} style={{marginTop:"5px"}}>
            <div className="numbers">
                <span>Total Time: {event.timeComplete}</span>
            </div>
        </div>
        <div 
          onClick={e => hideHallPassEvent(e)}
          className={`mb-2 me-2 badge bg-secondary`} style={{marginTop:"5px"}}
        >
            <span>X</span>
        </div>
        </>
    )
}


    // if(minutesInt > event.time + 1) {
    //     var bgBadge = 'danger'
    // }else if(minutesInt > event.time) {
    //     var bgBadge = 'flag'
    // } else if(minutesInt == event.time) {
    //     var bgBadge = 'warning'
    // } else { var bgBadge = 'secondary'}
import React, { useState, useEffect, useContext } from "react";

import Form from "./Form";
import Modal from 'react-bootstrap/Modal';

import { SiteContext } from "../../../App";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary"; 

export default function HPModal({handleShow, handleClose, show, type, payload}) {

    const {mode, title, modalMode, handleActive} = payload

    const {
        student, initialLoad, classInformation, socket, studentList,
        studentDataFunctions: {
          getMongoList, updateMongo, setStudentList, createMongo
        }
    } = useContext(SiteContext)

    const [hpForm, setHpForm] = useState(blankForm)

    function updateForm(e, value) {
      e.preventDefault()
      const editHpForm = {...hpForm, ...value}

      const keys = Object.keys(value)
      // console.log(keys,'keys', value)
      if(keys[0] === 'purposeOption') {
        if(keys[0] !== '-- Select Class Role --' && keys[0] !== 'Other') {
          editHpForm.purpose = value[keys[0]]
        }
      }
      setHpForm(editHpForm)
    }

    function submitForm(e) {
      e.preventDefault()

      var index = studentList.findIndex(entry => entry.studentId === hpForm.studentId)
      if(index === -1) return
      const newEvent = {...hpForm,
        name: studentList[index].name,
        period: studentList[index].period,
        timeStamp: Date.now(),
        active: true,
        approvedTimeStamp: Date.now(),
        closed: false
      }

      createMongo(newEvent, 'hallPassEvents')
      console.log(newEvent,'newEvent')

      setHpForm(blankForm)
      handleCloseLocal()
    }

    function handleCloseLocal() {
        handleClose(type)
    }
    
 return (
  <ErrorBoundary>    
    <div
      onKeyDown={e => e.stopPropagation()}
    >
    <Modal 
      show={show} 
      onHide={handleCloseLocal}
    //   dialogClassName="my-modal"
    >
      <Modal.Header>
        <Modal.Title>{payload && title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form hpForm={hpForm} updateForm={updateForm} />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={e => submitForm(e)}>Submit</button>
      </Modal.Footer>
    </Modal>
    </div>
  </ErrorBoundary>
  
 );
}


const blankForm = {
  name: '',
  purpose: 'Bathroom',
  purposeOption: 'Bathroom',
  time: '3',
  moreTime: '',
  reason: '',
  explain: '',
  approved: true,
  allowSimultaneous: false,
}



import React, { useContext, useEffect, useState } from 'react'

import { SiteContext, baseUrl } from '../App';

import Student from './Student';
import GenInfo from './GenInfo';
import Store from './Store';
import Reflection from '../weekReview/reflection/Reflection';
import WeekReview from '../weekReview/WeekReview';
import SInventory from '../modules/inventory/student/SInventory'
import StudentHeader from '../header/StudentHeader'
import Checklist from '../modules/checklist/student/SChecklist';
import Preview from '../student/preview/Preview';
import Profile from '../student/Profile'
import SHallPass from '../hallPass/student/SHallPass';
import InProgress from '../student/studentCards/InProgress';
import ColorReminder from '../student/ColorReminder';

import { useSocket } from '../files/student/socket';
import { useSHallPass } from '../files/student/sHallPass';
import { useStore } from '../files/student/store';
import { useBehavior } from '../files/student/behavior';
import { useMongo } from '../files/student/mongo';
import { useModal } from '../files/student/modal';
import { useChecklist } from '../files/app/checklist';
import { useIdle } from '../files/student/idle';

import { ErrorBoundary } from '../other/utils/ErrorBoundary';
const LOCAL_STORAGE_KEY_LIST = 'classWebsite.namesList'

export const StudentContext = React.createContext()

export default function StudentContainer({ type, text }) {

    const {
        student, classInformation, socket, focusAgreements, room, show, modalShowFunction,
        studentDataFunctions: {
            setStudent, getMongoById, setUpdateFocusAgreements, useCreateStorageState, setShow, handleShow, handleClose
        }
    } = useContext(SiteContext)

    const [namesList, setNamesList] = useState(useCreateStorageState(LOCAL_STORAGE_KEY_LIST, []))
    const [nominatedName, setNominatedName] = useState('')
    // const [show, setShow] = useState(modalShowFunction());
    const [update, setUpdate] = useState(0)

    useMongo({setNamesList})

    const { hpEvents, setHpEvents, updateEvents, setUpdateEvents, updateExistingEvent, activePass, getHallPasses } 
    = useSHallPass()

    const { orders, setOrders, activeChips, setActiveChips, pendingChips, updateChips, setUpdateChips } 
    = useStore()

    const {updateStudent, setUpdateStudent, connected} 
    = useSocket({setUpdateChips, setOrders, namesList, setNamesList}) /// handle socket functions and effects
    
    const { reviewEntries } 
    = useBehavior()

    // const {}
    // = useIdle()

    const { 
        // handleClose, handleShow, 
        handleStudentOverride, currentEntry }
    = useModal({show, setShow, setUpdate})

    const studentVariable = { 
        hpEvents, setHpEvents, updateEvents, setUpdateEvents, updateExistingEvent, activePass, 
        orders, setOrders, activeChips, setActiveChips, pendingChips, updateChips, setUpdateChips,
        reviewEntries, namesList, updateStudent, setUpdateStudent, handleClose, handleShow, nominatedName, setNominatedName,
        connected, currentEntry, getHallPasses
    }

    useEffect(() => {
        document.title = process.env.NODE_ENV === 'development' && text ? 'D ' + text : document.title
    }, [type])
    
    return (
      <StudentContext.Provider value={studentVariable}>
        <ErrorBoundary>
          <ColorReminder/>
          <div className="app-container app-theme-white body-tabs-shadow">
            <ErrorBoundary>
              <StudentHeader studentName={student.name} period handleClose={handleClose} handleShow={handleShow} handleStudentOverride={handleStudentOverride} show={show} update={update} type={type} />
            </ErrorBoundary>
            <div className="app-main">
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <ErrorBoundary>
                        {useNetwork() === false ? <NoConnection/> : <>
                        {/* {type !== 'checklistS' && <InProgress />} */}
                        <SHallPass type={'header'}/>
                        {type === 'student' && <Student/>}
                        {type === 'genInfo' && <GenInfo/>}
                        {type === 'store' && <Store/>}
                        {type === 'reflection' && <Reflection/>}
                        {type === 'colorReview' && <WeekReview/>}
                        {type === 'inventoryS' && <SInventory/>}
                        {type === 'checklistS' && <Checklist/>}
                        {type === 'preview' && <Preview/>}
                        {type === 'profile' && <Profile/>}
                        </>}
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
          </div>
        </ErrorBoundary>   
      </StudentContext.Provider>   

    )
}

function useNetwork() {
    const [isOnline, setNetwork] = useState(window.navigator.onLine);
  
    const updateNetwork = () => {
      setNetwork(window.navigator.onLine);
    };
  
    useEffect(() => {
      window.addEventListener("offline", updateNetwork);
  
      window.addEventListener("online", updateNetwork);
  
      return () => {
        window.removeEventListener("offline", updateNetwork);
  
        window.removeEventListener("online", updateNetwork);
      };
    });
  
    return isOnline;
  }

  function NoConnection({}) {
      return <div>
          <div className="badge bg-secondary">No Internet Connection</div>
      </div>
  }
import React, { useContext, useState, useEffect, createContext } from 'react'
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import Header from './Header'
import ReviewCard from './ReviewCard'

import { SiteContext, baseUrl } from '../App'
import { ErrorBoundary } from '../other/utils/ErrorBoundary'

import SummaryChart from './SummaryChart'
import GradeChart from './GradeChart'
import AgreementsChart from './AgreementsChart'
import ReviewModal from './ReviewModal'
import EventCard from './reflection/EventCard';
import AllEventCard from './reflection/AllEventCard';
import ModuleCompletions from './reflection/ModuleCompletions';
import Profile from '../student/Profile';

export default function WeekReview({}) {

    const { 
      student, classInformation, colorMatch, reviewEntries, loginState, reviewStudent, studentList, randomStudentIndex,
      studentDataFunctions: {
        calculateTimeElapsed, getMongoAllById, getMongoById, setReviewStudent
      }
    } = useContext(SiteContext)

    let location = useLocation();

    const [showReviewModal, setShowReviewModal] = useState(false);
    // const [updateAddChips, setUpdateAddChips] = useState(false)
    const [periodBg, setPeriodBg] = useState(blankBg)
    const [filterField, setFilterField] = useState("Colors Review");
    const [filterMilli, setFilterMilli] = useState(() => {
        const date = new Date()
        var match = blankBg.filter(entry => entry.period === filterField)[0]
            date.setDate(date.getDate() - match.days)
            date.setHours(1,0,0)
            return date.getTime()
    })

    const [currentEntry, setCurrentEntry] = useState({})

    const handleReviewClose = () => setShowReviewModal(false);
    const handleReviewShow = () => setShowReviewModal(true);
 
    function handleReviewModal(e, type, entry) {
        e.preventDefault()

        if(type === 'open') {
            if(entry.attendance === 'Absent') {return}
            handleReviewShow()
            setCurrentEntry(entry)
        } else if(type === 'close') {
            handleReviewClose()
        }
    }

    useEffect(() => {
        const editPeriodBg = [...periodBg]
        editPeriodBg.map(entry => {
            entry.bg = 'light'
        })
        var index = editPeriodBg.findIndex(entry => entry.period === filterField)
            if(index === -1) return
            editPeriodBg[index].bg = 'primary'
            setPeriodBg(editPeriodBg)

        const date = new Date()
            var match = blankBg.filter(entry => entry.period === filterField)[0]
                date.setDate(date.getDate() - match.days)
                date.setHours(1,0,0)

        setFilterMilli(date.getTime())

    }, [filterField])

    function getException() {
        const date = new Date()
    
        var match = blankBg.filter(entry => entry.period === filterField)[0]
        var filterException = filterField === 'Colors Review' ? 14 : match.days
        date.setDate(date.getDate() - filterException)
        date.setHours(1,0,0)
        return date.getTime()
    }


    var sortedEntries = [...reviewEntries].sort( compare );
    const filteredReviewEntriesBefore = sortedEntries.filter(event => event.timeStamp > filterMilli);
    const filteredReviewEntriesChart = sortedEntries.filter(event => event.timeStamp > getException());

    var today = new Date()
    today.setHours(0)
    today.setMinutes(0)

    var filteredReviewEntries = filterField === 'Colors Review' ? filteredReviewEntriesBefore.filter(entry => entry.timeStamp < today.getTime()) : filteredReviewEntriesBefore
    // var match = blankBg.filter(entry => entry.period === filterField)[0]
    // console.log(match.days,'match.days')
    // sortedEntries.length = match.days
    // const filteredReviewEntries = [...sortedEntries]

    var allChangesBefore = []

    const variants = [
        {variant: 'primary', category: [], hex: '#3f6ad8'},
        {variant: 'info', category: [], hex: '#16aaff'},
        {variant: 'purple', category: [], hex: '#794c8a'},
        {variant: 'warning', category: [], hex: '#f7b924'},
        {variant: 'danger', category: [], hex: '#d92550'},
        {variant: 'success', category: [], hex: '#3ac47d'},
        {variant: 'black', category: [], hex: '#343a40'},
        {variant: 'secondary', category: [], hex: '#6c757d'},
        {variant: 'warning', category: [], hex: '#f7b924'},
    ]

    var countVariant = 0

    // console.log(colorMatch,'colorMatch')
    filteredReviewEntries.map(entry => {

        entry.colorChanges.map(item => {
            // console.log(item,'item')
            item.dateString = entry.dateString
        })
        allChangesBefore = [...allChangesBefore, ...entry.colorChanges]
    })

    // console.log(allChangesBefore,'allChangesBefore')
    const allChanges = allChangesBefore.filter(entry => entry.levelChange > 0)

    allChanges.map(entry => {
        // console.log(entry,'entry')
        var index = variants.findIndex(item => item.category.includes(entry.category))
        if(index === -1) {
            if(entry.category.length === 0) return
            variants[countVariant].category.push(entry.category)
            entry.categoryVariant = variants[countVariant].variant
            countVariant++
            if(countVariant > 8) {countVariant = 0}
        } else {
            entry.categoryVariant = variants[index].variant
        }
    })

    const variantsExport = []
    variants.map(entry => {
        entry.category.map(item => {
            variantsExport.push({variant: entry.variant, category: item, hex: entry.hex})
        })
    })

    var count = 0

    var selectedStudent = (location.pathname === '/colorReview' && loginState && loginState.admin) ? studentList[randomStudentIndex] : student

    // console.log(filteredReviewEntries,'filteredReviewEntries')

    var count = 0
    var currentDate = new Date(Date.now())
    const day = currentDate.getDay()
        // currentDate.setDay(0)
        currentDate.setHours(0)
        currentDate.setMinutes(0)
        currentDate.setSeconds(0)
    
    var currentMilli = currentDate.getTime() - day*86400000

    // console.log(currentMilli,'currentMilli', new Date(currentMilli), day)

    return (
        <ErrorBoundary>
        <div className={`row badgeEvents ${!loginState.admin && `sticky-top`}`}>
            {/* <Filter title={'Filter'} colorValue={'info'} sizeClass={'col-sm-12'}/> */}
            <Header title={'Colors Review'} colorValue={'primary'} sizeClass={'col-sm-12'} periodBg={periodBg} setFilterField={setFilterField} numEntries={filteredReviewEntries.length}/> 
        </div> 
        <div className="row">
            <div className="col-12 col-sm-6">
                <div className="row">
                {filteredReviewEntries.map(entry => {
                    // console.log(entry,'entry')
                    count++
                    if(entry.timeStamp < currentMilli + 7*86400000) {
                        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                        
                        var weeks = 0
                        for(let i = 0; i < 52; i++){
                            if(entry.timeStamp < (currentMilli + 7*86400000 - 7*i*86400000)) {
                                weeks++
                            } else {
                                break
                            }
                        }
                        currentMilli = currentMilli - 604800000*weeks
                        console.log('here')

                        var milliDate = new Date(currentMilli + 86400000 + 7*86400000)
                            var month = milliDate.getMonth()
                            var date = milliDate.getDate()
                            var year = milliDate.getFullYear()

                        var fridayDate = new Date(currentMilli + 86400000*5 + 7*86400000)
                            var monthFriday = fridayDate.getMonth()
                            var dateFriday = fridayDate.getDate()
                            var yearFriday = fridayDate.getFullYear()

                        var dateToday = new Date()
                            var yearToday = dateToday.getFullYear()

                        var activities = []
                        student.moduleCompletions && student.moduleCompletions.map(entry => {
                            if(entry.completeEvent && entry.completeEvent.date > currentMilli && entry.completeEvent.date < currentMilli + 7*86400000) {
                                activities.push(entry)
                            }
                        })

                        const importFunctions = {year, yearToday, yearFriday, activities, selectedStudent, variantsExport, date: `${months[month]} ${date}`}
                        return (
                            <>
                                <div>
                                <div className="card bg-secondary mb-3 fw-bold text-white ps-2 pe-2" style={{}}>
                                    <div className="d-flex justify-content-between">
                                    <span>Week of {months[month]} {date}-{month !== monthFriday && `${months[monthFriday]} `}{dateFriday}</span>
                                    <WeekModuleCompletions importFunctions={importFunctions} />
                                    </div>
                                </div>
                                </div>
                            <ReviewCard title={'Mon 10/25'} key={count} subtitle={'Click to Expand'} colorValue={entry.color} sizeClass={'col-sm-6 col-md-4'} entry={entry}
                            showReviewModal={showReviewModal} handleReviewModal={handleReviewModal} handleReviewClose={handleReviewClose} currentEntry={currentEntry} />
                            </>
                        )
                    } else {
                    return (
                        
                        <ReviewCard title={'Mon 10/25'} key={count} subtitle={'Click to Expand'} colorValue={entry.color} sizeClass={'col-sm-6 col-md-4'} entry={entry}
                            showReviewModal={showReviewModal} handleReviewModal={handleReviewModal} handleReviewClose={handleReviewClose} currentEntry={currentEntry} />
                    )
                }
                })}
                <ReviewModal showReviewModal={showReviewModal} handleReviewModal={handleReviewModal} handleReviewClose={handleReviewClose} currentEntry={currentEntry} variantsExport={variantsExport} />
                </div>
                {/* {filterField === 'Colors Review' && <div className="mt-4">
                    <AllEventCard title={'Smiley'} subtitle={'Check-In'} colorValue={'primary'} sizeClass={'col-sm-12'} colorChanges={allChanges} />
                </div>} */}

            </div>
            {(!loginState.admin || reviewStudent || location.pathname === '/colorReview') && <div className="col-12 col-sm-6">
               {filterField === 'Colors Review' && <ValuesCard selectedStudent={selectedStudent} sizeClass={'col-sm-12'} />}
               <GradeChart title={'Average Color & Grade by Day'} colorValue={'info'} sizeClass={'col-sm-12'} filteredReviewEntries={filteredReviewEntriesChart} />
               <ModuleCompletions colorValue={'primary'} selectedStudent={selectedStudent} sizeClass={'col-sm-12'} filteredReviewEntries={allChanges} type={'attendance'} variants={variantsExport} />
               {/* {filterField === 'Colors Review' &&  */}               
               {filterField !== 'Colors Review' && <SummaryChart title={'Community Colors'} colorValue={'info'} sizeClass={'col-sm-12'} filteredReviewEntries={filteredReviewEntries} type={'colors'} />}
               {filterField !== 'Colors Review' && <SummaryChart title={'Attendance'} colorValue={'primary'} sizeClass={'col-sm-12'} filteredReviewEntries={filteredReviewEntries} type={'attendance'} />}
               <AgreementsChart title={'Agreement Categories'} colorValue={'primary'} sizeClass={'col-sm-12'} filteredReviewEntries={allChanges} type={'attendance'} variants={variantsExport} />
               {filterField === 'Colors Review' && <AllEventCard title={'Smiley'} subtitle={'Check-In'} colorValue={'primary'} sizeClass={'col-sm-12'} colorChanges={allChanges} />}
               {selectedStudent && selectedStudent.values && selectedStudent.values.length < 5 && <Profile valuesOnly={true} />}
            </div>}
        </div>
        </ErrorBoundary>
    )
}

function ValuesCard({sizeClass, selectedStudent}) {
    const {student} = useContext(SiteContext)

    // if(!student.values || student.values.length === 0) return

    var count = 0
    return (
        <div className={sizeClass}>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="fw-bold m-1">Values</div>
                    {(!selectedStudent || !selectedStudent.values || selectedStudent.values.length === 0) ? <span className="m-1">Add Values Below</span> :
                        <>
                        <div className="d-flex flex-wrap">
                            {selectedStudent.values.map(entry => {
                                count++
                                return <div key={count} className="badge bg-light m-1 text-wrap">
                                    {count}. {entry.keyValue}
                                    </div>
                            })}
                        </div>
                        </>}
                </div>
            </div>
        </div>
    )
}

function WeekModuleCompletions({importFunctions}) {
    
    const {year, yearToday, yearFriday, activities, date, selectedStudent, variantsExport} = importFunctions

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <span className="" onClick={activities.length > 0 && handleShow} role={`${activities.length > 0 && `button`}`}>
            {(year !== yearToday || yearFriday !== yearToday) && year !== yearFriday ? `${year}-${yearFriday}` : (year !== yearToday) ? `${year}` : `${activities.length} ${activities.length !== 1 ? 'Activities' : 'Activity'}`}
        </span>  
  
        <Modal show={show} onHide={handleClose} closeButton>
          {/* <Modal.Header closeButton>
          </Modal.Header> */}
          {/* <Modal.Body closeButton> */}
          <ModuleCompletions colorValue={'primary'} selectedStudent={selectedStudent} sizeClass={'col-sm-12'} moduleCompletionsImport={activities} type={'attendance'} variants={variantsExport} />
          {/* </Modal.Body> */}
        </Modal>
      </>
    );
  }
const blankBg = [
    {period: 'Today', bg: 'light', days: 0},
    {period: '5 Days', bg: 'light', days: 5},
    {period: '10 Days', bg: 'light', days: 14},
    {period: '30 Days', bg: 'light', days: 36},
    {period: 'All Time', bg: 'light', days: 500},
    {period: 'Colors Review', bg: 'light', days: 7},
]

function compare( a, b ) {
    if ( a.timeStamp > b.timeStamp){
      return -1;
    }
    if ( a.timeStamp < b.timeStamp ){
      return 1;
    }
    return 0;
}

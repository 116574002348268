import React, {useEffect, useState} from 'react'

import { faCircleExclamation, faMagnifyingGlass, faWarning, faCalendar, faRecycle } from '@fortawesome/free-solid-svg-icons';

export function useInventory({getMongoList, getMongoAllByParam, student, updateMongo, socket, room, classInformation, moduleField, stringtoJSDate, loginState}) {

  // const moduleInit = moduleInitFunction()
  
    // const [moduleField, setModuleField] = useState('')
    // const [moduleField, setModuleField] = useState((student && student.module && !loginState.admin) ? moduleFind(student.module, 'title', 'fullTitle') : '')
    const [moduleItems, setModuleItems] = useState([])

    useEffect(() => {

        async function getItems() {
    
          // console.log(moduleField,'moduleField')
            if(moduleField === 'All') {
              const inventoryItems = await getMongoList('inventoryItems')
              if(!inventoryItems) return
    
              inventoryItems.map(entry => {
                entry.badges = !entry.badges ? badgesFunction() : entry.badges
                entry.daily = !entry.daily ? dailyFunction() : entry.daily
              })
      
              setModuleItems(inventoryItems)
              return
            }

            if(!moduleField || moduleField.length === 0) return
            const inventoryItems = await getMongoAllByParam(moduleField, 'inventoryItems', 'module')
            if(!inventoryItems) return
    
            inventoryItems.map(entry => {
              entry.badges = !entry.badges ? badgesFunction() : entry.badges
              entry.daily = !entry.daily ? dailyFunction() : entry.daily
            })
    
            setModuleItems(inventoryItems)
        }
    
        getItems()

        let interval;
        interval = setInterval(() => {
    
            if(loginState && !loginState.admin) return
            var {date, milli: milliEnd} = stringtoJSDate(classInformation.classEnd)
            var {dateStart, milli: milliStart} = stringtoJSDate(classInformation.classStart)
    
            if(milliStart > Date.now() || milliEnd < Date.now() || classInformation.dayType === 'No School') return
            console.log('update focusAgreements')
            getItems()
            
        }, 60000);
    
    }, [moduleField, student && student.module])

    function handleUpdateCheckout(entry, student) {

      var checkedOut = !entry.checkedOut

      const editModuleItems = [...moduleItems]
      var index = editModuleItems.findIndex(item => item._id === entry._id)
      if(index === -1) return
      editModuleItems[index].checkedOut = checkedOut

      const event = {
        period: classInformation.period,
        name: student.name,
        studentId: student.studentId,
        timeStamp: Date.now()
      }

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); 
      var yyyy = today.getFullYear();

      today = `month${mm}day${dd}`

      if(checkedOut === true) {
        const obj = {checkedOut, checkoutEvent: {...event, adminProcessed: false}, checkoutTimestamp: today}
          editModuleItems[index] = {...editModuleItems[index], ...obj}
          updateMongo(obj, entry._id, 'inventoryItems')
          socket.emit("send_checkoutEvent", { data: editModuleItems[index], room });
          // console.log('here checkOut',checkedOut, editModuleItems[index])
      } else {
        const obj = {checkedOut, checkinEvent: event}
          editModuleItems[index] = {...editModuleItems[index], ...obj}
          updateMongo(obj, entry._id, 'inventoryItems')
          socket.emit("send_checkoutEvent", { data: editModuleItems[index], room });
          // console.log('here checkIn', entry._id, '_id', obj)
      }

      setModuleItems(editModuleItems)
    }
  
    const dailyFunction = () => {
        const days = [1,2,3,4,5,6,7,8]
        const daily = []
        days.map(entry => {
          daily.push({text: `Day ${entry}`, variant: 'light', logo: faCalendar, action: () => {}, active: false })
        })
        return [...daily]
      }
      
      const badgesFunction = () => {
        return [
          {text: 'Careful', variant: 'purple', logo: faWarning, action: () => {}, active: false },
          {text: 'Teacher', variant: 'danger', logo: faCircleExclamation,  action: () => {}, active: false },
          {text: 'Review', variant: 'info', logo: faMagnifyingGlass,  action: () => {}, active: false },
          {text: 'Always Out', variant: 'success', logo: faMagnifyingGlass,  action: () => {}, active: false },
          {text: 'Out of Stock', variant: 'flag', logo: faRecycle,  action: () => {}, active: false },
        ]
      }


    const storage = {
        storage1: [],
        storage2: [],
        storage3: [],
        storage4: []
    }

    for (let i = 1; i <= 22; i++) {
        storage.storage1.push('Drawer ' + i)
    }

    for (let i = 1; i <= 19; i++) {
        storage.storage1.push('Cabinet ' + i)
    }

    for (let i = 1; i <= 4; i++) {
        storage.storage1.push('Cart ' + i)
        storage.storage1.push('Wall Magnet ' + i)
    }

    for (let i = 1; i <= 3; i++) {
        storage.storage2.push('Drawer ' + i)
        storage.storage2.push('Container ' + i)
        storage.storage2.push('Shelf ' + i)

        storage.storage3.push('Container ' + i)
    }

    for (let i = 1; i <= 20; i++) {
        storage.storage2.push('Slot ' + i)
        storage.storage3.push('Slot ' + i)
    }

    for (let i = 1; i <= 20; i++) {
        storage.storage4.push('Slot ' + i)
    }

    storage.storage1.push('Back Closet')
    storage.storage1.push('Front of Room')
    storage.storage1.push('Back Sink Area')

    storage.storage2.push('Loose')
    storage.storage2.push('Bottom')

    storage.storage3.push('Loose')
    storage.storage3.push('Left')
    storage.storage3.push('Middle')
    storage.storage3.push('Right')

    storage.storage4.push('Loose')
    storage.storage4.push('Left')
    storage.storage4.push('Middle')
    storage.storage4.push('Right')

    // var modules = moduleInit.sort((a, b) => a.fullTitle.localeCompare(b.fullTitle))

    return {
      //  modules, 
       storage, 
      //  moduleFind, 
       dailyFunction, badgesFunction,
       moduleItems, setModuleItems, 
      //  moduleField, setModuleField, 
       handleUpdateCheckout
    }
}



// function moduleInitFunction() {
//   return [ 
//     {title: 'Culinary', variant: 'purple', fullTitle: 'INTRO TO CULINARY ARTS', cleanupItems: []}, 
//     {title: 'Alternative Energy', variant: 'maroon', fullTitle: 'ALTERNATIVE ENERGY'}, 
//     {title: 'Structural Eng', variant: 'purple', fullTitle: 'STRUCTURAL ENGINEERING'},
//     {title: 'Material Sci', variant: 'indigo', fullTitle: 'MATERIALS PROCESSING & DESIGN'}, 
//     {title: 'Home Maintenance', variant: 'indigo', fullTitle: 'HOME MAINTENANCE FUNDAMENTALS'}, 
//     {title: 'Energy & Power', variant: 'indigo', fullTitle: 'ENERGY & POWER'}, 
//     {title: 'Video Prod', variant: 'primary', fullTitle: 'VIDEO PRODUCTION'}, 
//     {title: 'Robotics', variant: 'primary', fullTitle: 'ROBOTICS'}, 
//     {title: 'Flight & Drone', variant: 'info', fullTitle: 'FLIGHT & DRONE TECHNOLOGY'}, 
//     {title: 'Criminalistics', variant: 'info', fullTitle: 'CRIMINALISTICS'}, 
//     {title: 'Audio Prod', variant: 'info', fullTitle: 'DIGITAL AUDIO PRODUCTION'}, 
//     {title: 'Health Sci', variant: 'pink', fullTitle: 'INTRO TO HEALTH SCIENCE CAREERS'}, 
//     {title: 'Child Dev', variant: 'pink', fullTitle: 'INTRO TO CHILD DEVELOPMENT'}, 
//     {title: 'Design & Mark', variant: 'pink', fullTitle: 'DESIGN & MARKETING'}, 
//     {title: 'Fire & Emer', variant: 'maroon', fullTitle: 'EMERGENCY & FIRE MANAGEMENT SERVICES'}, 
//     {title: 'Game & Graphics', variant: 'warning', fullTitle: 'COMPUTER GRAPHICS & GAME DEVELOPMENT'}, 
//     {title: 'Electricity', variant: 'purple', fullTitle: 'ELECTRICITY & ELECTRONICS'}, 
//     {title: 'Digital Manu', variant: 'warning', fullTitle: 'DIGITAL MANUFACTURING'}, 
// ]
// }
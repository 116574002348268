import React, { useState, useEffect, useContext } from "react";

import { SiteContext } from "../../../App";
import { AdminContext } from "../../../pages/AdminContainer";

import Modal from 'react-bootstrap/Modal';
import HallPassBox from "./HPBox";
import { useHallPass } from "../useHallPass";

import { ErrorBoundary } from "../../../other/utils/ErrorBoundary"; 

export default function HPBoxModal({handleShow, handleClose, show, type, payload}) {

    const {mode, title, modalMode, handleActive} = payload

    const { 
        classInformation, socket, room,
        studentDataFunctions: { deleteMongo, getMongoAllById }
    } = useContext(SiteContext)

    const {hpEvents, setHpEvents, updateExistingEvent, getHallPassEvents} = useContext(AdminContext)

    function handleCloseLocal() {
        handleClose(type)
    }

    const hallPassData = useHallPass()
    // console.log(hallPassData,'hallPassData')
    const {count, pendingCount, completeCount, completeEvent, eventsPeriod} = hallPassData

    // const [show, setShow] = useState(false)

    // console.log(hpEvents,'hpEvents')

    useEffect(() => {

        socket.on("receive_newHallPass", async (data) => {
            console.log('received receive_newHallPass', data)
            const activePasses = await getMongoAllById(data.createObj.studentId, 'hallPassEvents')

            const editHpEvents = [...hpEvents]
            console.log()
            var index = editHpEvents.findIndex(entry => entry.period === classInformation.period)
                editHpEvents[index].events = editHpEvents[index].events.filter(entry => (entry.studentId !== data.createObj.studentId) || (entry.studentId === data.createObj.studentId && entry.approved))
                editHpEvents[index].events = [...editHpEvents[index].events, data.createObj]
            // console.log(activePasses,'activePasses')
            
            var updateEvents = false
            const promises = activePasses.map(async(entry) => {
                if(entry.approved || entry._id === data.createObj._id) return
                if(entry.studentId !== data.createObj.studentId) return
                console.log(entry,'entry would be deleting', entry, 'entry', data, 'data')
                // alert('entry would be deleting')

                /////////////////////////////////////////
                await deleteMongo(entry._id, 'hallPassEvents')
                // COMMENTED OUT, THINK THIS IS THE CAUSE OF THE DELETED PASSES //
                /////////////////////////////////////////

                // updateExistingEvent(entry, 'update', {closed: true})
                // updateExistingEvent(entry, 'remove', {})
                updateEvents = true
                return true
            })
            await Promise.all(promises)

            setHpEvents([...editHpEvents])
            if(updateEvents) {
              getHallPassEvents()
            }
            socket.emit("send_hallPass_addEvent", { data, room });
        })

        socket.on("receive_hallPass_update", (data) => {
            console.log('receive receive_hallPass_update', data)            
            const changed = updateExistingEvent(data.updatedObj, data.type, data.changeValue)

            console.log('NOT here--------')

            if(changed) {
                console.log('here--------')
                // socket.emit("send_hallPass_update", { updatedObj: data.updatedObj, type: data.type, changeValue: data.changeValue, room });
                socket.emit("send_hallPass_updateStudent", { updatedObj: data.updatedObj, type: data.type, changeValue: data.changeValue, room });
            }
            /// I think this is where the issue arises (admin keeps updating and resending update)

        })
    
        return () => {
          socket.off('receive_newHallPass');
          socket.off('receive_hallPass_update');
        };
    
    }, [socket, room, hpEvents])

 return (
  <ErrorBoundary>    
    <div
      onKeyDown={e => e.stopPropagation()}
    >
    <Modal 
      show={show} 
      onHide={handleCloseLocal}
      dialogClassName="my-modal2"
    //   dialogClassName="my-modal"
    >
      <Modal.Header className="bg-secondary text-white">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        {/* <Form hpForm={hpForm} updateForm={updateForm} /> */}
        <HallPassBox hpEvents={eventsPeriod} />
        {/* <HallPassBox hpEvents={eventsPeriod} /> */}
      </Modal.Body>
      <Modal.Footer className="">
            <div className="btn-actions-pane-right">
            <button 
            onClick={e => handleShow(undefined, 'hallPassModal', {title: "Create Hall Pass"})}
            className="btn btn-secondary me-2 float-right">Create Hall Pass</button>
            </div>
      </Modal.Footer>
    </Modal>
    </div>
  </ErrorBoundary>
  
 );
}



import React, { useContext, useState, useEffect, createContext } from 'react'
import OrderEntry from './OrderEntry'

import { SiteContext, baseUrl } from '../../App'
import { AdminContext } from '../../pages/AdminContainer'
import { ErrorBoundary } from '../../other/utils/ErrorBoundary'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpLong, faCircleUp, faCircleDown, faStore, faGlassWater } from '@fortawesome/free-solid-svg-icons';

export default function Orders( { title, sizeClass, type, context }) {

    const { 
      classInformation, classInformationArray, initialLoad,
      studentList,
      studentDataFunctions: {
      }
    } = useContext(SiteContext)

    const {orders} = useContext(AdminContext)

    const blankBg = () => {
      const array = []
      classInformationArray.map(entry => {
        array.push({period: entry.period, bg: 'light'})
      })
      array.push({period: 'P', bg: 'light'})
      return array
    }

    const [searchField, setSearchField] = useState("");
    const [periodField, setPeriodField] = useState(() => {
      if(context === 'dashboard') return classInformation.period
      return 'P'
    });
    const [periodBg, setPeriodBg] = useState(blankBg)

    useEffect(() => {
      const editPeriodBg = blankBg()
      editPeriodBg.map(entry => {
        entry.bg = 'light'
      })
      var index = editPeriodBg.findIndex(entry => entry.period === periodField)
        if(index === -1) return
        editPeriodBg[index].bg = 'primary'
        setPeriodBg(editPeriodBg)
    }, [periodField, classInformationArray.length])

    useEffect(() => {
      if(context !== 'dashboard') return
      setPeriodField(classInformation.period)
    }, [classInformation.period])
  
    var filteredOrders = orders.filter(
      person => {
        return (
          person
          .name
          .toLowerCase()
          .includes(searchField.toLowerCase()) 
          &&
          person
          .period
          .toLowerCase()
          .includes(periodField.toLowerCase())
        );
      }
    );

    filteredOrders = type === 'cups' ? filteredOrders.filter(entry => entry.item === 'Cup') : filteredOrders.filter(entry => entry.item !== 'Cup')
  
    const handleChange = e => {
      // console.log(e)
      setSearchField(e.target.value);
    };
  
    // const handleChangePeriod = e => {
    //   if(e.target.value === '-- Select Period --') {
    //     setPeriodField("");
    //     return
    //   }
    //   setPeriodField(e.target.value);
    // }

    const handleChangePeriod = (newPeriod) => {
      setPeriodField(newPeriod);
    }

    if(filteredOrders.length === 0) return
    
    return (
        <ErrorBoundary>
        <div className={sizeClass}>  
          <div className="main-card mb-3 card">
            <div className="card-header h-auto mt-2">
              <h5 className="card-title">
                <div className="d-flex align-items-stretch">
                  <div className={`badge p-2 bg-${title === 'Orders' ? 'info' : 'primary'} text-white d-flex align-items-center`}>
                      <p className="m-1 d-inline">{title}</p>
                      <FontAwesomeIcon className="m-0 ms-1 me-1 card-title d-inline text-white" icon={title === 'Orders' ? faStore : faGlassWater} />
                  </div>
                </div>
                {/* {title} */}
                {/* <FontAwesomeIcon className="m-0 ms-2 card-title" icon={title === 'Orders' ? faStore : faGlassWater} /> */}
              </h5>
                <div className="btn-actions-pane-right">
                    <div className="d-grid gap-2 d-md-flex justify-content-end align-items-center">
                    {type !== 'cups' && <input className="p-1 ps-2" style={{border:"2px solid", borderRadius:"3px", paddingBottom:"0px", fontSize:"0.8rem"}}
                      type = "search" placeholder = "Search Orders" onChange = {handleChange} />}  
                    {context !== 'dashboard' && <div>
                    {classInformationArray.map(entry => {
                      var index = periodBg.findIndex(item => item.period === entry.period)
                      // console.log(entry, ';entry balacne card')
                      if(index === -1) return
                      return <div key={entry.period} className={`m-1 badge bg-${periodBg[index].bg}`} onClick={e => handleChangePeriod(entry.period)}>
                        {entry.informationStrings.periodAbbreviation}</div>
                    })}
                  </div>}                  
                </div>
                </div>
            </div>
            {/* {!initialLoad && <div className="card-body">
              <p>Loading...</p>
            </div>} */}
            {filteredOrders.length === 0 && <div className="card-body">
              <p>{type === 'cups' ? `No Cup Orders` : `No Orders`}</p>
            </div>}
            {filteredOrders.length > 0 && <div className="m-2 table-responsive">
              <table className="align-middle text-truncate mb-0 table table-borderless table-hover">
                <thead>
                  <tr>
                    <th className="text-center">Name</th>
                    {/* <th className="text-center">Period</th> */}
                    <th className="text-center">Date</th>
                    {type !== 'cups' && <th className="text-center">Item</th>}
                    <th className="text-center">Cost</th>
                    {type !== 'cups' && <th className="text-center">Balance</th>}
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  { filteredOrders.map(entry => {
                    // if(entry.item !== 'Cup' && type === 'cups') return
                    // if(entry.item === 'Cup' && type !== 'cups') return
                    return <OrderEntry name={entry.name} studentId={entry.studentId} item={entry} key={entry.timeStamp} type={type} />
                  })}
                </tbody>
              </table>
            </div>}
          </div>
        </div>
        </ErrorBoundary>
    )
}

import React, {useState, useContext, useEffect} from 'react'

import { useTenMinRule } from '../../TenMinRule'
import { useHallPass } from '../useHallPass'
import { useStopwatchLocal } from '../../Stopwatch'

export default function ActiveEvent({events}) {

    const hallPassData = useHallPass()

    const {count, pendingCount, completeCount, completeEvent, eventsPeriod, eventCurrent, eventPending} = hallPassData

    const tenMinRule = useTenMinRule()

    if(count > 0 || pendingCount > 0) {
        return (
          <div className={`ms-1 me-1 d-flex flex-wrap`}>
            {count > 0 && <span>{eventCurrent.name}</span>}
            {count > 0 && <span className={`badge rounded-pill text-white bg-secondary d-flex align-items-center ${events && 'ms-2'}`}>{eventCurrent.purpose} ({eventCurrent.time})</span>}
            {count > 0 && <ActiveStopwatch eventCurrent={eventCurrent} events={events} />}
            
            {pendingCount > 0 && count === 0 && <span>Hall Pass</span>}

            {count > 1 && <span className={`badge rounded-pill bg-success text-white d-flex align-items-center ${events && 'ms-2'}`}>+{count - 1}</span>}
            {pendingCount > 0 && <span className={`badge rounded-pill bg-info text-white d-flex align-items-center ${events && 'ms-2'}`}>{eventPending.name.split(' ')[0]} ({eventPending.time})</span>}
            {/* {count > 0 && pendingCount > 0 && <span className={`badge rounded-pill bg-info text-white d-flex align-items-center`}>{eventPending.name.split(' ')[0]} ({eventPending.time})</span>} */}
            {pendingCount > 1 && <span className={`badge rounded-pill bg-info text-white d-flex align-items-center ${events && 'ms-2'}`}>+{pendingCount - 1}</span>}
            
            {tenMinRule && <span className={`badge rounded-pill text-white bg-flag d-flex align-items-center ${events && 'ms-2'}`}>10 Min Rule</span>}
          </div>
        )
    } else if(count === 0 && pendingCount === 0) {
        return (
            <span className="ms-0 me-2 d-flex flex-wrap">
                {completeCount > 0 && <span className={`badge rounded-pill bg-white text-black d-flex align-items-center`}>{completeEvent.name.split(' ')[0]} {completeEvent.timeComplete} ({completeEvent.time})</span>}
                {tenMinRule ? 
                    <span className={`badge rounded-pill text-white bg-flag d-flex align-items-center`}>10 Min Rule</span> : 
                    <span className="ms-2">No Active Passes</span>}
            </span>
        )
    }
}

function ActiveStopwatch({eventCurrent, events}) {

    const [running, setRunning] = useState(false);

    useEffect(() => {
        if(eventCurrent.active && eventCurrent.approved) {
            setRunning(true)
        } else {
            setRunning(false)
        }
    }, [])

    var {minutes, seconds} = useStopwatchLocal(eventCurrent.approvedTimeStamp, running)

    var minutesInt = parseInt(minutes)

    var bgBadge = minutesInt < eventCurrent.time ? 'secondary' : 'flag'

    if(minutesInt > eventCurrent.time + 1) {
        var bgBadge = 'danger'
    }else if(minutesInt > eventCurrent.time) {
        var bgBadge = 'flag'
    } else if(minutesInt == eventCurrent.time) {
        var bgBadge = 'warning'
    } else { var bgBadge = 'secondary'}

    return (
    <span className={`badge rounded-pill bg-${bgBadge} text-white d-flex align-items-center ${events && 'ms-2'}`}>
        <span>{minutes}:</span>
        <span>{seconds}</span>
    </span>
    )
}
import React from 'react';

import Modal from './ModalBox'

function Card({ person }) {

	return(
		<div style={{display: "inline-block"}} className="col-12 col-sm-6 col-md-4 col-lg-3">
			<div className="mb-3 card" style={{ border: 0, margin:"5px"}}>
				<div className="m-2">
					<div>
						<h2>{person.name}</h2>
						<div className="recipe__row" style={{paddingBottom:"10px"}}>
							<div className="recipe__value">
								<div className="ingredient-grid1">
									<span className="">{person.period}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="">
						<Modal person={person} title={"Expand"} edit={false} variant={"primary"}/>
						<Modal person={person} title={"Edit"} edit={true} variant={"warning"}/>
					</div>
				</div>    
			</div>
		</div>
	);
}

export default Card;
import React, { useContext, useState } from 'react'
import '../classes.css'
import Modal from 'react-bootstrap/Modal';

import ArrayItems from './2ArrayItems';
import InformationItems from './2InformationItems';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faX, faPlus } from '@fortawesome/free-solid-svg-icons';

export default function Body({modifiedPeriod, setModifiedPeriod}) {

  const [editHeading, setEditHeading] = useState(false)

  function handleHeadingSelect(e, key) {
    e.preventDefault()
    var index = arrays.findIndex(entry => entry.key === key)
    if(index === -1) return
    if(arrays[index].key === editHeading.key) {
        setEditHeading(false)
        return
    }
    setEditHeading(arrays[index])
  }

  function handleChangeInfo(e, item) {
    const editPeriod = {...modifiedPeriod}

    if(item === 'periodAbbreviation' && e.target && e.target.value.length > 3) return
      
    var value = (e && e.target && e.target.value) ? e.target.value : e
    value = value == '[object Object]' ? '' : value

    editPeriod.informationStrings = {...editPeriod.informationStrings, [item]: value}
    if(item === 'period' && e.target.value) {
      editPeriod.period = e.target.value
    }
    if(!e.target.value) {

    }
    // console.log(editPeriod,'editPeriod', e.target.value)
    setModifiedPeriod({...editPeriod})
    // setModifiedPeriod({...editPeriod, ['informationStrings']: {...editPeriod.informationStrings, [item]: (e.target && e.target.value) ? e.target.value : e}})
  }

  const arraysTitles = [
    {code: 'agreements', text: 'Agreements'}, 
    {code: 'classRoles', text: 'Class Roles'}, 
    {code: 'focusSchedule', text: 'Focus Schedule'}, 
    // {code: 'information', text: 'Information'}
  ]

  const arrays = []
  const information = []

  // console.log(modifiedPeriod.informationStrings,'informationStrings')

  for (var key in modifiedPeriod) {
    if (!modifiedPeriod.hasOwnProperty(key)) return
    if(typeof modifiedPeriod[key] === 'object') {
      var index = arraysTitles.findIndex(textEntry => textEntry.code === key)
      if(index > -1) {
        arrays.push({key: key, value: modifiedPeriod[key], text: arraysTitles[index].text })
      }
      // arrays.push({key: key, value: modifiedPeriod[key], text: arraysTitles[index].text })
    }
    // else {information.push({key: key, value: modifiedPeriod[key]})}
  }

  for (var key in modifiedPeriod.informationStrings) {
    if (modifiedPeriod.informationStrings.hasOwnProperty(key)) {
      information.push({key: key, value: modifiedPeriod.informationStrings[key]})
    }
  }

  const dataFunctions = {modifiedPeriod, setModifiedPeriod, editHeading, setEditHeading, arrays}

  return (
    <div className="">
       <div className="mb-3">
        {information.map(entry => {
          // console.log(entry,'entry')
            return <InformationItems key={entry.key} entry={entry} handleChangeInfo={handleChangeInfo} modifiedPeriod={modifiedPeriod} />
        })}
       </div>
       <div className="mb-2 d-flex align-items-center">
       {arrays.map(entry => {
           return <div key={entry.key} className={`me-2 badge bg-${editHeading.key === entry.key ? 'primary' : 'white'}`} onClick={e => handleHeadingSelect(e, entry.key)} role="button" style={{border: '2px solid #3f6ad8', color: editHeading.key === entry.key ? '#ffffff' : '#3f6ad8'}}>
                    {capitalizeFirstLetter(entry.text)}
                  </div>
       })}
      </div>
      {editHeading && <ArrayItems key={editHeading.key} entry={editHeading.value} dataFunctions={dataFunctions} />}
    </div>
  )
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

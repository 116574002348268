import React, { useContext, useEffect, useState } from 'react'

import { SiteContext, baseUrl } from '../../App';
import { AdminContext } from '../../pages/AdminContainer';

import BlankHoriz from './BlankHoriz';
import Group from './Group'

import { ErrorBoundary } from '../../other/utils/ErrorBoundary';

export default function SeatingChart({setNewAttendance, modeVal, period, modalMode, showComplete}) {

    const {
      classInformation,
      studentList, modules,
      studentDataFunctions: {
        setStudentList, updateMongo, getMongoList, moduleFind
      }
    } = useContext(SiteContext)

    const {handleOverride} = useContext(AdminContext)

    const [activeStudent, setActiveStudent] = useState({})
    const [mode, setMode] = useState(() => {
      if(modeVal) return modeVal
      else return 'attendance'
    })

    useEffect(() => {
      if(modeVal !== mode) {
        if(modeVal === 'attendance') { setActiveStudent({}) }
        setMode(modeVal)
      }
    }, [modeVal])

    function handleClick(e, entry) {
      e.preventDefault()
      if(modeVal === 'modal') {
        handleOverride(e, entry, !entry.modalOverride)
      }
      else if(modeVal === 'editSeating') {
        setActiveStudent(entry)
      } 
      else {
        const editStudentList = [...studentList]
        var index = editStudentList.findIndex(item => item.studentId === entry.studentId)
        var attendance = editStudentList[index].attendance 
        if(attendance === 'Present') {attendance = 'Absent'} 
        else if(attendance === 'Absent') {attendance = 'Tardy'}
        else if(attendance === 'Tardy') {attendance = 'Present'}

        editStudentList[index].attendance = attendance
        if(attendance === 'Tardy' && editStudentList[index].color.warningsGiven === 0) {
          editStudentList[index].color = {...editStudentList[index].color, warningsGiven: 1}
        }

        setStudentList(editStudentList)
        setNewAttendance((prev) => {
          var index = prev.findIndex(item => item.studentId === entry.studentId)
          if(index === -1) return [...prev, {studentId: entry.studentId, attendance, name: entry.name, color: entry.color}]
          else {
            prev[index].attendance = attendance
            return prev
          } 
        })
        getMongoList('dailyList').then(entries => {
          var index = entries.findIndex(item => item.studentId === entry.studentId)
          updateMongo({attendance}, entries[index]._id, 'dailyList')
        })
      }
    }

    function handleGroupClick(e, title) {
      e.preventDefault()
      if(!activeStudent.studentId || modeVal !== 'editSeating') return

      const editStudentList = [...studentList]
      var index = editStudentList.findIndex(entry => entry.studentId === activeStudent.studentId)
      editStudentList[index].module = title
      
      setStudentList(editStudentList)
      setActiveStudent({})
      updateMongo({module: title, moduleFull: moduleFind(title, 'title', 'fullTitle')}, activeStudent._id, 'records')
      updateMongo({module: title, moduleFull: moduleFind(title, 'title', 'fullTitle')}, activeStudent.dailyListId, 'dailyList')
    }

    modules.push({title: 'No Group', variant: 'secondary'})
    modules.map(entry => {
      entry.students = []
      entry.mode = mode
      entry.handleClick = handleClick
      entry.handleGroupClick = handleGroupClick
      entry.modalMode = modalMode
      entry.showComplete = showComplete
    })

    const groups = ['Group 1','Group 3','Group 5','Group 7',
                    'Group 2','Group 4','Group 6','Group 8']

    const periodVal = period ? period : classInformation.period
    var filteredList = studentList.filter(entry => entry.period === periodVal)
    filteredList.map(entry => {
      var index = modules.findIndex(item => item.title === entry.module)
      if(entry.studentId === activeStudent.studentId) {entry.activeStudent = true} else {entry.activeStudent = false}
      index !== -1 ? modules[index].students.push(entry) : modules[18].students.push(entry)
    })

    function filterModules(title) {
      return modules.filter(entry => entry.fullTitle === title)[0]
    }

    return (
      <ErrorBoundary>
        {((modules[18].students.length > 0 && modeVal === 'modal') || (modules[18].students.length > 0 && modeVal === 'attendance')|| modeVal === 'editSeating') && <div className="row" style={{marginBottom:"30px"}}>
          <Group sizeClass={'col-12'} group={modules[18]} />
        </div>}
        <div className="row">
          <div className="col-2"/>
          <div className="col-6">
            <div className="row">
              <div className="col-2"/>
              <Group sizeClass={'col-5'} group={filterModules('INTRO TO CULINARY ARTS')} />
              <Group sizeClass={'col-5'} group={filterModules('ELECTRICITY & ELECTRONICS')} />
              {/* <Group sizeClass={'col-5'} group={filterModules('ALTERNATIVE ENERGY')} /> */}
            </div>
          </div>
          <div className="col-4">
            <div className="row">
              <Group sizeClass={'col-8'} group={filterModules('STRUCTURAL ENGINEERING')} />
              <Group sizeClass={'col-4'} group={filterModules('MATERIALS PROCESSING & DESIGN')} horizontal={true} />
            </div>
          </div>
        </div>
        <div className="row" style={{marginBottom:"30px"}}>
          <div className="col-8">
            <div className="row">
              <div className="col-2">
                <Group sizeClass={'col-12'} group={filterModules('VIDEO PRODUCTION')} horizontal={true} />
                <Group sizeClass={'col-12'} group={filterModules('ROBOTICS')}horizontal={true} />
              </div>
              <Group sizeClass={'col-2'} group={filterModules('FLIGHT & DRONE TECHNOLOGY')} horizontal={true} />
              <div className="col-4">
                <Group sizeClass={'col-12'} group={filterModules('CRIMINALISTICS')}/>
                <Group sizeClass={'col-12'} group={filterModules('DIGITAL AUDIO PRODUCTION')}/>
                <div className="col-12" style={{height:"100px"}} />
                {/* <Group sizeClass={'col-12'} group={filterModules('ELECTRICITY & ELECTRONICS')} /> */}
                <Group sizeClass={'col-12'} group={filterModules('DIGITAL MANUFACTURING')} />
              </div>
              <div className="col-4">
                <Group sizeClass={'col-12'} group={filterModules('INTRO TO HEALTH SCIENCE CAREERS')} />
                <Group sizeClass={'col-12'} group={filterModules('INTRO TO CHILD DEVELOPMENT')} />
                <div className="col-12" style={{height:"100px"}} />
                <Group sizeClass={'col-12'} group={filterModules('COMPUTER GRAPHICS & GAME DEVELOPMENT')} horizontal={true} />
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="row">
              <Group sizeClass={'col-4'} group={filterModules('DESIGN & MARKETING')} horizontal={true} />
              <div className="col-4">
                <Group sizeClass={'col-12'} group={filterModules('EMERGENCY & FIRE MANAGEMENT SERVICES')} horizontal={true} />
                <Group sizeClass={'col-12'} group={filterModules('ALTERNATIVE ENERGY')} />
                {/* <Group sizeClass={'col-12'} group={filterModules('COMPUTER GRAPHICS & GAME DEVELOPMENT')} horizontal={true} /> */}
              </div>
              <div className="col-4">
                <Group sizeClass={'col-12'} group={filterModules('HOME MAINTENANCE FUNDAMENTALS')} horizontal={true} variant={'primary'}/>
                <Group sizeClass={'col-12'} horizontal={true} group={filterModules('ENERGY & POWER')}/>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    )
    
    // return (
    //     <>
    //       <div className="row">
    //         {groups.map(entry => {
    //             return (
    //                 <Group 
    //                   key={entry} 
    //                   group={entry} 
    //                   filteredList={filteredList}
    //                   handleSetAttendance={handleSetAttendance}
    //                   type={type}
    //                   editGroups={editGroups}
    //                   showGroup={showGroup}
    //                 />
    //             )
    //         })}
    //       </div>
    //     </>
    // )
}



/// STUDENT-FACING SEATING CHART

// return (
//   <ErrorBoundary>
//     <div className="row">
//       <div className="col-sm-4">
//       </div>
//       {/* <BlankHoriz sizeClass={'col-sm-4'}/> */}
//       <Group2 sizeClass={'col-sm-2'} group={`Culinary`}/>
//       <Group2 sizeClass={'col-sm-2'} group={`Environmental Sci`}/>
//       <Group2 sizeClass={'col-sm-2'} group={`Structural Eng`}/>
//       <Group2 sizeClass={'col-sm-1'} group={`Material Sci`} horizontal={true}/>
//     </div>
//     <div className="row">
//       <div className="col-sm-1">
//         <Group2 sizeClass={'col-sm-12'} group={`Video Prod`} horizontal={true}/>
//         <Group2 sizeClass={'col-sm-12'} group={`Robotics`} horizontal={true}/>
//       </div>
//       <BlankHoriz sizeClass={'col-sm-1'}/>
//       <div className="col-sm-1">
//         <BlankHoriz sizeClass={'col-sm-12'}/>
//         <Group2 sizeClass={'col-sm-12'} group={`Flight & Drone`} horizontal={true}/>
//       </div>
//       <div className="col-sm-2">
//         <BlankHoriz sizeClass={'col-sm-12'}/>
//         <Group2 sizeClass={'col-sm-12'} group={`Criminalistics`} />
//         <Group2 sizeClass={'col-sm-12'} group={`Audio Prod`} />
//       </div>
//       <div className="col-sm-2">
//         <BlankHoriz sizeClass={'col-sm-12'}/>
//         <Group2 sizeClass={'col-sm-12'} group={`Health Sci`} />
//         <Group2 sizeClass={'col-sm-12'} group={`Child Dev`} />
//       </div>
//       <div className="col-sm-1">
//         <BlankHoriz sizeClass={'col-sm-12'}/>
//         <Group2 sizeClass={'col-sm-12'} group={`Design & Mark`} horizontal={true}/>
//       </div>
//       <div className="col-sm-2">
//         <div className="row">
//           <div className="col-sm-3">
//             <BlankHoriz sizeClass={'col-sm-12'}/>
//           </div>
//           <div className="col-sm-6">
//             <Group2 sizeClass={'col-sm-12'} group={`Fire & Emer`} horizontal={true}/>
//             <Group2 sizeClass={'col-sm-12'} group={`Game & Graphics`} horizontal={true}/>
//           </div>
//         </div>
//       </div>
//       <div className="col-sm-1">
//         <Group2 sizeClass={'col-sm-12'} group={`Home Maintenance`} horizontal={true}/>
//         <Group2 sizeClass={'col-sm-12'} group={`Energy & Power`} horizontal={true}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="col-sm-2">
//         <BlankHoriz sizeClass={'col-sm-12'}/>
//         <Group2 sizeClass={'col-sm-12'} group={`Electricity`}/>
//       </div>
//       <div className="col-sm-2">
//         <BlankHoriz sizeClass={'col-sm-12'}/>
//         <Group2 sizeClass={'col-sm-12'} group={`Digital Manu`}/>
//       </div>
//       <div className="col-sm-1"/>
//       <div className="col-sm-2">
//         <Group2 sizeClass={'col-sm-12'} group={`Smith`} type={'smith'}/>
//       </div>
//       <div className="col-sm-1">
//         <Group2 sizeClass={'col-sm-12'} group={`Smith`} type={'smith'} horizontal={true}/>
//       </div>
//       <div className="col-sm-2"/>
//       <div className="col-sm-1">
//         <BlankHoriz sizeClass={'col-sm-12'}/>
//         <Group2 sizeClass={'col-sm-12'} group={`Door`} type={'door'} />
//       </div>
//     </div>
//   </ErrorBoundary>
// )
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { SiteContext, baseUrl } from "../App";
import { AdminContext } from "../pages/AdminContainer";
 
export default function Create() {

 const [form, setForm] = useState(blankForm);

 const {
   classInformationArray,
   studentDataFunctions: {
     setRecords, getRecords, createMongo
   }
 } = useContext(SiteContext)

 const {createCleanStudent} = useContext(AdminContext)

 const navigate = useNavigate();
 
 // These methods will update the state properties.
 function updateForm(value) {
   return setForm((prev) => {
     return { ...prev, ...value };
   });
 }

 // This function will handle the submission.
 async function onSubmit(e) {
   e.preventDefault();
  
   const formItems = ['name','firstName','studentId','email','homePhone', 'birthday']
   formItems.map(entry => {
     if(form[entry].length === 0) 
     return
   })

   createMongo({
     ...form,
     moduleHistory: [{module: 'No Group', date: Date.now(), groupNumber: 1, period: form.period}],
   }, 'records') 
   createMongo({
    name: form.name,
    studentId: form.studentId,
    days: [],
    hallPasses: [],
   }, 'dailyRecordRoster') 

   createCleanStudent({
     ...form,
     moduleHistory: [{module: 'No Group', date: Date.now(), groupNumber: 1, period: form.period}],
   }, true)
   setForm(blankForm);

   getRecords()
   navigate("/records");
 }

 return (
   <div>
     <h3>Create New Record</h3>
     {/* <button
       onClick={(e) => addJSON(studentJSobj, e)}
     >Create Student JSON Record</button> */}
     <form onSubmit={onSubmit}>
       <div className="form-group">
         <label htmlFor="name">Name</label>
         <input
           type="text"
           className="form-control"
           id="name"
           value={form.name}
           onChange={(e) => updateForm({ name: e.target.value })}
         />
       </div>
       <div className="form-group">
         <label htmlFor="position">First Name</label>
         <input
           type="text"
           className="form-control"
           id="position"
           value={form.firstName}
           onChange={(e) => updateForm({ firstName: e.target.value })}
         />
       </div>   
       <div className="form-group">
         <label htmlFor="position">Name Pronunciation</label>
         <input
           type="text"
           className="form-control"
           id="position"
           value={form.namePronunciation}
           onChange={(e) => updateForm({ namePronunciation: e.target.value })}
         />
       </div>     
       <div className="form-group">
         <label htmlFor="position">Email</label>
         <input
           type="text"
           className="form-control"
           id="position"
           value={form.email}
           onChange={(e) => updateForm({ email: e.target.value })}
         />
       </div>
       <div className="form-group">
         <label htmlFor="position">Student ID</label>
         <input
           type="text"
           className="form-control"
           id="position"
           value={form.studentId}
           onChange={(e) => updateForm({ studentId: e.target.value })}
         />
       </div>
       <div className="form-group">
         <label htmlFor="position">Attendance:</label>
         <input
           type="text"
           className="form-control"
           id="position"
           value={form.attendanceName}
           onChange={(e) => updateForm({ attendanceName: e.target.value })}
         />
       </div>
      <div className="form-group">
        <label htmlFor="position">Home Phone</label>
        <input
          type="text"
          className="form-control"
          id="position"
          value={form.homePhone}
          onChange={(e) => updateForm({ homePhone: e.target.value })}
        />
      </div>
      <div className="form-group">
        <label htmlFor="position">Birthday</label>
        <input
          type="text"
          className="form-control"
          id="position"
          value={form.birthday}
          onChange={(e) => updateForm({ birthday: e.target.value })}
        />
      </div>
      <div className="form-group">
      <label htmlFor="position" style={{margin:"10px", marginLeft:"0"}}>Grade</label>
           <input
             className=""
             type="number"
             name="positionOptions"
             id=""
             value={form.grade}
             max={8}
             min={7}
            //  checked={form.grade == "8"}
             onChange={(e) => updateForm({ grade: e.target.value })}
           />
      </div>
       <div className="form-group">
       <label htmlFor="position" style={{display:"block"}}>Period</label>
         {classInformationArray.map(classEntry => {
          return <div key={classEntry._id} className="form-check form-check-inline">
            <input className="form-check-input" type="radio"
              name="positionOptions" value={classEntry.period}
              checked={form.period === classEntry.period}
              onChange={(e) => updateForm({ period: e.target.value })}
            />
            <label htmlFor="positionIntern" className="form-check-label">{classEntry.informationStrings && classEntry.informationStrings.periodAbbreviation}</label>
          </div>
         })}
       </div>
       <br/>
       <div className="form-group">
         <input
           type="submit"
           value="Create Student"
           className="btn btn-primary"
         />
       </div>
     </form>
   </div>
 );
}



const blankForm = {
  name: "",
  firstName: "",
  email: "",
  studentId: "",
  grade: '7',
  period: "",
  group: "",
  attendanceName: "",
  phoneTaken: 0,
  classRole: '',
  notes: [],
  homePhone: '',
  birthday: '',
  groupNumber: 1
}

import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
// import VolumeUp from '@mui/icons-material/VolumeUp';

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function InputSlider({brushRadius, setState, updateDrawArr}) {
  const [value, setValue] = useState()

  const handleSliderChange = (e) => {
    e.preventDefault()
    // setState({ brushRadius: parseInt(e.target.value, 10) })
    updateDrawArr({brushRadius: parseInt(e.target.value, 10)})
    // setValue(e.target.value);
  };

  const handleInputChange = (e) => {
    e.preventDefault()
    // setState({ brushRadius: parseInt(e.target.value, 10) })
    updateDrawArr({brushRadius: parseInt(e.target.value, 10)})
    // setValue(e.target.value === '' ? '' : e.target.value);
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };

  return (
    <div className="mt-2">
    <Box sx={{ width: 250 }}>
      <Typography id="input-slider" gutterBottom>
        Brush Size:
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          {/* <VolumeUp /> */}
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof brushRadius === 'number' ? brushRadius : 0}
            onChange={e => handleSliderChange(e)}
            aria-labelledby="input-slider" min={1} max={30}
          />
        </Grid>
        <Grid item>
          <Input
            value={brushRadius}
            size="small"
            onChange={e => handleInputChange(e)}
            onBlur={handleBlur}
            inputProps={{
              step: 1,
              min: 1,
              max: 30,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </Box>
    </div>
  );
}

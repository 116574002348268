import React from 'react';

import PassEntry from './PassEntry';

function SearchList({filteredPasses, hallPasses, }) {
  
  filteredPasses.sort( compare );

  function compare( a, b ) {
      if ( a.timeStamp > b.timeStamp ){
        return -1;
      }
      if ( a.timeStamp < b.timeStamp ){
        return 1;
      }
      return 0;
  }

  // console.log(filteredPasses,'filteredPasses')

  const filtered = filteredPasses.map( pass =>  
    <PassEntry key={pass._id} pass={pass}/>
  ); 

  return (
    <div>
      <table className="align-middle text-truncate mb-0 table table-borderless table-hover">
        <thead>
          <tr>
            <th className="">Name</th>
            <th className="">Date</th>
            <th className="">Period</th>
            <th className="">Purpose</th>
            <th className="">Requested</th>
            <th className="">Actual</th>
            <th className="">Difference</th>
            <th className="">Edit</th>
            <th className="">Update</th>
            <th className="">Delete</th>
          </tr>
        </thead>
        <tbody>
          {filtered.length === 0 ? <span className="mt-3">No Passes</span> : filtered}
        </tbody>
      </table>
    </div>
  );
}

export default SearchList;
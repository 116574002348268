import React, { useContext, useState, useEffect } from 'react'
import { SiteContext } from '../../App'

export function useModal({student, setStudent}) {

    const [show, setShow] = useState(modalShowFunction());

    const handleClose = (type, e, adminOverride) => {
      if(e) e.preventDefault()

      console.log('close', type)
      const editShow = [...show]
      var index = editShow.findIndex(entry => entry.type === type)
      if(index === -1) return
      editShow[index].show = false

      setShow(editShow)

      console.log(adminOverride,'adminOverride')
      if(adminOverride === 'hide') {
        const editStudent = {...student}
        setStudent({...editStudent, modalOverride: true})
      }
    }

  const handleShow = (e, type, payload, adminOverride) => {
      if(e) e.preventDefault()

      const editShow = [...show]
      var index = editShow.findIndex(entry => entry.type === type)
      if(index === -1) return
      editShow[index].show = true
      if(payload) { editShow[index].payload = payload }

      setShow(editShow)

      if(adminOverride === 'show') {
        const editStudent = {...student}
        setStudent({...editStudent, modalOverride: false})
      }
  }

    return {
        handleClose, handleShow, modalShowFunction, show, setShow
    }
}

function modalShowFunction() {
    return [
        // student
        {type: 'hallPassInitial', show: false},
        {type: 'hallPassActive', show: false},
        {type: 'dayReview', show: false},
        {type: 'goalSetting', show: false},
        {type: 'paxPat', show: false},
        {type: 'shoutout', show: false},
        {type: 'customModal', show: false, studentOverride: false},
        {type: 'assignments', show: false},
        {type: 'goalSettingStatic', show: false},
        // admin
        {type: 'powerSchool', show: false, payload: {}},
        {type: 'customModal', show: false, payload: {}},
        {type: 'seatModal', show: false, payload: {}},
        {type: 'hallPassModal', show: false, payload: {}},
        {type: 'hallPassBoxModal', show: false, payload: {}},
    ] 
}

// function modalShowFunction() {
//     return [
//         {type: 'powerSchool', show: false, payload: {}},
//         {type: 'customModal', show: false, payload: {}},
//         {type: 'seatModal', show: false, payload: {}},
//         {type: 'hallPassModal', show: false, payload: {}},
//     ] 
// }
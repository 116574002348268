import React, {Component, useContext} from 'react'

import Rating from 'react-rating'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

class RatingComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      value: (this.props.type === 'moduleDay' && this.props.value) ? this.props.value : (this.props.type === 'moduleDay' && !this.props.value) ? 0 :
             (this.props.type !== 'moduleDay' && this.props.value) ? this.props.value : 1,
    }
  }

  handleRatingChange = (rating) => {
    this.setState({...this.state, value: rating});
    this.props.updateForm(this.props.category, this.props.item, rating)
  }

  render() {
    var count = 0
    var match = this.props.colorMatch.filter(entry => entry.level === this.state.value)[0]
    if(this.props.type !== 'moduleDay') 
    return (
        <>
        <Rating style={{marginTop:"10px"}}
        onChange={this.handleRatingChange} 
        initialRating={this.state.value}
        stop={9}
        emptySymbol={this.props.reversed.map(entry => {
          count++
          if(entry.color === 'White' && this.props.type !== 'moduleDay') return
          return <FontAwesomeIcon value={count} style={{color: 'white', marginLeft:"3px", marginRight:"3px"}} icon={faSquare} className="fa-lg" />
        })}
        fullSymbol={this.props.reversed.map(entry => {
          return <FontAwesomeIcon style={{color: entry.hex, marginLeft:"3px", marginRight:"3px"}} icon={faSquare} className="fa-lg" />
        })}
      />
      {this.props.type !== 'moduleDay' && match.color !== 'White' && <div style={{margin:"10px", color: match.hex, display:"inline",fontWeight:"bold", fontSize:"12px", marginBottom:"20px"}}>{`${match.color}`}</div>}
      </>
    );

    var count = 0
    var count2 = 0
    
    return (
        <>
        <Rating
        onClick={this.handleRatingChange} 
        initialRating={this.state.value}
        stop={10}
        // fractions={2}
        emptySymbol={days.map(entry => {
            count++
            return <IconNumber count={count} color={`white`}/>
          })}
        fullSymbol={days.map(entry => {
            count2++
            return <IconNumber count={count2} color={`black`}/>
        })}
      />
      {/* {this.props.type === 'moduleDay' && <span style={{color: 'black', display:"inline", marginTop:"5px", fontSize:"12px", fontWeight:"bold", margin:"10px"}}>
          {(this.state.value - Math.floor(this.state.value)) === 0 && this.state.value === 0 ? 
          `Haven't started` : this.state.value === 0.5 ? 'Part of Day 1' :
          (this.state.value - Math.floor(this.state.value)) === 0 ? `All of Day ${this.state.value}` : `Day ${Math.floor(this.state.value)}, Part of Day ${Math.round(this.state.value)}`}
      </span>} */}
      </>
    );
  }
}

function IconNumber({count, color}) {
    var textColor = color === 'black' ? 'white' : 'black'
    return (
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon value={count} style={{color: color}} icon={faSquare} className="fa-lg">1</FontAwesomeIcon>
            <span className={`fa-layers-text fa-inverse fa-xs text-${textColor}`} transform="shrink-8 down-3" style={{color:"black"}}>{count}</span>
        </span>
    )
}
const days = [1,2,3,4,5,6,7,8,9,10,11]

export default RatingComponent
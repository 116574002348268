import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';

import { SiteContext } from "../../../App";
import { StudentContext } from "../../../pages/StudentContainer";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

import { usePhotoGallery }  from '../../../modules/inventory/admin/upload/usePhotoGallery.ts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsToDot, faBook, faCheck, faSheetPlastic, faX } from '@fortawesome/free-solid-svg-icons';

export default function CompleteCard({activeAssignment, name, context, handleAction, exportFunctions}) {
  // const [show, setShow] = useState(false);

  const {show, setShow, handleClose, handleShow, activeModal, setActiveModal} = exportFunctions

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <>
      {context === 'dash' ?  
        <></>                       
        // <div className={`badge bg-${activeAssignment.completeAdmin || activeAssignment.returned ? 'light text-info' : 'info'}`} onClick={handleShow} role="button">{activeAssignment.title}</div> 
        :
        <></>
        // <div className={`badge bg-white text-success m-0 p-1 me-1 h-auto`} style={{height:"15px", fontSize:"12px"}} onClick={handleShow} role="button">
        //   <FontAwesomeIcon icon={faCheck} />
        // </div>
      }

      <Modal show={show} onHide={handleClose} dialogClassName={`my-modal3`}>
        <Modal.Header closeButton>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompleteCard2 activeAssignment={activeAssignment} context={context} handleAction={handleAction} />
        </Modal.Body>
        {/* <Modal.Footer>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

function CompleteCard2({activeAssignment, questions, variantColor, updateChecklistLocal, context, handleAction}) {

    var countQ = 0
    var count = 0
    
    return (
      <div className="mt-3 mb-3 d-flex align-items-stretch">
        <div className={`card flex-grow-1 border border-3 border-info`}>
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-start">
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="">
                  <h3 className="fsize-2 fw-bold mb-0">{activeAssignment.title}</h3>
                </div>
                {context === 'dash' && <div className="d-flex align-items-center">
                  <div className={`badge d-flex align-items-center ps-3 pe-3 pt-2 pb-2 me-2 border border-2 border-info ${activeAssignment.completeAdmin ? 'bg-info text-white' : 'bg-white text-info'}`} onClick={e => handleAction(e, true, activeAssignment)} role="button">
                    <h3 style={{fontSize:"15px"}} className="mb-0 fw-bold">Mark Complete</h3>
                  </div>
                  <div className={`badge d-flex align-items-center ps-3 pe-3 pt-2 pb-2 border border-2 border-secondary ${activeAssignment.returned ? 'bg-secondary text-white' : 'bg-white text-secondary'}`} onClick={e => handleAction(e, 'returned', activeAssignment)} role="button">
                    <h3 style={{fontSize:"15px"}} className="mb-0 fw-bold">Return</h3>
                  </div>
                </div>}
              </div>
            </div>
          </div>
          <div className="card-body">
            {/* <div className="card bg-secondary border border-3 border-success">
              <div className="card-body"> */}
                {/* {questions.map(item => {
                  countQ++  
                  return ( */}
                    <div key={countQ} className={`card bg-secondary border border-3 border-info mb-3`}>
                      <div className="card-body">
                        <div className="text-white fw-bold">1. In a couple sentences, what did you do in this activity?</div>
                        <div className="mt-2 p-1 text-white">
                          <span>{activeAssignment.what}</span>
                         </div>
                      </div>
                    </div>
                  {/* )
                })} */}
              {/* </div>
            </div> */}
            <div className={`card bg-black text-white mb-3 border border-3 border-${variantColor}`} role="button">
              <div className="card-body fw-bold">
                <div>Image{activeAssignment.url && activeAssignment.url.length > 1 && 's'}</div>
                <div className="d-flex align-items-top mt-2 flex-wrap">
                  {activeAssignment.url && activeAssignment.url.length > 0 && activeAssignment.url.map(item => {
                    count++
                    return <ImageModal key={count} item={item} length={activeAssignment.url.length} />
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function ImageModal({item, length}) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <img src={item} onClick={handleShow} className={`${length > 1 ? 'viewModalImage1' : 'w-100'} p-2 ps-0`}/>
  
        <Modal show={show} onHide={handleClose} dialogClassName={`my-modal3`}>
          {/* <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <img src={item} onClick={handleShow} className="viewModalImage2"/>
          </Modal.Body>
          {/* <Modal.Footer>
          </Modal.Footer> */}
        </Modal>
      </>
    );
  }
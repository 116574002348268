import React, { useState, useEffect, useContext } from "react";

import Modal from 'react-bootstrap/Modal';
import ViewModal from "../viewModal/ViewModal";
import Rating from './Rating'

import { SiteContext } from "../../../App";
import { StudentContext } from "../../../pages/StudentContainer";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandFist, faHand } from '@fortawesome/free-solid-svg-icons';

export default function SCustomModal({show, type, handleStudentOverride}) {

 const {
   student, colorMatch, room, socket, goalQuestions, focusAgreements,
   studentDataFunctions: {
    updateMongo
   }
 } = useContext(SiteContext)

 const {handleClose, handleShow} = useContext(StudentContext)

 const customType = focusAgreements.customModalType
 const options = [
     {rating: 0, hex: "#794c8a"},
     {rating: 1, hex: "#794c8a"},
     {rating: 2, hex: "#794c8a"},
     {rating: 3, hex: "#794c8a"},
     {rating: 4, hex: "#794c8a"},
     {rating: 5, hex: "#794c8a"},]

//  console.log(customType,'customType', type, focusAgreements[customType])

 function onImgLoad({target:img}) {
  this.setState({dimensions:{height:img.offsetHeight,
                             width:img.offsetWidth}});


                             console.log(img.offsetHeight, 'height')
}

// console.log(customType,'customType')
 if(focusAgreements && focusAgreements[customType] && focusAgreements[customType].responseType === "Full Screen, No Response") {
   return (
    <Modal show={show} dialogClassName={`my-modal3`}>
              <img src={`${focusAgreements[customType].url}?${new Date().getTime()}`} onLoad={onImgLoad} className="m-0 viewModalImage"/>

      {/* <Modal.Header>
      </Modal.Header>
      <Modal.Body>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer> */}
    </Modal>
   )
 }

 if(student.customOverride) return
 return (

    <Modal 
      show={show} 
      // onHide={handleCloseLocal}
      dialogClassName={customType === 'dailyLinks' ? '' 
      : customType === 'readingZone' ? `my-modal7` : `my-modal3`}
    >
      {/* <Modal.Header>
      </Modal.Header> */}
      <Modal.Body>
        <ViewModal/>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={e => handleStudentOverride(e, student.customOverride, type)} className="btn btn-purple text-white">
          Hide
        </button>
      </Modal.Footer>
    </Modal>
);
}



import React, { useContext } from 'react'
import { SiteContext } from '../../App';

import Accordion from 'react-bootstrap/Accordion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';

export default function LoginAccordion({sizeClass, title}) {

  const { 
    student, loginState,
    classInformation
  } = useContext(SiteContext)

  console.log(classInformation,'classInfo loginacc')
  // var index = paxpatCodes.findIndex(entry => entry.period === classInformation.period)
  // var code = loginState.admin ? '123456' : paxpatCodes[index].code
  const code = classInformation.informationStrings ? classInformation.informationStrings.paxpatCourseCode : false

    return (
      <div className={sizeClass}>
        <div className="main-card mb-3 card">
          <div className="card-header">
            {/* <FontAwesomeIcon style={{ marginRight: 10 }} icon={faCalendar} /> */}
            <a href="https://lms.paxpat.com/" target="_blank">{title}</a>
          </div>
          <div className="card-body">
            <Accordion>
                  <Accordion.Item eventKey={'sem1'}>
                    <Accordion.Header>Login</Accordion.Header>
                    <Accordion.Body>
                    <div className="recipe__row">
                        <div className="recipe__value recipe__value--indented">
                            <div className="ingredient-grid2">
                                <span className="bold">Email</span>
                                <span className="bold">Password/Student ID</span>
                                <span className="">{student.email}</span>
                                <span className="" style={{paddingBottom:"20px"}}>{student.studentId}</span>
                                <span className="bold">Name</span>
                                <span className="bold">Course / Product Code</span>
                                <span className="">{student.name}</span>
                                {code && <span className="">{code}</span>}
                            </div>
                        </div>
                    </div>  
                    {/* <div className="recipe__row">
                        <div className="recipe__value recipe__value--indented">
                            <div className="ingredient-grid2">
                                <span className="bold">Name</span>
                                <span className="bold">Course Code</span>
                                <span className="">{student.name}</span>
                                <span className="">{code}</span>
                            </div>
                        </div>
                    </div>   */}
                    </Accordion.Body>
                  </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    )
}

import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from 'uuid'

import AgreementBody from "./types/CreateAgBody";
import AQBody from "./types/AQBody";
import LinkBody from "./types/LinkBody";
import Assignments from '../../assignments/admin/AAssignments'

import { SiteContext } from "../../../App";
import { AdminContext } from "../../../pages/AdminContainer";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faBookmark, faX, faRotate, faShare } from '@fortawesome/free-solid-svg-icons';
 
export default function CustomModal({handleShow, handleClose, show, type, sizeClass}) {

    const {
      student, socket, room, focusAgreements, newCustomModal, classInformation, classInformationArray,
      studentDataFunctions: {updateMongo, setFocusAgreements, getMongoList, getMongoByUniqueId}
    } = useContext(SiteContext)

    const {modes, setModes} = useContext(AdminContext)

    const [localFocusAgreements, setLocalFocusAgreements] = useState(focusAgreements)

    // console.log(focusAgreements,'focusAg')
    var index = modes.findIndex(entry => entry.type === 'customModal')

    const activeOption = focusAgreements.customModalType
    // const [customOption, setCustomOption] = useState(focusAgreements && focusAgreements[activeOption])
    const customOption = focusAgreements[activeOption]
    
    // console.log(focusAgreements,'focusAgreements')
    function handleClear() {
      var modifyHeading = {...customOption}
      modifyHeading = newCustomModal(activeOption)
      setVariables(modifyHeading)
    }

    // useEffect(() => {

    //   let interval;
  
    //   interval = setInterval(async () => {
    //     if(localFocusAgreements && localFocusAgreements === focusAgreements) return
    //     socket.emit("send_modalDisplay", { data: {[activeOption]: {...localFocusAgreements[activeOption]}}, room });
    //   }, 1500);
  
    //   return () => clearInterval(interval);
  
    // }, [focusAgreements])

    function setVariables(modifyHeading, specificFocusAgreements) {
      const editFocusAgreements = specificFocusAgreements ? {...specificFocusAgreements} : {...focusAgreements}
      editFocusAgreements[activeOption] = {...modifyHeading}
      setFocusAgreements(editFocusAgreements)
      setLocalFocusAgreements(editFocusAgreements)
      // console.log(modifyHeading,'modifyHeading aedit')
      socket.emit("send_focusAgreementsUpdate", { focusAgreements: editFocusAgreements, room: 'admin' });
      socket.emit("send_modalDisplay", { data: {[activeOption]: {...modifyHeading}}, room });
      updateMongo({[activeOption]: {...modifyHeading}}, editFocusAgreements._id, 'focusAgreements')
    }
  
    async function updateModes(e, manualValue) {
      if(e) e.preventDefault()

      var value = manualValue ? manualValue : e.target.value
      const editModes = [...modes]
      var index = editModes.findIndex(entry => entry.type === 'customModal')
      var index2 = editModes[index].options.findIndex(entry => entry.type === value)

      var editMode = editModes[index].options[index2]
      editModes[index].active = value

      // console.log(editModes,'editModes')
      setModes(editModes)

      var output = {
        customModalType: value,
        customModalText: editMode.text
      }

      const editFocusAgreements = {...focusAgreements, ...output}

      // console.log(editFocusAgreements,'editFocusAgreements')
      setFocusAgreements(editFocusAgreements)
      await updateMongo({...output}, classInformation.focusAgreementId, 'focusAgreements') 

      socket.emit("send_modalDisplay", { data: {...output}, room });
      socket.emit("send_focusAgreementsUpdate", { focusAgreements: {...focusAgreements, ...output}, room: 'admin' });
    }

    useEffect(() => {
      if(focusAgreements.focusCategory !== 'Reading Zone') return
      updateModes(false, 'readingZone')
    }, [focusAgreements.focusCategory])

    const importVariables = {customOption, handleClear, setVariables}

    return (
      <ErrorBoundary>
        <div className={`${sizeClass}`}>
          <div className="card mb-2">
            <div className="card-header w-100 d-grid gap-1 d-flex justify-content-between align-items-center">
                <span className="d-inline-block">Custom Modal</span>
                <select className="ms-1 ms-0 me-1 btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm" onChange={e => updateModes(e)} value={activeOption}>
                    {modes[index].options.map(entry => {
                      // console.log(entry,'entry')
                        return <option key={entry.type} value={entry.type}>{entry.text}</option>
                    })}
                </select>
            </div>
            <div className="card-body pb-1">
              {activeOption === 'createAgreement' && <AgreementBody customOption={customOption} setVariables={setVariables} />}
              {activeOption === 'attendanceQuestion' && <AQBody customOption={customOption} setVariables={setVariables} />}
              {activeOption === 'dailyLinks' && <LinkBody customOption={customOption} setVariables={setVariables} />}
              {activeOption === 'assignments' && <Assignments customOption={customOption} />}
              {/* {activeOption === 'readingZone' && <div>Reading Zone</div>} */}
            </div>
            <div className="card-footer w-100 d-flex justify-content-between align-items-center mt-2 mb-2">
              {activeOption !== 'readingZone' && activeOption !== 'assignments' && <Footer importVariables={importVariables} />}
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );

}

function Footer({importVariables}) {

  const {classInformation, classInformationArray, focusAgreements, studentDataFunctions: {createMongo, updateMongo, deleteMongo, getMongoList, getMongoByUniqueId}} = useContext(SiteContext)
  const {customOption, handleClear, setVariables} = importVariables
  
  const [classTemplates, setClassTemplates] = useState([])
  const [activeTemplate, setActiveTemplate] = useState('none')
  
  useEffect(() => {

    async function getClasses() {
      const activeClasses = []

      // console.log('hello')
      const promises = classInformationArray.map(async(classEntry) => {
        
        const loadedEntry = await getMongoByUniqueId(classEntry.focusAgreementId, 'focusAgreements')
        // console.log(loadedEntry[customOption.type],'loadedEntry[customOption.type]', customOption.type)
        if(loadedEntry[customOption.type] && loadedEntry[customOption.type].text && loadedEntry[customOption.type].text.length > 0) {
          if(loadedEntry.period === classInformation.period) return
          activeClasses.push({...loadedEntry[customOption.type], period: loadedEntry.period, id: loadedEntry._id, active: true})
        }
        if(customOption.type === 'dailyLinks' && loadedEntry[customOption.type].bullets.length > 0 && loadedEntry[customOption.type].bullets[0].entry.length > 0 && loadedEntry[customOption.type].bullets[0].link.length > 0) {
          if(loadedEntry.period === classInformation.period) return
          activeClasses.push({...loadedEntry[customOption.type], period: loadedEntry.period, id: loadedEntry._id, active: true})
        }
        return true
      })
      await Promise.all(promises)
      

      activeClasses.sort(function(a, b) {
        var textA = a.period.toUpperCase();
        var textB = b.period.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      const templates = await getMongoList('customModalTemplates')
      templates.map(template => {
        if(template.type !== customOption.type) return
        activeClasses.push({...template, active: false, id: template._id})
      })

      setClassTemplates(activeClasses)
    }

    getClasses()

  }, [customOption.type, classTemplates.length, classInformation.period])

  useEffect(() => {
    setActiveTemplate('none')
  }, [classInformation.period])

  function handleSaveTemplate(e) {
    e.preventDefault()
    createMongo({...customOption, period: classInformation.period, timestamp: Date.now()}, 'customModalTemplates')
    // const editClassTemplates = [...classTemplates]
    setClassTemplates([])
  }

  function handleUseTemplate(e) {
    e.preventDefault()
    if(e.target.value === 'none') return
    var index = classTemplates.findIndex(entry => entry.id === e.target.value)

    const modifyTemplate = {...classTemplates[index]}

    delete modifyTemplate.period
    delete modifyTemplate.timestamp
    delete modifyTemplate._id
    // delete modifyTemplate.id
    delete modifyTemplate.active

    // modifyTemplate.id = uuidv4()

    setActiveTemplate(e.target.value)
    setVariables(modifyTemplate)
  }

  function handlePushTemplate(e) {
    e.preventDefault()

    classInformationArray.map(classEntry => {
      if(classEntry.period === classInformation.period) return
      updateMongo({[customOption.type]: {...customOption}, customModalType: 'dailyLinks', customModalText: 'Daily Links'}, classEntry.focusAgreementId, 'focusAgreements')
    })
  }

  function handleDeleteTemplate(e) {
    e.preventDefault()

    var index = classTemplates.findIndex(entry => entry.id === customOption.id)
    if(index === -1) return
    console.log(classTemplates[index],'classTemplates', classTemplates)
    
    deleteMongo(classTemplates[index]._id, 'customModalTemplates')
    setClassTemplates([])
  }

  return (
  <>
    <div className="flex-fill mt-2">
      <select className="form-control" role="button" onChange={e => handleUseTemplate(e)} style={{width:"100%", maxWidth:"100%"}} value={activeTemplate}>
          <option value="none">Choose From Templates</option>
          {classTemplates.map(classEntry => {
            return <option key={classEntry.id} value={classEntry.id}>{classEntry.active ? classEntry.period : getDateString(classEntry.timestamp)} - {(customOption.type === 'dailyLinks' && classEntry.bullets && classEntry.bullets.length > 0) ? classEntry.bullets[0].entry : classEntry.text}</option>
          })}
      </select>
    </div>
    <div className="ms-2 btn-group mt-2" role="group">
      <div type="button" className={`btn btn-primary`} onClick={e => handleSaveTemplate(e)}>
        <FontAwesomeIcon color="white" icon={faFloppyDisk} />
      </div>
      <div type="button" className={`btn btn-info`} onClick={handleClear}>
        <FontAwesomeIcon color="white" icon={faRotate} />
      </div>
      <div type="button" className={`btn btn-purple`} onClick={e => handlePushTemplate(e)}>
        <FontAwesomeIcon color="white" icon={faShare} />
      </div>
      <div type="button" className={`btn btn-danger`} onClick={e => handleDeleteTemplate(e)}>
        <FontAwesomeIcon color="white" icon={faX} />
      </div>
    </div>
  </>
  )
}

function getDateString(milliseconds) {
  var d1 = new Date(milliseconds),
    d = d1.getDate(),
    m = d1.getMonth() + 1,
    y = d1.getFullYear();

  return `${m}/${d}`
}
import React, {Component, useContext} from 'react'

import Rating from 'react-rating'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

class RatingComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      value: this.props.reviewRating ? this.props.reviewRating : 1,
    }
  }

  handleRatingChange = (rating) => {
    this.setState({...this.state, value: rating});
    this.props.updateForm(this.props.category, this.props.item, rating)
  }

  render() {
    var count = 0
    var match = this.props.colorMatch.filter(entry => entry.level === this.state.value)[0]
    
    return (
        <>
        <Rating style={{marginTop:"10px"}}
        onChange={this.handleRatingChange} 
        initialRating={this.state.value}
        stop={9}
        emptySymbol={this.props.reversed.map(entry => {
          count++
          if(entry.color === 'White' && this.props.type !== 'moduleDay') return
          return <FontAwesomeIcon value={count} style={{color: 'white', marginLeft:"3px", marginRight:"3px"}} icon={faSquare} className="fa-lg" />
        })}
        fullSymbol={this.props.reversed.map(entry => {
          return <FontAwesomeIcon style={{color: entry.hex, marginLeft:"3px", marginRight:"3px"}} icon={faSquare} className="fa-lg" />
        })}
      />
      {this.props.type !== 'moduleDay' && match.color !== 'White' && <div className="d-block mt-2 ms-1" style={{color: match.hex, display:"inline",fontWeight:"bold", fontSize:"12px"}}>{`${match.color}`}</div>}
      </>
    );
    
  }
}

export default RatingComponent
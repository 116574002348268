import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import ProgressChecklist from "../../../modules/checklist/student/SChecklist";

import { SiteContext } from "../../../App";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

 
export default function PaxPatModal({handleShow, handleClose, show, type}) {

    const {
      student, socket, room,
      studentDataFunctions: {createMongo}
    } = useContext(SiteContext)

    const [step, setStep] = useState(1)

    function handleCloseLocal() {
      handleClose(type)
    }

    function handleButton(e, open) {
        e.preventDefault()

        if(open === 'continue') {
          setStep(prev => {return prev + 1})
          return
        }

        handleClose(type)
        if(open) {
          window.open("https://lms.paxpat.com/", '_blank');
        }
    }
  
 // This following section will display the form that takes input from the user to update the data.
 return (
  <ErrorBoundary>    
    <div
      onKeyDown={e => e.stopPropagation()}
    >
    <Modal 
      show={show} 
      onHide={handleCloseLocal}
      dialogClassName={step === 1 ? `my-modal3` : ``}
    >
      <Modal.Header>
        <Modal.Title></Modal.Title>
          {/* <button onClick={e=>handleButton(e)} className={`btn btn-secondary`}>No</button>
          <button onClick={e=>handleButton(e, true)} className={`btn btn-info`}>Yes, Open PaxPat</button> */}
      </Modal.Header>
      <Modal.Body>
        <div>
            {step === 1 ? <ProgressChecklist title={'Choose Checklist Item'}/> :
            <span>Do you want to open the PaxPat Website?</span>}
        </div>
      </Modal.Body>
      <Modal.Footer>
          {step === 1 ? <button onClick={e=>handleButton(e, 'continue')} className={`btn btn-primary`}>Continue</button> : 
          <>
          <button onClick={e=>handleButton(e)} className={`btn btn-secondary`}>No</button>
          <button onClick={e=>handleButton(e, true)} className={`btn btn-info`}>Yes, Open PaxPat</button>
          </>}
      </Modal.Footer>
    </Modal>
    </div>
  </ErrorBoundary>
  
);
}



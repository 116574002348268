import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { SiteContext } from "../App";
import { AdminContext } from "../pages/AdminContainer";

import Reflection from "./reflection/Reflection";

export default function ReviewModal({showReviewModal, handleReviewClose, currentEntry, today, variantsExport}) {

//  const {} = useContext(SiteContext)
//  const {} = useContext(AdminContext)

const variantsExportVar = (currentEntry && !variantsExport) ? handleSingleEntry(currentEntry) : variantsExport

 return (

    <Modal 
      show={showReviewModal} 
      onHide={handleReviewClose}
      dialogClassName="my-modal3"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title>{currentEntry.dateString}</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <Reflection entry={currentEntry} title={today ? 'Today in Review' : `Your Day in Review - ${currentEntry.dateString}`} variantsExport={variantsExportVar} />
      </Modal.Body>
      {/* <Modal.Footer>

      </Modal.Footer> */}
    </Modal>
);
}


function handleSingleEntry(currentEntry) {
  const variants = [
    {variant: 'primary', category: [], hex: '#3f6ad8'},
    {variant: 'info', category: [], hex: '#16aaff'},
    {variant: 'purple', category: [], hex: '#794c8a'},
    {variant: 'warning', category: [], hex: '#f7b924'},
    {variant: 'danger', category: [], hex: '#d92550'},
    {variant: 'success', category: [], hex: '#3ac47d'},
    {variant: 'black', category: [], hex: '#343a40'},
    {variant: 'secondary', category: [], hex: '#6c757d'},
    {variant: 'warning', category: [], hex: '#f7b924'},
  ]
  
  var countVariant = 0
  
  // console.log(colorMatch,'colorMatch')
  
    currentEntry.colorChanges.map(item => {
        item.dateString = currentEntry.dateString
    })
  
    var allChanges = currentEntry.colorChanges
  
  allChanges.map(entry => {
    // console.log(entry,'entry')
    var index = variants.findIndex(item => item.category.includes(entry.category))
    if(index === -1) {
        if(entry.category.length === 0) return
        variants[countVariant].category.push(entry.category)
        entry.categoryVariant = variants[countVariant].variant
        countVariant++
        if(countVariant > 8) {countVariant = 0}
    } else {
        entry.categoryVariant = variants[index].variant
    }
  })
  
  const variantsExportNew = []
  variants.map(entry => {
    entry.category.map(item => {
        variantsExportNew.push({variant: entry.variant, category: item, hex: entry.hex})
    })
  })

  return variantsExportNew
}
import React from 'react'

import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowUp, faCircleArrowDown, faStar, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

export default function CustomToggle({ children, eventKey,  setEventKey, eventKeyVar }) {

    const decoratedOnClick = useAccordionButton(eventKey, () => {} );
    
    var style = null
    var icon = null
    if(children === "Raise") {
      style = "success"
      icon = faCircleArrowUp
    } else if (children == "Lower")  {
      style = "danger"
      icon = faCircleArrowDown
    } else if (children == "Shout Out") {
      style = "primary"
      icon = faStar
    } else if (children == "Call Out") {
      style = "warning"
      icon = faThumbsDown
    }

    var idString = style + "Color"
  
    return (
    <div style={{ border: 0 }} className="col-sm-3">
        <button
        id={idString}
        type="button"
        onClick={() => {
          decoratedOnClick()
          setEventKey(eventKey === eventKeyVar ? false : eventKey)
        }}
        className={`btn-icon-vertical btn-transition btn btn-outline-${style}`}
        >
        <FontAwesomeIcon className="btn-icon-wrapper" style={{marginLeft:"auto", marginRight:"auto"}} icon={icon} color={style} />
        {children}
        </button>
    </div>
    );
}

import React, {useState} from 'react'

export default function ShowMore({textString}) {

    const [fullString, setFullString] = useState(false)

    function handleFullString(e) {
        e.preventDefault()
        setFullString(!fullString)
    }

    var shortenedString = textString ? textString.substr(0, 300) : false

    return (
        <div className="word-wrap">
            <span className="me-2">{fullString ? textString : shortenedString}</span>
            {textString.length > 300 && <div className="badge bg-light" onClick={e => handleFullString(e)}>{fullString ? 'Show Less' : 'Show More'}</div>}
        </div>
    )
}

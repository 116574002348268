import React, {useEffect, useContext, useState} from 'react'

import { SiteContext } from '../../App'
import { usePostHog } from 'posthog-js/react'
import { useNavigate } from "react-router";

export function useSocket({setUpdateChips, setOrders, namesList, setNamesList}) {

    const {
        student, focusAgreements,
        socket, moduleItems,
        room, loginState,
        roomJoined,
        classInformation,
        studentDataFunctions: {
            joinRoom, setUpdateFocusAgreements, moduleFind,
            setRoom, setModuleItems,
            setRoomJoined,
            setStudent, setStoreItems,
            setClassInformation,
            checkToday,
            handleLogout, setFocusAgreements,
            createMongo, getMongoList, getMongoById, updateMongo, deleteMongo
        }
    } = useContext(SiteContext)

    const posthog = usePostHog()
    const [updateStudent, setUpdateStudent] = useState(false)
    const [connected, setConnected] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        setRoom(student.period)
    }, [student])

    useEffect(() => {

        if(loginState.admin) return
        // if(!checkToday(student.loginTimeStamp)) handleLogout()
        handleStudentLoad()
    }, [])

    useEffect(() => {

        socket.on('connect', function() {
            console.log('connected', socket.connected);
            setConnected(socket.connected)
            socket.emit("join_room", {room: classInformation.period, personName: student.name});
            if(room) {
              if(loginState.admin) {
                socket.emit("join_room", {room: 'admin', personName: student.name});
              }
              setRoomJoined(true)
            }
        });
  
        socket.on('disconnect', function(reason) {
            console.log('disconnected', socket.connected, 'reason', reason);
            setConnected(false)
            if (reason === "io server disconnect") {
                // the disconnection was initiated by the server, you need to reconnect manually
                socket.connect();
            }
        });

        socket.on("receive_message_updateClassInformation", (data) => {
            console.log(data, 'receive_message_updateClassInformation')
            setClassInformation(data.classInformation)
        })

        socket.on("receive_refreshAll", (data) => {
            console.log(data, 'receive_refreshAll')
      
            window.location.reload();
        })

        socket.on("receive_updateNamesList", (data) => {
            console.log(data, 'receive_updateNamesList')
      
            const editNamesList = [...namesList]
            var index = editNamesList.findIndex(entry => entry.studentId === data.student.studentId)
            editNamesList[index] = {...editNamesList[index], ...data.student}
            setNamesList(editNamesList)
        })

        socket.on("receive_orderComplete", (data) => {
            console.log(data, 'receive_orderComplete')
            
            if(student.studentId !== data.student.studentId) return
            
            const editStudent = {...student}
              editStudent.chips = data.student.chips
              editStudent.chipsToday = data.student.chipsToday
  
              setStudent(editStudent)
        })

        socket.on("receive_updateOrders", (data) => {
            console.log(data, 'receive_updateOrders')
            
            if(student.studentId !== data.studentId) return
            setUpdateChips(prev => {return !prev})
            setOrders(prev => { return prev.filter(entry => entry.orderId !== data.orderId) })
        })

        socket.on("receive_chipsAdded", (data) => {
            console.log(data, 'receive_chipsAdded')

            const editStudent = {...student}
            var index = data.filtered.findIndex(entry => entry.studentId === student.studentId)
                editStudent.chips = data.filtered[index].chips
                editStudent.chipsToday = data.filtered[index].chipsToday

            setStudent(editStudent)
        })

        socket.on("receive_modalDisplay", (data) => {
            console.log(data, 'receive_modalDisplay', data.data)

            const editFocusAgreements = {...focusAgreements, ...data.data}
            setFocusAgreements(editFocusAgreements)

            if(!student.customOverride) return
            const editStudent = {...student, customOverride: false}
            setStudent(editStudent)
            updateMongo({customOverride: false}, student.dailyListId, 'dailyList')
        })

        socket.on("receive_modalOverride", (data) => {
            console.log(data, 'receive_modalOverride', data.data.studentId)
            if(student.studentId !== data.data.studentId) return
            const editStudent = {...student}
              editStudent[data.data.type] = {...editStudent[data.data.type], ...data.data.payload}
            //   editStudent.modalOverride = data.data.modalOverride
              setStudent(editStudent)
        })

        socket.on("receive_resetModal", (data) => {
            console.log(data, 'receive_modalOverride', data.data.studentId)
            if(student.studentId !== data.data.studentId) return
            const editStudent = {...student}
              editStudent[data.data.type] = {...editStudent[data.data.type], ...data.data.payload}
              setStudent(editStudent)
        })

        socket.on("receive_adminCheckoutEvent", (data) => {
            console.log(data, 'receive_adminCheckoutEvent')

            const editModuleItems = [...moduleItems]
            var index = editModuleItems.findIndex(entry => entry._id === data.event._id)
            if(index === -1) {
                console.log('failed')
                return
            }
            editModuleItems[index] = {...editModuleItems[index], ...data.event}
            setModuleItems(editModuleItems)
        })

        socket.on("receive_updateSingleStudent", (data) => {
            console.log(data, 'updateSingleStudent')
            if(!data.student || data.student.studentId !== student.studentId ) return
            const editStudent = {...student, ...data.student}
            // editStudent.color = {...editStudent.color, ...data.student.color}
            setStudent(editStudent)
        })

        socket.on("receive_checkoutEvent", (data) => {
            console.log(data, 'receive_checkoutEvent')
            const editCheckout = [...moduleItems]
            var index  = editCheckout.findIndex(entry => entry._id === data.data._id)
            if(index === -1) {
                console.log('failed')
                return
            }
            editCheckout[index] = {...editCheckout[index], ...data.data}
            // index === -1 ? editCheckout.push(data.data) : editCheckout[index] = data.data
            setModuleItems(editCheckout)
        })

        socket.on("receive_updateProgressChecklist", (data) => {
            console.log(data, 'updateProgressChecklist')

            if(data.oneModule) {
                const editFocusAgreements = {...focusAgreements}
                var index = editFocusAgreements.modules.findIndex(entry => entry.title === data.oneModule.title)
                if(index === -1) return
                editFocusAgreements.modules[index] = {...data.oneModule}
                setFocusAgreements(editFocusAgreements)
            }
        })

        socket.on("receive_updateStoreItems", (data) => {
            console.log(data, 'receive_updateStoreItems')
  
            if(data.storeItems) {
              setStoreItems(data.storeItems)
            }
        })

        socket.on("receive_focusAgreementsUpdate", (data) => {
            console.log(data, 'receive_focusAgreementsUpdate')
            if(data.focusAgreements) {
              const editFocusAgreements = {...focusAgreements}
              setFocusAgreements({...editFocusAgreements, ...data.focusAgreements})
            }
        })

        socket.on("receive_adminUpdateSingleStudent", (data) => {
            if(data.studentId !== student.studentId) return
            console.log(data, 'receive_adminUpdateSingleStudent')
            const editStudent = {...student}
            editStudent[data.param] = data[data.param]
            console.log(editStudent,'editStudent')
        })

        socket.on("receive_adminStudentDashboard", (data) => {
            console.log(data, 'receive_adminStudentDashboard')
            if(!loginState.admin) return
            setRoom(data.data.period)
            setClassInformation(data.data)
        })
        
        socket.on("refresh_socket", (data) => {
            // console.log(data, 'refresh_socket')
        })

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('receive_response_Login');
            socket.off('receive_message_updateClassInformation');
            socket.off('receive_orderComplete');
            socket.off('receive_updateOrders');
            socket.off('receive_chipsAdded');
            socket.off('receive_modalDisplay');
            socket.off('receive_modalOverride');
            socket.off('receive_adminCheckoutEvent');
            socket.off('receive_updateProgressChecklist');
            socket.off('receive_adminUpdateSingleStudent');
            socket.off('receive_checkoutEvent');
            socket.off('receive_updateProgressChecklist');
            socket.off('receive_updateSingleStudent');
            socket.off('receive_updateStoreItems');
            socket.off('receive_adminStudentDashboard');
            socket.off('receive_focusAgreementsUpdate');
            socket.off('receive_updateNamesList');
            // socket.off('refresh_socket');
        };

    }, [socket, student, focusAgreements, connected, moduleItems, room, namesList, classInformation.period])

    async function handleStudentLoad() {
    
        const dailyListRecord = await getMongoById(student.studentId, 'dailyList')
        const record = await getMongoById(student.studentId, 'records')

        // if(!checkToday(record.loginTimeStamp)) {
        //     navigate("/");
        // }       
        
        const loginInfo = {
            loginTimeStamp: Date.now(),
            loggedIn: true,
            checkIn: {...student.checkIn},
            studentId: student.studentId,
            dailyListId: dailyListRecord._id,
            todayTimestamp: Date.now()
        }

        const editStudent = {
            ...student, ...dailyListRecord, ...record, ...loginInfo,
            // period: record2.period,
            dailyListId: dailyListRecord._id,

            // might need to turn this off if it causes the runaway module change again
            module: dailyListRecord.module,
            /////////////
            
            moduleFull: ((dailyListRecord.moduleFull && dailyListRecord.moduleFull.length === 0) && dailyListRecord.module.length > 0) ? moduleFind(dailyListRecord.module, 'title', 'fullTitle') : dailyListRecord.moduleFull,
        }

        // console.log(dailyListRecord._id, 'dailyListRecord._id', loginInfo)
        
        setStudent(editStudent)
        updateMongo({...loginInfo}, dailyListRecord._id, 'dailyList')
        socket.emit("send_updateSingleStudent", { student: loginInfo, room: 'admin' })
        // socket.emit("send_updateSingleStudent", { student: loginInfo, room: student.period })
        
        posthog.identify(record.email, {
            email: record.email,
            period: record.period
        })
        posthog.group('period', record.period);

        console.log('logging in-----------', dailyListRecord.email)
    };

    return { 
        updateStudent, connected, setUpdateStudent
    }

}



import React, { useContext, useState, useEffect } from 'react'

import { SiteContext } from '../../App'
import { StudentContext } from '../../pages/StudentContainer'


import PendingItems from './PendingItems'
import './chips.css'

export default function PendingOrders({ title, colorValue, sizeClass, orders, setOrder }) {

    const {student} = useContext(SiteContext)
    const {pendingChips} = useContext(StudentContext)

    var total = 0
    var count = 0
    orders.map(entry => {
      if(entry.quantity !== 0) {
        total = total + entry.price*entry.quantity
      }
      if(entry.studentId === student.studentId) {count = count + 1}

    })

    // console.log(orders, 'orders pending', count)

    return (

      <div className={`${sizeClass}`}>
        <div className={`card-shadow-${colorValue} mb-3 widget-chart widget-chart2 text-start card`}>
          <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className={`widget-content-left pe-2 fsize-1 text-${colorValue}`}>
                  <div className="widget-numbers mt-0 fsize-3">
                      {title}
                      <div className={`mb-2 me-2 badge bg-${colorValue}`} style={{marginLeft:"15px"}}>{pendingChips}€</div>
                  </div>
                </div>
              </div>
              <div className="">
                      <div className="fsize-1">
                          <div className="ingredient-grid3 text-muted">
                              {count === 0 && <span className="bold">No Pending Orders</span>}
                              {count > 0 && <span className="bold">Price</span>}
                              {count > 0 && <span className="bold">Item</span>}
                              {count > 0 && <span className="bold"></span>}
                              {count > 0 && orders.map(entry => {
                                  if(entry.quantity === 0) {return}
                                  if(entry.studentId !== student.studentId) {return}
                                  // console.log('returning')
                                  return (
                                      <PendingItems entry={entry} key={entry.timeStamp} colorValue={colorValue}/>
                                  )
                              })}
                          </div>
                      </div>
                </div>  
              </div>
          </div>
        </div>
      </div>

    )
}

import React, {useContext} from 'react'
import { AdminContext } from './AdminContainer';

import Warnings from '../admin/grid/GridContainer';

export default function Grid({}) {

    const { hpMessage, orders, modes, mode } = useContext(AdminContext)

    return (
        <Warnings sizeClass={'col-12'} mode={mode} />
    )
}

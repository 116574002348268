import React, {useRef} from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie, getElementAtEvent } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const colors = ['#dcf3ff','#c8e4ec','#f9fafc','#313131'] //'#d2e7d6','#dcf3ff','#a2d2df' 1: dark 2: second 3: light 4: border
const hexPalletURL= 'https://www.color-hex.com/color-palette/68923'  /// green pallet

const options = {
    responsive: true,
    maintainAspectRatio: false,
    // rotation: 90,
    plugins: {
      legend: {
        display:false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        anchor: "center", //start, center, end
        color: colors[3],
        font: {
          weight: 'bold'
        },
        padding: 6,
        rotation: function(ctx) {
          const valuesBefore = ctx.dataset.data.slice(0, ctx.dataIndex).reduce((a, b) => a + b, 0);
          const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
          const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex] /2) /sum *360);
          return rotation < 180 ? rotation-90 : rotation+90;
        },
        formatter: function (value, context) {
            if(context.datasetIndex === 1) {
                var setIndex = 68
            } else if(context.datasetIndex === 2) {
                var setIndex = 68 + 34
            } else {
                var setIndex = 0
            }
            const labelIndex = setIndex + context.dataIndex;
            return context.chart.data.labels[labelIndex]
        },
      },
    },
}

const love = [
    {word: 'Affectionate', children: ['Romantic','Fondness']},
    {word: 'Longing', children: ['Sentimental','Attracted']},
    {word: 'Desire', children: ['Passion','Infatutation']},
    {word: 'Tenderness', children: ['Caring','Compassionate']},
    {word: 'Peaceful', children: ['Relieved','Satisfied']}
]

const fear = [
    {word: 'Scared', children: ['Frightened','Helpless']},
    {word: 'Terror', children: ['Panic','Hysterical']},
    {word: 'Insecure', children: ['Inferior','Inadequate']},
    {word: 'Nervous', children: ['Worries','Anxious']},
    {word: 'Horror', children: ['Horrified','Dread']}
]

const anger = [
    {word: 'Rage', children: ['Hate','Hostile']},
    {word: 'Exasperated', children: ['Agitated','Frustrated']},
    {word: 'Irritable', children: ['Annoyed','Aggravated']},
    {word: 'Envy', children: ['Resentful','Jealous']},
    {word: 'Disgust', children: ['Distaste','Revolted']},
]

const sadness = [
    {word: 'Suffering', children: ['Agony','Hurt']},
    {word: 'Sadness', children: ['Depressed','Sorrow']},
    {word: 'Disappointed', children: ['Displeased','Dismayed']},
    {word: 'Shameful', children: ['Regretful','Guilty']},
    {word: 'Neglected', children: ['Lonely','Isolated']},
    {word: 'Despair', children: ['Grief','Powerless']}
]

const surprise = [
    {word: 'Stunned', children: ['Shocked','Dismayed']},
    {word: 'Confused', children: ['Disillusioned','Perplexed']},
    {word: 'Amazed', children: ['Astonished','Awe-struck']},
    {word: 'Overcome', children: ['Speechless','Astonished']},
    {word: 'Moved', children: ['Stimulated','Touched']},
]

const joy = [
    {word: 'Content', children: ['Pleased','Satisfied']},
    {word: 'Happy', children: ['Amused','Delighted']},
    {word: 'Cheerful', children: ['Joyful','Bliss']},
    {word: 'Proud', children: ['Triumphant','Illustrious']},
    {word: 'Optimistic', children: ['Eager','Hopeful']},
    {word: 'Enthusiastic', children: ['Excited','Zeal']},
    {word: 'Elation', children: ['Euphoric','Jubilation']},
    {word: 'Enthralled', children: ['Enchanted','Rapture']},
]

export default function ChartJs({words, setWords}) {

const chartRef = useRef();
const onClick = (event) => {
    const elementAtEvent = getElementAtEvent(chartRef.current, event)
    // conso?le.log(elementAtEvent[0].index, 'elementsAtEvent.index',elementAtEvent[0].datasetIndex);
    console.log(elementAtEvent[0],'console.log(elementAtEvent[0]')
    const dataSets = [thirdLevel, secondLevel, firstLevel]

    const editWords = [...words]
    for(let i = 0; i < words.length; i++) {
      if(words[i] === '') {
        editWords[i] = dataSets[elementAtEvent[0].datasetIndex][elementAtEvent[0].index]
        setWords(editWords)
        break
      }
    }
    // elementAtEvent[0]._chart.controller.config.data.datasets[0]._meta[0].data[elementAtEvent[0]._index]._model.backgroundColor = 'red'
    // console.log(dataSets[elementAtEvent[0].datasetIndex][elementAtEvent[0].index], 'word')
    // console.log(dataSets[elementAtEvent[0].datasetIndex],'dataSets[elementAtEvent[0].datasetIndex]')
    // elementAtEvent[0].element.options.backgroundColor = 'red'
    // ChartJS.render(0,true);
}

const tree = [
  {word: 'Love', children: love},
  {word: 'Fear', children: fear},
  {word: 'Anger', children: anger},
  {word: 'Sadness', children: sadness},
  {word: 'Surprise', children: surprise},
  {word: 'Joy', children: joy},
]

const firstLevel = ['Love','Fear','Anger','Sadness','Surprise','Joy']
const secondLevel = []
const thirdLevel = []

const firstLevelVal = [10,10,10,12,10,16]
const secondLevelVal = []
const thirdLevelVal = []

const firstLevelBg = [colors[0],colors[1],colors[2],colors[0],colors[1],colors[2]]
const secondLevelBg = []
const thirdLevelBg = []

const mapEntries = [love, fear, anger, sadness, surprise, joy]

var count2 = 0
var count3 = 0

mapEntries.map(word => {
    count2++
    count3++
    word.map(entry => {
        // console.log(count2,'count2')
        secondLevel.push(`${entry.word}`)
        secondLevelVal.push(entry.children.length)

        if(count2 === 1 || count2 === 4) {
            var bgColor2 = colors[0]
        } else if(count2 === 2 || count2 === 5) {
            var bgColor2 = colors[1]
        } else {
            var bgColor2 = colors[2]
        }

        secondLevelBg.push(bgColor2)
        entry.children.map(item => {
            // count++
            thirdLevel.push(`${item}`)
            thirdLevelVal.push(1)

            if(count3 === 1 || count3 === 4) {
                var bgColor3 = colors[0]
            } else if(count3 === 2 || count3 === 5) {
                var bgColor3 = colors[1]
            } else {
                var bgColor3 = colors[2]
            }

            thirdLevelBg.push(bgColor3)
        })
    })
})

const data = {
    labels: [...thirdLevel, ...secondLevel, ...firstLevel],
    datasets: [
          {
            backgroundColor: thirdLevelBg,
            data: thirdLevelVal,
            label: 'Level 3',
            borderWidth: 2,
            borderColor: colors[3],
          },
          {
            backgroundColor: secondLevelBg,
            data: secondLevelVal,
            label: 'Level 2',
            borderWidth: 2,
            borderColor: colors[3]
          },
          {
            backgroundColor: firstLevelBg,
            data: firstLevelVal,
            label: 'Level 1',
            borderWidth: 2,
            borderColor: colors[3]
          }
    ]
  };

  return (
    <Pie data={data}  
      ref={chartRef}
      onClick={onClick}
      options={options} plugins={[ChartDataLabels]}
    />
  )

}
import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../../App'

export default function ModuleEntry({ entry, bgVariant }) {

    const { 
        colorMatch, studentList, student,
        studentDataFunctions: { handleDateString }
    } = useContext(SiteContext)

    var bgColor = entry.type === 'Test' ? 'bg-black' : 
    entry.type === 'Activity' ? 'bg-purple' : 
    entry.type === 'Design Brief' ? 'bg-primary' : 'bg-secondary'

    function isEven(n) {
        return n % 2 == 0;
    }

    var bgColor2 = !isEven(bgVariant) ? 'bg-light' : ''
    return (
        <tr className={`${bgColor2}`}>
            <td className="text-center">
                <div className="box">
                    <span className="text">{handleDateString(entry.completeEvent.date)}</span>
                </div>
            </td>
            <td className="">
                <div className={`mb-2 me-2 badge ${bgColor}`}>{entry.type}</div>
            </td>
            <td className="">
                {/* <div style={{wordWrap:"break-word", width:"100%"}}>{reasonString}</div> */}
                <div className="box">
                    <div className={`mb-2 me-2 badge bg-${'secondary'}`}>{entry.day}</div>
                    <span className="text">{entry.item}</span>
                    {/* <span className="text">{entry.reason.slice(0,50)}</span> */}
                </div>
            </td>
        </tr>
    )
}

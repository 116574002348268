import React, { useContext, useEffect, useState } from 'react'

import { SiteContext, baseUrl } from '../App';

import PQuizGroup from '../admin/pquiz/PQuizGroup';
import PQuizNorm from '../admin/pquiz/PQuizNorm';

export default function PQuiz() {

    const {
        socket,
        room,
        studentDataFunctions: {
        }
    } = useContext(SiteContext)

    const [presentMode, setPresentMode] = useState(false)
    const [groupScores, setGroupScores] = useState({
        "Group 1": 'C',
        "Group 2": 'C',
        "Group 3": 'C',
        "Group 4": 'C',
        "Group 5": 'C',
        "Group 6": 'C',
        "Group 7": 'C',
        "Group 8": 'C',
    })

    console.log(presentMode, 'pmode')

    useEffect(() => {

        socket.on("receive_pquiz", (data) => {
            console.log(data, "receive_pquiz")
            fetchData();        
        })
    
        return () => {
          socket.off('receive_pquiz');
        };
    
    },[socket])

    useEffect(() => {

        fetchData()
      
        return;
      }, []);
 
  async function fetchData() {
        
    const response = await fetch(`${baseUrl}/readOne/groupScores/6308092236718869114dee76`);

    if (!response.ok) {
        const message = `An error has occurred records edit: ${response.statusText}`;
        window.alert(message);
        return;
    }

    const scores = await response.json();
    if (!scores) {
        window.alert(`Record with id not found`);
        // navigate("/recordsNew");
        return;
    }
    
    delete scores["_id"];

    setGroupScores(scores)

  }

  async function handleChange(grade, group) {

    var editGroupScores = {...groupScores}
        editGroupScores[group] = grade

    // This will send a post request to update the data in the database.
    await fetch(`${baseUrl}/update/groupScores/6308092236718869114dee76`, {
      method: "POST",
      body: JSON.stringify(editGroupScores),
      headers: {
        'Content-Type': 'application/json'
      },
    });

    setGroupScores(editGroupScores)

    socket.emit("send_pquiz", { message: "updated pquiz!", room: 'admin' });
  }

    const groups = ['Group 1','Group 3','Group 5','Group 7',
                    'Group 2','Group 4','Group 6','Group 8']
    
    return (
        <>
          <PQuizNorm presentMode={presentMode} setPresentMode={setPresentMode} />
          <div className="row">
            {groups.map(entry => {
                return (
                    <PQuizGroup 
                      key={entry} 
                      group={entry} 
                      groupScores={groupScores} 
                      handleChange={handleChange} 
                      presentMode={presentMode}
                    />
                )
            })}
          </div>
        </>
    )
}
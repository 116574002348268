
export default function birthdayFunction (birthdayVal, student) {

    if(!birthdayVal || !birthdayVal.split('/')[1] || !birthdayVal.split('/')[0]) return false
    const month = birthdayVal.split('/')[0]
    const day = birthdayVal.split('/')[1].split('/')[0]

    var monthParse = parseInt(month)
    var dayParse = parseInt(day)
    
    var dateNow = new Date()
        var dayNow = dateNow.getDate()
        var monthNow = dateNow.getMonth() + 1

    if(monthNow !== monthParse) return false

    if(dayNow === dayParse) return '🥳 '
    if(dayNow + 1 === dayParse) return '🥳1 '
    if(dayNow + 2 === dayParse) return '🥳2 '
    if(dayNow - 1 === dayParse) return '1🥳 '
    if(dayNow - 2 === dayParse) return '2🥳 '

    return false
    
}
import React from 'react'

export default function Item({entry, count, handleFunctions}) {

    var {updateChecklist, showTasks, setShowTasks} = handleFunctions

    var typeBg = entry.type === 'Test' ? 'bg-black' :
                 entry.type === 'Activity' ? 'bg-purple' :
                 entry.type === 'Design Brief' ? 'bg-primary' : 'bg-secondary'

    function handleTasks() {
        setShowTasks(prev => {return !prev})
    }

    if(entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview' && !showTasks) return
    var showTasksBadge = (entry.type !== 'Design Brief' || entry.designBriefTask !== 'Overview') ? false : true
    var showBadges = (entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview') ? false : true

    return (
    <div className="mt-3 mb-3 d-flex">
        <div className={`d-flex align-items-stretch me-3`} style={{minWidth:"30px"}}>
            {(entry.type !== 'Design Brief' || entry.designBriefTask === 'Overview') && <div className={`card ${typeBg} text-white w-100`}>
              <div className="d-flex align-items-center justify-content-center" style={{height:"100%"}}>
                  {entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview' ? entry.designBriefTask : <h2 className="m-0">{count}</h2>}
              </div>
            </div>}
        </div>
        <div className={`d-flex align-items-stretch w-100`}>
          <div className="card w-100">
            <div className="card-body">
              <div className="d-flex align-items-stretch justify-content-between">
                <span>
                  <div>
                    {entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview' && <div className={`me-2 badge text-primary bg-light d-inline`}>{entry.designBriefTask}</div>}
                    {entry.link ? <a href={entry.link} target="_blank">{entry.item}</a> : `${entry.item}`}
                  </div>
                  {/* <div className="d-flex justify-content-between align-items-baseline"> */}
                  {showBadges && <span>
                    {/* {(entry.type !== 'Design Brief' || entry.designBriefTask === 'Overview') ?
                    <> */}
                    <div className={`badge mt-2 bg-secondary`}>{entry.day}</div>
                    <div className={`ms-2 badge ${typeBg}`}>{entry.type}</div>
                    {showTasksBadge && <div className={`ms-3 badge bg-light text-primary`} onClick={handleTasks}>{showTasks ? 'Hide Tasks' : 'Show Tasks'}</div>}
                    {/* </> : <span className="text-white m-1"></span>} */}
                  </span>}
                </span>
              </div>
            </div>
          </div>
        </div>
    </div>
    )
}

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function CheckoutOverlay({entry}) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
    <div>
      {entry.checkoutItems.map(entry => {
          if(!entry.checkedOut) return
          return <div className="card bg-secondary text-white mb-1">{entry.item}</div>
      })}
    </div>
    </Tooltip>
  );

  var badgeBg = entry.checkoutItems.length > 3 ? 'bg-danger' :
                entry.checkoutItems.length > 1 ? 'bg-flag' : 'bg-secondary'
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
        <div className={`checkoutsGrid ${badgeBg} w-auto h-auto ps-1 pe-1 text-white`}>
            {entry.checkoutItems.length}
        </div>
    </OverlayTrigger>
  );
}

import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { SiteContext } from "../../App";
 
export default function NominateModal({handleShow, handleClose, showModal, pointsForm}) {

    const {colorMatch, focusAgreements} = useContext(SiteContext)

 // This following section will display the form that takes input from the user to update the data.
 return (

    <Modal 
      show={showModal}
      onHide={handleClose}
      // style={{width:"80%"}}
      // dialogClassName="modal-height"
    >
      <Modal.Header closeButton>
        <Modal.Title>🎉 Class Point{pointsForm.change > 1 && 's'} Earned! 🎉</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center">
          <div className="fsize-3 badge bg-primary d-flex align-items-center">
            <h4 className="mb-0 me-2 fsize-1">New Total:</h4>
            <span>{focusAgreements.classPoints} Point{focusAgreements.classPoints > 1 && 's'}</span> 
          </div>
        </div>
        {pointsForm.change > 1 && <div className="fsize-1 d-flex align-items-center">
          <span>Points Added: </span> 
          <span className="ms-2">{pointsForm.change}</span> 
        </div>}
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
);
}




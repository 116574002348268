import React, { useEffect, useContext, useState } from 'react'
import { SiteContext } from '../../App'
import { isMobile } from 'react-device-detect';

export function useSocket({orders, setOrders, setUpdateOrders, setUpdateBEvents, setUpdateHPEvents, 
  type, moduleItems, setModuleItems, checkoutItems, setCheckoutItems, handlePeriodSelect, handleShow,
  behaviorEvents, setBehaviorEvents, handleAttendance }) {

    const {
        classInformation, socket, studentList, loginState, room, focusAgreements, classInformationArray,
        studentDataFunctions: {
            setRoom, joinRoom, setStudentList, setFocusAgreements, setStoreItems, updateMongo,
        }
    } = useContext(SiteContext)

    const [connected, setConnected] = useState(false)
    const [updateTime, setUpdateTime] = useState(Date.now())
    useEffect(() => {

        setRoom(classInformation.period)
        classInformationArray.map(entry => {
          if(entry.archived || entry.period == 'Dropped') return
          joinRoom('Mr.Smith', entry.period)
        })
        
        setConnected(true)

    }, [classInformationArray.length])

    function refreshAllStudents () {
      socket.emit("send_refreshAll", { refresh: true, room });
    };

    useEffect(() => {
        
        function sendResponse_Login (studentObj) {
            socket.emit("send_response_Login", { studentObj, room });
        };

        socket.on('connect', function() {
          console.log('connected', socket.connected);
          socket.emit("join_room", {room: 'admin', personName: 'Mr. Smith'});
          setConnected(true)
        });

        socket.on('disconnect', function(reason) {
          console.log('disconnected', socket.connected, reason);
          if(type === 'dashboard') {
            // alert("disconnected");
            setConnected(false)
          }
        });
        
        socket.on("receive_inventoryItem", (data) => {
          console.log('receive_inventoryItem', data)
          // moduleItems, setModuleItems
          setModuleItems(prev => {return [data.createObj, ...prev]})
       })

        socket.on("receive_message_Login", (data) => {
          if(!loginState.admin) {return}

          console.log(data, 'receive_message_Login')

          const editStudentList = [...studentList]
          var index = editStudentList.findIndex(entry => entry.studentId === data.studentObj.studentId)
          if(index === -1) return
          editStudentList[index].loggedIn = true
          editStudentList[index].loginTimeStamp = data.studentObj.loginTimeStamp
          
          setStudentList(editStudentList)
          sendResponse_Login(data.studentObj)
    
        })

        socket.on("receive_order", (data) => {
            console.log(data, 'receive_order', orders)
            const editOrders = [...orders]
            setOrders([...editOrders, data.order])
        })

        socket.on("receive_deleteOrder", (data) => {
          console.log(data, 'receive_deleteOrder', orders)
          const editOrders = [...orders]
          setOrders(editOrders.filter(entry => entry.timeStamp !== data.order.timeStamp))
        })

        socket.on("receive_checkoutEvent", (data) => {
          console.log(data, 'receive_checkoutEvent')
          const editCheckout = [...checkoutItems]
          var index  = editCheckout.findIndex(entry => entry._id === data.data._id)

          index === -1 ? 
            editCheckout.push(data.data) : 
            editCheckout[index] = {...editCheckout[index], ...data.data}
          // const obj = {checkedOut, checkoutEvent: {...event, adminProcessed: false}, checkoutTimestamp: today}
          setCheckoutItems(editCheckout)
        })

        socket.on("receive_changePeriod", (data) => {
          console.log(data, 'receive_changePeriod')
          // if(updateTime + 1000 > Date.now()) return
          handlePeriodSelect(data.data)
          // setUpdateTime(Date.now())
        })

        socket.on("receive_adminBehaviorUpdate", (data) => {
          console.log(data, 'receive_adminBehaviorUpdate')
          if(data.behaviorEvents) {
            setBehaviorEvents(data.behaviorEvents)
          }
        })

        socket.on("receive_studentListUpdate", (data) => {
          console.log(data, 'receive_studentListUpdate')
          if(data.studentList) {
            setStudentList(data.studentList)
          }
        })

        socket.on("receive_focusAgreementsUpdate", (data) => {
          console.log(data, 'receive_focusAgreementsUpdate')
          if(data.focusAgreements) {
            const editFocusAgreements = {...focusAgreements}
            // console.log('here')
            setFocusAgreements({...editFocusAgreements, ...data.focusAgreements})
            // console.log({...editFocusAgreements, ...data.focusAgreements},'{...editFocusAgreements, ...data.focusAgreements}')
          }
        })

        socket.on("receive_checkoutsUpdate", (data) => {
          console.log(data, 'receive_checkoutsUpdate')
          if(data.checkoutItems) {
            setCheckoutItems(data.checkoutItems)
          }
        })

        socket.on("receive_updateSingleStudent", (data) => {
          // console.log(data, 'updateSingleStudent admin')
          if(data.student) {
            const editStudentList = [...studentList]
            var index = editStudentList.findIndex(studentEntry => studentEntry.studentId === data.student.studentId)
            if(index === -1) return
            editStudentList[index] = {...editStudentList[index], ...data.student}

            setStudentList(editStudentList)
          }
        })

        socket.on("receive_refreshAll", (data) => {
          console.log(data, 'receive_refreshAll')
    
          window.location.reload();
        })

        socket.on("receive_updateProgressChecklist", (data) => {
          console.log(data, 'updateProgressChecklist admin')

          if(data.oneModule) {
            const editFocusAgreements = {...focusAgreements}
            var index = editFocusAgreements.modules.findIndex(entry => entry.title === data.oneModule.title)
            if(index === -1) return
            editFocusAgreements.modules[index] = {...editFocusAgreements.modules[index], ...data.oneModule}
            setFocusAgreements(editFocusAgreements)
            // updateMongo({modules: [...editFocusAgreements.modules]}, editFocusAgreements._id, 'focusAgreements')
          } else if(data.focusAgreements) {
            const editFocusAgreements = {...focusAgreements}
            setFocusAgreements({...editFocusAgreements, ...data.focusAgreements})
            // updateMongo({modules: [...editEntry.modules]}, focusAgreements._id, 'focusAgreements')
          }
        })

        socket.on("receive_updateSingleProgressChecklist", (data) => {
          console.log(data, 'updateProgressChecklist admin')

        })

        socket.on("receive_updateStoreItems", (data) => {
          console.log(data, 'receive_updateStoreItems')

          if(data.storeItems) {
            setStoreItems(data.storeItems)
          }
        })

        socket.on("receive_outcomes", (data) => {
          console.log(data, "receive_outcomes")
          
          if(isMobile) return 

          const attendanceOutcome = {
            period: data.period,
            failures: [],
            message: JSON.stringify(data)
          }

          if(data.attendanceStatus.length > 0) {
            var string = ''
            data.attendanceStatus.map(entry => {
              // string = string + ` ${entry.periodReal} ${entry.text},`
              attendanceOutcome.failures.push({name: entry.periodReal, message: entry.text})
            })
            attendanceOutcome.period = 'Attendance Check'
            // alert(string,'string')
            // return
          }
          else if (data.failures && data.failures.length > 0) {
            data.failures.map(entry => {
              attendanceOutcome.failures.push({name: entry.name, message: entry.message})
            })
          }

          const payload = {
            // title: `Attendance Outcome`,
            attendanceOutcome
          }

          handleShow(undefined, 'powerSchool', payload)
        })

        socket.on("receive_runAll", (data) => {
          console.log(data, "receive_runAll")

          var stop = false
          data.runAll.map(entry => {
            if(entry.complete || stop) return
            handleAttendance(entry, undefined, data.runAll)
            stop = true
          })
        })

        socket.on("receive_updateBehaviorEvents", (data) => {
          console.log(data, "receive_updateBehaviorEvents")
          
          setBehaviorEvents(data.behaviorEvents)
        })
        
    
        return () => {
          socket.off('connect');
          socket.off('disconnect');
          socket.off('receive_message_Login');
          socket.off('receive_order');
          socket.off('receive_deleteOrder');
          socket.off('refresh_socket');
          socket.off('receive_changePeriod');
          socket.off('receive_inventoryItem');
          socket.off('receive_checkoutEvent');
          socket.off('receive_outcomes');
          socket.off('receive_runAll');
          socket.off('receive_adminBehaviorUpdate');
          socket.off('receive_studentListUpdate');
          socket.off('receive_focusAgreementsUpdate');
          socket.off('receive_checkoutsUpdate');
          socket.off('receive_updateProgressChecklist');
          socket.off('receive_updateSingleProgressChecklist');
          socket.off('receive_updateSingleStudent');
          socket.off('receive_updateStoreItems');
          socket.off('receive_refreshAll');
          socket.off('receive_updateBehaviorEvents');
        };
    
      }, [socket, studentList, orders, room, connected, checkoutItems, behaviorEvents, focusAgreements, checkoutItems, classInformationArray.length])

      useEffect(() => {

        let interval;
        interval = setInterval(() => {
            // socket.emit("refresh_socket", { refresh: true });
            // console.log(socket.connected,'socket.connected-----')
            setUpdateOrders(prev => {return !prev})
        }, 20000);
        return () => clearInterval(interval)

      }, [])

      useEffect(() => {

        let interval;
        interval = setInterval(() => {
            setUpdateBEvents(prev => {return !prev})
            setUpdateHPEvents(true)
        }, 60000);
        return () => clearInterval(interval)

      }, [socket])

      return {
        refreshAllStudents, connected
      }
}





          // const filtered = studentList.filter(entry => entry.studentId === data.studentObj.studentId)[0]
          //   const checkinObj = data.studentObj.checkIn
    
          //   if(checkinObj.complete) {
          //     filtered.checkIn = checkinObj
          //     filtered.color.pending = data.studentObj.color.pending
          //   }
    
          //   filtered.loginTimeStamp = data.studentObj.loginTimeStamp
          //   filtered.loggedIn = data.studentObj.loggedIn

          //   var modifiedStudentList = [...studentList]
          //       const filteredStudentList = modifiedStudentList.filter(entry => entry.studentId !== filtered.studentId)
          //       filteredStudentList.push(filtered)
      
            // setStudentList(filteredStudentList)  
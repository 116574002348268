import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../../App'

export default function D_BehaviorEntry({ entry, variantsExport }) {

    const { 
        colorMatch,
        studentList,
        student,
        studentDataFunctions: {
        }
    } = useContext(SiteContext)
    
    const match = colorMatch.filter(item => item.color == entry.color)[0]
        const matchVariant = match.variant

    var matchOld = colorMatch.filter(item => item.color == entry.oldColor)[0]
        const matchVariantOld = matchOld.variant

    var bgLevelChange = entry.levelChange > 0 ? 'success' : 'danger'
    var bgType = entry.type === 'Self' ? 'success' : 'primary'

    const matchChangeGreen = colorMatch.filter(item => item.color == 'Green')[0]
        const matchChangeGreenHex = matchChangeGreen.hex

    const matchChangeRed = colorMatch.filter(item => item.color == 'Red')[0]
        const matchChangeRedHex = matchChangeRed.hex
    
    var hexLevelChange = entry.levelChange > 0 ? matchChangeGreenHex : matchChangeRedHex

    var catVariant = 'secondary'
    var index = variantsExport && variantsExport.findIndex(item => item.category === entry.category)
        // if(index === -1) {catVariant = 'secondary'}
        catVariant = variantsExport && variantsExport[index] && variantsExport[index].variant

    return (
        <tr className={`${''}`}>
            <td className="text-center">
                <div className="box">
                    <span className="text">{parseInt(entry.timeElapsed)} min</span>
                </div>
            </td>
            <td className="text-center">
                <div>
                    <div className={`badge rounded-pill bg-${matchVariantOld} text-${matchVariantOld}`} style={{width:"5px", height:"20px"}}>{`.`}
                    </div>
                    {` `}→{` `}
                    <div className={`badge rounded-pill bg-${matchVariant} text-${matchVariant}`} style={{width:"5px", height:"20px"}}>{`.`}
                    </div>
                    <div className={`badge rounded-pill text-${bgLevelChange}`} style={{margin:"10px", border:`2px solid ${hexLevelChange}`}}>
                        {entry.levelChange > 0 && '+'}
                        {entry.levelChange}
                    </div>
                </div>
            </td>
            <td className="text-center">
                {/* <div> */}
                <div className={`badge rounded-pill bg-${bgType}`} style={{margin:"10px"}}>
                        {entry.type}
                    </div>
                {/* </div>
                <div>
   
                </div> */}
            </td>
            <td className="">
                {/* <div style={{wordWrap:"break-word", width:"100%"}}>{reasonString}</div> */}
                <div className="box">
                    <div className={`mb-2 me-2 badge bg-${catVariant}`}>{entry.category}</div>
                    {/* <span className="text">{entry.reason}</span> */}
                    {/* <span className="text">{entry.reason.slice(0,50)}</span> */}
                </div>
            </td>
            <td className="">
                {/* <div style={{wordWrap:"break-word", width:"100%"}}>{reasonString}</div> */}
                <div className="box">
                    {/* <div className={`mb-2 me-2 badge bg-${'secondary'}`}>{entry.category}</div> */}
                    <span className="text">{entry.reason}</span>
                    {/* <span className="text">{entry.reason.slice(0,50)}</span> */}
                </div>
            </td>
        </tr>
    )
}

import React, { useState, useEffect, useContext } from 'react';

import { SiteContext, baseUrl } from '../../../../App';
import { AdminContext } from '../../../../pages/AdminContainer';

import ModuleCard from './ModuleCard';
// import Scroll from '../../../admin/classEdit/Scroll'
import dailyMaterials from '../../../../other/json/dailyMaterials.json'

export default function InventoryByDay() {
    
      const { 
        modules, student, moduleItems, moduleField,
        studentDataFunctions: {
          moduleFind, getMongoAllByParam, getMongoList, setModuleField, setModuleItems
        }
      } = useContext(SiteContext)
    
      const{ 
      } = useContext(AdminContext)
    
      const [searchField, setSearchField] = useState("");
      const [module, setModule] = useState("")
    
      console.log(dailyMaterials,'materialsByDay')

      const handleChangeModule = (e) => {
        e.preventDefault()
        setModuleField(e.target.value);
      }
    
      const filtered = dailyMaterials.filter(
        person => {
          return (
            person.module.toLowerCase().includes(moduleField.toLowerCase()) 
          );
        }
      )
    
      return (
        <section className="">
           <select
            className="form-control"
            style={{display:"inline", width:"25%", margin: "5px"}}
            onChange={(e) => handleChangeModule(e)}
          >
            <option value={``}>-- Select Module --</option>
            {dailyMaterials.map(entry => {
                return (
                    <option key={entry.module} value={entry.module}>{entry.module} </option>
                )
            })}
          </select>
          {/* <Scroll>
            <div className="row">
              {dailyMaterials.map(entry => {
                  return <ModuleCard key={entry.module} entry={entry} />
              })}
            </div>
          </Scroll> */}
        </section>
      ) 
    }


import React, { useContext } from 'react'
import { SiteContext } from '../../App';

import Accordion from 'react-bootstrap/Accordion';
import Calendar  from '../../admin/schedulesCalendar/Calendar';
import CalendarPicker from 'react-calendar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';

export default function CalendarAccordion({sizeClass, title}) {

  const { 
    classInformation: {
    }
  } = useContext(SiteContext)

    return (
      <div className={sizeClass}>
        <div className="main-card mb-3 card">
          <div className="card-header">
            <FontAwesomeIcon style={{ marginRight: 10 }} icon={faCalendar} />
            {title}
            <a 
              href="https://www.wccusd.net/cms/lib/CA01001466/Centricity/domain/1/calendars/School%20Calendar%2023-24%20-Revised%207.8.21.pdf"
              style={{marginLeft:"auto"}}
              target="_blank"
            >District Calendar</a>
          </div>
          <div className="card-body">
            <Accordion>
              <Accordion.Item eventKey={'sem1'}>
                <Accordion.Header>Calendar</Accordion.Header>
                <Accordion.Body>
                  <Calendar/>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    )
}

import React, { useState, useEffect, useContext } from "react";
import { SiteContext } from "../../App";

export default function useStopwatch(subtitle, classStart, classEnd) { 

  const {classInformation} = useContext(SiteContext)

    const [time, setTime] = useState(0);
    const [running, setRunning] = useState(false);

    // console.log('hello', subtitle, classStart, classEnd)
    var startMilli = convertToMilli(classStart, subtitle)
    var endMilli = convertToMilli(classEnd, subtitle)

    var timeImportDateMilli = subtitle === 'Time Elapsed' ? startMilli : endMilli

    var milliNow = Date.now()

    useEffect(() => {

      if(typeof classStart === 'undefined' || typeof classEnd === 'undefined') return
      var dateNowMilli = Date.now()

      subtitle === 'Time Elapsed' ? setTime(dateNowMilli-timeImportDateMilli) : setTime(timeImportDateMilli-dateNowMilli)
      setRunning(true)

    }, [classStart, classEnd, classInformation.period])

    useEffect(() => {

      let interval;

      if (running && subtitle === 'Time Elapsed') {

        interval = setInterval(() => {
          setTime(Date.now() - timeImportDateMilli + 10);
        }, 10);

      } else if (running && (subtitle === 'Time Remaining' || subtitle === 'Time Til Cleanup')) {

        interval = setInterval(() => {
          setTime(timeImportDateMilli - Date.now() - 10);
        }, 10);

      } else if (!running) {
        clearInterval(interval);
      }

      return () => clearInterval(interval);

    }, [running, classStart, classEnd]);

    var minutes = ("0" + Math.floor((time / 60000) % 60)).slice(-2)
    var seconds = ("0" + Math.floor((time / 1000) % 60)).slice(-2)

      if(minutes > 50 && subtitle === 'Time Elapsed' ) {
        minutes = '50'
        seconds = '00'
      } 
      else if(minutes < 0) {
        minutes = ("0" + Math.floor((time / 60000) + 1 % 60)).slice(-2)
      }

      if(classStart === 'No School' || classEnd === 'No School') {
        return 'No School'
      }

      if(subtitle === 'Time Remaining' && milliNow < startMilli) return `${classInformation.informationStrings ? classInformation.informationStrings.periodAbbreviation : classInformation.period} Not Started`

      if(milliNow > endMilli) {
        // console.log(milliNow,'milliNow', endMilli,'endMilli')
        if(subtitle === 'Time Elapsed') {
          return 'Class'
        } else if(subtitle === 'Time Til Cleanup'){
          return 'Clean Up!'
        } else {
          return 'Complete'
        }
      }

    if(minutes.substring(0, 1) == '0') {minutes = minutes.slice(1)}

    return `${minutes}:${seconds}`
};

function convertToMilli(time, subtitle) {
  var hours = parseInt(time.split(':')[0])
  var min = time.split(':')[1]
  // hours = parseInt(hours)
  // if(hours < 8) {hours = hours + 12}

  var timeImportDate = new Date()
    timeImportDate.setHours(hours, min, 0)

  if(subtitle === 'Time Til Cleanup') {
    timeImportDate = removeMin(timeImportDate, 4)
  }

  // console.log(timeImportDate,timeImportDate.getTime(),'timeImportDate', hours)

  return timeImportDate.getTime()
}

function removeMin(inputTime, subtraction) {
  return new Date(inputTime - subtraction * 60000)
}
import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { SiteContext } from "../../App";
 
export default function NominateModal({handleShow, handleClose, showModal, modalForm}) {

    const{colorMatch} = useContext(SiteContext)

    var newString = ''
    if(modalForm.newColor.length > 0) {
        const match = colorMatch.filter(item => item.color === modalForm.newColor)
            newString = match[0].string
    }

 // This following section will display the form that takes input from the user to update the data.
 return (

    <Modal 
      show={showModal} 
      onHide={handleClose}
      // style={{width:"80%"}}
      // dialogClassName="modal-height"
    >
      <Modal.Header closeButton>
        <Modal.Title>Nomination!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 style={{marginBottom:"20px"}}>New Color: &nbsp; {newString}</h4>
        <div className="recipe__row">
            <div className="recipe__value recipe__value--indented">
                <div className="ingredient-grid2">
                    <span className="bold">Nominated By:</span><span className="">{modalForm.studentInitiatingName}</span>
                    <span className="bold">Category:</span><span className="">{modalForm.category}</span>
                    <span className="bold">Reason:</span><span className="">{modalForm.reason}</span>
                    {/* <span className="">Monday, October 10</span><span className="">No School</span> */}
                </div>
            </div>
        </div>  
        {/* <p>Nominated By: {modalForm.studentNominatingName}</p>
        <p>Category: {modalForm.category}</p>
        <p>Reason: {modalForm.reason}</p> */}
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
);
}




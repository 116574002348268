import React, { useContext, useState, useEffect } from 'react'

import { SiteContext } from '../../../App'
import DeleteModal from './DeleteModal';
import { usePhotoGallery } from './upload/usePhotoGallery.ts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faX, faArrowsRotate, faCheck, faArrowDown, faArrowUp, faPlus, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

import { ErrorBoundary } from '../../../other/utils/ErrorBoundary';

export default function AItem({item, setNewItems, viewOptions, loadItems, setModuleItems}) {

    const {
        classInformation, modules, storage,
        studentDataFunctions: {
            updateMongo, createMongo
        } 
    } = useContext(SiteContext)

    const { takePhoto } = usePhotoGallery();
    const [itemForm, setItemForm] = useState(item)
    const [updated, setUpdated] = useState(false)
    
    const [storageArray, setStorageArray] = useState({
        storage1: (item.storageArray && item.storageArray.storage1) ? item.storageArray.storage1 : '',
        storage2: (item.storageArray && item.storageArray.storage2) ? item.storageArray.storage2 : '',
        storage3: (item.storageArray && item.storageArray.storage3) ? item.storageArray.storage3 : '',
        storage4: (item.storageArray && item.storageArray.storage4) ? item.storageArray.storage4 : '',
    })

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
  
    const handleClose = () => {
      setShow(false);
    }

    function handleUpdateStorage(e, level) {
        const editStorage = {...storageArray}
        editStorage[level] = e.target.value
        setStorageArray(editStorage)
    }

    function handleUpdate(e, type, extra) {

        e.preventDefault()
        const editForm = {...itemForm}
        editForm[type] = (type === 'badges' || type === 'daily') ? handleBadgesDaily() : type === 'url' ? extra : e.target.value
        
        // console.log(editForm,'editForm---------')
        setItemForm(editForm)

        function handleBadgesDaily() {
            if(e.target.value == 'false') {var truthy = false} else { var truthy = true }
            var index = editForm[type].findIndex(entry => entry.text === extra)
            if(index === -1) return
            
            editForm[type][index].active = !truthy
            return editForm[type]
        }
    }

    function handleUpdateEntry(e, id) {
        e.preventDefault()

        const editObj = {...itemForm, storageArray}
            delete editObj['_id']
            console.log(editObj,'editObj')
        updateMongo({...editObj, storageArray}, id, 'inventoryItems')

        setUpdated(true)
    }

    async function handleCreate() {
        itemForm.storageArray = storageArray
        delete itemForm.newId

        await createMongo(itemForm, 'inventoryItems')
        setNewItems((prev) => {return prev.filter(entry => entry.newId !== item.newId)})
        window.location.reload()
    }

    const timeMilli = new Date().getTime()
    const updateBg = updated ? 'green' : 'black'
    const updateBg2 = updated ? 'bg-success text-white' : ''

    const storages = [
        {type: 'storage1', text: 'Storage 1'},
        {type: 'storage2', text: 'Storage 2'},
        {type: 'storage3', text: 'Storage 3'},
        {type: 'storage4', text: 'Storage 4'},
    ]

    const itemProps = [
        {type: 'item', text: 'Item', width: false},
        {type: 'displayTitle', text: 'Display Title', width: false},
        {type: 'stockNum', text: 'Stock Number', width: 'w-25'},
        {type: 'quantity', text: 'Quantity', width: 'w-25'},
    ]

    function OptionEntry({entry, type}) {
        var checkBg = entry.active ? 'primary' : entry.variant
        return (
        <div className={`badge rounded-pill cardButton bg-${checkBg} checkCss`}>
            <span style={{marginRight:"5px", paddingTop:"10px", display:"inline"}}>{entry.text}</span>
            <input
                className={`form-check-input`}
                type="checkbox" name="positionOptions"
                style={{marginRight:"5px", margin:"0px", marginTop:"0px"}}
                value={entry.active}
                checked={entry.active}
                onChange={e => handleUpdate(e, type, entry.text)}
            />
        </div>
        )
    } 

    return (
    <ErrorBoundary>
    <DeleteModal show={show} handleClose={handleClose} item={item} setNewItems={setNewItems} setModuleItems={setModuleItems} />
    <div className="d-flex align-items-stretch mb-3">
        <div className={`card text-black p-2 me-2 shadow-none ${updateBg2}`} style={{minWidth:"200px"}}>
            <div className="card-body">
              <img src={itemForm.url} 
              style={{objectFit:"contain", height:"100%", maxWidth: "80%", margin:"auto", verticalAlign:"middle", display:"block", maxHeight:"160px"}}
              />
            </div>
            <div className="card-body">
            {itemForm.daily && itemForm.daily.map(entry => {
                        // if(parseInt(entry.text.split(' ')[1]) > 4) {return}
                        return <OptionEntry key={entry.text+'1'} entry={entry} type={'daily'} />
                    })}
            </div>
        </div>
        <div className="card flex-grow-1 shadow-none bg-light">
            <div className="m-2 me-3 ms-3 p-2 d-flex flex-wrap">
                {itemProps.map(entry => {
                    return (
                        <span key={entry.text} className={`me-2 ms-2 mb-2 ${entry.width ? entry.width : 'flex-fill'}`}>
                          {entry.text}: 
                          <input value={itemForm[entry.type]} onChange={e => handleUpdate(e, entry.type)} className={`form-control d-inline`}/>  
                        </span>
                    )
                })}
                <span className={`flex-fill me-2 ms-2 mb-2 w-25`}>
                Module: 
                <select className={`form-control`} value={itemForm.module} onChange={e => handleUpdate(e, 'module')}>
                    <option>-- Select Module --</option>
                    {modules.map(entry => {
                        return (
                            <option key={entry.fullTitle} value={entry.fullTitle}>{entry.fullTitle}</option>
                        )
                    })}
                </select>
                </span>
                <div className={`w-100 m-2 bg-secondary pt-3 pb-3 text-white rounded row`}>
                {storages.map(entry => {
                    return (
                        <div key={entry.text} className="col-12 col-md-6">
                        <div className={`d-inline me-2 ms-2 mb-2 d-flex justify-content-between align-items-center`}>
                        <span>{entry.text}:</span>
                        <label className="ms-2 flex-fill">
                            <input 
                            list={`${entry.type}`} className={`form-control`}  value={storageArray[entry.type]}
                            onChange={(e) => handleUpdateStorage(e, entry.type)}
                            />  
                        </label>   
                        <datalist id={`${entry.type}`}>
                            <option value={''}/>
                            {storage[entry.type].map(entry2 => {
                                // console.log(entry2,'entry2')
                                return (
                                    <option key={entry2} value={entry2} key={entry2} />
                                )
                            })}
                        </datalist>
                        </div>
                        </div>
                    )
                })}
                </div> 
                <div className="ms-2">
                    {itemForm.badges && itemForm.badges.map(entry => {
                        return <OptionEntry key={entry.text} entry={entry} type={'badges'} />
                    })}
                </div>
                <div className="ms-2 mt-2 mb-2">
                </div>
            </div>
            {/* </div> */}
        </div>
        <div className={`card ms-2 shadow-none ${updateBg2} text-black`} style={{maxWidth:"100px", minWidth:"50px"}}>
            <div className="card-body d-flex flex-shrink-1 align-content-center justify-content-center flex-wrap">
                <div className="d-flex justify-content-center flex-wrap">
                {!item.newId && <FontAwesomeIcon className="m-2" icon={faCamera} value={`https://ccr-items.s3.us-west-1.amazonaws.com/${timeMilli}.jpeg`}
                    onClick={async (e) => {
                        await takePhoto(timeMilli, 20)
                        handleUpdate(e, 'url', `https://ccr-items.s3.us-west-1.amazonaws.com/${timeMilli}.jpeg`)
                    }} role="button" />}     

                {item.newId ? 
                <FontAwesomeIcon className={`m-2`} icon={faCheck} role="button" style={{color:`${updateBg}`}} onClick={(e) => handleCreate(e)}/> 
                : <FontAwesomeIcon className={`m-2`} icon={faFloppyDisk} role="button" onClick={(e) => handleUpdateEntry(e, item._id)}/>}  
                
                <FontAwesomeIcon className={`m-2`} role="button" icon={faX} onClick={(e) => handleShow()}/>               
                {/* <FontAwesomeIcon className="m-2" onClick={e => handleAddDelete(e, 'delete', entry)} icon={faX} />
                </div>
                <div className="d-flex justify-content-center flex-wrap">
                {count > 1 && length > 1 && <FontAwesomeIcon className="m-2" onClick={e => handleChangeIndex('raise', entry, count-1)} icon={faArrowUp} />}
                {count <= length - 1 && <FontAwesomeIcon className="m-2" onClick={e => handleChangeIndex('lower', entry, count-1)} icon={faArrowDown} />} */}
                </div>
            </div>
        </div>
    </div>
    </ErrorBoundary>
    )
}


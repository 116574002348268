import React, { useContext, useState, useEffect } from 'react'

import CheckoutEntry from './CheckoutEntry'
import ColorTitle from '../../../../other/utils/ColorTitle'

import { SiteContext, baseUrl } from '../../../../App'
import { AdminContext } from '../../../../pages/AdminContainer'
import { ErrorBoundary } from '../../../../other/utils/ErrorBoundary'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLeft } from '@fortawesome/free-solid-svg-icons';

export default function Checkouts( { title, sizeClass, type, context, modes, mode }) {

    const { 
      classInformation, initialLoad,
      studentList,
      studentDataFunctions: {
      }
    } = useContext(SiteContext)

    const {orders, checkoutItems} = useContext(AdminContext)

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 

    today = `month${mm}day${dd}`
  
    var filteredCheckoutsPending = []
    var filteredCheckoutsActive = []
    var filteredCheckoutsComplete = []

    var somethingElse = []

    if(!checkoutItems) return
    checkoutItems.map(entry => {
        if(entry.checkoutTimestamp !== today && !entry.checkedOut) return
        if(!entry.checkoutEvent || entry.checkoutEvent.period !== classInformation.period) return
        (entry.checkedOut && !entry.checkoutEvent.adminProcessed) ? filteredCheckoutsPending.push(entry) : 
        (entry.checkedOut && entry.checkoutEvent.adminProcessed) ? filteredCheckoutsActive.push(entry) : 
        (!entry.checkedOut && entry.checkoutEvent.adminProcessed) ? filteredCheckoutsComplete.push(entry) : somethingElse.push(entry)
    })

    const filteredCheckouts = [...filteredCheckoutsPending, ...filteredCheckoutsActive, ...filteredCheckoutsComplete]

    var count = 0

    return (
        <ErrorBoundary>
        <div className={sizeClass}>  
          <div className="main-card mb-3 card">
            <div className="card-header h-auto mt-2">
              <h5 className="card-title">
                <ColorTitle icon={faRightLeft} text={title} variant={'flag'} />
              </h5>
            </div>
            {filteredCheckouts.length === 0 && <div className="card-body">
              <p>No Checkouts</p>
            </div>}
            {filteredCheckouts.length > 0 && <div className="m-2 table-responsive">
              <table className="align-middle text-truncate mb-3 table table-borderless table-hover">
                <thead>
                  <tr>
                    <th className="text-center">Module</th>
                    <th className="text-center">Name</th>
                    <th className="text-center">Date</th>
                    <th className="text-center">Item</th>
                    <th className="text-center">Location</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCheckouts.map(entry => {
                    // console.log(entry,'entry checkout')
                    count++
                    if(count > 8) return
                    return <CheckoutEntry key={entry._id} name={entry.name} studentId={entry.studentId} item={entry} type={type} />
                  })}
                </tbody>
              </table>
            </div>}
            {filteredCheckouts.length > 8 && <div className="d-block text-center card-footer" style={{minHeight:"30px"}}>
              {count > 8 ? `+${parseInt(count) - 8} More` : ' '}
            </div>}
          </div>
        </div>
        </ErrorBoundary>
    )
}
import React, { useContext } from 'react'
import { SiteContext } from '../../App';
import { ErrorBoundary } from '../utils/ErrorBoundary';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function FeelingChoice({ title, subtitle, colorValue, sizeClass }) {

    // const { student } = useContext(SiteContext)

    return (
    <ErrorBoundary>
      <div className={`${sizeClass}`}>
        <div className={`card-shadow-${colorValue} mb-3 widget-chart widget-chart2 text-start card`}>
          <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className={`widget-content-left pe-2 fsize-1 text-${colorValue}`}>
                {/* <div className="widget-numbers mt-0 fsize-3 text-white"> */}
                  <div className="widget-numbers mt-0 fsize-3">
                      {title}
                  </div>
                </div>
              </div>
              <div className="widget-content-left fsize-1">
                <div className="text-muted">{subtitle}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
    )
}

// export default function Balance({ title, subtitle, colorValue, sizeClass }) {

//     const { student } = useContext(SiteContext)

//     return (

//       <div className={`${sizeClass}`}>
//         <div className={`card-shadow-${colorValue} mb-3 widget-chart widget-chart2 text-start card`}>
//           <div className="widget-content p-0 w-100">
//             <div className="widget-content-outer">
//               <div className="widget-content-wrapper">
//                 <div className={`widget-content-left pe-2 fsize-1 text-${colorValue}`}>
//                   <div className="widget-numbers mt-0" style={{fontSize:"50px"}}>
//                     {title}€
//                   </div>
//                 </div>
//                 <div className={`widget-content-right pe-2 fsize-1 text-${colorValue}`}>
//                   <div className="widget-numbers mt-0 fsize-3">{subtitle}</div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//     )
// }
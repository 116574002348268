import React, {useState} from 'react';

import Body from './Body';
import EditModal from '../edit/EditModal';

function Card({ classPeriod, variant }) {

    const [show, setShow] = useState(false)

    function handleShow() {
        setShow(true)
    }

    function handleClose(e) {
        setShow(false)
    }

	return(
		<div className="col-sm-12 col-md-6">
            <EditModal key={classPeriod.period} handleShow={handleShow} handleClose={handleClose} show={show} classPeriod={classPeriod} />
			<div className={`mb-3 card`} style={{ border: 0}}>
				<div className={`card-body bg-${variant} d-flex align-items-center justify-content-between`} style={{borderTopLeftRadius:"4px", borderTopRightRadius:"4px"}}>
                    <span className="text-white fsize-2 font-bold">{classPeriod.period}</span>
                    <button className={`btn btn-light`} onClick={handleShow}>
                        Edit
                    </button>
                </div>
                <div className="card-body">
                    <Body key={classPeriod.period} classPeriod={classPeriod} handleShow={handleShow} />
                </div>
                {/* <div className="card-footer">
                </div> */}
			</div>
		</div>
	);
}

export default Card;


{/* <div className="recipe__row" style={{paddingBottom:"10px"}}>
<div className="recipe__value">
    <div className="ingredient-grid1">
        {/* <span className="">{classPeriod.period}</span> 
    </div>
</div>
</div> */}
import React, { useEffect, useState, useContext } from 'react'
import { SiteContext } from '../../App'

export  function useBehavior() {
    return {}
}


function changeDayMonth(timeStamp) {

    const date = new Date()
        const day = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()

    const dateImport = new Date(timeStamp)
        dateImport.setDate(day)
        dateImport.setMonth(month)
        dateImport.setFullYear(year)
    
    return dateImport.getTime()
}
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function GoalOverlay({student, showGroup, bgColor}) {
  const renderTooltip = (props) => (
    <Tooltip id="" {...props}>
      <div className="d-flex flex-wrap">
        <div className="badge bg-black mb-1 d-inline me-2">Goal</div>
        <span>
          {(student.goalSetting && student.goalSetting.goal && student.goalSetting.goal.goal.length > 0) ? student.goalSetting.goal.goal : 'No Goal'}
        </span>
      </div>
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
      // trigger="click"
    >
      <div className={`badge ${bgColor} text-white me-1 mt-1`} style={{fontWeight:"bold"}}>
        <div className="d-flex align-items-center">
        {student.name.split(' ')[0]} {student.name.split(' ')[1].substring(0,1)}
        <div className="badge bg-white text-black ms-1">{showGroup && student.groupNumber}</div>
        </div>
      </div>
    </OverlayTrigger>
  );
}

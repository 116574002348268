import React from 'react'

export default function CartItems({entry}) {
    const priceCalc = entry.price * entry.quantity
    return (
        <>
            <span className="notranslate">{entry.price}€ x {entry.quantity} = {priceCalc}€</span>
            <span className="">{entry.item}</span>
        </>
    )
}

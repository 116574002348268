import React, { useState, useEffect, useContext } from "react";
import { SiteContext } from "../../App";

const Stopwatch = ({subtitle, classStart, classEnd}) => { 

  const {classInformation} = useContext(SiteContext)

    const [time, setTime] = useState(0);
    const [running, setRunning] = useState(false);
    
    var startMilli = convertToMilli(classStart, subtitle)
    var endMilli = convertToMilli(classEnd, subtitle)

    var timeImportDateMilli = subtitle === 'Time Elapsed' ? startMilli : endMilli

    var milliNow = Date.now()

    useEffect(() => {

      if(typeof classStart === 'undefined' || typeof classEnd === 'undefined') {
        console.log('undefined')
        return
      }
      
      var dateNowMilli = Date.now()
      subtitle === 'Time Elapsed' ? setTime(dateNowMilli-timeImportDateMilli) : setTime(timeImportDateMilli-dateNowMilli)

      setRunning(true)

    }, [classStart, classEnd, classInformation.period])

    useEffect(() => {

      let interval;

      if (running && subtitle === 'Time Elapsed') {

        interval = setInterval(() => {
          var dateNowMilli = Date.now()
          setTime(dateNowMilli - timeImportDateMilli + 10);
        }, 10);

      } else if (running && (subtitle === 'Time Remaining' || subtitle === 'Time Til Cleanup')) {

        interval = setInterval(() => {
          var dateNowMilli = Date.now()
          setTime(timeImportDateMilli - dateNowMilli - 10);
        }, 10);

      } else if (!running) {
        clearInterval(interval);
      }

      return () => clearInterval(interval);

    }, [running, classStart, classEnd]);

    var minutes = ("0" + Math.floor((time / 60000) % 60)).slice(-2)
    var seconds = ("0" + Math.floor((time / 1000) % 60)).slice(-2)

      if(minutes > 50 && subtitle === 'Time Elapsed' ) {
        minutes = '50'
        seconds = '00'
      } 
      else if(minutes < 0) {
        minutes = ("0" + Math.floor((time / 60000) + 1 % 60)).slice(-2)
        // seconds = '00'
      }

      if(milliNow > endMilli) {
        if(subtitle === 'Time Elapsed') {
          return <span>Class</span>
        } else if(subtitle === 'Time Til Cleanup'){
          return <span>Clean Up!</span>
        } else {
          return <span>Complete</span>
        }
      }

    if(minutes.substring(0, 1) == '0') {minutes = minutes.slice(1)}

    return (
        <div className="stopwatch">
          <div className="numbers">
            <span>{minutes}:</span>
            <span>{seconds}</span>
          </div>
        </div>
      );
};

export default Stopwatch;


function convertToMilli(time, subtitle) {
  var hours = time.split(':')[0]
  var min = time.split(':')[1]
  hours = parseInt(hours)
  if(hours < 8) {hours = hours + 12}

  var timeImportDate = new Date()
    timeImportDate.setHours(hours, min, 0)

  if(subtitle === 'Time Til Cleanup') {
    timeImportDate = removeMin(timeImportDate, 5)
  }

  return timeImportDate.getTime()
}

function removeMin(inputTime, subtraction) {
  return new Date(inputTime - subtraction * 60000)
}
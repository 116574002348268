import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate, useLocation } from "react-router-dom";

export function useGeneral({student, setStudent, checkToday, updateMongo, loginState}) {

    const [widthWindow, setWidth] = useState(window.innerWidth);

    const navigate = useNavigate();

    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
         // subscribe to window resize event "onComponentDidMount"
         window.addEventListener("resize", handleResizeWindow);
         return () => {
           // unsubscribe "onComponentDestroy"
           window.removeEventListener("resize", handleResizeWindow);
         };
    }, []);

    useEffect(() => {
        // console.log(student,'student', typeof student)
        if(!student || typeof student !== 'object' || (student.todayTimestamp && checkToday(student.todayTimestamp))) return

        const editStudent = {...student, todayTimestamp: Date.now()}
        setStudent(editStudent)
        updateMongo({todayTimestamp: Date.now()}, student.dailyListId, 'dailyList')
        // console.log('editing')

        if(loginState && loginState.admin) {
            window.location.reload();
        } else {
            navigate(`/`, { replace: true });
        }
        
    }, [student, loginState])

    return {
        widthWindow
    }
}
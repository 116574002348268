import React, { useContext } from 'react'

import { SiteContext } from '../../App'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

export default function ClassPoints({sizeClass, colorValue, subtitle}) {

    const {focusAgreements, classInformation, socket, studentDataFunctions: {updateMongo, setFocusAgreements}} = useContext(SiteContext)

    function updateClassPoints(e, change) {
        const editFocusAgreements = {...focusAgreements}
        editFocusAgreements.classPoints = editFocusAgreements.classPoints ? editFocusAgreements.classPoints + change : change

        setFocusAgreements(editFocusAgreements)
        updateMongo({classPoints: editFocusAgreements.classPoints}, classInformation.focusAgreementId, 'focusAgreements')
        socket.emit("send_focusAgreementsUpdate", { focusAgreements: editFocusAgreements, room: classInformation.period });
        socket.emit('send_classPointsModal', { classPoints: editFocusAgreements.classPoints, change , room: classInformation.period })
    }

    return (
      <div className={sizeClass}>
        <div className={`mb-3 widget-chart widget-chart2 text-start card ${colorValue}`} >
          <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className="widget-content-left pe-2 fsize-1">
                  <div className={`widget-numbers mt-0 fsize-3 text-${colorValue} d-flex align-items-center`}>
                      {focusAgreements.classPoints ? focusAgreements.classPoints : 0}
                      <div className="badge bg-light text-purple mt-0 mb-0 ms-3" style={{padding:"4px", fontSize:"16px"}}>
                        <FontAwesomeIcon className="" icon={faMinus} role="button" onClick={e => updateClassPoints(e, -1)} />
                      </div>
                      <div className="badge bg-light text-purple mt-0 mb-0 ms-2" style={{padding:"4px", fontSize:"16px"}}>
                        <FontAwesomeIcon className="" icon={faPlus} role="button" onClick={e => updateClassPoints(e, 1)} />
                      </div>
                  </div>
  
                </div>
                <div className="widget-content-right fsize-1 align-top" style={{minHeight:"40px"}}>
                    {/* <div className="d-flex align-items-start"> */}

                    {/* </div> */}
                </div>
              </div>
              <div className="widget-content-left fsize-1">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="text-muted">
                        {subtitle}
                    </div>

                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    //   <div className={sizeClass}>
    //     <div className="card">
    //         <div className="widget-content widget-chart">
    //             <div className={`widget-content-left widget-numbers mt-0 fsize-3 fw-bold text-${colorValue}`}>
    //                 {focusAgreements.classPoints}
    //                 Hello
    //             </div>
    //         </div>
    //         <div className="card-body">
    //             <div className="text-muted fsize-1">{subtitle}</div>
    //         </div>
    //     </div>
    //   </div>

    )
}

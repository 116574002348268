import React, { useContext, useState } from 'react'
// import Rating from 'react-rating'
import RatingComponent from './Rating'
import Accordion from 'react-bootstrap/Accordion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFaceFrown, faFaceFrownOpen } from '@fortawesome/free-solid-svg-icons';
import { faFaceFrown, faFaceFrownOpen, faFaceLaughSquint, faFaceLaugh } from '@fortawesome/free-regular-svg-icons';

import { SiteContext } from "../../../../App";

export default function ReflectionQs({question, type, form, category, item, updateForm, value}) {

  const {colorMatch, student} = useContext(SiteContext)

  var reversed = [...colorMatch]
      reversed.reverse()

    if(type === 'q') return (
      <>
        <div className={'col-6'}>
        <div className={'m-2'}>
            <div className={`card-shadow-secondary bg-light widget-chart widget-chart2 text-start card`} style={{width:"100%", height:"100%"}}>
            <div className="widget-content p-0 w-100">
                <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                </div>
                <div className="widget-content-left fsize-1">
                    {`Rate how well`} 
                    <div className={`badge bg-secondary`} style={{marginLeft:"5px", marginRight:"5px"}}>{question}</div>
                    {`took care of our class community today`}
                </div>
                <div className="fsize-1">
                  <RatingComponent reversed={reversed} updateForm={updateForm} form={form} colorMatch={colorMatch} category={category} item={item} value={value} />
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
      </>
    )

    if(type === 'moduleDay') return (
      <>
        <div className={'col-6'}>
        <div className={'m-2'}>
            <div className={`card-shadow-secondary bg-light widget-chart widget-chart2 text-start card`} style={{width:"100%", height:"100%"}}>
            <div className="widget-content p-0 w-100">
                <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                </div>
                <div className="widget-content-left fsize-1">
                    {`How much are you enjoying your module?`} 
                </div>
                <div className="fsize-1 d-flex align-items-center mt-2">
                  <FontAwesomeIcon className="me-2" color="black" icon={faFaceFrownOpen} />
                  <RatingComponent updateForm={updateForm} type={'moduleDay'} colorMatch={colorMatch} category={category} item={item} value={value} />
                  <FontAwesomeIcon className="ms-2" color="black" icon={faFaceLaugh} />
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
      </>
    )

}











      
import React, { useState, useEffect, useContext } from 'react';
import SearchList from './SearchList';

import { SiteContext, baseUrl } from '../../App';
import { AdminContext } from '../../pages/AdminContainer';

export default function Roster({}) {

  const { 
    studentList, classInformationArray, profileElements,
    studentDataFunctions: {}
  } = useContext(SiteContext)

  const items = [
    {text: 'Attendance Name', prop: 'attendanceName', active: false},
    {text: 'Name', prop: 'name', active: true},
    {text: 'First Name', prop: 'firstName', active: true},
    {text: 'Period', prop: 'period', active: true},
    {text: 'Email', prop: 'email', active: true},
    {text: 'Grade', prop: 'grade', active: false},
    {text: 'Student ID', prop: 'studentId', active: false},
    // {text: 'Values', prop: 'values', active: false, array: true},
    // {text: 'Agreement Ideas', prop: 'agreementIdeas', active: false, array: true},
  ]

  profileElements.map(entry => {
    items.push(
      {text: entry.adminText, prop: entry.property, active: false, array: true}
    )
  })

  const [periodField, setPeriodField] = useState("");
  const [editMode, setEditMode] = useState(true)
  const [activeItems, setActiveItems] = useState(() => {
    // const editItems = [...items]
    // editItems.map(entry => {
    //   entry.active = true
    // })
    return items
  })

  const filteredList = studentList.filter(entry =>  entry.period.toLowerCase().includes(periodField.toLowerCase()))

  const handleChangePeriod = (e, period) => {
    e.preventDefault()
    setPeriodField(period === periodField ? "" : period);
  }

  function PeriodSelection({entry}) {
    return (
      <div className={`ms-2 badge bg-${entry.period === periodField ? 'primary' : 'light'}`}
        onClick={e => handleChangePeriod(e, entry.period)} role="button">
        {/* {entry.informationStrings.periodAbbreviation} */}
        {entry.period}
      </div>
    )
  }

  function handleEditMode() {
    setEditMode(prev => {return !prev})
  }

  function handleActive(e, entry) {
    e.preventDefault()
    const editActiveItems = [...activeItems]
    var index = editActiveItems.findIndex(itemEntry => itemEntry.text === entry.text)
    if(index === -1) return
    editActiveItems[index].active = !editActiveItems[index].active
    setActiveItems(editActiveItems)
  }

  var count = -1

  const exportItems = {activeItems, setActiveItems, editMode}
  return (
    <>
    <section className="" style={{margin:"10px"}}>
      <div className="m-2 d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <div className="badge bg-secondary text-white d-inline">Results: {filteredList.length}</div>
          <div className="d-flex justify-content-end d-inline ms-3">
            {classInformationArray.map(entry => {
                return <PeriodSelection key={entry._id} entry={entry} />
            })}
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center flex-wrap">
          <div className="badge bg-secondary m-1" onClick={handleEditMode} role="button">{editMode ? 'Stop Edits' : 'Allow Edits'}</div>
          {/* <div className=""> */}
          {activeItems.map(entry => {
            return <div key={entry.text} className={`m-1 badge ${entry.active ? 'bg-primary text-white' : 'bg-white text-primary'}`} onClick={e => handleActive(e, entry)} role="button">{entry.text}</div>
          })}
          </div>
        {/* </div> */}
      </div>
      {filteredList.length === 0 && <div style={{marginTop:"20px"}}>Loading...</div>}
        <SearchList filteredList={filteredList} exportItems={exportItems} />
    </section>
    </>
  );
}
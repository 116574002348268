import React, { useState, useEffect, useContext } from "react";
import { SiteContext } from "../../App";

const Timer = ({startTime}) => { 

    const [time, setTime] = useState(0);
    const [running, setRunning] = useState(false);

    useEffect(() => {

      if(!startTime) return

      setTime(Date.now() - startTime)
      setRunning(true)

    }, [startTime])

    useEffect(() => {

      if(!startTime) return

      let interval;
      if (running) {
        interval = setInterval(() => {
          setTime(Date.now() - startTime + 10);
        }, 10);
      } else if (!running) {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
      
    }, [running, startTime]);

    var minutes = ("0" + Math.floor((time / 60000) % 60)).slice(-2)
    var seconds = ("0" + Math.floor((time / 1000) % 60)).slice(-2)

    if(minutes.substring(0, 1) == '0') {minutes = minutes.slice(1)}

    if(minutes < 0) {
      minutes = ("0" + Math.floor((time / 60000) + 1 % 60)).slice(-2)
    }

    if(seconds > 59) {seconds = '00'}
    if(seconds < 0) {seconds = `0${parseInt(seconds)*-1}`}

    return `${minutes}:${seconds}`
};

export default Timer;

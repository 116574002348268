import { useState, useEffect, useCallback } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';

export default function useLongPress(currentClasses, callback = () => {}, ms = 300, actionSimpleClick, actionDoubleClick, actionTripleClick) {
  const [startLongPress, setStartLongPress] = useState(false);
  const [click, setClick] = useState(0);

  // console.log(gridMode,'gridMode')
  useEffect(() => {
    let timerId;
    if(startLongPress) {
      timerId = setTimeout(() => {
        setClick(0);
        callback()
      }, ms);
    
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [callback, ms, startLongPress]);

  useEffect(() => {
    const timer = setTimeout(() => {
        // simple click
        if (click === 1) actionSimpleClick();
        if (click === 2) actionDoubleClick();
        setClick(0);
    }, ms+100);

    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    // if (click === 2) actionDoubleClick();
    if (click === 3) actionTripleClick();

    return () => clearTimeout(timer);
    
  }, [click, currentClasses]);
  
  const start = useCallback(() => {
    setStartLongPress(true);
    setClick(prev => prev + 1);
  }, []);

  const stop = useCallback(() => {
    setStartLongPress(false);
  }, []);

  // if(gridMode === 'Edit') return

  return {
    onMouseDown: isBrowser ? start : () => {},
    onMouseUp: isBrowser ? stop : () => {},
    onMouseLeave: isBrowser ? stop : () => {},
    onTouchStart: start,
    onTouchEnd: stop,
  };
}
import React, { useContext, useState, useEffect } from 'react'
import EntryImage from './EntryImage'

import { SiteContext, baseUrl } from '../../../../App'
import { AdminContext } from '../../../../pages/AdminContainer'
import { ErrorBoundary } from '../../../../other/utils/ErrorBoundary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faRightLeft, faRightFromBracket, faX, faXmark } from '@fortawesome/free-solid-svg-icons';
import ItemEntry from '../old/ItemEntry'

// import './d_behavior.css'

export default function CheckoutEntry({item}) {

    const { 
        classInformation, socket, room, periodBg,
        studentDataFunctions: {moduleFind, updateMongo, handleUpdateCheckout}
    } = useContext(SiteContext)

    const {
        checkoutItems, setCheckoutItems
    } = useContext(AdminContext)

    if(item.checkoutEvent.period !== classInformation.period) return

    function handleHandled(e, approved, manualClose) {
        e.preventDefault()
        const editCheckoutItems = [...checkoutItems]
        var index = editCheckoutItems.findIndex(entry => entry._id === item._id)
        // console.log(editCheckoutItems[index], editCheckoutItems)
        if(index === -1) return
        const obj = {adminProcessed: true, approved}
        editCheckoutItems[index].checkoutEvent = {...editCheckoutItems[index].checkoutEvent, ...obj}
        editCheckoutItems[index].checkedOut = manualClose ? false : !approved ? false : item.checkedOut
        // editCheckoutItems[index].checkoutEvent.approved = approved
        setCheckoutItems(editCheckoutItems)
        socket.emit("send_checkoutsUpdate", { checkoutItems: editCheckoutItems, room: 'admin' });
        updateMongo({checkoutEvent: {...item.checkoutEvent, ...obj}, checkedOut: !approved ? false : item.checkedOut}, item._id, 'inventoryItems')
        socket.emit("send_adminCheckoutEvent", { event: editCheckoutItems[index], room });
    }

    // console.log(periodBg,'periodBg')
    var indexBg = periodBg.findIndex(entry => entry.period === item.checkoutEvent.period)
    if(indexBg === -1) return
        var bg = periodBg[indexBg].bg

    var timeStamp = new Date(item.timeStamp)
        var dayEntry = timeStamp.getDate().toString()
        var monthEntry = (timeStamp.getMonth() + 1).toString()
        var weekDayEntry = timeStamp.getDay().toString()
            const weekDays = ['Sunday','Mon','Tue','Wed','Thu','Fri', 'Sat']
            const weekDay = weekDays[weekDayEntry]

    // var now = new Date
    var today = new Date()
    var timestampDate = new Date(item.checkoutEvent.timeStamp)
    const {date, time} = prettyDate(item.checkoutEvent.timeStamp)
    var dateString = (today.getMonth() === timestampDate.getMonth() && today.getDate() === timestampDate.getDate()) ? 
    `Today` : `${date}`


    // var dateString = timeStamp.c?ustomFormat( "#DD#/#MM# #hh#:#mm#" )
    return (
        <ErrorBoundary>
                <tr className={`${item.checkedOut && !item.checkoutEvent.adminProcessed && 'bg-light'}`}>
                    <td className="text-center">
                        <span className="text">{moduleFind(item.module, 'fullTitle', 'title')}</span>
                    </td>
                    <td className="text-center">
                        <span className="text">{item.checkoutEvent.name}</span>
                    </td>
                    <td className="text-center">
                        {dateString === 'Today' ? 
                        <div className={`badge bg-flag`}>{dateString}</div>
                        :
                        <div className="text">{dateString}</div>}
                        <div>{time}</div>
                    </td>
                    <td className="text-center">
                        <EntryImage item={item} />
                        {/* <span className="text">{item.item.substring(0, 30)}</span>       */}
                    </td>
                    <td className="text-center">
                        {item.storageArray && item.storageArray.storage1}
                    </td>
                    <td className="text-center" 
                    style={{width:"90px"}}
                    > 
                        <div role="group" className="btn-group-sm btn-group d-flex justify-content-center w-100"
                        // style={{minWidth:"70px", maxWidth:"90px"}}
                        >
                            {item.checkedOut && !item.checkoutEvent.adminProcessed && 
                            <>
                            <button value="approved" onClick={e => handleHandled(e, true)} 
                            className={`btn-shadow btn btn-secondary text-white border border-2 border-secondary`} role="button">
                            <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <button value="approved" onClick={e => {handleHandled(e, false)}} 
                            className={`btn-shadow btn btn-light text-secondary border border-2 border-secondary`} role="button">
                            <FontAwesomeIcon icon={faXmark} />
                            </button>
                            </>}
                            {item.checkedOut && item.checkoutEvent.adminProcessed && <div className="badge bg-flag p-2" onClick={e => {handleHandled(e, true, true)}} role="button">
                            <FontAwesomeIcon icon={faRightFromBracket} />
                            </div>}
                            {!item.checkedOut && item.checkoutEvent.approved && <div className="badge bg-light text-flag p-2">
                            <FontAwesomeIcon icon={faCheck} />
                            </div>}
                            {!item.checkedOut && !item.checkoutEvent.approved && <div className="badge bg-light text-flag p-2">
                            <FontAwesomeIcon icon={faXmark} />
                            </div>}
                        </div>
                    </td>
                </tr>
                </ErrorBoundary>
    )

}


function prettyDate(dateInput, fullString) {
    var date = new Date(dateInput)
    // console.log(date, 'inside---')
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var hours = ((date.getHours() + 11) % 12 + 1);
    var minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()

    return {date: `${months[date.getMonth()]} ${date.getDate()}`, time: `${hours}:${minutes}`}  

  }


                        //   {/* {item.checkedOut && item.checkoutEvent.adminProcessed && <div role="group" className="btn-group-sm btn-group" onClick={e => {handleHandled(e, true, true)}} >
                        //     <div className="badge bg-flag p-2">
                        //     <FontAwesomeIcon icon={faRightFromBracket} />
                        //     </div>
                        // </div>}
                        // {!item.checkedOut && item.checkoutEvent.approved && <div role="group" className="btn-group-sm btn-group">
                        //     <div className="badge bg-light text-flag p-2">
                        //     {/* <FontAwesomeIcon icon={faRightLeft} /> */}
                        //     <FontAwesomeIcon icon={faCheck} />
                        //     </div>
                        // </div>} */}
                        // {!item.checkedOut && !item.checkoutEvent.approved && <div role="group" className="btn-group-sm btn-group">
                        //     <div className="badge bg-light text-flag p-2">
                        //     <FontAwesomeIcon icon={faX} />
                        //     </div>
                        // </div>}
import React, { useContext, useState, useEffect, createContext } from 'react'

import FeelingsWheel from './FeelingsWheel'
import FeelingChoice from './FeelingChoice'
import Instructions from './Instructions'

import { SiteContext, baseUrl } from '../../App'
import { ErrorBoundary } from '../utils/ErrorBoundary'

export default function FeelingPage( { title, sizeClass }) {

    const { 
      student, classInformation, colorMatch,
      studentDataFunctions: {
        calculateTimeElapsed
      }
    } = useContext(SiteContext)

    const [words, setWords] = useState(['','',''])

    return (
        <ErrorBoundary>
        <div className="row">
            <Instructions title={'Purpose'} colorValue={'primary'} sizeClass={'col-sm-4'}/>
            <Instructions title={'Instructions'} colorValue={'info'} sizeClass={'col-sm-8'}/>

        </div>
        <div className="row">
            <div className="col-sm-8">
                <FeelingsWheel words={words} setWords={setWords} />
            </div>
            <div className="col-sm-4">
                {words[0] !== '' && <FeelingChoice title={words[0]} subtitle={'Feeling #1'} colorValue={'primary'} sizeClass={'col-sm-12'}/>}
                {words[1] !== '' && <FeelingChoice title={words[1]} subtitle={'Feeling #2'} colorValue={'info'} sizeClass={'col-sm-12'}/>}
                {words[2] !== '' && <FeelingChoice title={words[2]} subtitle={'Feeling #3'} colorValue={'primary'} sizeClass={'col-sm-12'}/>}
            </div>
        </div>
        {/* <div className="row">
            {words[0] !== '' && <Instructions title={'Purpose'} colorValue={'primary'} sizeClass={'col-sm-4'}/>}
            {words[0] !== '' && <Instructions title={'Instructions'} colorValue={'info'} sizeClass={'col-sm-8'}/>}
        </div> */}
        </ErrorBoundary>
    )
}

import React, { useContext } from 'react'
import { SiteContext } from '../../App'

export function useChecklist() {

    // const {focusAgreements, student} = useContext(SiteContext)

    function calculateCompletion(focusAgreements, student, type) {

        var complete = 0
        var pending = 0
        var taskCountNum = 0
        var checklistLength = 0
        var mathComplete = ''

        // if(!focusAgreements || !focusAgreements.modules) return {complete, pending, taskCountNum, checklistLength, mathComplete}
        // var index = focusAgreements.modules.findIndex(entry => entry.title === module)
        // if(index === -1) return {complete, pending, taskCountNum, checklistLength, mathComplete}

        if(!focusAgreements || !focusAgreements.modules) return

        var index = focusAgreements.modules.findIndex(item => item.title === student.module)
            if(index === -1) return
        var index1 = focusAgreements.modules[index].checklists.findIndex(item => item.groupNumber === student.groupNumber)
            if(index1 === -1) return

        var checklist = focusAgreements.modules[index].checklists[index1].checklist
        // focusAgreements.modules[index].checklist
        if(!checklist) return
        checklist.map(entry => {
            if(entry.type === 'Test') return
            if(entry.complete && entry.completeAdmin) {
                complete++
            }
            if(entry.complete && !entry.completeAdmin) {
                pending++
            }
            if(entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview') {
                taskCountNum++
            }
            if(entry.designBriefTask !== 'Overview') {
                checklistLength++
            }
        })

        var percentComplete = complete/checklistLength*100
        var percentPending = (pending)/checklistLength*100
    
        var percentCombined = percentComplete + percentPending
    
        var percentCompleteInt = parseInt(percentComplete)
        var percentPendingInt = parseInt(percentPending)
        var percentCombinedInt = parseInt(percentCombined)
    
        mathComplete = percentCombinedInt > 98.9 ? `100` : `${percentCombinedInt}`
        mathComplete = type === 'numberOnly' ? mathComplete : `${mathComplete}%` 
        // console.log(mathComplete,'mathComplete')
        return {complete, pending, taskCountNum, checklistLength, mathComplete: mathComplete ? mathComplete : 0}
    }

    // console.log(calculateCompletion(),'inside')

    return {calculateCompletion}

}

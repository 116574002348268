import React, { useContext } from 'react'

import { SiteContext } from '../../App';

import Accordion from 'react-bootstrap/Accordion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGraduate } from '@fortawesome/free-solid-svg-icons';

export default function GradesAccordion({sizeClass, title}) {

  const { 
  } = useContext(SiteContext)

    return (
      <div className={sizeClass}>
        <div className="main-card mb-3 card">
          <div className="card-header">
            <FontAwesomeIcon style={{ marginRight: 10 }} icon={faUserGraduate} />
            {title}
          </div>
          <div className="card-body">
            <Accordion>
                  <Accordion.Item eventKey={'normal'}>
                    <Accordion.Header>Community Color & Grades</Accordion.Header>
                    <Accordion.Body>
                    <div className="recipe__row">
                        <div className="recipe__value recipe__value--indented">
                            <div className="ingredient-grid2">
                                <span className="bold">Color</span>
                                <span className="bold">Grade</span>
                                <span className="">⬛ Black</span><span className="">A+</span>
                                <span className="">🏅 Gold</span><span className="">A</span>
                                <span className="">🟪 Purple</span><span className="">A-</span>
                                <span className="">🟦 Blue</span><span className="">B</span>
                                <span className="">🟩 Green</span><span className="">B-</span>
                                <span className="">🟨 Yellow</span><span className="">C</span>
                                <span className="">🟧 Orange</span><span className="">D</span>
                                <span className="">🟥 Red</span><span className="">D-</span>
                                <span className="">⬜ White</span><span className="">F</span>
                            </div>
                        </div>
                    </div>  
                    </Accordion.Body>
                  </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    )
}

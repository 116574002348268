import React, { useEffect, useState } from 'react'

export function useClassInformation({getMongoList, updateMongo, calendarDays, useCreateStorageState, student, setClassesUpdated, useSaveLocalStorage, LOCAL_STORAGE_KEY4, classesUpdated, loginState, classInformationObject, checkCalendar, getClassTimes, schedules}) {

    const [classInformationArray, setClassInformationArray] = useState([])
    const [classInformationArrayArchived, setClassInformationArrayArchived] = useState([])
    const [classInformation, setClassInformation] = useState(useCreateStorageState(LOCAL_STORAGE_KEY4, classInformationObject))
    useSaveLocalStorage(LOCAL_STORAGE_KEY4, classInformation)

    useEffect(() => {
      
      async function getClassInformation() {

        const classPeriods = await getMongoList('classPeriods')

        if(!classPeriods) return
        const newClassInformationArray = []

        const promises = classPeriods.map(async (entry) => {
          entry.dayType = checkCalendar()
          // console.log(checkCalendar(), 'checkCalendar()')
          
          if(entry.dayType) {
            const classTimeOutput = getClassTimes(entry.dayType, entry.period)
            entry = {...entry, ...classTimeOutput}
            // console.log(classTimeOutput,'classTimeOutput')
          }

          entry.dailyEventSubmitted = false
          
          if(!entry.focusAgreementId) {
            entry.focusAgreementId = await addFocusAgreementId(entry)
          }
          newClassInformationArray.push(entry)
        })
        await Promise.all(promises)

        const sortedClassInformationArray = newClassInformationArray.sort((a,b) => (a.period > b.period) ? 1 : ((b.period > a.period) ? -1 : 0))

        var activeClasses = sortedClassInformationArray.filter(entry => !entry.archived)
        var archivedClasses = sortedClassInformationArray.filter(entry => entry.archived)

        setClassInformationArray([...activeClasses])
        setClassInformationArrayArchived([...archivedClasses])

        if(classInformationArray && classInformationArray.length === 0) return

        var index = activeClasses.findIndex(entry => entry.period === classInformation.period)

        if(index !== -1) {
          const editClassInformation = {...classInformation, ...activeClasses[index]}
          setClassInformation(editClassInformation)
        }

      }

      getClassInformation();

    }, [calendarDays, schedules]);
  // }, [loginState, classesUpdated, classInformation.period, student.period]);

    async function addFocusAgreementId(inputMatchingPeriod) {

      const matchingPeriod = {...inputMatchingPeriod}
      const classPeriods = await getMongoList('focusAgreements')
      var index = classPeriods.findIndex(entry => entry.creationId === matchingPeriod.creationId)
      console.log(classPeriods[index],'classPeriods')

      const id = matchingPeriod._id
      delete matchingPeriod._id
      await updateMongo({...matchingPeriod, focusAgreementId: classPeriods[index]._id}, id, 'classPeriods')
      return classPeriods[index]._id
    }

    const periodBgBefore = [
      {bg: 'teal'},
      {bg: 'primary'},
      {bg: 'info'},
      {bg: 'pink'},
      {bg: 'purple'},
      {bg: 'teal'},
      {bg: 'primary'},
      {bg: 'info'},
      {bg: 'pink'},
      {bg: 'purple'},
    ]

    const periodBg = []

    var count = 0
    classInformationArray.map(entry => {
        // periodBg[count] = {...periodBg[count], period: entry.period}
        periodBg.push({bg: periodBgBefore[count].bg, period: entry.period})
        count++
        if(count > 4) {count = 0}
    })
    

    return {
        classInformationArray, setClassInformationArray, classInformationArrayArchived, setClassInformationArrayArchived, classInformation, setClassInformation, periodBg 
    }
}

import React, {useEffect, useState} from "react";
import GridLayout, {WidthProvider} from "react-grid-layout";

import '../../../node_modules/react-grid-layout/css/styles.css'
import '../../../node_modules/react-resizable/css/styles.css'
import './utils/grid.css'
import blankLayout from '../../other/json/blankLayout.json'

import GridEntry from "./GridEntry";
import GoalOverlay from './overlays/GoalOverlay'
import ChecklistModal from './overlays/ChecklistModal'
import CheckoutOverlay from "./overlays/CheckoutOverlay";
import Templates from "./utils/Templates";

const DecoratedReactGridLayout = WidthProvider(GridLayout);
// const originalLayout = this.props.layout ? this.props.layout : getFromLS("layout") || [];

export default class SeatGrid extends React.Component {

  static defaultProps = {
    className: "layout",
    cols: 50,
    rows: 50,
    // rowHeight: 30,
    // width: "100px",
    isBounded: true,
    compactType: null,
    // allowOverlap: true,
    // verticalCompact: false,
    preventCollision: true,
    onLayoutChange: function() {}
  }; 

  // componentDidUpdate(prevProps) {
  //   console.log()
  //   if(prevProps.studentList != this.props.studentList) {
  //     this.setState({studentList: this.props.studentList});
  //   }
  // }

  constructor(props) {
    super(props);

    this.state = {
      layout: 
      // []
      this.props.layout 
      ? this.props.layout : JSON.parse(JSON.stringify(
        getFromLS("layout") || []
      ))
    };

    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.resetLayout = this.resetLayout.bind(this);
    this.saveLayout = this.saveLayout.bind(this);
    this.handleLayout = this.handleLayout.bind(this);
  }

  resetLayout() {
    const blankLayoutParsed = JSON.parse(JSON.stringify(blankLayout))
    this.setState({
      layout: [...blankLayoutParsed]
    });
    // console.log(blankLayoutParsed,'blankLayoutParsed')
    this.updateMongoLocal(blankLayoutParsed)
    saveToLS("layout", blankLayoutParsed);
    window.location.reload();
    // console.log(JSON.stringify(this.state.layout),'JSON.stringify(')
  }

  onLayoutChange(layout) {
    // console.log(layout,'layout', typeof layout, layout[0])
    saveToLS("layout", layout);
    this.setState({ layout });
    this.props.onLayoutChange(layout); // updates status display
  }

  saveLayout() {
    var layout = this.state.layout

    var layoutArray = []
    for (var key in layout) {
      if (layout.hasOwnProperty(key)) {
          layoutArray.push(layout[key])
      }
    }

    this.updateMongoLocal(layoutArray)
  }

  handleLayout() {
    this.props.setCreateLayout(prev => {return !prev})
  }

  createLayoutFunction(e, title) {
    e.preventDefault()
  }

  async saveTemplate() {
    var layout = this.state.layout
    this.props.createMongo({...layout, name: `Layout (${this.props.layouts.length})`}, 'layouts')
  }

  async updateMongoLocal(layout) {
    // this.props.classInformationArray.map(async(classEntry) => {
    //   // const entry = await getMongoByUniqueId(classEntry.focusAgreementId, 'focusAgreements')
    //   this.props.updateMongo({layout: [...layout]}, classEntry.focusAgreementId, 'focusAgreements') 
    // })
    
    this.props.updateMongo({layout: [...layout]}, this.props.classInformation.focusAgreementId, 'focusAgreements') 
  }



  render() {
    
    function compare( a, b ) {
      if ( a.index < b.index ){
        return -1;
      }
      if ( a.index > b.index ){
        return 1;
      }
      return 0;
    }
    // var modulesCombined = [...this.props.focusAgreements.modules, {title: 'No Group', variant: 'secondary', fullTitle: 'No Group'}]

    var modulesCombined = []

    const sorted = this.props.focusAgreements.modules.sort( compare );

    sorted.map(entry => {
      modulesCombined.push(entry)
      // console.log('looping')
    })
    modulesCombined.push({title: 'No Group', variant: 'secondary', fullTitle: 'No Group'})

    // console.log(this.props.focusAgreements, 'focusAg', this.props.focusAgreements.period)
    modulesCombined.map(entry => {
      entry.students = []
      entry.checkoutItems = []
    })

    this.props.studentList.map(entry => {
  
      if(entry.period !== this.props.period || (this.props.gridMode === 'Colors' &&  entry.attendance === 'Absent') ) return
  
      var moduleMatch = ((this.props.gridMode !== 'Edit' && this.props.gridMode !== 'Module Progress') || (this.props.gridMode === 'Edit' && this.props.editMode === 'Seat')) && entry.seat ? entry.seat : entry.module
      if(this.props.gridMode === 'Edit' && this.props.editMode === 'Pending') { 
        moduleMatch = entry.pending 
      }
      if(!moduleMatch || moduleMatch.length === 0 || entry.module === 'zzz' || entry.module === '') { 
        moduleMatch = 'No Group'
      }
      var index = modulesCombined.findIndex(item => item.title === moduleMatch)
      if(index === -1) return

      modulesCombined[index].students.push(entry)
    })

    this.props.checkoutItems.map(entry => {
      if(!entry.checkedOut) return
      var index = modulesCombined.findIndex(item => item.fullTitle === entry.module)
      if(index === -1) return
      modulesCombined[index].checkoutItems.push(entry)
    })

    var index = modulesCombined.findIndex(item => item.fullTitle === 'No Group')
    // console.log(modulesCombined[index],'index', this.props.layout)

    var studentsLength = modulesCombined[index].students.length
    this.props.layout[18].h = studentsLength > 32 ? 12 : studentsLength > 24 ? 11 : studentsLength > 16 ? 8 : studentsLength > 8 ? 6 : 5
    this.props.layout[18].w = 50

    var count = 0
    const currentLayout = this.props.layout ? this.props.layout : JSON.parse(JSON.stringify(blankLayout))
    // // console.log(JSON.stringify(currentLayout),'currentLayout', this.props.period)
    // // currentLayout.sort((a, b) => a.x.localeCompare(b.x) || b.y - a.y);

    // currentLayout.sort(
    //   function(a, b) {          
    //      if (a.x === b.x) {
    //         // Price is only important when cities are the same
    //         return b.x - a.x;
    //      }
    //      return a.y > b.y ? 1 : -1;
    //   });

      // var count = 0
      // currentLayout.map(entry => {
      //   count++
      //   entry.i = `${count}`
      // })

    
    return (
    <div>
      <DecoratedReactGridLayout
          {...this.props}
          // layout={this.props.layout ? this.props.layout : this.state.layout}
          onLayoutChange={this.onLayoutChange}
      >
        {modulesCombined.map(entry => {
            count++
            if(entry.students.length === 0 && entry.title === 'No Group' && this.props.gridMode !== 'Edit') return
            return (
            <div className={`gridBox pt-1 bg-light`} key={count} data-grid={{ w: 36, h: 3, x: 0, y: 0 }} 
                 onClick={e => this.props.handleGroupClick(e, entry.title)} style={{position:"absolute"}}>
                {this.props.gridMode === 'Modal Completion' && entry.checkoutItems.length > 0 && <CheckoutOverlay entry={entry} />}
                {this.props.gridMode === 'Edit' && 
                <div className="d-flex justify-content-between align-items-center mb-1">
                <span className="ms-2 flex-shrink-1" style={{fontSize:"0.8rem"}}>
                  <span className="me-1">{entry.emoji}</span>
                  {entry.title}
                </span>
                </div>}
                {this.props.gridMode !== 'Module Progress' ? entry.students.map(student => {
                  return <GridEntry key={student.studentId} student={student} setActiveStudent={this.props.setActiveStudent} alphaView={1} gridMode={this.props.gridMode} handleAddWarning={this.props.handleAddWarning} module={entry}
                            handleClick={this.props.handleClick} activeStudent={this.props.activeStudent} useSingleAndDoubleClick={this.props.useSingleAndDoubleClick} editMode={this.props.editMode} 
                            handleGroupNumber={this.props.handleGroupNumber} showSeats={this.props.showSeats} chipMode={this.props.chipMode} />
                }) :
                  <ModuleProgressEntry key={entry.title} entry={entry} />
                }
                <br/>
                {this.props.gridMode === 'Edit' && this.props.editMode === 'Group' &&
                <>
                <div className={`badge d-inline bg-white text-black ms-1 flex-wrap`}># Groups: {entry.checklists && entry.checklists.length}</div>
                <div className="badge bg-white text-black ms-1 me-1" role="button" onClick={e => this.props.handleAddGroup(e, entry.itemId, 'add')}>+ Group</div>
                </>}
            </div>
            )
        })}
      </DecoratedReactGridLayout>
      {this.props.gridMode === 'Edit' && 
      <EditButtons editFunctions={{resetLayout: this.resetLayout, createLayout: this.props.createLayout, createLayoutFunction: this.createLayoutFunction, layouts: this.props.layouts, layout: this.state.layout, onLayoutChange: this.onLayoutChange, resetStudents: this.props.resetStudents, editMode: this.props.editMode,
        resetPendingStudents: this.props.resetPendingStudents, handleAllowEdit: this.props.handleAllowEdit, handleSimplifySeats: this.props.handleSimplifySeats, allowEdit: this.props.allowEdit, showSeats: this.props.showSeats, setShowSeats: this.props.setShowSeats, saveLayout: this.saveLayout, handleLayout: this.handleLayout}}/>}
    </div>
    );
  }
}

function ModuleProgressEntry({entry}) {

  const [activeChart, setActiveChart] = useState(entry.checklists && entry.checklists[0])

  useEffect(() => {
    if(!entry.checklists || !entry.checklists[0]) return
    setActiveChart(entry.checklists[0])
  }, [entry.checklists])

  if(entry.students.length === 0) {
    return (
    <div className="m-2 mt-1">
      <div className="d-flex justify-content-between">
        <span className="font-weight-bold" style={{fontWeight:"bold", fontSize:"12px"}}>
          {entry.title.substring(0,12)}
        </span>     
       </div>
    </div>
    )
  } 

  var count = 0

  // console.log(activeChart, 'activeChart', activeChart.groupNumber)
  return (
    <div className="m-2 mt-1">
      <ChecklistModal entryImport={{...entry, groupNumber: activeChart ? activeChart.groupNumber : 1, checklist: activeChart && activeChart.checklist}} setActiveChart={setActiveChart} />
      <div className="mt-0">
        {entry.students.map(studentItem => {
          count++
          var bgColor = activeChart && entry.checklists.length > 1 && studentItem.groupNumber !== activeChart.groupNumber ? 'bg-secondary' : 'bg-black'
          // bgColor = (bgColor === 'bg-black' && studentItem.attendance === 'Absent') ? 'bg-danger' : 
          var borderCol = (bgColor === 'bg-black' && studentItem.attendance === 'Absent') ? 'border border-2 border-danger' : 'border border-1 border-secondary'
          // (bgColor === 'bg-secondary' && studentItem.attendance === 'Absent') ? 'opacity-25 bg-danger' : 
          // bgColor
          return <GoalOverlay key={studentItem.studentId} student={studentItem} showGroup={entry.checklists && entry.checklists.length > 1 ? true : false} bgColor={`${bgColor} ${borderCol}`} />
        })}
      </div>
    </div>
  )
}

function EditButtons({editFunctions}) {
  const {saveLayout, createLayout, onLayoutChange, createLayoutFunction, resetLayout, handleLayout, layout, layouts, resetStudents, resetPendingStudents, handleAllowEdit, allowEdit, handleSimplifySeats, showSeats, editMode} = editFunctions

  function Buttons() {
    return (
      <>
      <button className="btn btn-secondary m-1" onClick={resetLayout}>Reset Layout</button>
      <button className="btn btn-danger m-1" onClick={resetStudents}>Reset Students</button>
      <button className="btn btn-primary m-1" onClick={resetPendingStudents}>Activate Pending</button>
      </>
    )
  }
  
  const exportFunctions = {allowEdit, handleAllowEdit, layout, saveLayout, onLayoutChange}

  return (
    <>
    <div className="mt-3 d-flex justify-content-between align-items-center">
        <div className="">
          {editMode !== 'Pending' && <Buttons/>}
        </div>
        <div className="d-flex">
        <Templates exportFunctions={exportFunctions} />
        </div>
        
    </div>
    <div className="d-flex justify-content-end">
      {editMode === 'Pending' && <Buttons/>}
    </div>
    </>
  )
}

function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-7")) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls[key];
}
  
  function saveToLS(key, value) {
    if (global.localStorage) {
      global.localStorage.setItem(
        "rgl-7",
        JSON.stringify({
          [key]: value
        })
      );
    }
  }

import { useState, useEffect } from 'react';

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
// import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
// import { Filesystem, Directory } from '@capacitor/filesystem';
// import { Preferences } from '@capacitor/preferences';
// import { Capacitor } from '@capacitor/core';
// import {  CameraOptions, CameraResultType, CameraSource, Plugins } from '@capacitor/core';
// const { Camera } = Plugins;

import {uploadImageToS3} from './uploadImageToS3.js'

export function usePhotoGallery(quality) {

  const [photos, setPhotos] = useState<UserPhoto[]>([]);

  const takePhoto = async (timeMilli, quality) => {
    const photo = await Camera.getPhoto({
      // webUseInput: true,
      resultType: CameraResultType.Uri,
      // source: CameraSource.Photos,
      source: CameraSource.Prompt,
      // preserveAspectRatio: true,
      // allowEditing: false,
      quality: 80,
      width: 800,
      height: 800,
    });

    // const photo = await Camera.getPhoto({
    //   resultType: CameraResultType.Uri,
    //   source: CameraSource.Photos,
    // })

    const fileName = timeMilli + '.jpeg';
    console.log(photo.webPath!,'photo.webPath', fileName)
    const base64Data = await base64FromPath(photo.webPath!);
    uploadImageToS3(fileName, base64Data)
  };
  
  return {
    takePhoto, photos
  };
}

export interface UserPhoto {
  filepath: string;
  webviewPath?: string;
}

export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject('method did not return a string');
      }
    };
    reader.readAsDataURL(blob);
  });
}
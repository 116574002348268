import React, { useContext, useState } from 'react'

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ChecklistModal from '../grid/overlays/ChecklistModal';

import { SiteContext } from '../../App'
import { ErrorBoundary } from '../../other/utils/ErrorBoundary'

export default function GradeEntry({person, exportFunctions}) {

    const { 
        colorMatch, studentList, focusAgreements,
        studentDataFunctions: {
          updateMongo,
        }
    } = useContext(SiteContext)

    // console.log(person,'person')
    const {mode, onlyAbsent, firstDate, secondDate, gradeBreaks, completionArray, localFocusAgreements, findChecklist, setTableCopied} = exportFunctions
    const [attendance, setAttendance] = useState(person.attendance)

    var dataVal = ''

    function handleUpdate(e) {
      e.preventDefault()
      if(mode.type === 'Grades') return
      console.log(person,'person---', dataVal)
      var attendance = dataVal === 'Present' ? 'Tardy' :
                       dataVal === 'Tardy' ? 'Absent' :
                       dataVal === 'Absent' ? 'Present' : 'Present'

      updateMongo({attendance}, person._id, 'dailyRecord')
      setAttendance(attendance)
    }

    function handleGradeAttendance() {
      const match = colorMatch.filter(item => item.color == person.color)

      if(!match[0]) return {bgColor: 'secondary', dataVal: ''}
      dataVal = match[0].grade

      const index = studentList.findIndex(entry => entry.studentId === person.studentId)
      var name = index !== -1 ? studentList[index].attendanceName : person.name

      if(mode.type === 'Grades' && attendance === 'Absent') {
        dataVal = ''
      }
      if(mode.type !== 'Grades') {
        dataVal = attendance
      }
      if(mode.type !== 'Grades' && onlyAbsent && attendance === 'Present') return

      var bgColor = dataVal === 'Present' ? 'success' :  
                    dataVal === 'Tardy' ? 'flag' : 
                    dataVal === 'Absent' ? 'danger' : 'secondary'

      return {bgColor, dataVal}
    }

    if(mode.type === 'Completion' && person.period === 'Dropped') return

    var outcome = mode.type === 'Completion' ? 
        {percentage: (person.percentage && person.percentage.mathComplete) ? person.percentage.mathComplete : 0, bgColor: 'secondary'} 
        : handleGradeAttendance()

    const index = studentList.findIndex(entry => entry.studentId === person.studentId)

    const checklistCount = mode.type === 'Completion' ? findChecklist(person) : false

    // console.log(person,'person', person.name)
    if(mode.type === 'Completion' && (!firstDate || !secondDate)) return

    function findChecklist2() {

      if(localFocusAgreements.length === 0) return

      var index00 = localFocusAgreements.findIndex(item => item.period === person.period)
      if(index00 === -1) return
      const localFocus = localFocusAgreements[index00].focusAgreements
      // console.log(localFocusAgreements[index00], person.name)
      var index0 = localFocus.modules.findIndex(item => item.title === person.module)
          if(index0 === -1) return
      var index1 = localFocus.modules[index0].checklists.findIndex(item => item.groupNumber === person.groupNumber)
          if(index1 === -1) return

        return localFocus.modules[index0]
    }

    var absentCount = 0
    completionArray.map(item => {
      var index = item.data.findIndex(item2 => item2.studentId === person.studentId)
      if(index === -1) return
      if(item.data[index].attendance === 'Absent') {absentCount++}
    })

    // console.log(completionArray,'completionArray', completionArray.length, absentCount, person.name)

    const checklistMatch = findChecklist2()
    
    return (
      <ErrorBoundary>
        <tr className={`${""}`}>
            <td className="">
                {/* {(mode.type === 'Attendance' || mode.type === 'Completion') ? studentList[index].attendanceName : person.name} */}
                {/* {person.name} */}
                {mode.type === 'Completion' ? <ShowModule student={person} attendanceName={studentList[index].attendanceName } /> : mode.type === 'Attendance' ? studentList[index].attendanceName : studentList[index].attendanceName}
            </td>
            {onlyAbsent && <td className="">
                {person.studentId}
            </td>}
            <td className="">
                {mode.type === 'Completion' && checklistMatch && checklistMatch.checklists ?
                <ChecklistModal entryImport={{...checklistMatch, period: person.period, groupNumber: person.groupNumber ? person.groupNumber : 1, checklist: checklistMatch.checklists[0].checklist}} setActiveChart={false} grades={true} checklistCount={checklistCount} firstDate={firstDate} secondDate={secondDate} student={{...person, absentCount: absentCount}} setTableCopied={setTableCopied} />
              :
              <div className={`badge bg-${outcome && outcome.bgColor ? outcome.bgColor : 'secondary'}`} onClick={e => handleUpdate(e)}>{outcome && outcome.dataVal}</div>}
                {/* <div className={`badge border border-2 ${checklistCount > 85 ? 'border-secondary bg-success' : checklistCount > 70 ? 'border-success bg-success' : checklistCount > 40 ? 'border-warning bg-warning' : 'border-danger bg-danger'}`}>{checklistCount}</div> : 
                <div className={`badge bg-${outcome && outcome.bgColor ? outcome.bgColor : 'secondary'}`} onClick={e => handleUpdate(e)}>{outcome && outcome.dataVal}</div>} */}
            </td>
        </tr>
      </ErrorBoundary>
    )
}

function ShowModule({student, attendanceName}) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {student.module ? student.module : 'No Module'}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <span role="button">
        {attendanceName}
        {/* {student.name} */}
      </span>
    </OverlayTrigger>
  );
}
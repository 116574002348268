import React, { useContext, useState } from 'react'
// import Rating from 'react-rating'
import RatingComponent from './Rating'
import Accordion from 'react-bootstrap/Accordion';

import { SiteContext } from "../../../../App";

export default function TextQuestions({question, type, form, setForm, category, item, updateForm}) {

  const {colorMatch, student} = useContext(SiteContext)

  function localUpdate(e) {
    e.preventDefault()
    updateForm(category, item, e.target.value)
  }

    return (
      <>
        <div className={'m-2 flex-fill'}>
            <div className={`card-shadow-secondary bg-light widget-chart widget-chart2 text-start card`}>
            <div className="widget-content p-0 w-100">
                <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                </div>
                <div className="widget-content-left fsize-1">
                    {question} 
                </div>
                <textarea
                className="form-control" style={{marginTop:"10px", resize:"none"}}
                onChange={e => localUpdate(e)}
                cols={40}
                rows={2}
                id="textAreaSpace"
                placeholder="Type here . . ."
                defaultValue={(!form || !form[category][item]) ? '' : form[category][item]}
                // value={form[category][item]}
                />
                </div>
            </div>
            </div>
        </div>
      </>
    )
}



{/* <input
type="text" style={{marginTop:"10px"}}
className="form-control"
// value={''}
// onChange={(e) => updateForm(e, { explain: e.target.value })}
/> */}








      
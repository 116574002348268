import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';

import Toggle from "./Toggle";

import { SiteContext } from "../../../App";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

 
export default function GoalSettingStaticModal({handleShow, handleClose, show, type}) {

    const {
      student, socket, room, goalQuestions,
      studentDataFunctions: {createMongo}
    } = useContext(SiteContext)

    function handleCloseLocal() {
      handleClose(type)
    }

    const [form, setForm] = useState(() => {return student.goalSetting})
  
    useEffect(() => {
        setForm(student.goalSetting)
    }, [student.goalSetting])
       
 // This following section will display the form that takes input from the user to update the data.
 return (
  <ErrorBoundary>    
    <div
      onKeyDown={e => e.stopPropagation()}
    >
    <Modal 
      show={show} 
      onHide={handleCloseLocal}
      dialogClassName="my-modal3"
    >
      <Modal.Header>
        <Modal.Title>
            {/* Module Goal: {student.module} */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Toggle goalQuestions={goalQuestions} review={true} staticGoal={true} form={form} />
      </Modal.Body>
      <Modal.Footer>
          <button onClick={e=>handleClose(type)} className={`btn btn-secondary`}>Close</button>
      </Modal.Footer>
    </Modal>
    </div>
  </ErrorBoundary>
  
);
}



import React, { useContext } from 'react'

import { SiteContext } from '../../App';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CheckInToolTip({checkIn}) {

    const {loginState} = useContext(SiteContext)
    if(loginState.admin || !checkIn || !checkIn.emoji) return
    
    return (
        
        <OverlayTrigger
            placement="top"
            // trigger="click"
            overlay= {
                <Tooltip 
                    id="button-tooltip-2"
                    // style={{color:"green"}}
                >
                    {checkIn.note.length === 0 && "No Check In Note"}
                    {checkIn.note.length > 0 && checkIn.note}
                </Tooltip>
            }
        >
            <FontAwesomeIcon  className="btn-icon-wrapper" color={checkIn.color} icon={checkIn.emoji}/>
        </OverlayTrigger>         

    )
}
import React, { useState, useEffect, useContext } from "react";
import { SiteContext } from "../../App";

const Timer = ({timerLength, startTime}) => { 

    const [time, setTime] = useState(0);
    const [running, setRunning] = useState(false);

    var milliStart = startTime ? startTime : Date.now()
    var milliEnd = milliStart + timerLength*60000
    var milliNow = Date.now()

    useEffect(() => {

      if(!startTime) return

      var milliStart = startTime ? startTime : Date.now()
      var milliEnd = milliStart + timerLength*60000

      setTime(milliEnd-milliStart)
      setRunning(true)

    }, [startTime])

    useEffect(() => {

      if(!startTime) return

      let interval;
      if (running) {
        interval = setInterval(() => {
          var dateNowMilli = Date.now()
          setTime(milliEnd - dateNowMilli - 10);
        }, 10);
      } else if (!running) {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
      
    }, [running, startTime]);

    var minutes = ("0" + Math.floor((time / 60000) % 60)).slice(-2)
    var seconds = ("0" + Math.floor((time / 1000) % 60)).slice(-2)

    if(minutes.substring(0, 1) == '0') {minutes = minutes.slice(1)}

      if(minutes < 0) {
        minutes = ("0" + Math.floor((time / 60000) + 1 % 60)).slice(-2)
        // seconds = '00'
      }

    // console.log(milliStart, milliEnd, startTime, '--------', milliStart-milliEnd)
    if(!startTime) return <span>{timerLength}:00</span>
    if(milliNow > milliEnd) return <span>0:00</span>

    return (
        <div className="stopwatch">
          <div className="numbers">
            <span>{minutes}:</span>
            <span>{seconds}</span>
          </div>
        </div>
      );
};

export default Timer;

import React, {useEffect, useState, useContext} from 'react'
import { SiteContext } from '../../App'

export function useInventory() {

    const {
        studentDataFunctions: {getMongoAllByParam}
    } = useContext(SiteContext)

    const [checkoutItems, setCheckoutItems] = useState([])

    useEffect(() => {

      async function getItems() {
  
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); 
        var yyyy = today.getFullYear();
  
        today = `month${mm}day${dd}`

        const checkoutItems = await getMongoAllByParam(true, 'inventoryItems', 'checkedOut')
        const checkoutItemsToday = await getMongoAllByParam(today, 'inventoryItems', 'checkoutTimestamp')
        // console.log(checkoutItems,'inventoryItems by today')
        if(!checkoutItemsToday) return
        checkoutItemsToday.map(entry => {
          var index = checkoutItems.findIndex(item => item._id === entry._id)
          if(index === -1) {checkoutItems.push(entry)}
        })
        setCheckoutItems(checkoutItems)
        // setCheckoutItems([...checkoutItemsToday, ...checkoutItems])
      }
  
      getItems()
  
    }, [])

    return { checkoutItems, setCheckoutItems }
}

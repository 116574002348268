import React, { useState, useEffect, useContext } from 'react';
import { SiteContext, baseUrl } from '../../../App';
import { AdminContext } from '../../../pages/AdminContainer';

import Item from './Item'

import { faCircleExclamation, faMagnifyingGlass, faWarning, faCalendar, faRecycle } from '@fortawesome/free-solid-svg-icons';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

defineCustomElements(window);

export default function AInventory({ inventoryItems, type }) {

  const { 
    modules, student, moduleItems, moduleField, loginState,
    studentDataFunctions: {
      moduleFind, getMongoAllByParam, getMongoList, setModuleField, setModuleItems
    }
  } = useContext(SiteContext)

  const{}
  = useContext(AdminContext)

  const [searchField, setSearchField] = useState("");
  const [newItems, setNewItems] = useState([])
  const [viewOptions, setViewOptions] = useState(true)

  const filtered = moduleItems.filter(
    person => {
      return (
        person.item.toLowerCase().includes(searchField.toLowerCase()) 
        || 
        (person.displayTitle && person.displayTitle.toLowerCase().includes(searchField.toLowerCase()))  
      );
    }
  )

  const handleChange = e => {
    e.preventDefault()
    setSearchField(e.target.value);
  };

  const handleChangeModule = (e) => {
    e.preventDefault()
    // console.log(e.target.value,'e.target.value')
    setModuleField(e.target.value);
  }

  function handleAddItem(e) {
    e.preventDefault()
    const milli = new Date().getTime()
    const editBlankitem = {
      ...blankItem,
      item: `${blankItem.item} (${newItems.length})`,
      module: moduleField, 
      fileName: newItems.length + milli,
      newId: milli
    }    
    
    setNewItems((prev) => {return [...prev, editBlankitem]})
  }

  function handleViewOptions(e) {
    e.preventDefault()
    setViewOptions((prev) => {return !prev})
  }

  const itemsCombined = [...newItems, ...filtered]

  const inventoryFunctions = {moduleItems, setModuleItems}

  var loadItems = moduleField === 'All' && moduleItems.length > 50 ? false : true

  return (
    <div className="m-2">
      <div className="card bg-white shadow-none">
        <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-1 d-inline">
              <div className="row">
                <div className="col-6 pe-0">
                  <select className="form-control col-6" onChange={(e) => handleChangeModule(e)}>
                      <option value={`zzz`}>-- Select Module --</option>
                      {modules.map(entry => {
                          return (
                              <option key={entry.fullTitle} value={entry.fullTitle}>{entry.fullTitle} </option>
                          )
                      })}
                      <option value={`All`}>All Modules</option>
                  </select>
                </div>
                <div className="col-6">
                  <input 
                    className="form-control" value={searchField} 
                    onChange = {handleChange} placeholder = "Search For Item" 
                  />
                </div>
              </div>
            </div>
            <div className="ms-2" style={{fontWeight:"bold"}}>Results: {filtered.length}</div>
          </div>
          <span>
            <button
                variant="white" id="closeSeatingChart"
                className="btn btn-wide btn-secondary"
                // className="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm"
                onClick={handleAddItem}
            >
              Create Item
            </button>
            {/* <button
                variant="white"
                className="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm manualButton"
                id="closeSeatingChart"
                onClick={e => handleViewOptions(e)}
            >
              {!viewOptions ? 'Show Options' : 'Hide Options'}
            </button> */}
          </span>
        </div>
        </div>
      </div>
      <div className="mt-3">
        {loadItems && itemsCombined.map(item => 
        <Item key={item._id ? item._id : item.url} item={item} setNewItems={setNewItems} viewOptions={viewOptions} blankItem={blankItem} loadItems={loadItems} setModuleItems={setModuleItems} />)}
      </div>
    </div>
  ) 
}


const days = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7', 'Day 8', 'Day 9', 'Day 10']

const dailyFunction = () => {
  const days = [1,2,3,4,5,6,7,8]
  const daily = []
  days.map(entry => {
    daily.push({text: `Day ${entry}`, variant: 'light', logo: faCalendar, action: () => {}, active: false })
  })
  return [...daily]
}

const badgesFunction = () => {
  return [
    {text: 'Careful', variant: 'purple', logo: faWarning, action: () => {}, active: false },
    {text: 'Teacher', variant: 'danger', logo: faCircleExclamation,  action: () => {}, active: false },
    {text: 'Review', variant: 'info', logo: faMagnifyingGlass,  action: () => {}, active: false },
    {text: 'Always Out', variant: 'success', logo: faMagnifyingGlass,  action: () => {}, active: false },
    {text: 'Out of Stock', variant: 'flag', logo: faRecycle,  action: () => {}, active: false },
  ]
}


const blankItem = {
  item: 'New Item',
  url: '',
  displayTitle: '',
  module: '',
  stockNum: '',
  quantity: '',
  fileName: '',
  newId: '',
  daily: dailyFunction(),
  badges: badgesFunction()
}

import React, { useContext } from 'react'

import { SiteContext } from '../../App';
import { StudentContext } from '../../pages/StudentContainer';
import { ErrorBoundary } from '../../other/utils/ErrorBoundary'

import Accordion from 'react-bootstrap/Accordion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshakeSimple, faStar } from '@fortawesome/free-solid-svg-icons';

export default function Agreements({sizeClass}) {

  const { 
    classInformation, focusAgreements, colorMatch, student, loginState,
  } = useContext(SiteContext)

  if(!classInformation || !classInformation.agreements || !focusAgreements) return

  const keys = []
  for(let i = 0; i < classInformation.agreements.length; i++) {
    // if(!agreements[i].focus) {continue}
    keys.push(i)
  }

  var currentIndex = colorMatch.findIndex(entry => entry.color === student.color.current)
    var currentVariant = colorMatch[currentIndex].variant

  var agreementCount = 0

  // console.log(student,'student')
    return (
      <ErrorBoundary>
      <div className={sizeClass}>
        <div className="main-card mb-3 card">
          <div className="card-body mb-0 mt-2 ms-1 pb-0">
            {/* Community Agreements */}
            <div className="card-title d-inline d-flex align-items-center">
              <FontAwesomeIcon className="d-inline me-2" icon={faHandshakeSimple} />
              Community Agreements
              <div className={`ms-2 badge bg-secondary p-1 ps-2 pe-2`} 
              // style={{fontSize:"12px"}}
              >Class</div>
            </div>
          </div>
          <div className="card-body">
            <Accordion 
              // defaultActiveKey={keys}
            >
              {classInformation.agreements.map((agreement) => {
                agreementCount++
                var count = 0
                var matchCount = 0
                student.behaviorEvents.map(entry => {
                  if(entry.category === agreement.category) {matchCount++}
                })
                return (
                  <Accordion.Item key={agreement.id} eventKey={agreementCount}>
                    <Accordion.Header>
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div>
                          {agreement.category}
                        </div>
                        <div className="d-flex">
                          {focusAgreements.focusCategory === agreement.category && <div className={`badge bg-${loginState.admin ? 'primary' : currentVariant} me-2 p-1 pe-2 ps-2 d-flex align-items-center`}>
                            Focus
                            {/* <FontAwesomeIcon className="m-1" icon={faStar} /> */}
                          </div>}
                          {matchCount > 0 && <div className={`badge bg-${loginState.admin ? 'primary' : currentVariant} me-2`}>{matchCount}</div>}
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {agreement.elements.map((element) => {
                        count++
                        return (
                        <div key={element.id} className="d-flex justify-content-start align-items-top">
                          <div className="me-2">{count}.</div>
                          <div>{element.entry}</div>
                        </div>
                        )
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })}
            </Accordion>
          </div>
        </div>
      </div>
      </ErrorBoundary>
    )
}

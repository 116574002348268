import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';

import HallPassMenuStatus from "../header/HallPassMenuStatus";
import Form from "./Form";

import {useTenMinRule} from '../../TenMinRule'
import { SiteContext } from "../../../App";
import { StudentContext } from "../../../pages/StudentContainer";
import { HallPassContext } from "../SHallPass";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

 
export default function HallPassModal({handleShow, handleClose, show, type, update}) {

    const [hpForm, setHpForm] = useState(blankForm)
    const [step, setStep] = useState(1)

    const {
      student, socket, room,
      studentDataFunctions: {createMongo, getMongoAllById, deleteMongo, getMongoList}
    } = useContext(SiteContext)

    const {updateExistingEvent, getHallPasses} = useContext(StudentContext)

    const {reason: message, time } = HallPassMenuStatus()

    function updateForm(e, value) {
      e.preventDefault()
      const editHpForm = {...hpForm, ...value}

      const keys = Object.keys(value)
      // console.log(keys,'keys', value)
      if(value.hasOwnProperty('time') && value.time > 50) return
      if(keys[0] === 'purposeOption') {
        if(keys[0] !== '-- Select Class Role --' && keys[0] !== 'Other') {
          editHpForm.purpose = value[keys[0]]
        }
      }
      setHpForm(editHpForm)
    }

    async function submitForm(e) {
      e.preventDefault()

      const newEvent = {...hpForm,
        name: student.name,
        studentId: student.studentId,
        period: student.period,
        timeStamp: Date.now(),
        active: true,
        approved: false,
        approvedTimeStamp: 0,
        closed: false
      }

      console.log(newEvent,'newEvent')

      try {

        const activePasses = await getMongoAllById(student.studentId, 'hallPassEvents')
        // const activePasses = await getMongoList('hallPassEvents')
        console.log(activePasses,'activePasses')
        const promises = activePasses.map(async(entry) => {
          if(entry.approved) return
          if(entry.studentId !== student.studentId) return
          // if(entry._id === data.createObj._id) return
          // await deleteMongo(entry._id, 'hallPassEvents')
          // updateExistingEvent(entry, 'update', {closed: true})
          // updateExistingEvent(entry, 'remove', {})
          alert('deleting')
          await deleteMongo(entry._id, 'hallPassEvent')
          return true
        })
        await Promise.all(promises)
        
        await createMongo(newEvent, 'hallPassEvents')
        setHpForm(blankForm)
        handleClose(type)
        setStep(1)
        
        function delay(time) {
          return new Promise(resolve => setTimeout(resolve, time));
        }

        await delay(1000)
        getHallPasses()
      }
      catch(err) {
        alert(err);
      }

    }

    function handleCloseLocal() {
      handleClose(type)
      setHpForm(blankForm)
      setStep(1)
    }

 // This following section will display the form that takes input from the user to update the data.
 return (
  <ErrorBoundary>    
    <div
      onKeyDown={e => e.stopPropagation()}
    >
    <Modal 
      show={show} 
      onHide={handleCloseLocal}
    >
      <Modal.Header>
        <Modal.Title>Create Hall Pass</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message !== 'Create Hall Pass' ? <Unavailable message={message} time={time} /> :
        step === 1 ? <Form hpForm={hpForm} updateForm={updateForm} /> : <Instructions/>}
      </Modal.Body>
      <Modal.Footer>
        {message !== 'Create Hall Pass' ? <></> : step === 1 ? <NextStepButton hpForm={hpForm} step={step} setStep={setStep} /> : <SubmitButton submitForm={submitForm} hpForm={hpForm} />}
      </Modal.Footer>
    </Modal>
    </div>
  </ErrorBoundary>
  
);

}

function NextStepButton({hpForm, step, setStep}) {

  var incomplete = false
  if(hpForm.purpose === '-- Select Hall Pass Purpose --' || hpForm.purpose.length === 0) incomplete = true
  if(hpForm.moreTime && hpForm.explain.length < 10 ) incomplete = true
  if(hpForm.moreTime && hpForm.time < 4 ) incomplete = true
  if(hpForm.moreTime === '') incomplete = true

  function handleStep(e) {
    e.preventDefault()
    const editStep = step
    setStep(editStep + 1)
  }

  return <button className={`btn btn-info me-2 ${incomplete ? 'disabled' : ''}`} onClick={e => handleStep(e)}>
    Next
  </button>
}

function SubmitButton({submitForm, hpForm}) {

  return <button className="btn btn-info me-2" onClick={e => submitForm(e)}>
            Submit
          </button>  
}


function Instructions() {
  return (
    <div className="row">
      <div className="col-12">
        <div className="card m-1 bg-info text-white shadow-none">
           <div className="card-header">
           Instructions:
           </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="col-12 col-md-4">
          <div className="d-flex m-1 me-2">
            <div className="card shadow-none bg-light me-1" style={{minHeight:"100px"}}>
              <div className="card-body d-flex justify-content-center align-items-center">
                <span className="fw-bold" style={{fontSize:"16px"}}>1.</span>
              </div>
            </div>
            <div className="card shadow-none bg-light" style={{minHeight:"100px"}}>
              <div className="card-body d-flex justify-content-start align-items-center">
                {/* <span className="me-2">1.</span> */}
                <span>Click Submit</span>
              </div>
            </div>
          </div>

        </div>
        <div className="col-12 col-md-4">
        <div className="d-flex m-1 me-2">
            <div className="card shadow-none bg-light me-1" style={{minHeight:"100px"}}>
              <div className="card-body d-flex justify-content-center align-items-center">
                <span className="fw-bold" style={{fontSize:"16px"}}>2.</span>
              </div>
            </div>
            <div className="card shadow-none bg-light" style={{minHeight:"100px"}}>
              <div className="card-body d-flex justify-content-center align-items-center">
                {/* <span className="me-2">1.</span> */}
                <span>Fill out paper hall pass</span>
              </div>
            </div>
          </div>
          {/* <div className="card m-1 shadow-none" style={{minHeight:"80px"}}>
            <div className="card-body d-flex justify-content-start align-items-start">
              <span className="me-2">2.</span>
              <span>Fill out paper hall pass</span>
            </div>
          </div> */}
        </div>
        <div className="col-12 col-md-4">
        <div className="d-flex m-1">
            <div className="card shadow-none bg-light me-1" style={{minHeight:"100px"}}>
              <div className="card-body d-flex justify-content-center align-items-center">
                <span className="fw-bold" style={{fontSize:"16px"}}>3.</span>
              </div>
            </div>
            <div className="card shadow-none bg-light" style={{minHeight:"100px"}}>
              <div className="card-body d-flex justify-content-center align-items-center">
                {/* <span className="me-2">1.</span> */}
                <span>Bring to Mr. Smith</span>
              </div>
            </div>
          </div>
          {/* <div className="card m-1 shadow-none" style={{minHeight:"80px"}}>
            <div className="card-body d-flex justify-content-start align-items-start">
              <span className="me-2">3.</span>
              <span>Bring to Mr. Smith</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

function Unavailable({message, time}) {
  // console.log(time,'time')
  return (
    <>
    <div className="d-flex justify-content-start align-items-center">
      <div className="badge bg-flag p-3">Hall Pass Unavailable</div>
      <div className="badge bg-secondary ms-3 p-3 d-flex">
        <div className="fw-normal me-2">Reason:</div>
        <div className="">{message}</div>
      </div>
    </div>
    {time.length > 0 && <div className="d-flex justify-content-start align-items-center mt-3">
      <div className="badge bg-light p-3 d-flex">
          <div className="fw-normal me-2">{message === 'Hall Pass In Use' && 'Expected '}Time Until Available:</div>
          <div className="">{time}</div>
      </div>
    </div>}
    </>
  )
}

const blankForm = {
  purpose: '',
  purposeOption: '',
  time: '',
  moreTime: '',
  reason: '',
  explain: '',
  allowSimultaneous: false,
}



import React from 'react'
import Question from './Question'
import Card from './Card'

export default function Toggle({form, step, goalQuestions, updateForm, review, staticGoal}) {

    var count = 0

    if(review) return (
        <div className="row">
        {goalQuestions.map(item => {
            var mode = item.type === 'goal' ? 'purple text-white' : 'white'
            count++
            // console.log(item,'item---')
            return <Questions key={item.type} mode={mode} item={item} />
        })}
        {!staticGoal && <Card bold={'Final Review'} text={'Rate how you feel about your goal:'} updateForm={updateForm} mode={'light'} sizeClass={'col-4'} />}
        </div>
    )

    return (
        <>
        {step > 1 && <Card bold={'Goal'} text={form.goal.goal}/>}
        {goalQuestions[step-1].type === 'things' && <Card bold={'Question'} text={'What are three things you can do to help you meet your goal this module?'}/>}
        {goalQuestions[step-1].questions.map(entry => {
          return <Question key={entry.question} entry={entry} updateForm={updateForm} form={form} />
        })}
        </>
    )

    function Questions({item, mode}) {
        return (
            <>
            {item.questions.map(item2 => {
            return <Card key={item2.item + count} entry={item2} breakLine={true} form={form} mode={mode} sizeClass={staticGoal ? item2.sizeStatic : item2.sizeReview} />
            })}
            </>
        )
    }
}


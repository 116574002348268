import React from 'react'

export function useProfile() {

    const valuesElements = [
        {property: 'Value 1', keyValue: ''},
        {property: 'Value 2', keyValue: ''},
        {property: 'Value 3', keyValue: ''},
        {property: 'Value 4', keyValue: ''},
        {property: 'Value 5', keyValue: ''},
    ]

    const strengthElements = [
        {property: 'Strength 1', keyValue: ''},
        {property: 'Strength 2', keyValue: ''},
        {property: 'Strength 3', keyValue: ''},
        {property: 'Strength 4', keyValue: ''},
        {property: 'Strength 5', keyValue: ''},
    ]

    const ideasElements = [
        {property: 'Idea 1', keyValue: ''},
        {property: 'Idea 2', keyValue: ''},
        {property: 'Idea 3', keyValue: ''},
    ]

    const interestsElements = [
        {property: 'Interest 1', keyValue: ''},
        {property: 'Interest 2', keyValue: ''},
        {property: 'Interest 3', keyValue: ''},
        {property: 'Interest 4', keyValue: ''},
        {property: 'Interest 5', keyValue: ''},
    ]

    const profileElements = [
        {property: 'values', title: 'Top 5 Values', adminText: 'Values', elements: valuesElements, textArea: false},
        {property: 'strengths', title: 'Top 5 Character Strengths', adminText: 'Strengths', elements: strengthElements, textArea: false},
        {property: 'agreementIdeas', title: '3 Agreement Ideas', adminText: 'Agreement Ideas', elements: ideasElements, textArea: true},
        {property: 'interests', title: '5 Interests', adminText: 'Interests', elements: interestsElements, textArea: false},
        // {property: 'yearGoal', title: 'Year Goal'},
        // {property: 'lifeGoal', title: 'Life Goal'},
    ]

    return { profileElements }
}

import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../../../App'
import { AdminContext } from '../../../pages/AdminContainer'
import { ErrorBoundary } from '../../../other/utils/ErrorBoundary'

// import './d_behavior.css'

export default function BalanceEntry({item}) {

    const { 
        colorMatch,
        studentList,
        studentDataFunctions: {
        }
    } = useContext(SiteContext)

    // console.log(item,'item')

    // const { handleAddChipsManual } = useContext(DStoreContext)
    const {handleAddChipsManual} = useContext(AdminContext)

    if(!item.chips || !item.chips.balance) return

    return (
        <ErrorBoundary>
        <tr>
            <td className="text-center">
                <div className="box">
                    <span className="text">{item.name}</span>
                </div>
            </td>
            <td className="text-center">
                <div className="box">
                    <span className="text">{item.chips.balance}€</span>
                    {/* <input
                    type="text" className={`form-control text-white`} min={0}
                    style={{width:"25%", display:"inline", fontSize: "12px"}}
                    value={item.chips.balance}
                    onChange={(e) => handleAddChipsManual(e, item.studentId, 0, -1, 'balance')} 
                    /> */}
                </div>
            </td>
            <td className="text-center">
                <div role="group" className="btn-group-sm btn-group">
                    <button 
                      onClick={(e) => handleAddChipsManual(e, item.studentId, 0, -5, 'balance')} 
                      className={`btn-shadow btn btn-secondary`}>
                    -5
                    </button>
                    <button 
                      className={`btn-shadow btn btn-light`}
                      onClick={(e) => handleAddChipsManual(e, item.studentId, 0, -1, 'balance')} >
                    -1
                    </button>
                    <button 
                      onClick={(e) => handleAddChipsManual(e, item.studentId, 1, 0, 'balance')} 
                      className={`btn-shadow btn btn-info`}>
                    +1
                    </button>
                    <button 
                      onClick={(e) => handleAddChipsManual(e, item.studentId, 5, 0, 'balance')} 
                      className={`btn-shadow btn btn-primary`}>
                    +5
                    </button>
                </div>
            </td>
            <td className="text-center">
                <div className="box">
                    <span className="text">{item.chips.total}€</span>
                </div>
            </td>
            <td className="text-center">
                <div role="group" className="btn-group-sm btn-group">
                    <button 
                      value={-1}
                      onClick={(e) => handleAddChipsManual(e, item.studentId, 0, -1, 'total')} 
                      className={`btn-shadow btn btn-light`}>
                    -1
                    </button>
                    <button 
                      value={1}
                      onClick={(e) => handleAddChipsManual(e, item.studentId, 1, 0, 'total')} 
                      className={`btn-shadow btn btn-info`}>
                    +1
                    </button>
                    <button 
                      value={1}
                      onClick={(e) => handleAddChipsManual(e, item.studentId, 5, 0, 'total')} 
                      className={`btn-shadow btn btn-primary`}>
                    +5
                    </button>
                    {/* <button 
                      onClick={(e) => handleAddChipsManual(e, item.studentId, 5, 0, 'total')} 
                      className={`btn-shadow btn btn-primary`}>
                    +5
                    </button>
                    <button 
                      onClick={(e) => handleAddChipsManual(e, item.studentId, 10, 0, 'total')} 
                      className={`btn-shadow btn btn-purple text-white`}>
                    +10
                    </button> */}

                </div>
            </td>
        </tr>
        </ErrorBoundary>
    )
}

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function EntryImage({item}) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        <img src={item.url} style={{objectFit:"contain", height:"100%", maxWidth: "150px", margin:"auto", verticalAlign:"middle", display:"block"}}/>
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <div className={`text badge bg-${item.checkedOut && !item.checkoutEvent.adminProcessed ? 'secondary' : 'light'}`} role="button">{item.item.substring(0, 30)}</div>  
    </OverlayTrigger>
  );
}

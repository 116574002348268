import React from 'react'

export default function AgEntry({count, element, checkFocus, updateFocus}) {

    if(!element.adminFocus)

    return (
        <>
            <span>{count}.</span>
            <span className="">
                <input
                className="form-check-input"
                type="checkbox"
                name="positionOptions"
                checked={checkFocus(element, 'agreements', 'adminFocus')}
                value={checkFocus(element, 'agreements', 'adminFocus')}
                style={{marginRight:"10px"}}
                onChange={e => updateFocus(e, element, 'agreements', e.target.value, 'adminFocus')}
                />
            </span>
            <span>{element.entry}</span>
        </>
    )
}

import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from 'uuid'

import { SiteContext } from "../../../App";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faBookmark, faX, faRotate, faShare, faLock, faUnlock, faPlus } from '@fortawesome/free-solid-svg-icons';

export default function Templates({exportFunctions}) {

    const {classInformation, classInformationArray, focusAgreements, studentDataFunctions: {createMongo, updateMongo, deleteMongo, setFocusAgreements, getMongoList, getMongoByUniqueId}} = useContext(SiteContext)
    const {allowEdit, handleAllowEdit, saveLayout, layout, onLayoutChange} = exportFunctions

    const [classTemplates, setClassTemplates] = useState([])
    const [activeTemplate, setActiveTemplate] = useState('none')
    
    useEffect(() => {
  
      async function getClasses() {
        const activeClasses = []
  
        // console.log('hello')
        const promises = classInformationArray.map( async (classEntry) => {
          
          const loadedEntry = await getMongoByUniqueId(classEntry.focusAgreementId, 'focusAgreements')
          // console.log(loadedEntry[customOption.type],'loadedEntry[customOption.type]', customOption.type)
          if(loadedEntry.layout) {
            activeClasses.push({template: loadedEntry.layout, period: loadedEntry.period, id: loadedEntry._id, active: true, text: `Active Seating Chart`})
          }
          return true
        })
        await Promise.all(promises)
        
        activeClasses.sort(function(a, b) {
          var textA = a.period.toUpperCase();
          var textB = b.period.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
  
        const templates = await getMongoList('seatTemplates')
        templates.map(template => {
          activeClasses.push({...template, template: template.layout, active: false, id: template._id})
        })
  
        setClassTemplates(activeClasses)

        var filter1 = activeClasses.filter(entry => entry.period === classInformation.period)
        var filter2 = filter1.filter(entry => entry.active)
        console.log(filter1,'1', filter2)
        setActiveTemplate(filter2[0].id)
      }
  
      getClasses()
  
    }, [classInformation.period, classTemplates.length])
  
    useEffect(() => {
      setActiveTemplate('none')
    }, [classInformation.period])
  
    async function handleSaveTemplate(e) {
      e.preventDefault()
      console.log(layout[0],'0')
      await updateMongo({layout: layout, period: 'Period 12'}, activeTemplate, 'seatTemplates')
    //   createMongo({...customOption, period: classInformation.period, timestamp: Date.now()}, 'customModalTemplates')
      setClassTemplates([])
    }
  
    function handleUseTemplate(e) {
      e.preventDefault()
      if(e.target.value === 'none') return
      var index = classTemplates.findIndex(entry => entry.id === e.target.value)
  
      const modifyTemplate = {...classTemplates[index]}
  
      delete modifyTemplate.period
      delete modifyTemplate.timestamp
      delete modifyTemplate._id
      // delete modifyTemplate.id
      delete modifyTemplate.active
  
      // modifyTemplate.id = uuidv4()
  
      setActiveTemplate(e.target.value)
      console.log(classTemplates[index].template[0], 'use', classTemplates[index])
      const editFocusAgreements = {...focusAgreements, layout: classTemplates[index].template}
      setFocusAgreements(editFocusAgreements)
      onLayoutChange(classTemplates[index].template)
    //   setVariables(modifyTemplate)
    }
  
    function handlePushTemplate(e) {
      e.preventDefault()
  
      classInformationArray.map(classEntry => {
        if(classEntry.period === classInformation.period) return
        // updateMongo({[customOption.type]: {...customOption}}, classEntry.focusAgreementId, 'focusAgreements')
      })
    }
  
    function handleDeleteTemplate(e) {
      e.preventDefault()
  
      var index = classTemplates.findIndex(entry => entry.id === activeTemplate)
      if(index === -1) return
      if(classTemplates[index].active) return
      deleteMongo(classTemplates[index].id, 'seatTemplates')
      setClassTemplates([])
    }

    function saveLayoutLocal() {
        saveLayout()
        setClassTemplates([])
    } 
  
    var index = classTemplates.findIndex(entry => entry.id === activeTemplate)
    var currentTemplate = index === -1 ? false : classTemplates[index]

    console.log(currentTemplate,'currentTemplate')
    
    return (
    <>
      <div className="d-flex align-items-center">
      <span className="m-2 fw-bold">Template:</span>
      <div className="">
        <select className="form-control" role="button" onChange={e => handleUseTemplate(e)} style={{maxWidth:"300px"}} value={activeTemplate}>
            <option value="none">Choose From Templates</option>
            {classTemplates.map(classEntry => {
              return <option key={classEntry.id} value={classEntry.id}>{classEntry.active ? classEntry.period : getDateString(classEntry.timestamp)} - {classEntry.text}</option>
            })}
        </select>
      </div>
      <div className="btn-group d-flex align-items-center ms-2 me-4" role="group">
        <div type="button" className={`btn btn-primary`} onClick={e => handleSaveTemplate(e)}>
          <FontAwesomeIcon color="white" icon={faFloppyDisk} /> 
          {/* <span className="ms-2">Template</span> */}
        </div>
        <AddComponent layout={layout} classTemplates={classTemplates} setClassTemplates={setClassTemplates} />
        {currentTemplate && !currentTemplate.active && <DeleteComponent handleDeleteTemplate={handleDeleteTemplate} currentTemplate={currentTemplate} />}
      </div>
      </div>
      <div className="ms-2 btn-group d-flex align-items-center" role="group">
        <div type="button" className={`btn btn-primary`} onClick={saveLayoutLocal}>
          <FontAwesomeIcon color="white" icon={faFloppyDisk} /> 
          <span className="ms-2">Class</span>
        </div>
        <div type="button" className={`btn ${allowEdit ? `btn-light text-flag` : `btn-flag text-white`}`} onClick={handleAllowEdit}>
          <FontAwesomeIcon color={allowEdit ? 'flag' : 'white'} icon={allowEdit ? faLock : faUnlock} /> 
          <span className="ms-2">Grid</span>
        </div>
      </div>
    </>
    )
  }

  function getDateString(milliseconds) {
    var d1 = new Date(milliseconds),
      d = d1.getDate(),
      m = d1.getMonth() + 1,
      y = d1.getFullYear();
  
    return `${m}/${d}`
  }

  function AddComponent({layout, classTemplates, setClassTemplates}) {

    const {classInformation, focusAgreements, studentDataFunctions: {createMongo}} = useContext(SiteContext)

    const [show, setShow] = useState(false);
    const [title, setTitle] = useState(() => {
        var filter = classTemplates.filter(entry => !entry.active)
        return `Template (${filter.length + 1})`
    })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    async function handleCreate() {
        handleClose()
        createMongo({layout: layout, period: classInformation.period, timestamp: Date.now(), text: title}, 'seatTemplates')
        setClassTemplates([])
    }

    return (
        <>
        <div type="button" className={`btn btn-success`} onClick={handleShow}>
          <FontAwesomeIcon color="white" icon={faPlus} /> 
        </div>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Create New Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>This converts the current displayed seat layout into a saved template.</span>
                <div className="mt-3 d-flex align-items-center">
                    <span className="me-2">Title:</span>
                    <input placeholder={title} type="text" className="form-control"
                    value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>

            </Modal.Body>
            <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
                Close
            </Button> */}
            <Button variant="success" onClick={handleCreate}>
                Create New Template
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
  }

  function DeleteComponent({handleDeleteTemplate, currentTemplate}) {

    const {classInformation, focusAgreements, studentDataFunctions: {createMongo}} = useContext(SiteContext)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
        <div type="button" className={`btn btn-danger`} onClick={handleShow}>
          <FontAwesomeIcon color="white" icon={faX} />
        </div>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Delete Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span className="me-2">Confirm Deletion:</span>
                <span className="fw-bold">{currentTemplate.title}</span>
            </Modal.Body>
            <Modal.Footer>
            {/* <Button variant="secondary" onClick={handleClose}>
                Close
            </Button> */}
            <Button variant="danger" onClick={handleDeleteTemplate}>
                Delete Template
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
  }
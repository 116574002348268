import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid'
import { usePostHog } from 'posthog-js/react'

import blankLayout from '../../other/json/blankLayout.json'

export function useReset({getMongoList, deleteMongo, getMongoById, getMongoByUniqueId, updateMongo, setFocusAgreements, socket, setInitialLoad, studentList, setStudentList, getRecords, setStudent, setRoomJoined, setClassInformation, classInformationObject, classInformation, classInformationArray, LOCAL_STORAGE_KEY, LOCAL_STORAGE_KEY2, LOCAL_STORAGE_KEY3, LOCAL_STORAGE_KEY4, setUpdateFocusAgreements, loginState, modules }) {

    const navigate = useNavigate();
    const posthog = usePostHog()

    function handleResetOtherLists() {
        getRecords()
    
        getMongoList('messages').then(entries => {
          entries.map(entry => {
            deleteMongo(entry._id, 'messages')
          })
        })
    
        getMongoList('behaviorEvents').then(entries => {
          entries.map(entry => {
            deleteMongo(entry._id, 'behaviorEvents')
          })
        })

        getMongoList('hallPassEvents').then(entries => {
          entries.map(entry => {
            deleteMongo(entry._id, 'hallPassEvents')
          })
        })

        // clearFocusAgreements()
    
    }
    
      function clearFocusAgreements() {
        classInformationArray.map(async(classEntry) => {

              const entryBefore = await getMongoByUniqueId(classEntry.focusAgreementId, 'focusAgreements')
              const entry = cleanFocusAgreement(entryBefore)
              // entry.agreements.map(item => {
              //   item.adminFocus = false
              // })
              // entry.categories.map(item => {
              //   item.adminFocus = false
              // })

              // entry.dayReview = false
              // entry.goalSetting = false
              // entry.customModal = false
              // entry.timerMilli = false
              // entry.timeLength = 5
              // entry.createAgreement = newCustomModal('createAgreement')
              // entry.attendanceQuestion = newCustomModal('attendanceQuestion')
              // entry.dailyLinks = newCustomModal('dailyLinks')
              // entry.customModalType = 'attendanceQuestion'

              const id = entry._id
              delete entry._id
      
              var index = classInformationArray.findIndex(item => item.period === entry.period)

              const newCategories = []
              const newAgreements = []
              classInformationArray[index].agreements.map(item => {
                newCategories.push({category: item.category, adminFocus: false, id: uuidv4()})

                item.elements.map(entry2 => {
                  newAgreements.push({id: uuidv4(), category: item.category, adminFocus: false, studentFocus: false, entry: entry2.entry})
                })
              })
              const newFocusAgreements = {...entry, agreements: newAgreements, categories: newCategories}
              updateMongo(newFocusAgreements, id, 'focusAgreements') 
              setFocusAgreements(newFocusAgreements)
              socket.emit("send_focusAgreementsUpdate", { focusAgreements: newFocusAgreements, room: 'admin' });
            })
        // })
        // setFocusAgreements()
        // setUpdateFocusAgreements(true)
      }

      function cleanFocusAgreement(entry, newEntry) {

        if(newEntry) {
          entry.period = `New Period (${classInformationArray.length})`
          entry.agreements = []
          entry.categories = []
          entry.layout = JSON.parse(JSON.stringify(blankLayout))

          entry.modules = addCleanModules()
        }
        
        entry.agreements.map(item => {
          item.adminFocus = false
        })
        entry.categories.map(item => {
          item.adminFocus = false
        })

        entry.dayReview = false
        entry.goalSetting = false
        entry.customModal = false
        entry.timerMilli = false
        entry.timeLength = 5

        entry.createAgreement = newCustomModal('createAgreement')
        entry.attendanceQuestion = newCustomModal('attendanceQuestion')
        entry.dailyLinks = newCustomModal('dailyLinks')
        entry.readingZone = newCustomModal('readingZone')
        entry.assignments = newCustomModal('assignments')

        entry.customModalType = 'attendanceQuestion'
        entry.chips = [{variant: 'light', timeStamp: '15'}, {variant: 'light', timeStamp: '30'}, {variant: 'light', timeStamp: '45'}]
        entry.auctionModule = false
        entry.focusCategory = ''
        entry.pauseResponses = false
        
        return entry
      }

      function addCleanModules() {
        const newModules = []

        modules.map(entry => {
          entry.checklists = [{groupNumber: 1, checklist: entry.checklist}]
          delete entry.checklist
          newModules.push(entry)
        })

        return newModules
      }

      function handleResetStudent () {
        localStorage.setItem(LOCAL_STORAGE_KEY2, JSON.stringify([]))
      }
    
      function handleResetLoginState () {
        localStorage.setItem(LOCAL_STORAGE_KEY3, JSON.stringify({}))
      }
    
      function handleResetClassInformation () {
        if(classInformationObject == undefined) {return}
        localStorage.setItem(LOCAL_STORAGE_KEY4, JSON.stringify(classInformationObject))
      }
    
      function handleLogout() {
        navigate('/'); 
        setStudent({})
        setClassInformation(classInformationObject)
        // if(loginState && loginState.admin) {handleResetStudent()}
        handleResetLoginState()
        handleResetClassInformation()
        setRoomJoined(false)
        // posthog.reset()
      }

      async function updateDailyRecord(studentListFiltered) {

        const editStudentList = [...studentListFiltered]

        editStudentList.map(item => {
          const newItem = individualAttendanceData(item)
           item = newItem
        })

        // console.log(editStudentList,'editStudentList')
        setStudentList(editStudentList)
        // setInitialLoad(true)
    }

    function individualAttendanceData(item, local) {

      const attendanceColors = {
        'P': 'success',
        'T': 'warning',
        'A': 'danger',
      } 

      var attendancePercent = {
        pCount: 0, tCount: 0, aCount: 0,
        percent: 0,
        tPercent: 0,
        aPercent: 0
      }

      // console.log(item, 'item', item.days)
      if(!item.days) {
        item.attendanceString = ''
        item.attendancePercent = attendancePercent
        item.days = []
      }

      // if(item.firstName === 'Natalia') {
      //   console.log(item.days, 'item.days', item.name)
      // }

      const dailyMongoRecent = !local ? item.days.reverse() : item.days
      var attendanceString = []

      if(dailyMongoRecent.length > 0){
          dailyMongoRecent.map(entry => {
            // console.log(entry,'entry days')
              if(!entry.attendance || !entry.attendance.substring(0, 1)) return
              var letter = entry.attendance.substring(0, 1).toUpperCase()
              attendanceString.push({
                  letter,
                  variant: attendanceColors[letter]
              })
          })
      }

      var attendanceStringFinal = attendanceString

      var letterToday = item.attendance ? item.attendance.substring(0, 1).toUpperCase() : ''
      if(item.loggedIn || item.attendance !== 'Present') {
        attendanceString = [...attendanceString, {
          letter: letterToday,
          variant: attendanceColors[letterToday]
        }]
        attendanceStringFinal = attendanceString.slice(1)
      }

      const pCount = attendanceStringFinal.filter(entry => entry.letter === "P").length
      const tCount = attendanceStringFinal.filter(entry => entry.letter === "T").length
      const aCount = attendanceStringFinal.filter(entry => entry.letter === "A").length

      attendancePercent = {
        pCount: pCount, tCount: tCount, aCount: aCount,
        percent: Math.round((tCount + aCount)/(tCount + aCount + pCount)*100),
        tPercent: Math.round((tCount)/(tCount + aCount + pCount)*100),
        aPercent: Math.round((aCount)/(tCount + aCount + pCount)*100)
      }

      // console.log(attendancePercent,'attendancePercent')
      item.attendanceString = attendanceStringFinal
      // item.hallPassEvents = item.hallPasses
      item.attendancePercent = attendancePercent
      // item.days = []

      return item
    }

    return {
      handleResetOtherLists, handleLogout, handleResetStudent, clearFocusAgreements, updateDailyRecord, newCustomModal, cleanFocusAgreement, individualAttendanceData
    }
}



const newCustomModal = (type) => {

  const dailyLinksBullet = {
    id: uuidv4(),
    entry: '',
    link: ''
  }

  return {
      type,
      id: uuidv4(),
      text: '',
      bullets: type === 'dailyLinks' ? [{...dailyLinksBullet}] : [],
      url: '',
      responseType: 'No Response'
  }
}
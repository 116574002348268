import React, { useContext, useState } from 'react'
import { SiteContext } from "../../../App";
import RatingComponent from './Rating'

export default function Card({bold, text, breakLine, entry, mode, sizeClass, updateForm}) {

  const {colorMatch, student} = useContext(SiteContext)

  const form = student.goalSetting

  var reversed = [...colorMatch]
    reversed.reverse()

  if(entry) {
    text = form[entry.category][entry.item] ? form[entry.category][entry.item] : ''
    bold = entry.reviewText ? entry.reviewText : entry.question
    // sizeClass = entry.sizeClass
  }

  console.log(mode,'mode')
  if(!form || !form.review) return

    return (
      <div className={`${sizeClass ? `${sizeClass}` : 'col-12'} mb-4 d-flex align-items-stretch`}>
        <div className={`card-shadow-secondary bg-${mode ? mode : 'light'} ${bold === 'Final Review' && 'border border-3 border-secondary'} card p-3 w-100`}>
          <div className="fsize-1">
              <div className="d-flex align-content-start flex-wrap">
                <div className="w-100">
                {bold && <span style={{fontWeight:"bold"}}>{bold}:</span>}
                </div>
                <div className="text-left d-block">
                {text} 
                </div>
              
              </div>
              
              {/* {bold === 'Rating' && <div><RatingComponent reversed={reversed} updateForm={updateForm} colorMatch={colorMatch} category={'review'} item={'review'} /></div>} */}
          </div>
          <div className="widget-content-left fsize-1">
              <div className="">
                  {bold === 'Final Review' && <div><RatingComponent reversed={reversed} updateForm={updateForm} colorMatch={colorMatch} category={'review'} item={'review'} reviewRating={form && form.review && form.review.review} /></div>}
              </div>
          </div>
        </div>
      </div>
    )
}







      
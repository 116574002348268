import React, { useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { SiteContext } from '../../App';

import Accordion from 'react-bootstrap/Accordion';
import ScheduleEntry from './ScheduleEntry';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';

const schedulesFunction = require('./schedule.js')

export default function ScheduleAccordion({sizeClass, title, grade}) {

  const { 
    classInformation: {
    }
  } = useContext(SiteContext)

  const schedules = schedulesFunction()

  const scheduleSelect = [
      {
          grade: 7,
          normal: schedules['normal7'],
          minimum: schedules['minimum7'],
          noon: schedules['noon7']
      },
      {
        grade: 8,
        normal: schedules['normal8'],
        minimum: schedules['minimum8'],
        noon: schedules['noon8']
    }
  ]

  const schedulesArray = scheduleSelect.filter(entry => entry.grade === grade)[0]

    return (
      <div className={sizeClass}>
        <div className="main-card mb-3 card">
          <div className="card-header">
            <FontAwesomeIcon style={{ marginRight: 10 }} icon={faCalendarDays} />
            {title}
          </div>
          <div className="card-body">
            <Accordion>
                  <Accordion.Item eventKey={'normal'}>
                    <Accordion.Header>Normal</Accordion.Header>
                    <Accordion.Body>
                    <div className="recipe__row">
                        <div className="recipe__value recipe__value--indented">
                            <div className="ingredient-grid3">
                                <span className="bold">Period</span>
                                <span className="bold">Start</span>
                                <span className="bold">End</span>
                                {schedulesArray['normal'].map(entry => {
                                    return <ScheduleEntry key={uuidv4()} entry={entry}/>
                                })}
                            </div>
                        </div>
                    </div>  
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={'minimum'}>
                    <Accordion.Header>Minimum</Accordion.Header>
                    <Accordion.Body>
                    <div className="recipe__row">
                        <div className="recipe__value recipe__value--indented">
                            <div className="ingredient-grid3">
                                <span className="bold">Period</span>
                                <span className="bold">Start</span>
                                <span className="bold">End</span>
                                {schedulesArray['minimum'].map(entry => {
                                    return (
                                        <ScheduleEntry key={uuidv4()} entry={entry}/>
                                    )
                                })}
                            </div>
                        </div>
                    </div>  
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={'noon'}>
                    <Accordion.Header>12:10 Dismissal</Accordion.Header>
                    <Accordion.Body>
                    <div className="recipe__row">
                        <div className="recipe__value recipe__value--indented">
                            <div className="ingredient-grid3">
                                <span className="bold">Period</span>
                                <span className="bold">Start</span>
                                <span className="bold">End</span>
                                {schedulesArray['noon'].map(entry => {
                                    return (
                                        <ScheduleEntry key={uuidv4()} entry={entry}/>
                                    )
                                })}
                            </div>
                        </div>
                    </div>  
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* {grade === 8 && <Accordion.Item eventKey={'promotion'}>
                    <Accordion.Header>Promotion Requirements</Accordion.Header>
                    <Accordion.Body>
                    <div className="font-weight-bold mb-2">In order to participate, students must: </div>
                    <div>- have less than 3 Semester 2 F’s</div>
                    <div>- not be suspended during Quarter 4</div>
                    <div>- not have more than 10 unexcused absences</div>
                    </Accordion.Body>
                  </Accordion.Item>} */}
            </Accordion>
          </div>
        </div>
      </div>
    )
}

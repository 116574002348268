import React, { useState, useEffect, useContext } from "react";

export function useTimeRemaining(timeImportMilli) { 

    const [time, setTime] = useState(0);
    const [running, setRunning] = useState(false);

    // console.log(classInformation,'classInfo', timeImport)

    const timeImport = timeImportMilli

    useEffect(() => {
      
    //   if(!classInformation.classStart || !classInformation.classLength) return
    if(!timeImport) return

      var dateNow = new Date()
          var dateNowMilli = dateNow.getTime()

        setTime(timeImportMilli-dateNowMilli)
        setRunning(true)

      return () => {}

    }, [timeImport])

    useEffect(() => {

      let interval;

      if (running) {
        interval = setInterval(() => {
          setTime((prevTime) => prevTime - 10);
        }, 10);
      } else if (!running) {
        clearInterval(interval);
      }

      return () => clearInterval(interval);
    }, [running]);
    
    // console.log(time/60000,'time/60000', timeImportMilli)

    if(time > 0) {
        var minutes = ("0" + Math.floor((time / 60000) % 60)).slice(-2)
        var seconds = ("0" + Math.floor((time / 1000) % 60)).slice(-2)
        return `${minutes}:${seconds}`
    }

    return 'Returning Soon'

};

import React,{ useState, useContext, useEffect } from 'react'

export function useStore({studentList, dailyList, updateMongo, setStudentList, getMongoList, socket, room}) {

    const [cart, setCart] = useState([])
    const [storeItems, setStoreItems] = useState([])

    useEffect(() => {

        async function getItems() {
            const items = await getMongoList('storeItems')
            setStoreItems(items)
            setCart(items)
        }
        getItems()

    }, [])

    return {
        cart, setCart, storeItems, setStoreItems
    }
}

const cartArr = [
    { name: 'Cup', price: 1, quantity: 0 },
    { name: 'Draw From Small Box', price: 8, quantity: 0 },
    // { name: 'Fidget Toy for the Day', price: 7, quantity: 0 },
    { name: 'Positive Phone Call Home', price: 15, quantity: 0 },
    { name: '$10 Cougar Bucks (Max of $30 Per Week)', price: 15, quantity: 0 },
    { name: 'Draw From Big Drawer', price: 16, quantity: 0 },
    { name: 'Choose Your Seat for the Day', price: 20, quantity: 0 },
    { name: 'Draw From XL Drawer (chips, poptarts)', price: 24, quantity: 0 },
    // { name: '$25 Cougar Bucks', price: 15, quantity: 0 },
    { name: 'Build A Crossbow', price: 80, quantity: 0 },
]
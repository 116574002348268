import React from 'react'

export default function AgreementList({category, arrayCategory}) {

    // console.log(category,'category')

    var count = 0

    return (
        <>
        {category.elements.map(categoryEntries => {
          count++
          return <Entry key={categoryEntries.entry} count={count} category={category} categoryEntries={categoryEntries} arrayCategory={arrayCategory} />
        })}

        </>
    )
}

function Entry({count, category, categoryEntries, arrayCategory}) {
  // console.log(category,'category', category.category)
  return (
    <>
      <span>{count}.</span>
      <span> 
        {arrayCategory === 'Agreements' && categoryEntries.adminStatus && <div className="badge me-1 bg-primary">A</div>}
        {categoryEntries.entry}
      </span> 
    </>      
  )
}
import React, { useState, useEffect, useContext } from "react";
import { SiteContext } from "../App";

export function useTenMinRule() { 

  const {classInformation, focusAgreements} = useContext(SiteContext)

  // console.log(timeImportNormal, timeImportMinimum, dayType,'')

    const [time, setTime] = useState(0);
    const [running, setRunning] = useState(false);
    const [count, setCount] = useState(false)

    const timeImport = classInformation.classStart
    const classLength = classInformation.classLength

    useEffect(() => {
      
      if(!classInformation.classStart || !classInformation.classLength) return

      var hours = timeImport.split(':')[0]
      var min = timeImport.split(':')[1]
      hours = parseInt(hours)
      if(hours < 8) {hours = hours + 12}

      // console.log('gello')
      var timeImportDate = new Date()
        timeImportDate.setHours(hours)
        timeImportDate.setMinutes(min)
        timeImportDate.setSeconds(0)
        timeImportDate.setMilliseconds(0)

        var timeImportDateMilli = timeImportDate.getTime()

      var dateNow = new Date()
          var dateNowMilli = dateNow.getTime()

        setTime(dateNowMilli-timeImportDateMilli)
        setRunning(true)

      return () => {}

    }, [classInformation.classStart, classInformation.classLength, focusAgreements, count])

    useEffect(() => {

      let interval;

      if(running) {
        interval = setInterval(() => {
          setTime((prevTime) => prevTime + 10);
        }, 10);
      } else if (!running) {
        clearInterval(interval);
      }

      return () => clearInterval(interval);
    }, [running]);

    useEffect(() => {

      let interval;

      interval = setInterval(() => {
        const editCount = count
        setCount(prev => {return prev + 1})
      }, 5000);

    }, []);
    
    // console.log(time/60000,'time/60000', count)

    // var remaining = time
    var remaining = 600000 - time
    // console.log(remaining,'remaining')

    if(time/60000 < 10 && time/60000 > 0) {
      var minutes = ("0" + Math.floor((remaining / 60000) % 60)).slice(-2)
      var seconds = ("0" + Math.floor((remaining / 1000) % 60)).slice(-2)
      return `${minutes}:${seconds}`
    } else if(time/60000 > classLength - 10 && time/60000 < classLength) {
      return true
    }

    return false

};

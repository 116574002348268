import React, {useContext} from 'react'

import DataOverlay from './DataOverlay';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { AdminContext } from '../../../pages/AdminContainer';
import { SiteContext } from '../../../App';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faArrowDown, faArrowUp, faCircle, faCircleHalfStroke, faPen, faList } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

import './overlay.css'

export default function HallPassMath({student, hallPasses, type, trigger}) {

    const {initialLoad} = useContext(SiteContext)
    const {convertTimeString, calcHallPassMath} = useContext(AdminContext)

    if(!initialLoad) return
    
    var fortNight = 12096e5/2
    if(hallPasses && hallPasses.length > 0) {
        var {over, under, total, totalComplete, totalDifference, totalString, averagePass} = calcHallPassMath(hallPasses, Date.now()-(2*fortNight))
    }
        const popover = (
            <Popover className="tooltip">
              <Popover.Header>
                  <DateSummary hallPasses={hallPasses} pastRange={Date.now()-(fortNight)} title={'Past Week'} titleVariant={'black'}/>
                  <DateSummary hallPasses={hallPasses} pastRange={Date.now()-(2*fortNight)} title={'Past 2 Weeks'} titleVariant={'secondary'}/>
                  <DateSummary hallPasses={hallPasses} pastRange={Date.now()-(4*fortNight)} title={'Past Month'} titleVariant={'light'}/>
                  <DateSummary hallPasses={hallPasses} pastRange={Date.now()-(200*fortNight)} title={'All Time'}/>
              </Popover.Header>
              <Popover.Body>
                <DataOverlay entry={student} hallPasses={hallPasses} convertTimeString={convertTimeString} />
              </Popover.Body>
            </Popover>
        );

        return (
        <>
         <OverlayTrigger placement="bottom" trigger={trigger ? trigger : ['hover', 'focus']} className="tooltip"
          delay={250} overlay={hallPasses && hallPasses.length > 0 ? popover : <></>}
          >
             {type === 'menu' ?           
             <button type="button" style={{fontSize:"10px"}} className={`mb-2 me-2 btn-pill btn btn-outline-2x btn-outline-light active p-1`} style={{fontSize:"12px"}}>
                {total > 0 ? 
                <>
                    <div className={`me-0 badge bg-secondary`}>{averagePass}</div>
                    <div className={`me-0 badge bg-success`}>{under}</div>
                    <div className={`me-0 badge text-danger p-0`}>
                        {totalDifference < 0 && <FontAwesomeIcon className="me-1" icon={faArrowDown} />}
                        <span>{totalString}</span>
                        {totalDifference > 0 && <FontAwesomeIcon className="ms-1" icon={faArrowUp} />}
                    </div>
                    <div className={`me-1 badge bg-danger`}>{over}</div>
                </>
                    :
                <div className="fw-bold">No Hall Passes</div>}
            </button> 
          : <FontAwesomeIcon className="m-1 ms-2" icon={faList} role="button" />}
          </OverlayTrigger> 
            {type === 'menu' ? <></> : hallPasses && hallPasses.length > 0 ? 
            <div className="w-100 text-center">
                <DateSummary hallPasses={hallPasses} pastRange={Date.now()-(2*fortNight)} title={'Past 2 Weeks'} type={'main'}/>
            </div> :
            
            <div className="d-flex justify-content-center w-100">
              <div className={`d-block badge bg-secondary flex-shrink`}>No Passes</div>
            </div>
            }
        </>
        )



    function DateSummary({hallPasses, pastRange, title, titleVariant, type}) {

        const {over, under, total, totalComplete, totalDifference, totalString, averagePass} = calcHallPassMath(hallPasses, pastRange)

        if(pastRange && type !== 'main') return (
        <div className="m-2">  
            <div className={`me-1 badge bg-secondary`}>{under + over}</div>
            <div className={`me-3 badge bg-secondary`}>{averagePass}</div>
            <div className={`me-1 badge bg-success`}>{under}</div>
            <div className={`me-1 badge text-${totalDifference < 0 ? 'success' : 'danger'}`}>
                {totalDifference < 0 && <FontAwesomeIcon className="me-1" icon={faArrowDown} />}
                <span>{totalString}</span>
                {totalDifference > 0 && <FontAwesomeIcon className="ms-1" icon={faArrowUp} />}
            </div>
            <div className={`me-1 badge bg-danger`}>{over}</div>
            <span className="ms-2">
                <div className={`me-1 badge bg-${titleVariant ? titleVariant : 'white text-black'}`} style={{border: !titleVariant ? "1px solid black" : ''}}>{title}</div>
            </span>
        </div>
        )
        return (
        <>
        <div className={`me-2 badge bg-secondary`}>{averagePass}</div>
        {/* <div className="m-2 mt-0">   */}
            <div className={`badge bg-success`}>{under}</div>
            <div className={`badge text-${totalDifference < 0 ? 'success' : 'danger'}`}>
                {totalDifference < 0 && <FontAwesomeIcon className="me-1" icon={faArrowDown} />}
                <span>{totalString}</span>
                {totalDifference > 0 && <FontAwesomeIcon className="ms-1" icon={faArrowUp} />}
            </div>
            <div className={`me-2 badge bg-danger`}>{over}</div>
            {/* <div className={`me-1 badge bg-secondary`}>{under + over}</div> */}
        {/* </div> */}
        </>
        )
    }    

}

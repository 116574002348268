import React, { useContext } from 'react'

import { SiteContext } from '../../App'

import Stopwatch from '../../other/utils/Stopwatch'
import Timer from '../../other/utils/Timer'
import calculateTime from '../../other/utils/calculateTime'

export default function Card( { title, subtitle, sizeValue, time, timerLength, startTime, displayTime }) {

  const {classInformation} = useContext(SiteContext)
  const {classStart, classEnd} = calculateTime(classInformation.dayType, classInformation.classStartNormal, classInformation.classStartMinimum)

    return (
        <div className={`flex-fill`}>
          <div className={`mb-3 widget-chart text-start card card-shadow-secondary p-3`}>
            <div className="widget-content p-0 w-100">
              <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left pe-2 fsize-1">
                    <div className={`widget-numbers mt-0 text-primary`} style={{fontSize:"32px"}}>
                        {time && time === 'stopwatch' && displayTime}
                        {time && time === 'timer' && <Timer timerLength={timerLength} startTime={startTime} />}
                        {!time && title}
                    </div>
                  </div>
                </div>
                <div className="widget-content-left fsize-1">
                  <div className="text-muted">{subtitle}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

function StopwatchContainer({}) {
  //  === 'Clean Up!' ? 
  //                useStopwatch('Time Til Cleanup', classStart, classEnd) : 
}
import React, {useEffect} from 'react'

export  function useStorage() {

    function useCreateStorageState(key, nullObj) {
        const obj = localStorage.getItem(key)
        // console.log(obj.anchor,'obj', typeof obj)
        // if (obj == null || typeof obj === 'string')  {
        if (obj == null || undefined)  {
            return nullObj
        } else {
            return JSON.parse(obj)
        } 
    }
    
    function useSaveLocalStorage(key, obj) {
        useEffect(() => {
            localStorage.setItem(key, JSON.stringify(obj))
        }, [obj])
    }

    return {
        useCreateStorageState, useSaveLocalStorage
    }
}

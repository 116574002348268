import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faX, faPlus, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

export default function AItem({entry, count, handleFunctions, length}) {

    const { updateChecklist, handleSave, handleAddDelete, handleChangeIndex } = handleFunctions

    const [form, setForm] = useState({...entry})

    function handleUpdateForm(e, param) {
        e.preventDefault()

        const editForm = {...form}
        editForm[param] = e.target.value
        setForm(editForm)
    }
    
    var bgColor = 'bg-primary'

    return (
    <div className="d-flex align-items-stretch mb-3">
        <div className={`card ${bgColor} text-white p-2 me-2 shadow-none`}>
            <div className="d-flex align-items-center justify-content-center" style={{height:"100%", width:"30px"}}>
                <h2 className="m-0">{count}</h2>
            </div>
        </div>
        <div className="card flex-grow-1 shadow-none bg-light">
            <div className="m-2 me-3 ms-3 p-2 d-flex flex-wrap">
                <span className={`flex-fill me-2 ms-2 mb-2`}>
                Item: 
                <input value={form.item} onChange={(e) => handleUpdateForm(e, 'item')} className={`form-control d-inline ${entry.item === form.item ? 'bg-white' : 'bg-secondary text-white'}`}
                    list="items" />  
                {/* <datalist id="items">
                    <option value={''}/>
                    {items.map(entry => {
                        return (
                            <option value={entry} key={entry} />
                        )
                    })}
                </datalist> */}
                </span>
                <span className="flex-fill me-2 ms-2 mb-2">
                    Price:
                    <input type="number" min={1} max={1000} className={`form-control d-inline w-100 ${entry.price === form.price ? 'bg-white' : 'bg-secondary text-white'}`} value={form.price} onChange={(e) => handleUpdateForm(e, 'price')} />
                </span>
                <span className="flex-fill me-2 ms-2 mb-2">
                Out of Stock:
                <select className={`form-control d-inline ${entry.outOfStock === form.outOfStock ? 'bg-white' : 'bg-secondary text-white'}`}
                    value={form.outOfStock} onChange={(e) => handleUpdateForm(e, 'outOfStock')}>
                    <option value={"No"}>No</option>
                    <option value={"Yes"}>Yes</option>
                </select>
                </span>
            </div>
            {/* </div> */}
        </div>
        <div className="card ms-2 shadow-none bg-light" style={{maxWidth:"100px"}}>
            <div className="card-body d-flex flex-shrink-1 align-content-center justify-content-center flex-wrap">
                <div className="d-flex justify-content-center flex-wrap">
                <FontAwesomeIcon className="m-2" onClick={e => handleSave(e, entry, form)} icon={faFloppyDisk} role="button" />
                <FontAwesomeIcon className="m-2" onClick={e => handleAddDelete(e, 'delete', entry)} icon={faX} role="button" />
                </div>
                <div className="d-flex justify-content-center flex-wrap">
                {count > 1 && length > 1 && <FontAwesomeIcon className="m-2" onClick={e => handleChangeIndex('raise', entry, count-1)} icon={faArrowUp} role="button" />}
                {count <= length - 1 && <FontAwesomeIcon className="m-2" onClick={e => handleChangeIndex('lower', entry, count-1)} icon={faArrowDown} role="button" />}
                </div>
            </div>
        </div>
    </div>
    )
}


import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';

import { SiteContext } from "../../../App";
import { StudentContext } from "../../../pages/StudentContainer";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

import SAssignments from './SAssignments'
 
export default function SAssignmentsModal({handleShow, handleClose, show, type, update}) {

    const {
      student, socket, room,
      studentDataFunctions: {createMongo, getMongoAllById, deleteMongo, getMongoList}
    } = useContext(SiteContext)

    const {updateExistingEvent} = useContext(StudentContext)

    function handleCloseLocal() {
      handleClose(type)
    //   setHpForm(blankForm)
    //   setStep(1)
    }

 // This following section will display the form that takes input from the user to update the data.
 return (
  <ErrorBoundary>    
    <div
      onKeyDown={e => e.stopPropagation()}
    >
    <Modal dialogClassName="my-modal5"
      show={show} 
      onHide={handleCloseLocal}
    >
      {/* <Modal.Header>
        <Modal.Title>Create Hall Pass</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <SAssignments sizeClass={'col-12'} dataOnly={true} />
      </Modal.Body>
        
      {/* <Modal.Footer>
        {message !== 'Create Hall Pass' ? <></> : step === 1 ? <NextStepButton hpForm={hpForm} step={step} setStep={setStep} /> : <SubmitButton submitForm={submitForm} hpForm={hpForm} />}
      </Modal.Footer> */}
    </Modal>
    </div>
  </ErrorBoundary>
  
);

}




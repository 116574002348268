import React, { useContext } from 'react'

import { SiteContext } from '../../../App';
import { AdminContext } from '../../../pages/AdminContainer';
import { ErrorBoundary } from '../../../other/utils/ErrorBoundary';

import Dropdown from 'react-bootstrap/Dropdown';
import StatusMenu from '../../gridMenu/elements/MenuItems';

import './gridContainer.css'
import '../../../behavior/dashboard/d_behavior.css'
import birthdayFunction from '../../../other/utils/birthday.js'

export default function WarningsEntry({student, alphaView}) {

    const { 
        colorMatch,
        studentList,
        socket,
        studentDataFunctions: {
            setStudentList, updateMongo
        }
     } = useContext(SiteContext)
     
    const {updateDailyListMongo, handleColorClick} = useContext(AdminContext)
    
    const {
        color: {
            current, warningsGiven,
        },
    } = student
      
    const sendMessage_updateWarnings = (studentID, currentWarnings, roomInput) => {

        const dataSend = {
          studentId: studentID,
          currentWarnings
        }
    
        socket.emit("send_message_updateWarnings", { dataSend, room: roomInput });
    };

    function handleAddWarning (e, action) {
        e.preventDefault()

        var currentWarningsGiven = warningsGiven

        if(action === 'removeWarning') {
            currentWarningsGiven--
        } else if(currentWarningsGiven < 3) {
            currentWarningsGiven++
        } else if(currentWarningsGiven >= 3) {
            handleColorClick(e, student, 'warning4')
            return
        }

        var studentListOriginal = [...studentList]
        var index = studentListOriginal.findIndex(entry => entry.studentId === student.studentId)
            studentListOriginal[index].color.warningsGiven = currentWarningsGiven

        setStudentList(studentListOriginal)
        socket.emit("send_studentListUpdate", { studentList: studentListOriginal, room: 'admin' });
        sendMessage_updateWarnings(student.studentId, currentWarningsGiven, student.period)
        updateMongo({color: studentListOriginal[index].color}, studentListOriginal[index].dailyListId, 'dailyList')
        // updateDailyListMongo(student.studentId, {color: studentListOriginal[index].color})
      }

    var variant = ''
    if(current) {
        var match = colorMatch.filter(item => item.color === current)
            variant = match[0].variant
    }

    var nameBg = alphaView === 0 ? 'lightGrey' : 'light'
    var colorBg = !student.loggedIn && variant !== 'flag' ? `stripes ${variant}` : `bg-${variant}`
        colorBg = !student.loggedIn && variant === 'flag' ? `stripes flagVariant` : colorBg
        colorBg = !student.loggedIn && variant === 'light' ? `stripes lightVariant` : colorBg

    var textColor = (variant === 'light') ? 'textChoice' : ''

    return (
    <ErrorBoundary>
        <div className={`btn btn-${nameBg} m-1 p-1`}>
        {/* <div className={'p-1 d-inline'}> */}
          <div className={`d-inline-block boxes boxLeft ${colorBg}`}  style={{position:"relative"}} onClick={(e) => handleAddWarning(e)}
            style={{color: (current === 'White' && student.loggedIn) ? '#343a40' : 'white'}} >
                          {warningsGiven > 0 && <Warnings num={1} handleAddWarning={handleAddWarning} />}
          {warningsGiven > 1 && <Warnings num={2} handleAddWarning={handleAddWarning} />}
          {warningsGiven > 2 && <Warnings num={3} handleAddWarning={handleAddWarning} />}
            <StudentBox student={student} textColor={textColor} />
          </div>
          <DropdownWarning student={student} />
        </div>  
    </ErrorBoundary>
    )
}

function StudentBox({student, textColor}) {
    const outcome = birthdayFunction(student.birthday)
    return (
        <div className={`d-inline-block p-1 m-1 ms-0 me-0`} style={{width:"85px", whiteSpace:"nowrap", textAlign:"center"}}>
            {outcome} 
            <span style={{fontWeight:"bold"}}>{student.name.split(' ')[0]}</span>
            <span>{` ${student.name.split(' ')[1].substring(0, 1)}`}</span>
      </div>
    )
}

function Warnings({num, handleAddWarning}) {
    return <div className={`warnings warnings${num} bg-flag`} onClick={e => handleAddWarning(e, 'removeWarning')}/>
}


function DropdownWarning({student}) {

    const { 
        initialLoad,
        studentDataFunctions: {
          setStudentList
        }
    } = useContext(SiteContext)

    var tardyColor = student.attendance === 'Tardy' ? 'flag' : 'secondary'
    return (
      <ErrorBoundary>
        <Dropdown style={{display:"inline"}}>
            <Dropdown.Toggle className={`p-0 warning-dropdown`} variant="white" id="dropdown-basic">
              <div className={`d-inline-block ms-0 boxes h-auto boxRight bg-${tardyColor}`}>
                <div className={`d-inline-block p-1 mt-1 mb-1 text-${tardyColor}`}>-</div>
              </div>
            </Dropdown.Toggle>
  
            <Dropdown.Menu className="dropdown-menu-xxl dropdown-menu"
            // style={{ width: "100%", maxWidth: "100%" }}
            >
                {initialLoad ? <StatusMenu student={student} /> : <span>Loading...</span>}
            </Dropdown.Menu>
      </Dropdown>
    </ErrorBoundary>
    )
}
import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from "react-router";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { SiteContext, baseUrl } from "../../App";

export default function ModalBox({title, norm, setNorm, presentMode, setPresentMode}) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  return (
    <>
      <Button 
        onClick={handleShow}
        style={{float:"right"}}
        variant="na"
        // style={{width:"47%", textAlign: "center", marginRight:"5px"}}
      >
        {title}
      </Button>

      <Modal 
        show={show} 
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="form-group">
         <label htmlFor="position">Norm:</label>
         <input
           type="text"
           className="form-control"
           id="position"
           value={norm}
           onChange={(e) => setNorm(e.target.value)}
         />
         <br/>
         <label htmlFor="position">Present Mode:</label> &nbsp;
          <input
            className="form-check-input"
            type="checkbox"
            name="positionOptions"
            id="period1"
            // value={presentMode}
            checked={presentMode}
            onChange={(e) => setPresentMode(!presentMode)}
          />
       </div>  
        </Modal.Body>
      </Modal>
    </>
  );
}
import React, { useContext, useState, useEffect, createContext } from 'react'

import { SiteContext, baseUrl } from '../../../App'
import { v4 as uuidv4 } from 'uuid'

import HallPassEntry from './HPEntry'

export default function HallPassBox( { hpEvents, displayEvent }) {
  
    const { 
      classInformation, 
      studentDataFunctions: {
      }
    } = useContext(SiteContext)

    // console.log(hpEvents, 'hpEvents')

    const notApprovedActive = hpEvents.filter(entry => !entry.approved && entry.active)
    const approvedActive = hpEvents.filter(entry => entry.approved && entry.active)
    const approvedNotActive = hpEvents.filter(entry => entry.approved && !entry.active)
    const events = [...approvedActive, ...notApprovedActive, ...approvedNotActive]

    return (
      <div className="" style={{overflowY: 'scroll'}}>
        <div className="table-responsive ps-3 pe-3" style={{minHeight: "20em"}}>
          <table className="align-middle text-truncate mb-0 table table-borderless table-hover">
            <thead>
              <tr>
                <th className="text-center">Name</th>
                <th className="text-center">Info</th>
                <th className="text-center" style={{width:"125px"}}>Action</th>
              </tr>
            </thead>
            <tbody>
                {events.map(entry => {
                    return <HallPassEntry entry={entry} key={uuidv4()}/>
                })}
            </tbody>
          </table>
        </div>
	  </div>	
    )
}

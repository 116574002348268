function schedules () {
    return {
        'normal7': [
            {
                period: 'Period 1',
                start: '8:40',
                end: '9:30'
            },
            {
                period: 'Period 2',
                start: '9:35',
                end: '10:25'
            },
            {
                period: 'Period 3',
                start: '10:30',
                end: '11:20'
            },
            {
                period: 'Lunch',
                start: '11:20',
                end: '11:55'
            },
            {
                period: 'Period 5',
                start: '12:00',
                end: '12:50'
            },
            {
                period: 'Period 6',
                start: '12:55',
                end: '1:45'
            },
            {
                period: 'Period 7',
                start: '1:50',
                end: '2:40'
            },
        ],
        'normal8': [
            {
                period: 'Period 1',
                start: '8:40',
                end: '9:30'
            },
            {
                period: 'Period 2',
                start: '9:35',
                end: '10:25'
            },
            {
                period: 'Period 3',
                start: '10:30',
                end: '11:20'
            },
            {
                period: 'Period 4',
                start: '11:25',
                end: '12:15'
            },
            {
                period: 'Lunch',
                start: '12:15',
                end: '12:50'
            },
            {
                period: 'Period 6',
                start: '12:55',
                end: '1:45'
            },
            {
                period: 'Period 7',
                start: '1:50',
                end: '2:40'
            },
        ],
        'minimum7': [
            {
                period: 'Period 1',
                start: '8:40',
                end: '9:20'
            },
            {
                period: 'Period 2',
                start: '9:25',
                end: '10:05'
            },
            {
                period: 'Period 3',
                start: '10:10',
                end: '10:50'
            },
            {
                period: 'Lunch',
                start: '10:50',
                end: '11:25'
            },
            {
                period: 'Period 5',
                start: '11:30',
                end: '12:10'
            },
            {
                period: 'Period 6',
                start: '12:15',
                end: '12:55'
            },
            {
                period: 'Period 7',
                start: '1:00',
                end: '1:40'
            },
        ],
        'minimum8': [
            {
                period: 'Period 1',
                start: '8:40',
                end: '9:20'
            },
            {
                period: 'Period 2',
                start: '9:25',
                end: '10:05'
            },
            {
                period: 'Period 3',
                start: '10:10',
                end: '10:50'
            },
            {
                period: 'Period 4',
                start: '10:55',
                end: '11:35'
            },
            {
                period: 'Lunch',
                start: '11:35',
                end: '12:10'
            },
            {
                period: 'Period 6',
                start: '12:15',
                end: '12:55'
            },
            {
                period: 'Period 7',
                start: '1:00',
                end: '1:40'
            },
        ],
        'noon7': [
            {
                period: 'Period 1',
                start: '8:40',
                end: '9:05'
            },
            {
                period: 'Period 2',
                start: '9:10',
                end: '9:35'
            },
            {
                period: 'Period 3',
                start: '9:40',
                end: '10:05'
            },
            {
                period: 'Lunch',
                start: '10:05',
                end: '10:35'
            },
            {
                period: 'Period 5',
                start: '10:40',
                end: '11:10'
            },
            {
                period: 'Period 6',
                start: '11:15',
                end: '11:40'
            },
            {
                period: 'Period 7',
                start: '11:45',
                end: '12:10'
            },
        ],
        'noon8': [
            {
                period: 'Period 1',
                start: '8:40',
                end: '9:05'
            },
            {
                period: 'Period 2',
                start: '9:10',
                end: '9:35'
            },
            {
                period: 'Period 3',
                start: '9:40',
                end: '10:05'
            },
            {
                period: 'Period 4',
                start: '10:10',
                end: '10:40'
            },
            {
                period: 'Lunch',
                start: '10:40',
                end: '11:10'
            },
            {
                period: 'Period 6',
                start: '11:15',
                end: '11:40'
            },
            {
                period: 'Period 7',
                start: '11:45',
                end: '12:10'
            },
        ]
    }
}

module.exports = schedules
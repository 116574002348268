import React, {useState} from 'react'

import EditModal from './EditModal'

export default function PQuizNorm({presentMode, setPresentMode}) {

    const [norm, setNorm] = useState('')

    return (
        <div className="col-12">
            <div className="main-card mb-3 card">
                <div className="card-body">
                    <h1 style={{margin:"0px", display:"inline"}}>
                        <span >PQuiz Norm: </span> &nbsp; &nbsp;
                        <span style={{fontWeight:'bold'}}>{norm}</span>
                    </h1>
                    <EditModal title={'Edit'} norm={norm} setNorm={setNorm} presentMode={presentMode} setPresentMode={setPresentMode} />
                </div>
            </div>
        </div>
    )
}

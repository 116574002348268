import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import '../assets/base.scss'
import '../assets/style.css'

import { SiteContext } from "../App";

export default function ChoosePeriod() {

    const {
        studentDataFunctions: {
            setClassInformation, checkCalendar
        },
        adminPages, classInformationArray
    } = useContext(SiteContext)

    const navigate = useNavigate();

    function handleLoadDashboard(e) {
        e.preventDefault();
        
        const pageSelected = e.target.selectPage.value
        var periodSelected = e.target.selectPeriod.value

        periodSelected =
          (pageSelected === 'dashboard' && periodSelected === '-- Select Period --') 
          || 
          (pageSelected !== 'dashboard' && periodSelected === '-- Select Period --')
        ? 
        'Period 1' : periodSelected

                var index = classInformationArray.findIndex(entry => entry.period === periodSelected)
        if(index !== -1) {
            var newClassInformation = classInformationArray[index]
                newClassInformation.dayType = checkCalendar()
                // console.log(newClassInformation,'newClassInformation')
            setClassInformation(newClassInformation)
        }    
        
        navigate(`/${pageSelected}`);  
    }

    return (

        <div className="app-container app-theme-white body-tabs-shadow">
            <div className="h-100 bg-plum-plate bg-animation">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <div className="mx-auto app-login-box col-md-8">
                    <div className="app-logo-inverse mx-auto mb-3" />
                        <div className="modal-dialog w-100 mx-auto">
                            <div className="modal-content">
                                <div className="modal-body">
                                        <div className="card-header">
                                            Choose Period:
                                        </div>
                                        <div className="card-body">
                                            <form 
                                              onSubmit={(e) => handleLoadDashboard(e)}
                                              style={{paddingTop:""}}
                                            >
                                                <div className="position-relative mb-3">
                                                <select
                                                    name="select"
                                                    id="selectPage"
                                                    className="form-select form-control"
                                                    defaultValue="dashboard"
                                                    style={{marginBottom:"10px"}}
                                                >
                                                    {adminPages.map(entry => {
                                                        if(!entry.loginPage) return
                                                        return <option key={entry.type} value={entry.type}>{entry.text}</option>
                                                    })}
                                                </select>
                                                <select
                                                    name="select"
                                                    id="selectPeriod"
                                                    className="form-select form-control"
                                                >
                                                    <option>-- Select Period --</option>
                                                    {classInformationArray.map(entry => {
                                                        return <option key={entry.period}>{entry.period}</option>
                                                    })}
                                                </select>
                                                </div>
                                                <button type="submit" style={{ width: "100%", border: "none" }} className={`mt-1 btn btn-primary`}>
                                                    Submit                            
                                                </button>
                                            </form>
                                        </div>
                                    </div>


                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

import React, { useContext } from 'react'
import { SiteContext } from '../App';
import { ErrorBoundary } from '../other/utils/ErrorBoundary';

import ReviewModal from './ReviewModal';

export default function ReviewCard({ title, subtitle, colorValue, sizeClass, entry, showReviewModal, handleReviewModal, handleReviewClose, currentEntry,  }) {
      
    const {studentDataFunctions: {checkToday} } = useContext(SiteContext)
    // console.log(entry,'entry')
    entry.variant = entry.attendance === 'Absent' ? 'light' : entry.variant
    var subtitle = entry.attendance === 'Absent' ? 'Absent - No Color' : subtitle
    var textColor = entry.attendance === 'Absent' ? 'text-danger' : 'text-white'

    // console.log(entry,'entry')
    var dateString = checkToday(entry.timeStamp) ? 'Today ' + entry.dateString.split(' ')[1] : entry.dateString
    var attendanceBg = entry.attendance === 'Present' ? 'success' : 
        entry.attendance === 'Tardy' ? 'flag' : 'danger'
    
    return (
    <ErrorBoundary>
      <div className={`${sizeClass}`} onClick={e => handleReviewModal(e,'open', entry)} role="button">
        <div className={`card-shadow-${colorValue} mb-3 widget-chart widget-chart2 text-start card bg-${entry.variant}`}>
          <div className="widget-content p-0 w-100">
              <span className={`position-absolute top-0 start-100 translate-middle p-2 bg-${attendanceBg} rounded-circle`} style={{border:"2px solid white"}}>
                <span className="visually-hidden">New alerts</span>
              </span>
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className={`widget-content-left fsize-1 ${textColor}`}>
                <div className="widget-numbers mt-0" style={{fontSize:"16px"}}>
                  {dateString}
                  </div>
                </div>
              </div>
              <div className="widget-content-left fsize-1">
                <div className={`${textColor}`} style={{fontSize:"12px"}}>{subtitle}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
    )
}
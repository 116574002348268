import React from 'react';
import GradeEntry from './GradeEntry';

function SearchList({ filteredPersons, exportFunctions }) {
  
  const {month, day, onlyAbsent, mode} = exportFunctions

  const filtered = filteredPersons.map(person =>  <GradeEntry key={person._id} person={person} exportFunctions={exportFunctions} />); 
  return (
    <div>
      <table id="table2" className="align-middle text-truncate mb-0 table table-borderless table-hover" style={{width:"40%"}}>
        <thead>
          <tr>
            <th className="pt-0">Name</th>
            {onlyAbsent && <th className="">Student ID</th>}
            <th className="pt-0">{mode.type === 'Completion' ? mode.type : `${month}/${day} ${mode.type}`}</th>
          </tr>
        </thead>
        <tbody>
          {filtered}
        </tbody>
      </table>
    </div>
  );
}

export default SearchList;
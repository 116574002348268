import React from 'react'

export default function ScheduleEntry({entry}) {
    return (
        <>
        <span className="bold" key={entry.period}>{entry.period}</span>
        <span className="" key={entry.start}>{entry.start}</span>
        <span className="" key={entry.end}>{entry.end}</span>
        </>
    )
}

import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../../App'
import { AdminContext } from '../../pages/AdminContainer'
import { ErrorBoundary } from '../../other/utils/ErrorBoundary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';


// import './d_behavior.css'

export default function OrderEntry({item, name, studentId, type}) {

    const { 
        colorMatch, periodBg,
        studentList,
        studentDataFunctions: {
            createMongo, getMongoList, getMongoById, updateMongo, deleteMongo
        }
    } = useContext(SiteContext)

    const {handleAddChipsManual, handleUpdateOrders, orders} = useContext(AdminContext)
    // const {} = useContext(DStoreContext)

    function handleUpdateChipToday(e, item) {
        e.preventDefault()
        console.log(item,'item handleUpdateChipToday-----')
        const total = parseInt(item.price) * parseInt(item.quantity)

        getMongoById(item.studentId, 'dailyList').then(record => {
            const chipsToday = {...record.chipsToday}
            chipsToday.subtracted = chipsToday.subtracted + total
    
            updateMongo({chipsToday:chipsToday}, record._id, 'dailyList')
        }) 
    }

    var index = studentList.findIndex(entry => entry.studentId === studentId)
        if(index === -1) { return }
        var balance = studentList[index].chips.balance

    var indexBg = periodBg.findIndex(entry => entry.period === item.period)
    if(indexBg === -1) return
        var bg = periodBg[indexBg].bg

    // console.log(item.timeStamp,item,'teim')

    function prettyDate(dateInput) {
        var date = new Date(dateInput)
        // console.log(date, 'inside---')
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var hours = ((date.getHours() + 11) % 12 + 1);
        var minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        
        return `${hours}:${minutes}`
    
    }

    var timeStamp = new Date(item.timeStamp)
        var dayEntry = timeStamp.getDate().toString()
        var monthEntry = (timeStamp.getMonth() + 1).toString()
        var weekDayEntry = timeStamp.getDay().toString()
            const weekDays = ['Sunday','Mon','Tue','Wed','Thu','Fri', 'Sat']
            var weekDay = weekDays[weekDayEntry]

    var today = new Date()
    weekDay = (today.getMonth() === timeStamp.getMonth() && today.getDate() === timeStamp.getDate()) ? 
    `Today` : weekDay

    const priceCalc = item.price * item.quantity

    if(type === 'cups') return (
        <ErrorBoundary>
                <tr>
                    <td className="text-center">
                        <span className="text">{name}</span>
                    </td>
                    {/* <td className="text-center">
                        <div className={`mb-2 me-2 badge bg-${bg}`}>P{item.period.split(' ')[1]}</div>
                    </td> */}
                    <td className="text-center">
                        {weekDay === 'Today' ? 
                        <div className={`badge bg-primary`}>{weekDay}</div> :
                        <span className="text text-wrap">{`${weekDay} ${monthEntry}/${dayEntry}`}</span>
                        }
                        <span className="text text-wrap d-block">{`${prettyDate(timeStamp)}`}</span>
                    </td>
                    <td className="text-center">
                        {item.quantity > 0 && <div className={`mb-2 me-2 badge bg-primary`}>{item.quantity}</div>}
                        {balance >= priceCalc && <div className={`mb-2 me-2 badge bg-light`}>{balance}€</div>}
                        {balance < priceCalc && <div className={`mb-2 me-2 badge bg-secondary`}>{balance}€</div>}
                    </td>
                    <td className="text-center" style={{width:"90px"}}>
                        <div role="group" className="btn-group-sm btn-group"
                        // style={{minWidth:"70px", maxWidth:"70px"}}
                        >
                            <button 
                            value="approved"
                            onClick={(e) => {
                                handleAddChipsManual(e, studentId, 0, priceCalc*-1, 'balance')
                                handleUpdateOrders(e, item, orders)
                            }} 
                            className={`btn-shadow btn btn-primary`}>
                            <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <button 
                            value="rejected"
                            // style={{backgroundColor:'red'}}
                            onClick={(e) => {
                                handleUpdateChipToday(e, item)
                                handleUpdateOrders(e, item, orders)
                            }}
                            className={`btn-shadow btn btn-light text-primary`}>
                            <FontAwesomeIcon icon={faXmark} />
                            </button>
                        </div>
                    </td>
                </tr>
                </ErrorBoundary>
    )

            return (
                <ErrorBoundary>
                <tr>
                    <td className="text-center">
                        <span className="text">{name}</span>
                    </td>
                    <td className="text-center">
                        {weekDay === 'Today' ? 
                        <div className={`badge bg-info`}>{weekDay}</div> :
                        <span className="text text-wrap">{`${weekDay} ${monthEntry}/${dayEntry}`}</span>
                        }
                        <span className="text text-wrap d-block">{`${prettyDate(timeStamp)}`}</span>
                    </td>
                    <td className="text-center">
                        <span className="text">{item.item.split(' (') ? item.item.split(' (')[0] : item.item}</span>
                    </td>
                    <td className="text-center">
                        {item.quantity > 0 && <div className={`mb-2 me-2 badge bg-light`}>{item.price}€</div>}
                        {item.quantity > 0 && <div className={`mb-2 me-2 badge bg-info`}>x{item.quantity}</div>} 
                        {item.quantity > 1 &&  <div className={`mb-2 me-2 badge bg-secondary`}>={priceCalc}€</div>}
                    </td>
                    <td className="text-center">
                        {/* <span className="text">{balance}€</span> */}
                        {balance >= priceCalc && <div className={`mb-2 me-2 badge bg-light`}>{balance}€</div>}
                        {balance < priceCalc && <div className={`mb-2 me-2 badge bg-secondary`}>{balance}€</div>}
                    </td>
                    <td className="text-center" style={{width:"90px"}}>
                        <div role="group" className="btn-group-sm btn-group"
                        // style={{minWidth:"70px", maxWidth:"70px"}}
                        >
                            <button 
                            value="approved"
                            onClick={(e) => {
                                handleUpdateOrders(e, item, orders)
                                handleAddChipsManual(e, studentId, 0, priceCalc*-1, 'balance')
                            }} 
                            className={`btn-shadow btn btn-info`}>
                            <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <button 
                            value="rejected"
                            // style={{backgroundColor:'red'}}
                            onClick={(e) => {
                                handleUpdateOrders(e, item, orders)
                                handleUpdateChipToday(e, item)
                            }}
                            className={`btn-shadow btn btn-light text-info`}>
                            <FontAwesomeIcon icon={faXmark} />
                            </button>
                        </div>
                    </td>
                </tr>
                </ErrorBoundary>
            )

}



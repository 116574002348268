import React, { useContext, useEffect, useState } from 'react'
import { SiteContext } from '../../App'

import useStatus from '../../hallPass/status'

export function useHallPass() {

    const {classInformationArray, studentDataFunctions:{stringtoJSDate}} = useContext(SiteContext)

    const messageOut = useStatus({key:'message', prop:'Out'})
    const messageAvailable = useStatus({key:'message', prop:'Available'})

    const blankEvents = []
    classInformationArray.map(entry => {
        blankEvents.push({period: entry.period, events: []})
    })

    const [updateHPEvents, setUpdateHPEvents] = useState(true)
    const [hpEvents, setHpEvents] = useState(blankEvents)
    const [hpMessage, setHpMessage] = useState(messageAvailable)

    const {
        classInformation,
        studentDataFunctions: {
            createMongo, getMongoList, getMongoById, updateMongo, deleteMongo
        }
    } = useContext(SiteContext)

    useEffect(() => {

        getHallPassEvents()

        let interval;
        interval = setInterval(() => {

            var {date, milli: milliEnd} = stringtoJSDate(classInformation.classEnd)
            var {dateStart, milli: milliStart} = stringtoJSDate(classInformation.classStart)
  
            if(milliStart > Date.now() || milliEnd < Date.now() || classInformation.dayType === 'No School') return
            console.log('update hall pass events')
            getHallPassEvents()
            
        }, 30000);

        return () => clearInterval(interval);

    }, [classInformationArray.length, classInformation.classEnd, classInformation.classStart, classInformation.dayType])
    
    async function getHallPassEvents() {
        if(!updateHPEvents || !classInformationArray || classInformationArray.length === 0) return
        const events = await getMongoList('hallPassEvents')
        if(!events) return
        const activeEvents = blankEvents

        events.map(entry => {

            var index = activeEvents.findIndex(item => item.period === entry.period)
            var indexExist = activeEvents[index].events.findIndex(item => item.timeStamp === entry.timeStamp)

            var today = new Date().setHours(0, 0, 0, 0);
            var tsDay = new Date(entry.timeStamp).setHours(0, 0, 0, 0);

            if(indexExist === -1 && today === tsDay) {
                activeEvents[index].events = [...activeEvents[index].events, entry]
            } else if(today === tsDay){
                activeEvents[index].events[indexExist] = entry
            }
        })
        setHpEvents(activeEvents)
        // setUpdateHPEvents(false)
    }
    
    function updateExistingEvent(event, type, changeValue) {
        
        const editHpEvents = [...hpEvents]
        console.log(event,'event function')
        var indexPeriod = editHpEvents.findIndex(entry => entry.period === classInformation.period)

        const periodEvents = editHpEvents[indexPeriod]
        var index = periodEvents.events.findIndex(entry => entry.timeStamp === event.timeStamp)

        if(index === -1 && type !== 'remove') {
            editHpEvents[indexPeriod].events.push(event)
            console.log(editHpEvents,'editHpEvents push')
            setHpEvents(editHpEvents)
            updateMessage(editHpEvents)
            return
        }
    
        if(type === 'remove') {
            const filter = editHpEvents[indexPeriod].events.filter(entry => entry._id !== event._id)
                editHpEvents[indexPeriod].events = filter
            console.log(editHpEvents[indexPeriod],'filter remove')
            setHpEvents(editHpEvents)
            updateMessage(editHpEvents)
            return
        }
    
        editHpEvents[indexPeriod].events[index] = {...editHpEvents[indexPeriod].events[index], ...changeValue}
        console.log(editHpEvents,'editHpEvents change')
        setHpEvents(editHpEvents)
        updateMessage(editHpEvents)

        return editHpEvents == hpEvents ? false : true
    }

    function updateMessage(importEvents) {
    
        const index = importEvents.findIndex(entry => entry.period === classInformation.period)
        const eventsPeriod = importEvents[index].events
        
        var passOut = false
        if(eventsPeriod) {
            for(let i = 0; i < eventsPeriod.length; i++) {
                if(eventsPeriod[i].active && eventsPeriod[i].approved) {
                    passOut = true
                    break
                } 
            }
        }
        if(passOut){
            setHpMessage(messageOut)
        } else {
            setHpMessage(messageAvailable)
        }
        
    }

    function calcHallPassMath(hallPasses, pastRange) {
        var under = 0
        var over = 0
        var total = 0
        var totalComplete = 0

        if(!hallPasses || hallPasses.length === 0) return {under, over, total, totalComplete, totalDifference: 0, totalString: '', averagePass: ''}

        hallPasses.map(item => {
            if(!item.timeComplete || !item.timeComplete.split(':')[0] || !item.timeComplete.split(':')[1]) return
            
            if(item.timeStamp < pastRange || !item.timeComplete ) return {under, over, total, totalComplete, totalDifference: 0, totalString: '', averagePass: ''}
            var completeCalc = parseInt(item.timeComplete.split(':')[0]) + item.timeComplete.split(':')[1]/60
            item.time < completeCalc ? over++ : under++

            total = parseInt(total) + parseInt(item.time) 

            // console.log(item.timeComplete.split(':')[1],'item.timeComplete.split()[1]', parseFloat(item.timeComplete.split(':')[1])/60, item.time, total)
            totalComplete += parseInt(item.timeComplete.split(':')[0]) + parseFloat(item.timeComplete.split(':')[1])/60
            // console.log(totalComplete)
            // console.log(item.time, item.name, item.timeComplete, item.timeComplete.split(':')[0], item.timeComplete.split(':')[1], totalComplete)
            // console.log(item.timeComplete,'item.timeComplete',item)
        }) 
        var totalDifference = totalComplete - total
        // console.log(totalDifference,'totalDifference')
        const totalString = convertTimeString(totalDifference, under + over)
        const averagePass = convertTimeString(totalComplete, under + over)
        totalComplete = totalComplete.toFixed(1)

        return { over, under, total, totalComplete, totalDifference, totalString, averagePass, totalCount: over + under }
    }

    function convertTimeString(total, numHallPasses) {
        
        var total = Math.round((total)/numHallPasses * 10) / 10
        var totalAbs = Math.abs(total)

        var decimal = (totalAbs + "").split(".")[1] ? (totalAbs + "").split(".")[1] : '00'
        var seconds = decimal && decimal/10*60 < 10 ? `0${decimal/10*60}` : decimal/10*60 > 10 ? decimal/10*60 : '00'
        return Math.floor(totalAbs) ? `${Math.floor(totalAbs)}:${seconds}` : `0:${seconds}`
    }

    function updateHallPassEvent(e, time, event) {
        e.preventDefault()

        const id = event._id
        var secondsOut = parseInt(time.seconds)
        console.log(secondsOut,'secondsOut')
        if(secondsOut < 10) {secondsOut = '0' + secondsOut}
        const updatedObj = {...event, timeComplete: `${time.minutes}:${secondsOut}`}
            delete updatedObj["_id"];
        updateMongo(updatedObj, id, 'hallPassEvents')
    }

    function deleteHallPassEvent(e, event) {
        e.preventDefault()

        const id = event._id
        deleteMongo(id, 'hallPassEvents')
    }

    // }, [updateHPEvents])    

    return {
        updateHPEvents, setUpdateHPEvents,
        hpEvents, setHpEvents,
        hpMessage, setHpMessage,
        updateExistingEvent,
        updateHallPassEvent, deleteHallPassEvent, 
        convertTimeString, calcHallPassMath,
        getHallPassEvents
    }

}




import React, { useContext, useState, useEffect, createContext } from 'react'

import { SiteContext, baseUrl } from '../App'
import SeatModal from '../admin/seatingChart/SeatModal'
import BalanceCard from '../store/admin/balance/BalanceCard'
import SeatingChart from '../admin/seatingChart/SeatingChart'
import SeatGrid from '../student/preview/SeatGrid'
import { ErrorBoundary } from '../other/utils/ErrorBoundary'

export default function Auction( {sizeClass }) {

    const { 
      student, classInformation, colorMatch, modules, socket, focusAgreements, 
      studentDataFunctions: {
        calculateTimeElapsed, updateMongo, setFocusAgreements, 
      }
    } = useContext(SiteContext)

    const [currentModule, setCurrentModule] = useState('')
    const [slot, setSlot] = useState('Slot 1')
    const [bid, setBid] = useState(0)
    const [show, setShow] = useState(false);

    function handleChoose(e, type, newValue) {
        e.preventDefault()

        var value = newValue ? newValue : e.target.value

        if(type === 'module') {
            setSlot('Slot 1')
            setCurrentModule(value)
            setBid(0)
            updateMongo({auctionModule: value}, focusAgreements._id, 'focusAgreements')

            const editFocusAgreements = {...focusAgreements, auctionModule: value}
            setFocusAgreements(editFocusAgreements)
            socket.emit("send_focusAgreementsUpdate", { focusAgreements: editFocusAgreements, room: classInformation.period });
        } else {
            setSlot(value)
            setBid(0)
        }
        
    }

    function handleBid(e, change, reset) {
        e.preventDefault()

        if(reset) {setBid(0)}
        setBid(prev => { return prev + change })
    }

    function handleShowSeating(e) {
        e.preventDefault()
        setShow(prev => {return !prev})
    }

    function BidButton({entry}) {
        var textColor = entry.variant === 'purple' ? 'white' : ''
        return (
            <button 
              onClick={(e) => handleBid(e, entry.change)} 
              className={`btn-shadow btn btn-${entry.variant} text-${textColor}`}
              style={{margin:"3px"}}
            >
            {entry.change}
            </button>
        )
    }

    function handleClick(e, title) {
        e.preventDefault()
        console.log(title,'title')

        handleChoose(e, 'module', title)
        // setCurrentModule(title)
        // updateModule(e, title)
    }

    if(!focusAgreements || !focusAgreements.modules) return

    var index = modules.findIndex(entry => entry.title === currentModule)

    return (
        <ErrorBoundary>
        <div className="d-flex">
            <div className={`m-2`}>
              <div className={`card`} style={{minWidth:"350px"}}>
                <div className={`card-header text-primary h-auto`} style={{maxHeight:"150px"}}>
                    <div className="mt-0" style={{fontSize:"120px"}}>
                        ${bid}
                    </div>
                </div>
                <div className="card-body widget-content-left fsize-1">
                    Current Bid 
                </div>
              </div>
              <ErrorBoundary>
              <div className="card p-2 mt-3">
                <div className="d-flex justify-content-start">
                    <div>🚪 Back Door</div>
                </div>
                <div className="d-flex justify-content-start">
                    <div>🖥 Promethean Board</div>
                </div>
                <SeatGrid focusAgreements={focusAgreements} isResizable={false} isDraggable={false} handleClick={handleClick} layout={focusAgreements && focusAgreements.layout} rowHeight={1} moduleCurrent={currentModule} />
                <div className="d-flex justify-content-end">
                    <div>🚪 Front Door</div>
                </div>
            </div>
            </ErrorBoundary>
            </div>
            <div className={`${`m-2 flex-fill`}`}>
                <div className={`card-shadow-${'primary'} widget-chart widget-chart2 text-start card`}>
                    <div className="widget-content p-0 w-100">
                        <div className="widget-content-outer">
                        <div className="widget-content-wrapper">
                            <div className={`widget-content-left pe-2 fsize-1 text-${'primary'}`}>
                                <div className="widget-numbers mt-0 d-flex justify-content-between" style={{fontSize:"30px"}}>
                                  {index > -1 && modules[index].emoji} {currentModule.length === 0 ? 'Select Module' : `${currentModule} - ${slot}`}
                                </div>
                                
                            </div>
                        </div>
                        <div className="widget-content-left fsize-1">
                        Current Module
                        </div>
                        </div>
                    </div>
            </div>
            <div className={`flex-fill mt-3`}>
              <div className={`card-shadow-primary widget-chart widget-chart2 text-start card`}>
                <div className="widget-content p-0 w-100">
                    <div className="widget-content-outer">
                        <div className="widget-content-wrapper">
                        <div className={`widget-content-left pe-2 fsize-3 text-${'primary'}`}>
                            {bids.map(entry => {
                                // console.log(entry,'entey', entry.change)
                                return <BidButton key={entry.change} entry={entry} />
                            })}
                            <button 
                            onClick={(e) => handleBid(e, 0, true)} 
                            style={{marginLeft:"20px"}}
                            className={`btn-shadow btn btn-secondary text-white`}>
                            Reset
                            </button>
                            <button 
                            onClick={(e) => handleShowSeating(e)} 
                            style={{marginLeft:"20px"}}
                            className={`btn-shadow btn btn-secondary text-white`}>
                            Show Seating
                            </button>
                        </div>
                    </div>
                    <div style={{paddingTop:"20px"}}>
                        <select
                            className="form-control" value={currentModule}
                            style={{display:"inline", width:"45%", margin: "5px", display:"inline"}}
                            onChange={(e) => handleChoose(e, 'module')}
                        >
                            <option value={``}>-- Select Module --</option>
                            {modules.map(entry => {
                                return (
                                    <option key={entry.fullTitle} value={entry.title}>{entry.fullTitle} </option>
                                )
                            })}
                        </select>
                        <select
                            className="form-control"
                            style={{display:"inline", width:"45%", margin: "5px", display:"inline"}}
                            onChange={(e) => handleChoose(e)}
                            value={slot}
                        >
                            <option value={`Slot 1`}>Slot 1</option>
                            <option value={`Slot 2`}>Slot 2</option>
                            <option value={`Slot 3`}>Slot 3</option>
                        </select>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            </div>
        </div>
        <div className="row">
            {show &&
            <> 
            <BalanceCard title={'Balance'} colorValue={'primary'} sizeClass={'col-sm-12'} orders={[]} handleUpdateOrders={() => {}} />
            <div className="main-card mb-3 card">
              <div className="card-body" style={{paddingTop:"10px"}}>
                <SeatingChart modeVal={'editSeating'} setNewAttendance={() => {}} />
              </div>
            </div>
            </>}
        </div>
        </ErrorBoundary>
    )
}

const bids = [
    {change: -10, variant: 'secondary'}, 
    {change: -5, variant: 'light'},
    {change: -1, variant: 'light'},
    {change: 1, variant: 'info'},
    {change: 5, variant: 'primary'}, 
    {change: 10, variant: 'purple'}
]
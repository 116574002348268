import React, { useContext, useState, useEffect } from 'react'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { SiteContext } from '../../App'
import { AdminContext } from '../../pages/AdminContainer'
import { ErrorBoundary } from '../../other/utils/ErrorBoundary'

import Dropdown from 'react-bootstrap/Dropdown';
import StatusMenu from '../gridMenu/elements/MenuItems';
import SeatGrid from './SeatGrid.js'
import Assignments from '../../modals/assignments/admin/AAssignments'

import useKeyPress from '../../other/utils/useKeyPress'
import '../../assets/base.scss'

import WarningsEntry from './utils/StudentCardEntry'

const modes = [
    {mode: 'Colors', rowHeight: 2},
    {mode: 'Attendance', rowHeight: 6},
    {mode: 'Modal Completion', rowHeight: 6},
    {mode: 'Assignments', rowHeight: 9},
    {mode: 'Reading Zone', rowHeight: 6},
    {mode: 'Chips', rowHeight: 7},
    {mode: 'Module Progress', rowHeight: 10},
    {mode: 'Edit', rowHeight: 8, edit: true},
]

const editModes = [
    {mode: 'Module'},
    {mode: 'Seat'},
    {mode: 'Group'},
    {mode: 'Pending'},
]

const chipsModes = [
    {mode: 'Chips'},
    {mode: 'Auction'},
]

export default function Warnings({sizeClass}) {

    const { 
        studentList, initialLoad, socket, room, classInformationArray,
        classInformation, focusAgreements, widthWindow,
        studentDataFunctions: {
            setStudentList, updateMongo, getMongoList, moduleFind, createMongo,
        }
    } = useContext(SiteContext)

    const {handleAttendance, handleShow, handleAttendanceClick, handleColorClick, updateDailyListMongo, mode, checkoutItems, modalModes, handleModifyModuleGroup} = useContext(AdminContext)

    const [alphaView, setAlphaView] = useState(1)
    const [showAbsent, setShowAbsent] = useState(false)
    const [showGrid, setShowGrid] = useState(widthWindow < 800 ? false : true)
    const [allowEdit, setAllowEdit] = useState(false)
    const [showSeats, setShowSeats] = useState(true)
    const [gridMode, setGridMode] = useState(modes[0])
    const [editMode, setEditMode] = useState(editModes[0])
    const [chipMode, setChipMode] = useState(chipsModes[0])
    const [activeStudent, setActiveStudent] = useState({})
    const [localFocus, setLocalFocus] = useState(focusAgreements)
    const [layouts, setLayouts] = useState([])
    const [createLayout, setCreateLayout] = useState(false)

    // useEffect(() => {
    //     setLocalFocus({...focusAgreements})
    // }, [focusAgreements])

    useEffect(() => {
        setShowGrid(widthWindow < 800 ? false : true)
    }, [widthWindow])

    // useEffect(() => {
    //     if(mode === 'dayReview') {
    //         changeMode(false, 'Modal Completion')
    //     }
    // }, [mode])

    const onKeyPress = (event) => {
        // console.log(`key pressed: ${event.key}`);
        if(event.shiftKey === true && event.ctrlKey === true) {
            if(event.key === 'A') {changeMode(false, 'Colors')}
            if(event.key === 'S') {changeMode(false, 'Attendance')}
            if(event.key === 'D') {changeMode(false, 'Modal Completion')}
            if(event.key === 'F') {changeMode(false, 'Module Progress')}
            if(event.key === 'E') {changeMode(false, 'Edit')}
        }
    };

    
    useKeyPress(['A', 'S', 'D', 'F', 'E'], onKeyPress);

    var alphaButtonString = alphaView === 1 ? 'Sort By Color' : 'Sort By Alphabetically'
    var alphaString = alphaView === 1 ? 'Alphabetical' : 'By Color'
    var alphaBg = alphaView === 1 ? 'light' : 'lightGrey'

    if(!focusAgreements || !focusAgreements.modules || !focusAgreements.layout) return
    const modules = focusAgreements.modules
    
    // console.log(modules,'modules')

    const alphaStudentList = studentList.sort((a, b) => a.name.localeCompare(b.name))

    const black = alphaStudentList.filter(student => student.color.current === 'Black')
    const gold = alphaStudentList.filter(student => student.color.current === 'Gold')
    const purple = alphaStudentList.filter(student => student.color.current === 'Purple')
    const blue = alphaStudentList.filter(student => student.color.current === 'Blue')
    const green = alphaStudentList.filter(student => student.color.current === 'Green')
    const yellow = alphaStudentList.filter(student => student.color.current === 'Yellow')
    const orange = alphaStudentList.filter(student => student.color.current === 'Orange')
    const red = alphaStudentList.filter(student => student.color.current === 'Red')
    const white = alphaStudentList.filter(student => student.color.current === 'White')

    const combined = [...black, ...gold, ...purple, ...blue, ...green, ...yellow, ...orange, ...red, ...white]

    const viewOptions = [combined, alphaStudentList]

    function changeMode(e, mode) {
        if(e) {e.preventDefault()}

        var index = modes.findIndex(entry => entry.mode === mode)
        if(index === -1) return
        setGridMode(modes[index])
    }

    function useSingleAndDoubleClick(actionSimpleClick, actionDoubleClick, delay = 250) {
        const [click, setClick] = useState(0);
    
        useEffect(() => {
            const timer = setTimeout(() => {
                // simple click
                if (click === 1) actionSimpleClick();
                setClick(0);
            }, delay);
    
            // the duration between this click and the previous one
            // is less than the value of delay = double-click
            if (click === 2) actionDoubleClick();
    
            return () => clearTimeout(timer);
            
        }, [click]);
    
        return () => setClick(prev => prev + 1);
    }

    function handleClick(student, mode, e) {
        if(e) e.preventDefault()

        // if(editMode === 'Group') return
        if(gridMode.mode === 'Colors' || gridMode.mode === 'Attendance') {
          handleAddWarning(undefined, student)
        }
        
        if(gridMode.mode !== 'Edit') return

        if(editMode.mode === 'Group') {

        } else {
            // console.log(mode,'mode---')
            setActiveStudent(student)
        }
    }
    
    function handleGroupClick(e, title, changeGroupNumber) {
        e.preventDefault()

        if(!activeStudent.studentId || gridMode.mode !== 'Edit') return

        const editStudentList = [...studentList]
        var index = editStudentList.findIndex(entry => entry.studentId === activeStudent.studentId)
        // editMode.mode === 'Seat' ? editStudentList[index].seat = title :
        //                         editStudentList[index].module = title
        
        // if(editMode.mode === 'Seat' && editStudentList[index].seat === editStudentList[index].module) {
        //     editStudentList[index].seat = false
        // }

        console.log(title,'title')

        var obj = {}
         if(editMode.mode === 'Seat') {
             obj = {seat: title}
             editStudentList[index].seat === editStudentList[index].module ? editStudentList[index].seat = false : editStudentList[index].seat = title
         } 
         if(editMode.mode === 'Module') {
             obj = {module: title, moduleFull: moduleFind(title, 'title', 'fullTitle'), seat: false, groupNumber: 1}
             editStudentList[index].module = title
             editStudentList[index].groupNumber = 1
             editStudentList[index].dayReview = {...editStudentList[index].dayReview, module: {progress: false}}
            //  console.log(editStudentList[index].moduleHistory,'editStudentList[index].moduleHistory', typeof editStudentList[index].moduleHistory)
            console.log(editStudentList[index].moduleHistory,'editStudentList[index].moduleHistory', typeof editStudentList[index].moduleHistory)
            editStudentList[index].moduleHistory = editStudentList[index].moduleHistory && typeof editStudentList[index].moduleHistory === 'object' ? [...editStudentList[index].moduleHistory, {module: title, date: Date.now(), groupNumber: 1, period: activeStudent.period}] : [{module: title, date: Date.now(), groupNumber: 1, period: activeStudent.period}]
            console.log(editStudentList[index].moduleHistory,'editStudentList[index].moduleHistory', typeof editStudentList[index].moduleHistory)
            obj.moduleHistory = editStudentList[index].moduleHistory
         } 
         if(editMode.mode === 'Pending') {
            obj = {pending: title}
            editStudentList[index].pending = title
         }             

        setStudentList(editStudentList)
        setActiveStudent({})

        console.log(obj,'obj---', activeStudent)
        updateMongo({...obj}, activeStudent._id, 'records')
        updateMongo({...obj}, activeStudent.dailyListId, 'dailyList')
        // socket.emit("send_studentListUpdate", { studentList: editStudentList, room: 'admin' });
        socket.emit("send_updateSingleStudent", { student: {...editStudentList[index], ...obj}, room: 'admin' });
    }

    function resetStudents() {
        const editStudentList = [...studentList]
        editStudentList.map(entry => {
          if(entry.period !== classInformation.period) return
        
          var obj = {module: '', moduleFull: '', seat: false}
          entry = {...entry, ...obj}
            
          updateMongo({...obj}, entry._id, 'records')
          updateMongo({...obj}, entry.dailyListId, 'dailyList')
        })
        setStudentList(editStudentList)
        socket.emit("send_studentListUpdate", { studentList: editStudentList, room: 'admin' });
        window.location.reload();
    }

    // async function resetPendingStudents() {

    //     studentList.map(entry => {
    //         if(entry.module === 'No Group') return
    //         entry.moduleHistory = [{module: entry.module, date: Date.now(), groupNumber: entry.groupNumber, period: entry.period}]
    //         console.log(entry,'entry')
    //         updateMongo({moduleHistory: entry.moduleHistory}, entry._id, 'records')
    //         updateMongo({moduleHistory: entry.moduleHistory}, entry.dailyListId, 'dailyList')
    //     })
    //     //   await updateMongo({...obj}, entry._id, 'records')
    //     //   await updateMongo({...obj}, entry.dailyListId, 'dailyList')
    // }

    async function resetPendingStudents() {
        const editStudentList = [...studentList]
        const promises = editStudentList.map(async (entry) => {
          if(entry.period !== classInformation.period) return
        
          var obj = entry.pending ? 
                    {
                        module: entry.pending, 
                        moduleFull: moduleFind(entry.pending, 'title', 'fullTitle'), 
                        seat: false, 
                        pending: false, 
                        dayReview: {...entry.dayReview, module: {progress: false}},
                        moduleHistory: (entry.moduleHistory && entry.moduleHistory.length > 0) ? entry.moduleHistory.push({module: entry.pending, date: Date.now(), groupNumber: 1, period: entry.period}) : [{module: entry.pending, date: Date.now(), groupNumber: 1, period: entry.period}],
                        groupNumber: 1,
                        auctionBalance: entry.auctionBalance && entry.pendingAuction ? parseInt(entry.auctionBalance) - parseInt(entry.pendingAuction) : entry.pendingAuction ? 200 - parseInt(entry.pendingAuction) : 200,
                        pendingAuction: false
                    } : 
                    {   module: '', 
                        moduleFull: '', 
                        seat: false, 
                        pending: false,
                        groupNumber: 1,
                        pendingAuction: false
                    }
            
          await updateMongo({...obj}, entry._id, 'records')
          await updateMongo({...obj}, entry.dailyListId, 'dailyList')
          socket.emit("send_studentListUpdate", { studentList: editStudentList, room: 'admin' });
          socket.emit("send_updateSingleStudent", { student: {...entry, ...obj}, room: entry.period });
          entry = {...entry, ...obj}
        })
        await Promise.all(promises)
        setStudentList(editStudentList)
        socket.emit("send_studentListUpdate", { studentList: editStudentList, room: 'admin' });
        window.location.reload();
    }

    function handleAllowEdit() {
        setAllowEdit(prev => {return !prev})
    }

    function handleSimplifySeats() {
        setShowSeats(prev => {return !prev})
    }

    function handleAddWarning(e, student, action) {
        if(e) e.preventDefault()

        if(gridMode.mode !== 'Colors' && gridMode.mode !== 'Attendance') return

        var currentWarningsGiven = student.color.warningsGiven

        if(action === 'removeWarning' && currentWarningsGiven > 0) {
            currentWarningsGiven-- 
        } else if(currentWarningsGiven < 3) {
            currentWarningsGiven++
        } else if(currentWarningsGiven >= 3) {
            handleColorClick(undefined, student, 'warning4')
            return
        }
        
        var studentListOriginal = [...studentList]
        var index = studentListOriginal.findIndex(entry => entry.studentId === student.studentId)
            studentListOriginal[index].color.warningsGiven = currentWarningsGiven
        var obj = studentListOriginal[index]
            obj.color.warningsGiven = currentWarningsGiven
        setStudentList(studentListOriginal)
        socket.emit("send_updateSingleStudent", { student: {...studentListOriginal[index]}, room: 'admin' });
        socket.emit("send_updateSingleStudent", { student: {...studentListOriginal[index]}, room });
        // const dataSend = { studentId: student.studentID, currentWarnings: currentWarningsGiven }
        // socket.emit("send_message_updateWarnings", { dataSend, room });
        updateMongo({color: studentListOriginal[index].color}, studentListOriginal[index].dailyListId, 'dailyList')
        // updateDailyListMongo(student.studentId, {color: studentListOriginal[index].color})
      }

    function handleAddGroup(e, itemId, mode) {
        e.preventDefault()
        handleModifyModuleGroup(itemId, mode)
    }

    var absent = studentList.filter(entry => entry.attendance === 'Absent' && entry.period === classInformation.period).length
    var total = studentList.filter(entry => entry.period === classInformation.period).length
    
    // console.log(editMode, 'editMode', gridMode === 'Edit', editMode.mode === 'Group')
    const exportFunctions = { showGrid, alphaView, setAlphaView, gridMode, editMode, changeMode, setEditMode, showAbsent, modalModes, mode, setShowAbsent, setShowGrid, setChipMode, chipsModes, chipMode }
    return (
        <ErrorBoundary>
        <div className={sizeClass}>
            <div className="main-card mb-3 card">
                <div className="card-header h-auto mt-2">
                    {/* <div onClick={e => addString(e)}>Click me</div> */}
                    <h5 className="card-title mb-0 me-2" style={{}}>
                        Color Status ({total - absent}/{total})
                        {!showGrid && <div className={`me-2 badge bg-${alphaBg}`} style={{marginLeft:"10px", display:"inline"}}>{alphaString}</div>}
                    </h5>
                    <div className="btn-actions-pane-right d-none d-md-block">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                         {initialLoad && <Buttons exportFunctions={exportFunctions} />}
                        </div>
                    </div>
                </div>
                <div className="card-header h-auto d-md-none">
                    <div className="d-grid gap-2 d-flex flex-wrap">
                        {initialLoad && <Buttons exportFunctions={exportFunctions} />}
                    </div>
                </div>
                {gridMode.mode === 'Assignments' && <div className="card-header h-auto">
                        <Assignments/>
                </div>}
                <div className="card-body">
                    {showAbsent && 
                    <div className="">
                    <div className="mb-3 d-grid d-flex">
                      <div className="align-items-right">
                        {absent === 0 ? 
                        <div className="badge bg-success m-1">No Students Absent!</div> : 
                        studentList.map(entry => {
                            if(entry.period !== classInformation.period || entry.attendance !== 'Absent') return
                            return <AbsentCard key={entry.studentId} student={entry} />
                        })}
                      </div>
                      </div>
                    </div>}
                    <div className="gridScroll" style={{}}>
                        {showGrid ? <SeatGrid modules={modules} period={classInformation.period} studentList={studentList} updateMongo={updateMongo} handleAddGroup={handleAddGroup}
                                        getMongoList={getMongoList} layout={focusAgreements && focusAgreements.layout} isDraggable={gridMode.mode === 'Edit' && allowEdit ? true : false} isResizable={gridMode.mode === 'Edit' && allowEdit ? true : false}
                                        handleAttendanceClick={handleAttendanceClick} gridMode={gridMode.mode} rowHeight={gridMode.mode === 'Edit' && editMode.mode === 'Group' ? 15 : gridMode.mode === 'Edit' && editMode.mode === 'Pending' ? 12 : gridMode.rowHeight} handleClick={handleClick} handleGroupClick={handleGroupClick} 
                                        handleAddWarning={handleAddWarning} activeStudent={activeStudent} useSingleAndDoubleClick={useSingleAndDoubleClick} editMode={editMode.mode} resetStudents={resetStudents} resetPendingStudents={resetPendingStudents}
                                        allowEdit={allowEdit} handleAllowEdit={handleAllowEdit} checkoutItems={checkoutItems} createMongo={createMongo} classInformation={classInformation} classInformationArray={classInformationArray} focusAgreements={focusAgreements}
                                        showSeats={showSeats} setShowSeats={setShowSeats} setActiveStudent={setActiveStudent} handleSimplifySeats={handleSimplifySeats} chipMode={chipMode} 
                                        layouts={layouts} setLayouts={setLayouts} createLayout={createLayout} setCreateLayout={setCreateLayout} /> :
                            viewOptions[alphaView].map(student => {
                            if(student.attendance === 'Absent' || student.period !== classInformation.period) return
                            return <WarningsEntry key={student.studentId} student={student} alphaView={alphaView} />
                        })}
                    </div>
                </div>
            </div>
        </div> 
        </ErrorBoundary>
    )
}

function Buttons({exportFunctions}) {

    const [attendanceMessage, setAttendanceMessage] = useState('Submit')
    const [time, setTime] = useState(Date.now())
    const {classInformation} = useContext(SiteContext)
    const {handleAttendance, mode, modalModes} = useContext(AdminContext)

    useEffect(() => {
        let interval;
        interval = setInterval(() => {
            if(Date.now() - time < 4000) return
            setAttendanceMessage('Submit')  
        }, 1000);
        return () => clearInterval(interval)
    }, [attendanceMessage])

    const {showGrid, alphaView, setAlphaView, setEditMode, editMode, gridMode, setChipMode, chipsModes, chipMode,
        changeMode, setShowGrid, showAbsent, setShowAbsent } = exportFunctions

    function changeEditMode(e, mode) {
        e.preventDefault()
        var index = editModes.findIndex(entry => entry.mode === mode)
        setEditMode(editModes[index])
    }

    function changeChipsMode(e, mode) {
        e.preventDefault()
        var index = chipsModes.findIndex(entry => entry.mode === mode)
        setChipMode(chipsModes[index])
    }

    function handleChangeView(e) {
        e.preventDefault()

        if(alphaView) {
          setAlphaView(0)
        } else {
          setAlphaView(1)
        }
    }

    function handleModeText() {
        var index = modalModes.findIndex(entry => entry.type === mode)
        if(index === -1) return
        return modalModes[index].text ? modalModes[index].text : 'None'
    }

    function handleAttendanceLocal(e) {
        e.preventDefault()
        handleAttendance(classInformation, e)
        setAttendanceMessage('Submitted!')
        setTime(Date.now())
    }

    var alphaButtonString = alphaView === 1 ? 'Sort By Color' : 'Sort By Alphabetically'

    return (
    <>
        {!showGrid && <button
            className="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm manualButton m-1"
            onClick={e => handleChangeView(e)}
        >
            {alphaButtonString}
        </button>}
        {showGrid && gridMode.mode === 'Edit' && <select
            className="m-1 btn-outline-2x btn btn-outline-focus"
            style={{fontSize:"0.8rem"}}
            onChange={e => changeEditMode(e, e.target.value)}
            value={editMode.mode}
        >
            {editModes.map(entry => {
                return <option className="p-1 ps-2" key={entry.mode} value={entry.mode}>{entry.mode}</option>
            })}
        </select>}
        {showGrid && <div className="d-flex flex-wrap">
            {modes.map(entry => {
                return <div className={`m-1 badge d-flex align-items-center bg-${entry.mode === gridMode.mode ? 'secondary' : 'light'}`} key={entry.mode} value={entry.mode} onClick={e => changeMode(e, entry.mode)} role="button">
                    {entry.mode == 'Modal Completion' ? `Completion: ${handleModeText()}` : entry.mode}
                    </div>
            })}

        </div>}
        {showGrid && gridMode.mode === 'Chips' && <div className="d-flex flex-wrap justify-content-end ms-3">
            {chipsModes.map(entry => {
                return <div className={`m-1 badge d-flex align-items-center border border-2 border-primary ${entry.mode === chipMode.mode ? 'bg-primary' : 'bg-light text-primary'}`} key={entry.mode} value={entry.mode} onClick={e => changeChipsMode(e, entry.mode)} role='button'>{entry.mode}</div>
            })}
        </div>}
        {!showGrid && <button
            className="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm manualButton m-1"
            onClick={e => setShowAbsent(prev => {return !prev})}
        >
            {showAbsent ? 'Hide Absent' : 'Show Absent'}
        </button>}
    <button
      className="btn-icon btn-wide btn-outline-2x btn btn-outline-focus btn-sm manualButton m-1 ms-2"
      onClick={e => handleAttendanceLocal(e, classInformation)}
    >
      {attendanceMessage}
    </button>
    </>
    )
}

function AbsentCard({student}) {

    const {initialLoad} = useContext(SiteContext)
    const {handleAttendanceClick} = useContext(AdminContext)

    return (
    <ErrorBoundary>
      <Dropdown style={{display:"inline"}}>
          <Dropdown.Toggle className={`p-0 warning-dropdown`} variant="white" id="dropdown-basic">
            <div className="btn btn-light btn-outline-danger m-1" style={{fontSize:"11px"}}>
                <span style={{fontWeight:"bold"}}>{student.name.split(' ')[0]}</span>
                <span>{` ${student.name.split(' ')[1].substring(0, 1)}`}</span>
                <div className="badge bg-success ms-2 m-1" onClick={e => handleAttendanceClick(e, student, 'Present')}>P</div>
                <div className="badge bg-warning m-1" onClick={e => handleAttendanceClick(e, student, 'Tardy')}>T</div>
            </div>
          </Dropdown.Toggle>
  
          <Dropdown.Menu className="dropdown-menu-xxl dropdown-menu">
                {initialLoad ? <StatusMenu student={student} /> : <span>Loading...</span>}
          </Dropdown.Menu>
      </Dropdown>
    </ErrorBoundary>
    )
}
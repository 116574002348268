import React, {useState, useEffect, useContext } from 'react'

import { SiteContext } from '../../App'
import { StudentContext } from '../../pages/StudentContainer'
import HallPassMenuStatus from './header/HallPassMenuStatus'
import ActiveHP from './ActiveHP'
import HPCard from './HPCard'

export default function SHallPass({type}) {

    const {
        classInformation, student, socket,
        studentDataFunctions: {
            createMongo, getMongoList, getMongoById, updateMongo, deleteMongo
        }
    } = useContext(SiteContext)
    
    const { hpEvents, setHpEvents, updateEvents, setUpdateEvents } = useContext(StudentContext)

    useEffect(() => {

        const interval = setInterval(() => {
            // console.log(hpEvents,'hpEvents')
            if(hpEvents.length === 0) return
            hpEvents.map(entry => {
                // console.log(entry.studentId === student.studentId,'entry.studentId === student.studentId')
                if(entry.active && entry.approved && entry.studentId === student.studentId) {
                    window.location.reload()
                }
            })
        }, 20000);

        return () => {
          clearInterval(interval);
        };
      }, [hpEvents]);

    useEffect(() => {

        socket.on("receive_hallPass_addEvent", (data) => {
            console.log('receive_hallPass_addEvent', data, data.data.createObj) 
            setHpEvents([...hpEvents, data.data.createObj])
        })

        socket.on("receive_hallPass_update", (data) => {
            console.log('receive_hallPass_update', data, data.updatedObj)
            updateExistingEvent(data.updatedObj, data.type, data.changeValue)
        })

        socket.on("receive_hallPass_updateStudent", (data) => {
            console.log('receive_hallPass_update', data, data.updatedObj)
            updateExistingEvent(data.updatedObj, data.type, data.changeValue)
        })
    
        return () => {
          socket.off('receive_hallPass_update');
          socket.off('receive_hallPass_updateStudent');
          socket.off('receive_hallPass_addEvent');
        };
    
    }, [socket])

    // console.log(hpEvents,'hpEvents')

    function updateExistingEvent(event, type, changeValue) {

        const editHpEvents = [...hpEvents]
        var index = editHpEvents.findIndex(entry => entry.timeStamp === event.timeStamp)
    
        if(index === -1 && type !== 'remove') {
            editHpEvents.push(event)
            console.log(editHpEvents,'editHpEvents push')
            setHpEvents(editHpEvents)
            return
        }
    
        if(type === 'remove') {
            const filter = editHpEvents.filter(entry => entry._id !== event._id)
            console.log(filter,'filter remove')
            setHpEvents(filter)
            return
        }
    
        editHpEvents[index] = {...editHpEvents[index], ...changeValue}
        console.log(editHpEvents,'editHpEvents change')
        setHpEvents(editHpEvents)
    }
    
    return (
        <>
            {hpEvents.map(entry => {
                    if(entry.studentId === student.studentId) {
                        return <HPCard event={entry} key={entry.timeStamp} subtitle={'Hall Pass'} colorValue={'primary'} sizeClass={'col-sm-12'} />
                    }
                })
            }
        </>
    )
    
}

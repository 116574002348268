import React, { useState, useEffect, useContext } from 'react';

import { SiteContext, baseUrl } from '../../../App';
import { AdminContext } from '../../../pages/AdminContainer';
import { StudentContext } from '../../../pages/StudentContainer';
import { ErrorBoundary } from '../../../other/utils/ErrorBoundary';

import AQGraph from '../admin/types/AQGraph';
import Modal from 'react-bootstrap/Modal';
import AEdit from '../admin/AEdit'
import DrawCanvas from './DrawCanvas'
import ReadingZone from '../../../student/studentCards/ReadingZone';
import SAssignments from '../../assignments/student/SAssignments'

import '../customModal.css'

export default function ViewModal({}) {

  const { 
    focusAgreements, loginState, classInformation, studentList,
    studentDataFunctions: {handleShow}
  } = useContext(SiteContext)

  // const {handleShow} = useContext(loginState.admin ? AdminContext : StudentContext)

  const [fontSize, setFontSize] = useState(loginState && loginState.admin && !loginState.studentView ? 2.5 : 2)
  const [columns, setColumns] = useState(1)
  const [showGraph, setShowGraph] = useState(false)
  const [showSettings, setShowSettings] = useState(false)

  if(!focusAgreements || !focusAgreements.createAgreement || !focusAgreements.attendanceQuestion || !focusAgreements.customModalType) return
  const customCategory = focusAgreements.customModalType
    if(!customCategory) return
  const customArray = focusAgreements[customCategory]
    if(!customArray) return

  var count = 0
  var variantCount = 1

  const filteredEntries = studentList.filter(studentEntry => studentEntry.period === classInformation.period && studentEntry.attendance !== 'Absent')
  const exportItems = {fontSize, setFontSize, columns, setColumns, handleShow, customArray, customCategory, showGraph, setShowGraph, showSettings, setShowSettings, showGraph, filteredEntries, customArray}

  // console.log(focusAgreements.customModalText,'focusAgreements.customModalText',focusAgreements, focusAgreements.customModalType)

  return (
    <ErrorBoundary>
      {focusAgreements.customModalType === 'readingZone' && <ReadingZone sizeClass={'col-12'} dataOnly={true} />}
      {focusAgreements.customModalType === 'assignments' && <SAssignments sizeClass={'col-12'} dataOnly={true} />}
      {focusAgreements.customModalType !== 'readingZone' && focusAgreements.customModalType !== 'assignments' && <div className="row">
        {loginState.admin && !loginState.studentView && <AdminSettings exportItems={exportItems}/>}
        <div className={(showGraph || (customCategory && customArray.url)) ? `col-8` : `col-12`}>
          <div className="card bg-secondary m-2">
              <div className="p-2 ps-3 pe-3 m-1 bg-secondary text-white h-auto" style={{border:"0px"}}>
                <h1 className="mt-0 mb-0" style={{fontSize:`${fontSize}rem`}}>
                  <div className="d-flex align-items-center flex-wrap m-2">
                    <span className="w-100" style={{fontSize:`${fontSize*.5}rem`}}>
                     {focusAgreements.customModalType !== 'dailyLinks' && `${focusAgreements.customModalText}:`}
                    </span>
                    <span className="d-block w-100" style={{fontWeight:"bold"}}>
                      {focusAgreements.customModalText}
                      {/* {focusAgreements.customModalType === 'dailyLinks' ? 'Daily Links' : focusAgreements[focusAgreements.customModalType].text} */}
                    </span>
                  </div>
                </h1>
              </div>
          </div>
          {customArray.bullets.map(entry => {
              count++
              variantCount++
              if(variantCount > 7) {
                variantCount = 1
              }
              if(!entry || (customCategory === 'dailyLinks' && entry.link.length === 0)) return
              return <BulletCard key={entry.id} entry={entry} count={count} variantCount={variantCount} exportItems={exportItems} />
          })}
        </div>
        <div className="col-4">
          {customArray.url && <ImageCard customArray={customArray}/>}
          {showGraph && <AQGraph title={``} sizeClass={`col-12`} filteredEntries={filteredEntries} type={customCategory} />}
        </div>
        <div className="col-12">
          {(!loginState.admin || loginState.studentView) ? <StudentResponses exportItems={exportItems} /> :
          (showSettings && <SettingsCard/>)}
        </div>
      </div>}
    </ErrorBoundary>
  );
}

function BulletCard({entry, count, variantCount, exportItems}) {

    const {loginState, colorMatch, student} = useContext(SiteContext)
    const {fontSize, columns, customCategory, showGraph, filteredEntries, customArray} = exportItems

    var variant = customCategory === 'attendanceQuestion' ? colorMatch[variantCount].variant : customCategory === 'dailyLinks' ? 'secondary' : 'secondary'
    var responseCount = filteredEntries.filter(studentEntry => studentEntry.customPollResponse && studentEntry.customPollResponse[customArray.type] && studentEntry.customPollResponse[customArray.type] === count).length

    const activeResponse = customCategory === 'attendanceQuestion' && (student.customPollResponse && student.customPollResponse[customArray.type] == count) ? true : false

    function handleLinkClick(e) {
      e.preventDefault()
      if(customCategory !== 'dailyLinks') return
      window.open(entry.link, "_blank");
    }
    // console.log(entry.customPollResponse,'entry.customPollResponse')
    return (
    <ErrorBoundary>
    <div className={`col-${columns == 1 ? '12' : columns == 2 ? '6' : '4'}`} style={{fontSize: loginState.admin && !loginState.studentView && `${fontSize - 1}rem`}}>
      <div className="m-2 d-flex">
      <div className={`card d-flex justify-content-center fsize-2 align-items-center fw-bold shadow-none ${activeResponse ? `bg-${variant} text-white` : `border-${variant} text-${variant}`}`} style={{minWidth:"50px"}}>
        #{count}
      </div>
      <div className={`btn card p-1 pt-2 pb-2 flex-fill ms-2 shadow-none ${activeResponse ? `bg-${variant} text-white` : `border-${variant} text-${variant}`}`} onClick={e => handleLinkClick(e)} role={customCategory === 'dailyLinks' ? 'button' : ''}>
        <div className="m-2 d-flex justify-content-between">
          {customCategory !== 'dailyLinks' ? <span className="text-start">{entry.entry}</span> : entry.link.length > 0 ? 
          <>
            {customCategory === 'dailyLinks' && entry.link.length > 0 && <a href={entry.link} target="_blank" className="text-start">{entry.entry}</a>}
            <span className="ms-3 text-end">Click to Open</span>
          </> : <></>}
          {/* {customCategory !== 'dailyLinks' && entry.entry} */}
        </div>
      </div>
      {showGraph && customCategory === 'attendanceQuestion' && <div className={`card d-flex justify-content-center ms-2 align-items-center shadow-none fw-bold bg-${variant} text-white`} style={{minWidth:"120px"}}>
        {responseCount} {responseCount === 1 ? ' Vote' : 'Votes'}
      </div>}
      </div>
    </div>
    </ErrorBoundary>
    )
}

function ImageCard({customArray}) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [drawArr, setDrawArr] = useState({
    color: "#ffc600",
    width: 400,
    height: 400,
    brushRadius: 8,
    lazyRadius: 12,
    backgroundImg: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg",
    imgs: [
      "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg",
      "https://i.imgur.com/a0CGGVC.jpg"
    ]
  })

  function updateDrawArr(input) {
    const editDrawArr = {...drawArr}
    setDrawArr({...editDrawArr, ...input})
  }

  return (
    <ErrorBoundary>
    <div className="col-12">
      <div className="card m-2 ms-0" onClick={handleShow} role="button">
        <img src={`${customArray.url}?${new Date().getTime()}`} className="m-0 viewModalImage"/>
      </div>
      <div>Click to expand & draw on image</div>
    </div>

    <Modal show={show} onHide={handleClose} size="xl">
        {/* <img src={`${customArray.url}?${new Date().getTime()}`} className="m-0 viewModalImage"/> */}
        <DrawCanvas url={`${customArray.url}`} drawArr={drawArr} updateDrawArr={updateDrawArr} customArray={customArray} handleClose={handleClose} />
    </Modal>
    </ErrorBoundary>
  )
}

function SettingsCard({}) {
  return (
    <ErrorBoundary>
    <div className="col-8 mt-4">
      <div className="m-2">
        <AEdit/>
      </div>
    </div>
    </ErrorBoundary>
  )
}

function AdminSettings({exportItems}) {

  const {fontSize, setFontSize, columns, setColumns, handleShow, showGraph, setShowGraph, showSettings, setShowSettings} = exportItems

  function handleShowGraph() {
    setShowGraph(!showGraph)
  }

  function handleShowSettings() {
    setShowSettings(!showSettings)
  }

  return (
  <ErrorBoundary>
  <div className="col-12">
  <div className="card bg-secondary m-2">
    <div className="d-flex justify-content-between align-content-center">
      <div className="p-2">
        <button className="btn btn-light m-1" onClick={handleShowGraph}>{showGraph ? 'Hide Graph' : 'Show Graph'}</button>
      </div>
      <div className="p-2 text-white d-flex align-items-center">
        <span className="m-2">Font</span>
        <input type="number" value={fontSize} 
          type="number" className={`btn bg-white m-1 d-inline`} min={1} step={'0.5'}  max={10} onChange={e => setFontSize(e.target.value)} />
        <span className="m-1">Cols</span>
        <input type="number" value={columns} 
          type="number" className={`btn bg-white m-1 d-inline`} min={1}  max={3} onChange={e => setColumns(e.target.value)} />
        {/* <button className="btn btn-light m-1" onClick={e => handleShow(undefined, 'customModal')}>Modal</button> */}
        <button className="btn btn-light m-1" onClick={handleShowSettings}>{showSettings ? 'Hide Settings' : 'Show Settings'}</button>
      </div>
    </div>

  </div>
  </div>
  </ErrorBoundary>
  )
} 

function StudentResponses({exportItems}) {

  const {student, socket, colorMatch, focusAgreements, studentDataFunctions: {setStudent, updateMongo}} = useContext(SiteContext)

  const {fontSize, customArray, customCategory} = exportItems
  var count = 0
  var variantCount = 1

  function handleTextResponse(e) {
    e.preventDefault()
    const editStudent = { ...student}
    editStudent.customTextResponse = {...student.customTextResponse, [customArray.type]: e.target.value}
    socket.emit("send_updateSingleStudent", { student: editStudent, room: 'admin' });
    updateMongo({customTextResponse: {...student.customTextResponse, [customArray.type]: e.target.value}}, student.dailyListId, 'dailyList')
    setStudent(editStudent)
  }

  const fistToFive = [1,2,3,4,5]
  // console.log(student.customTextResponse,'student.customTextResponse')

  if(focusAgreements.pauseResponses) return
  return (
  <ErrorBoundary>
  <div className="mt-4">
    {(customArray.responseType === 'Poll Response' || customArray.responseType === 'Poll Response and Explain') &&
    <div className="">
      <div className="d-block ms-2">{customArray.type === "createAgreement" ? 'Fist to Five:' : 'Choose One:'}</div>
      <div className="d-flex justify-content-between">
        {customArray.type === "createAgreement" ? fistToFive.map(entry => {
            variantCount++
            if(variantCount > 7) {variantCount = 1}
            return <StudentResponsesEntry key={variantCount} count={entry} variantCount={variantCount} type={customArray.type} />
        }) :   
        customArray.bullets.map(entry => {
          count++
          variantCount++
          if(variantCount > 7) {variantCount = 1}
          if(customCategory === 'dailyLinks' && entry.link.length === 0) return
          return <StudentResponsesEntry key={entry.id} count={count} variantCount={variantCount} type={customArray.type} />
      })}

      </div>
    </div>}
    {(customArray.responseType === 'Text Response' || (customArray.responseType === 'Poll Response and Explain')) && 
      <div className="mt-2 m-2" 
      // style={{fontSize:`${fontSize - 1}rem`}}
      >
        <div className="d-block">{customArray.responseType === 'Poll Response and Explain' ? 'Explain:' : 'Your Response:'}</div>
        <textarea type="text" className="form-control" cols={40} rows={1}
          value={student.customTextResponse && student.customTextResponse[customArray.type] ? student.customTextResponse[customArray.type] : ''} onChange={(e) => handleTextResponse(e)} />
      </div>
    }
    </div>
    </ErrorBoundary>
  )
}

function StudentResponsesEntry({count, variantCount, type}) {

  const {student, socket, colorMatch, studentDataFunctions: {setStudent, updateMongo}} = useContext(SiteContext)

  const activeResponse = student.customPollResponse && student.customPollResponse[type] == count ? true : false

  function handleChooseResponse(e) {
    e.preventDefault()
    var newValue = student.customPollResponse[type] === count ? false : count
    const editStudent = {...student}
    editStudent.customPollResponse = {...editStudent.customPollResponse, [type]: newValue}

    // console.log(newValue,'newValue',editStudent)
    socket.emit("send_updateSingleStudent", { student: editStudent, room: 'admin' });
    updateMongo({customPollResponse: {...editStudent.customPollResponse, [type]: newValue}}, student.dailyListId, 'dailyList')
    setStudent(editStudent)
  }

  const variants = ['','','danger','flag','flag','flag','success']
  var variant = type === 'createAgreement' ? variants[variantCount] : colorMatch[variantCount].variant

  var cssString = activeResponse ? `bg-${variant} text-white` : `border-${variant} text-${variant}`

  const textStrings = ['', 'No Agreement', 'Partial Agreement', 'Partial Agreement', 'Partial Agreement', 'Agreement']
  return (
  <ErrorBoundary>
    <div className={`card flex-fill m-2 shadow-none fw-bold ${cssString}`} role="button" onClick={e => handleChooseResponse(e)}>
      <div className="card-body text-center">
        <div className="fsize-2">{type !== "createAgreement" && '#'}{count}</div>
        {type === "createAgreement" && <div>{textStrings[count]}</div>}
      </div>
    </div>
  </ErrorBoundary>
  )
}
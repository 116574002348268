import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { SiteContext } from "../../../App";
import { StudentContext } from "../../../pages/StudentContainer";

import CleanupChecklist from "./checklist/Checklist";
import ProgressChecklist from "../../../modules/checklist/student/SChecklist";
import SubmitButton from "./SubmitButton";
import ColorsReflection from './colorReflection/ColorsReflection'

import './exitTicket.css'

export default function DayReview({show, type}) {

 const {
   student, moduleItems, loginState, socket, room,
   studentDataFunctions: {updateMongo, moduleFind, setStudent, handleClose}
 } = useContext(SiteContext)

 const {updateStudent} = useContext(StudentContext)

 const [checkList, setCheckList] = useState(() => {return checklistItem(moduleItems, student, moduleFind)})
 const [colorsReview, setColorsReview] = useState(false)
 const [showInventory, setShowInventory] = useState(false)

 useEffect(() => {
  setCheckList(checklistItem(moduleItems, student, moduleFind))
 }, [moduleItems])

 const form = student.dayReview
 if(!form) return
 const step = form.display
 
 const steps = [
  {type: 'Cleanup Checklist', buttonText: 'Continue to Step 2', warningText: 'Complete checklist first', filter: checkList.filter(entry => !entry.complete).length, instructions: 'Check each box as you clean up each item. Check all boxes to continue.' },
  {type: 'Progress Update', buttonText: 'Continue to Step 3', warningText: 'Update module progress first', filter: 0, instructions: 'If completed any activities today, update the Progress Checklist.'},
  {type: 'Colors Reflection', buttonText: 'Submit Exit Ticket', warningText: 'Complete reflection first', filter: filterForm(form), instructions: 'Respond to the prompts to reflect upon the day.' },
 ]

 function updateForm(category, item, value) {
  updateMongo({dayReview: {...form, display: step, [category]: {...form[category], [item] : value } }}, student.dailyListId, 'dailyList')

  const editStudent = {...student}
  editStudent.dayReview[category][item] = value

  socket.emit("send_exitTicket", { dayReview: editStudent.dayReview, studentId: student.studentId, room });
  setStudent(editStudent)
 }

 function handleShowInventory(e) {
  e.preventDefault()

  setShowInventory(prev => {return !prev})
 }

 function handleCloseLocal(e, adminOverride) {
  console.log('closing')
  handleClose(type, e, adminOverride)
 }

 return (

    <Modal show={show} dialogClassName="my-modal3">
      <Modal.Header style={{padding:0}}>
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center">
            <span>
              <div>
                <p style={{fontSize:"24px"}} className="mb-0">Daily Exit Ticket</p>
              </div>
              <p className="mb-0">Step {step} of {steps.length}: {steps[step-1] && steps[step-1].type ? steps[step - 1].type : ''}</p>
            </span>
            <div className="mt-2 d-flex">
              {step === 1 && <button className={`btn btn-${showInventory ? 'secondary' : 'purple'} text-white me-3`} onClick={e => handleShowInventory(e)}>{showInventory ? 'Hide Inventory' : 'Show Inventory'}</button>}
              <SubmitButton handleCloseLocal={e => handleCloseLocal(e)} form={form} steps={steps} step={step} checkList={checkList} setShowInventory={setShowInventory} />
            </div>
          </div>
        </Modal.Body>
      </Modal.Header>
      <Modal.Header className="bg-secondary text-white border rounded-0 border-0">
        <div className="m-0">
            <span className="fw-bold">Instructions:</span>
            <span className="ms-2">{steps[step-1] && steps[step-1].instructions}</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        {step === 1 && <CleanupChecklist checkList={checkList} setCheckList={setCheckList} updateStudent={updateStudent} showInventory={showInventory} />}
        {step === 2 && <ProgressChecklist />}
        {step === 3 && <ColorsReflection form={student.dayReview} updateForm={updateForm} student={student} updateStudent={updateStudent} colorsReview={colorsReview} setColorsReview={setColorsReview} />}
      </Modal.Body>
      <Modal.Footer style={{minHeight:"40px"}}>
        {loginState.admin && <button className="btn btn-light" onClick={e => handleCloseLocal(e, 'hide')}>Hide</button>}
        {/* <button className={`btn btn-primary`} onClick={e => handleShowInventory(e)}>{showInventory ? 'Hide Inventory' : 'Show Inventory'}</button> */}
      </Modal.Footer>
    </Modal>
);
}

function checklistItem(moduleItems, student, moduleFind) {

  var activeCheckouts = []
  var completeCheckouts = []

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); 
  var yyyy = today.getFullYear();

  today = `month${mm}day${dd}`

  // console.log(moduleItems,'moduleItems')
  moduleItems.map(entry => {
    if(!entry.checkoutEvent || !entry.checkoutEvent.adminProcessed || entry.checkoutEvent.period !== student.period && today !== entry.checkoutTimestamp) return
    if(entry.checkedOut) {
      activeCheckouts.push( {complete: false, item: entry.item.substring(0, 30), badges: [`Click To Turn In`], mongoEntry: entry} )
    } else if(!entry.checkedOut) {
      completeCheckouts.push( {complete: true, item: entry.item.substring(0, 30), badges: [`Turned In`], mongoEntry: entry} )
    } 
  })

  // console.log(activeCheckouts,'activeCheckouts')
  activeCheckouts = sortAlpha(activeCheckouts)
  completeCheckouts = sortAlpha(completeCheckouts)

  // const allCheckouts = [...activeCheckouts, ...completeCheckouts]
  const allCheckouts = [...activeCheckouts]


  const list = [
      {complete: true, item: 'Return to White Bin:', badges: [],
          secondary: allCheckouts
      },
      {complete: false, item: 'Put away:', badges: [],
          secondary: [ 
              {complete: false, item: `${allCheckouts.length > 0 ? `Other ` : ``}Module Materials`, badges: ['Take your time!']},  
              // {complete: false, item: 'Items from ‘Front of Room’ and ‘Back Closet’ in white bin'},  
              {complete: false, item: 'Charger and/or loaner tablet', badges: ['If Needed']},
              {complete: false, item: 'Trash', badges: ['If Needed']},
              // {complete: false, item: 'Loaner tablet', badges: ['If Needed']},
          ]
      },
      {complete: false, item: 'Check if properly sorted:', badges: [],
          secondary: [ 
              {complete: false, item: 'Cabinet(s) and drawers', badges: []},
              {complete: false, item: 'Container(s) and Slots', badges: []},
              // {complete: false, item: 'Slots', badges: []},
          ]
      },
      {complete: false, item: 'Clean work area table', badges: ['If Needed']}, 
      {complete: false, item: 'Sweep work area floor', badges: ['If Needed']},
      {complete: false, item: 'Put chair(s) back where you found them', badges: ['If Needed']},
      // {complete: false, item: 'Push in chair', badges: ['As You Leave']}, 
  ]

  return allCheckouts.length > 0 ? list : list.slice(1)
}



function sortAlpha(obj) {
  return obj.length === 0 ? obj 
  : 
  obj.sort(function(a, b) {
    return a.item.localeCompare(b.item);
  });
}

function filterForm(form) {
  var count = 0
  for (var key in form) {
   if (form.hasOwnProperty(key) && key !== 'display') {
     // console.log(form[key],'form')
     for (var key2 in form[key]) {
       if (form[key].hasOwnProperty(key2)) {
        // && typeof form[key][key2] === 'boolean'
         if(typeof form[key][key2] === 'boolean') {count++}
       }  
     }
   }  
  }
  return count
}
import React, { useState, useEffect, useContext } from "react";
import { SiteContext } from "../App";

// import NotesEdit from "./NotesEdit";

export default function Edit3(props) {

    const {classInformationArray} = useContext(SiteContext)

    const {
        form,
        updateForm,
        onSubmit
    } = props

 return (
  <div>
   <form onSubmit={onSubmit}>
      <div className="form-group edit-row">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          className="form-control"
          id="name"
          value={form.name}
          onChange={(e) => updateForm({ name: e.target.value })}
        />
      </div>
      <div className="form-group edit-row">
         <label htmlFor="position">First Name</label>
         <input
           type="text"
           className="form-control"
           id="position"
           value={form.firstName}
           onChange={(e) => updateForm({ firstName: e.target.value })}
         />
       </div>   
       <div className="form-group edit-row">
         <label htmlFor="position">Name Pronunciation</label>
         <input
           type="text"
           className="form-control"
           id="position"
           value={form.namePronunciation}
           onChange={(e) => updateForm({ namePronunciation: e.target.value })}
         />
       </div> 
       <div className="form-group edit-row">
        <label htmlFor="position">Student ID</label>
        <input
          type="text"
          className="form-control"
          id="position"
          value={form.studentId}
          onChange={(e) => updateForm({ studentId: e.target.value })}
        />
      </div> 
      <div className="form-group edit-row">
        <label htmlFor="position">Attendance</label>
        <input
          type="text"
          className="form-control"
          id="position"
          value={form.attendanceName}
          onChange={(e) => updateForm({ attendanceName: e.target.value })}
        />
      </div> 
      <div className="form-group">
        <label htmlFor="position">Email</label>
        <input
          type="text"
          className="form-control"
          id="position"
          value={form.email}
          onChange={(e) => updateForm({ email: e.target.value })}
        />
      </div>
      <div className="form-group edit-row">
        <label htmlFor="position">Grade</label>
        <input
          type="text"
          className="form-control"
          id="position"
          value={form.grade}
          onChange={(e) => updateForm({ grade: e.target.value })}
        />
      </div>
      <div className="form-group edit-row">
        <label htmlFor="position">PS Username</label>
        <input
          type="text"
          className="form-control"
          id="position"
          value={form.psUsername}
          onChange={(e) => updateForm({ psUsername: e.target.value })}
        />
      </div>
      <div className="form-group edit-row">
        <label htmlFor="position">PS Password</label>
        <input
          type="text"
          className="form-control"
          id="position"
          value={form.psPassword}
          onChange={(e) => updateForm({ psPassword: e.target.value })}
        />
      </div>
      {/* <div className="form-group edit-row">
        <label htmlFor="position">Phone Taken</label>
        <input
          type="number"
          className="form-control"
          id="position"
          value={form.phoneTaken}
          onChange={(e) => updateForm({ phoneTaken: e.target.value })}
        />
      </div>
      <div className="form-group edit-row">
        <label htmlFor="position">Bathroom Emergency</label>
        <input
          type="number"
          className="form-control"
          id="position"
          value={form.bathroomEmergency}
          onChange={(e) => updateForm({ bathroomEmergency: e.target.value })}
        />
      </div> */}
      <div className="form-group">
        <label htmlFor="position">Class Role</label>
        <select
          className="form-control"
          // className="pa3 bb br3 grow b--none bg-lightest-blue ma3"
          value={form.classRole}
          onChange={(e) => {
            if(e.target.value === '-- Select Class Role --') {return}
            updateForm({ classRole: e.target.value })
            
          }}
        >
          <option>-- Select Class Role --</option>
          <option>Materials Manager</option>
          <option>Decorations Coordinator</option>
          <option>Class Ambassador</option>
        </select>
      </div>
      <div className="form-group edit-row">
        <label htmlFor="position">Home Phone</label>
        <input
          type="text"
          className="form-control"
          id="position"
          value={form.homePhone}
          onChange={(e) => updateForm({ homePhone: e.target.value })}
        />
      </div>
      <div className="form-group edit-row">
        <label htmlFor="position">Name and Relation:</label>
        <input
          type="text"
          className="form-control"
          id="position"
          value={form.homePhoneRelation}
          onChange={(e) => updateForm({ homePhoneRelation: e.target.value })}
        />
      </div>
      <div className="form-group edit-row">
        <label htmlFor="position">Birthday</label>
        <input
          type="text"
          className="form-control"
          id="position"
          value={form.birthday}
          onChange={(e) => updateForm({ birthday: e.target.value })}
        />
      </div>
      <div className="form-group edit-row">
        <label htmlFor="position">Group</label>
        <select
          className="form-control"
          value={form.group}
          // type="checkbox" 
          onChange={(e) => {
            if(e.target.value === '-- Select Group --') {return}
            updateForm({ group: e.target.value })
          }}
        >
          <option>-- Select Group --</option>
          <option>Group 1</option>
          <option>Group 2</option>
          <option>Group 3</option>
          <option>Group 4</option>
          <option>Group 5</option>
          <option>Group 6</option>
          <option>Group 7</option>
          <option>Group 8</option>
        </select>
      </div>
      {/* <div className="mt-3"> */}
      <div className="form-group edit-row">
        <label htmlFor="position">Period</label>
        <select
          className="form-control" value={form.period}
          onChange={(e) => {
            if(e.target.value === '-- Select Period --') {return}
            updateForm({ period: e.target.value })
          }}
        >
          <option>-- Select Period --</option>
          {classInformationArray.map(entry => {
            return <option key={entry._id + entry.period} value={entry.period}>{entry.period}</option>
          })}
          {/* <option>Group 1</option>
          <option>Group 2</option>
          <option>Group 3</option>
          <option>Group 4</option>
          <option>Group 5</option>
          <option>Group 6</option>
          <option>Group 7</option> */}
          <option>Dropped</option>
        </select>
        {/* {classInformationArray.map(entry => {
          return (
          <div key={entry.period + entry._id} className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" value={entry.period}
              checked={form.period == entry.period} name="periodSelect"
              onChange={(e) => updateForm({ period: e.target.value })}
            />
            <label htmlFor="positionIntern" className="form-check-label">{entry.informationStrings.periodAbbreviation}</label>
          </div>   
          )
        })}
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="checkbox" value={'Dropped'}
            checked={form.period == 'Dropped'} name="periodSelect"
            onChange={(e) => updateForm({ period: e.target.value })}
          />
          <label htmlFor="positionIntern" className="form-check-label">Dropped</label>
        </div>   */}
      </div>
      <br/>
      <label htmlFor="position">Notes</label>
      <br/>
      {/* <NotesEdit recipe={form} updateForm={updateForm}></NotesEdit> */}
    </form>
  </div>
);
}

import React, {useContext} from 'react'
import { SiteContext } from '../../../../App'
import { AdminContext } from '../../../../pages/AdminContainer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

export default function Entry({entry, importVariables}) {

  const {colorMatch, focusAgreements, goalQuestions } = useContext(SiteContext)
  const {customArray} = importVariables

  var bulletCountMath = !entry.customPollResponse[customArray.type] ? false : entry.customPollResponse[customArray.type]

  const variants = ['','danger','flag','flag','flag','success']
  var variant = !entry.customPollResponse[customArray.type] ? 'white' : customArray.type === "createAgreement" ? variants[bulletCountMath] : colorMatch[bulletCountMath + 1].variant


  // console.log(variant,'variant', entry.name)

    return (
      <tr className={`${""}`}>
        <td className={``} style={{verticalAlign:"top"}}>
            <div className="box">
               <span className="text">{entry.name}</span>
            </div>
        </td>
        {(customArray.responseType === 'Poll Response' || customArray.responseType === 'Poll Response and Explain') && 
        <td className="" style={{verticalAlign:"top"}}>
            <div className="box">
               {/* <span className="text">{entry.customPollResponse[customArray.type]}</span> */}
               <div className={`badge bg-${variant}`}>{entry.customPollResponse[customArray.type]}</div>
            </div>
        </td>}
        {(customArray.responseType === 'Text Response' || customArray.responseType === 'Poll Response and Explain') && 
        <td className="" style={{verticalAlign:"top"}}>
            <div className="box">
               <span className="text text-break">{entry.customTextResponse[customArray.type]}</span>
            </div>
        </td>}
      </tr>
    )
}

 

import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { SiteContext } from "../../../App";
import { AdminContext } from "../../../pages/AdminContainer";

export default function ActiveModal({event, time, bgBadge, show, endHallPassEvent}) {

 var timeBg = bgBadge === 'secondary' ? 'light' : bgBadge
 var buttonBg = bgBadge === 'secondary' ? 'success' : bgBadge

 function triggerEnd(e) {
  // e.preventDefault()
  console.log('tirggied')
  endHallPassEvent()
}

 function Body() {


    return (
    <>
      <div className={`mb-0 me-0 badge bg-secondary`} style={{margin: "5px",fontSize:"12px", width:"48%"}}>
          Time Needed
          <div className={`badge bg-light`} style={{fontSize:"12px", margin:"3px", marginLeft:"10px"}}>
          {event.time} min
          </div>
      </div>
      <div className={`mb-0 me-0 badge bg-secondary`} style={{margin: "5px",fontSize:"12px", width:"48%"}}>
          Time Elapsed
          <div className={`badge bg-${timeBg}`} style={{fontSize:"12px", margin:"3px", marginLeft:"10px"}}>
          {time}
          </div>
      </div>
    </>
    )
 }
 return (

    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>
          Hall Pass: <span style={{fontWeight:"bold"}}>{event.purpose}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Body/>
      </Modal.Body>
      <Modal.Footer>
      <div className={`badge bg-${buttonBg}`} style={{margin: "5px",fontSize:"12px", width:"97%", padding:"10px"}}
        onClick={e => triggerEnd(e)} role="button"
      >
          End Hall Pass
      </div>
      </Modal.Footer>
    </Modal>
);
}

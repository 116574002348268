import React, {useEffect, useState} from 'react'

export function useModules({getMongoList, student, show}) {

    const [modules, setModules] = useState([])
    const [moduleField, setModuleField] = useState('')

    function findShow(type, parameter) {
        var index = show.findIndex(entry => entry.type === type)
        return parameter ? show[index][parameter] : show[index].show 
    }

    useEffect(() => {

        async function getModules() {
            const items = await getMongoList('modules')
            // console.log('getting modules')
            var modules = items.sort((a, b) => a.fullTitle.localeCompare(b.fullTitle))
            setModules(modules)
        }
        getModules()

    }, [])

    useEffect(() => {
        if(!student.module) return
        // console.log(student,'student----', window.location.href)
        setModuleField(
            (window.location.href.includes('inventoryS') || findShow('dayReview')) ? 
            moduleFind(student.module, 'title', 'fullTitle') : ''
        )
    }, [student && student.module, window.location.href, modules.length])

    function moduleFind(input, inputCategory, searchCategory) {
        if(modules.length === 0) return
        if(input === 'No Group') return 'No Group'
        var index = modules.findIndex(entry => entry[inputCategory] === input)
        if(index === -1) return 
        return modules[index][searchCategory]
    }

    return {
        modules, setModules, moduleFind, moduleField, setModuleField, 
    }
}
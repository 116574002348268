import React, { useState, useEffect, useContext } from 'react';

import ClassCard from './main/ClassCard'
import EditModal from './editClasslist/EditModal';
import { SiteContext } from '../../App';

import { v4 as uuidv4 } from 'uuid'

export default function Classes() {

  const { 
    classInformationArray, socket, colorMatch,
    studentDataFunctions: {cleanFocusAgreement, createMongo}
  } = useContext(SiteContext)

  const [show, setShow] = useState(false)

  var count = 0

  const variants = ['flag','primary','success','purple','info','warning','flag']

  function createNewPeriod() {
    const newPeriod = cleanFocusAgreement({}, true)
    createMongo(newPeriod,'focusAgreements')
  }

  function handleShow(e) {
    e.preventDefault()
    setShow(true)
  }

  function handleClose() {
    setShow(false)
  }

  return (
    <section className="m-3">
      <EditModal show={show} handleClose={handleClose} />
      <div className="card mb-3">
        <div className="card-body d-flex justify-content-between align-items-center">
          <div className="fsize-2 font-bold">Classes</div>
          <div>
            <div className="btn btn-light" onClick={e => handleShow(e)}>Manage Classes</div>
          </div>
        </div>
      </div>
      <div className="row">
        {classInformationArray.map(classPeriod => {
            count++
            // if(classPeriod.archived) return
            // console.log(classPeriod,'classPeriod')
            return <ClassCard key={classPeriod.period + classPeriod._id} variant={variants[count]} classPeriod={classPeriod} />
        })}
        <div>
        {/* <div className="btn btn-black text-white w-100" onClick={createNewPeriod}>+ Add Class</div> */}
        </div>
      </div> 
    </section>  
  )
}

import React, { useContext, useState, useEffect } from 'react'

import { SiteContext } from '../../App'

import CartItems from './CartItems'
import Checkout from './Checkout'

import './chips.css'

export default function Cart({ title, colorValue, sizeClass, cart, setCart }) {

    var total = 0
    cart.map(entry => {
      if(entry.quantity !== 0) {
        total = total + entry.price*entry.quantity
      }
    })

    return (

      <div className={`${sizeClass}`}>
        <div className={`card-shadow-${colorValue} mb-3 widget-chart widget-chart2 text-start card`}>
          <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className={`widget-content-left pe-2 fsize-1 text-${colorValue}`}>
                  <div className="widget-numbers mt-0 fsize-3">
                      {title}
                  </div>
                </div>
              </div>
              <div className="">
                        <div className="fsize-1">
                            <div className="ingredient-grid2 text-muted">
                                <span className="bold">Price</span>
                                <span className="bold">Item</span>
                                {cart.map(entry => {
                                    if(entry.quantity === 0) {return}
                                    return (
                                        <CartItems entry={entry} key={entry.name} colorValue={colorValue} cart={cart} />
                                    )
                                })}
                            </div>
                        </div>
                    </div>  
              </div>
            <div className="widget-content-right fsize-1">
                <div className="d-flex justify-content-between align-items-center">
                  <Checkout total={total} colorValue={colorValue} cart={cart} total={total} setCart={setCart} />
                  {/* <button className={`widget-numbers fsize-1 btn btn-${'light'} text-${colorValue}`} style={{margin:"10px", float:"left", width:"55%", margin:"0"}}>Checkout</button> */}
                  <button className={`widget-numbers fsize-1 btn btn-light text-${colorValue} flex-fill ms-2`}>
                  Total: {total}€
                  </button>
                </div>
            </div>
            <div className={`widget-content-right pe-2 fsize-1 text-${colorValue}`}>

            </div>
          </div>
        </div>
      </div>

    )
}

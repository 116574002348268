import React,{ useState } from 'react'

export function useTime() {

    function checkToday(timeStamp) {
        const checkDate = new Date(timeStamp)
            var dayEntry = checkDate.getDate().toString()
            var monthEntry = (checkDate.getMonth() + 1).toString()
        
        const nowDate = new Date()
            var dayEntryNow = nowDate.getDate().toString()
            var monthEntryNow = (nowDate.getMonth() + 1).toString()
        // console.log(checkToday)
        if(dayEntryNow !== dayEntry || monthEntryNow !== monthEntry) return false
        else return true
    }
    
    function calculateTimeElapsed(timeInitialString, timeAfterMilli, type) {

        // console.log(timeInitialString,'timeInitialString')
    
        var hours = timeInitialString.split(':')[0]
        var min = timeInitialString.split(':')[1]
        hours = parseInt(hours)
        if(hours < 8) {hours = hours + 12}
    
        var timeInitialDate = new Date()
            timeInitialDate.setHours(hours, min, 0)
    
          var timeInitialDateMilli = timeInitialDate.getTime()
                
        var dateAfter = timeAfterMilli ? new Date(timeAfterMilli) : new Date()    
            var dateAfterMilli = dateAfter.getTime()
        
        const time = dateAfterMilli-timeInitialDateMilli
    
        return Math.floor(time / 60000)
        // return ("0" + Math.floor((time / 60000) % 60)).slice(-2)
    }   

    function stringtoJSDate(string) {
        var hours = string.split(':')[0]
        var min = string.split(':')[1]
        hours = parseInt(hours)
        if(hours < 8) {hours = hours + 12}
    
        var timeInitialDate = new Date()
            timeInitialDate.setHours(hours, min, 0)

        return {
            date: timeInitialDate,
            milli: timeInitialDate.getTime()
        }
    }

    function handleDateString(timeStamp) {

        const weekDays = ['Sun','Mon','Tue','Wed','Thu','Fri', 'Sat']
    
        const date = new Date(timeStamp)
            const day = date.getDate()
            const month = date.getMonth() + 1
    
        const weekDayNum = date.getDay()
          const weekDay = weekDays[weekDayNum]
    
        return `${weekDay} ${month}/${day}`
    }

    return {
        checkToday, calculateTimeElapsed, stringtoJSDate, handleDateString
    }
}
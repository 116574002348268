import React, { useContext, useState } from 'react'

import { SiteContext } from '../../App'
import { useTenMinRule } from '../../hallPass/TenMinRule'

export default function HPCard( { message, subtitle, colorValue, cardType, sizeClass }) {

    const { 
        classInformation,
    } = useContext(SiteContext)
    
    const tenMinRule = useTenMinRule()
    if(tenMinRule) { colorValue = 'flag'}

    return (
        <div className={sizeClass}>
          <div className={`mb-3 widget-chart widget-chart2 text-start card card-shadow-${colorValue}`}>
            <div className="widget-content p-0 w-100">
              <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left pe-2 fsize-1">
                    <div id={cardType} className={`widget-numbers mt-0 fsize-3 text-${colorValue}`}>
                        {tenMinRule ? '10 Min' : message}
                    </div>
                  </div>
                </div>
                <div className="widget-content-left fsize-1">
                  <div className="text-muted">{subtitle}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle, faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons';
import { } from '@fortawesome/free-regular-svg-icons';

export default function DataOverlay({hallPasses, convertTimeString}) {
  const reverse = [...hallPasses].reverse()
  var count = 0
  return (
    <table className="align-middle text-truncate mb-0 table table-borderless table-hover" style={{fontSize:"14px"}}>
    <thead>
      <tr>
        <th className="text-center">Date</th>
        <th className="text-center">Purpose</th>
        <th className="text-center">Time</th>
        <th className="text-center">Actual</th>
        <th className="text-center">Diff</th>
        <th className="text-center">Exd?</th>
      </tr>
    </thead>
    <tbody>
      {reverse.map(item => {
        count++
        if(count > 10) return
        return <EventEntry key={item.timeStamp} entry={item} convertTimeString={convertTimeString} />
      })}
    </tbody>
   </table>
  )
}

function EventEntry({entry, convertTimeString}) {
  const date = new Date(entry.timeStamp);
  const dateString = date.toLocaleDateString('en-US')
  var milliNow = Date.now()
  var fortNight = 12096e5/2
  var variant = entry.timeStamp > milliNow-fortNight ? 'bg-black text-white' : entry.timeStamp > milliNow-2*fortNight ? 'bg-secondary text-white' : entry.timeStamp > milliNow-4*fortNight ? 'bg-light' : ''

  var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']  
  var weekDay = date.getDay()

  if(!entry.timeComplete) return
  var completeCalc = parseInt(entry.timeComplete.split(':')[0]) + entry.timeComplete.split(':')[1]/60
  var difference = completeCalc - entry.time
  const string = convertTimeString(completeCalc - entry.time, 1)

  return (
    <tr className={`${variant}`}>
      <td className="text-center"> 
          {days[weekDay]} {dateString.substring(0, dateString.lastIndexOf("/"))}
      </td>
      <td className="text-center"> 
        {entry.purpose}
      </td>
      <td className="text-center">
        {entry.time}
      </td>
      <td className="text-center"> 
        {entry.timeComplete}
      </td>
      <td className="text-center"> 
        <div className={`mb-2 me-2 badge bg-${difference > 2 ? 'danger': difference > 0 ? 'flag' : (difference <= 0 && difference > -1) ? 'light' : 'success'}`}>{string}</div>
      </td>
      <td className="text-center"> 
        {entry.excused === 'excused' && <FontAwesomeIcon className="me-1" icon={faCircle} />}
        {entry.excused === 'partial' && <FontAwesomeIcon className="me-1" icon={faCircleHalfStroke} />}
      </td>
  </tr>
  )
}

import React from 'react'
import '../../assets/base.scss'
// import '../style.css'

// import Dropdown from './Dropdown'

export default function CardLoggedIn ( { percent, subtitle, colorValue, sizeClass}) {

    var percentInt = parseInt(percent)

    return (
        <div className={sizeClass}>
          <div className={`mb-3 widget-chart widget-chart2 text-start card ${colorValue}`}>
            <div className="widget-content p-0 w-100">
              <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left pe-2 fsize-1">
                    <div className={`widget-numbers mt-0 fsize-3 text-${colorValue}`}>{percent}</div>
                  </div>
                  <div className="widget-content-right w-100">
                    <div className="progress-bar-xs progress">
                      <div
                        className={`progress-bar bg-${colorValue}`}
                        role="progressbar"
                        aria-valuenow={percentInt}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: percent }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="widget-content-left fsize-1">
                  <div className="text-muted">
                     {subtitle}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}
import React, { useState, useContext, useEffect } from "react";

import { SiteContext, baseUrl } from "../../App";
import { StudentContext } from "../../pages/StudentContainer";

import './s_behavior.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowUp, faCircleArrowDown, faStar, faThumbsDown, fa1 } from '@fortawesome/free-solid-svg-icons';

export default function S_BehaviorForm({name, newColor, color, icon, formType, nominateType, importVariables}) {

    const { 
        classInformation, focusAgreements, student, colorMatch, loginState,
        studentDataFunctions: {
            setStudent, levelChangeCalc, createMongo, getMongoById, 
        }
    } = useContext(SiteContext)

    const {colors, setColors} = importVariables

    const {namesList, handleShow, setNominatedName} = useContext(StudentContext)

    const [activeAgreement, setActiveAgreement] = useState('')
    const currentColor = student.color.current

    const match = colorMatch.filter(item => item.color === currentColor)
      const behaviorLevel = match[0].level
      const behaviorString = match[0].string
      const behaviorVariant = match[0].variant
      // const behaviorVariant = match[0].variant

    var focusCategory = false
    focusAgreements.categories && focusAgreements.categories.map(entry => {
      if(!entry.adminFocus) return
      focusCategory = entry.category
    })

    const [form, setForm] = useState(updateBlankForm(formType));

    useEffect(() => {
      setForm(updateBlankForm(formType))
    }, [student])

    useEffect(() => {
      const editForm = {...form}
      editForm.category = focusAgreements.focusCategory
      setForm(editForm)

    }, [focusAgreements.focusCategory])

    // useEffect(() => {

    //   setForm(editForm)
    //   const editColors = {...colors, currentColor: editForm.currentColor, pendingColor: editForm.pendingColor}
    //   // setColors(editColors)

    // }, [student])

    const matchPending = colorMatch.filter(item => item.color === form.pendingColor)
      var behaviorStringPending = ''
      if(matchPending.length > 0) {
        behaviorStringPending = `New Color: ${matchPending[0].string}`
      }

    // These methods will update the state properties.
    function updateForm(value) {
      const editForm = {...form}
      if ('pendingColor' in value || 'currentColor' in value) {
        editForm.levelChange = levelChangeCalc(form.currentColor, value.pendingColor)
      }
      setForm({...editForm, ...value})
    }
      
    const newForm = updateBlankForm(formType)
    
    // This function will handle the submission.
    async function onSubmit(e, setStudent, alphaNames) {
      
      e.preventDefault();

      // When a post request is sent to the create url, we'll add a new record to the database.
      var newBehaviorEvent = { 
          ...form, timeStamp: Date.now()
      };

      if(formType === 'nominate') {

        if(form.studentNominatedName === 'Mr. Smith') {
          newBehaviorEvent = {
            ...newBehaviorEvent,
            pendingColor: '',
            studentNominatedId: '1',
            nominateType: nominateType,
          }
        } else {
          newBehaviorEvent = {
            ...newBehaviorEvent,
            pendingColor: '',
            nominateType: nominateType,
          }
        }
      }
      
      if(currentColor === form.pendingColor || form.pendingColor === '') {
        if(form.type !== 'nominate') return
      }
      if(form.category === 'category' || form.pendingColor === 'choose' || (form.reason === 'reason' || form.reason.length === 0)) return

      if(form.type === 'self') {
        const editStudent = {...student}
        editStudent.color = {
          ...editStudent.color,
          pending: form.pendingColor,
          category: form.category,
          reason: form.reason
        } 

        setStudent(editStudent)
      }

      setForm({ 
          ...form, ...newForm,
          // category: focusCategory ? focusCategory : '',
          // reason: '',
          // // pendingColor: 'Purple',
          // timeStamp: '',
          // studentNominatedName: '',
          // studentNominatedId: '',
          // levelChange: formType === 'raise' ? 1 : formType === 'lower' ? -1 : 0
      });
      
      document.querySelector('#' + e.target.id + 'Color').click()

      if(form.type !== 'self' && form.studentNominatedName !== 'Mr. Smith' && loginState && !loginState.admin) {
        const nomineeMongo = await getMongoById(form.studentNominatedId, 'dailyList')
        if(nomineeMongo.color.current === 'Black') {
          setNominatedName(nomineeMongo.firstName)
          handleShow(undefined, 'shoutout')
          return
        }
      }

      createMongo(newBehaviorEvent, 'behaviorEvents')
    }

    function handleUpdateReasonWithChange(value) {
        const match = classInformation.agreements.filter(agreement => agreement.category === value)[0]
        // setCategoryChosen(match)
        const editForm = {...form}
        editForm.category = match
        setForm(editForm)
    }

    function updateBlankForm(formType) {
    
      const match = colorMatch.filter(item => item.color === student.color.current)
      const behaviorLevel = match[0].level
      const behaviorString = match[0].string
    
      var focusCategory = false
      focusAgreements.categories && focusAgreements.categories.map(entry => {
        if(!entry.adminFocus) return
        focusCategory = entry.category
      })
    
      var raise1index = behaviorLevel === 9 ? colorMatch.findIndex(entry => entry.level === behaviorLevel) : colorMatch.findIndex(entry => entry.level === behaviorLevel + 1)
        const raise1 = colorMatch[raise1index]
      var lower1index = behaviorLevel === 1 ? colorMatch.findIndex(entry => entry.level === behaviorLevel) : colorMatch.findIndex(entry => entry.level === behaviorLevel - 1)
        const lower1 = colorMatch[lower1index]
    
      function choosePendingColor() {
        var pendingColor = formType === 'raise' ? raise1.color : formType === 'lower' ? lower1.color : ''
        if(pendingColor === 'Black') return ''
        return pendingColor
      }
      return {
        activelyPending: true,
        studentInitiatingName: student.name,
        studentInitiatingId: student.studentId,
        result: '',
        type: formType === 'raise' || formType === 'lower' ? "self" : formType,
        studentNominatedName: '',
        studentNominatedId: '',
        category: focusAgreements.focusCategory ? focusAgreements.focusCategory : '',
        reason: '',
        // pendingColor: '',
        pendingColor: choosePendingColor(),
        currentColor: student.color.current,
        timeStamp: '',
        period: student.period,
        levelChange: formType === 'raise' ? 1 : formType === 'lower' ? -1 : 0,
      }
    }

    var categoryChosen = classInformation.agreements.filter(agreement => agreement.category === form.category)[0]

    var changeEmoji = ''
    var different = false
    // if(form.levelChange > -6 && form.levelChange < 6) {
    //   if(formType === 'raise' && currentColor === 'Gold') {
    //     changeEmoji = 'Get a nomination to get to  ⬛  Black!'
    //     different = true
    //   }
    //   else if(formType === 'raise' && currentColor === 'Black') {
    //     changeEmoji = 'Congrats!'
    //     different = true
    //   } 
    //   else {
    //     changeEmoji = levelChangeMatch.filter(entry => entry.change === form.levelChange)[0].emoji
    //   }
    // }

    const alphaNames = namesList.sort((a, b) => a.name.localeCompare(b.name))

    return (

    <div className="mt-3">
        <form onSubmit={(e) => onSubmit(e, setStudent, alphaNames)} id={color} className={color}>
            <div className={`position-relative mb-3 ${name}`}>
                <label htmlFor="exampleSelect" className="form-label">
                <span className='translate fw-bold'>Name</span>
                </label>
                <select
                  name="name"
                  id="name"
                  className="form-select form-control"
                  // defvalue={form.studentNominatedName}
                  value={form.studentNominatedId}
                  onChange={(e) => {
                    e.preventDefault()
                    if(e.target.value === 'select') return
                    if(e.target.value === 'Mr. Smith') { 
                      var nominatedStudent = "Mr. Smith"
                      updateForm({ studentNominatedName: "Mr. Smith", studentNominatedId: '123456' })
                    } 
                    else {
                      var nominatedStudent = alphaNames.filter(student => student.studentId === e.target.value)[0]
                      // console.log(e.target.value,'e.target.value----', nominatedStudent)
                      updateForm({ studentNominatedName: nominatedStudent.name, studentNominatedId: nominatedStudent.studentId })
                    }
                    
                  }}
                >
                    <option value={'select'}>-- Select Student --</option>
                    { alphaNames.map((entry) => {
                      // console.log(entry.attendance,'attednace', entry)
                        if(entry.studentId === student.studentId || entry.period !== student.period || entry.attendance === 'Absent') return
                        return <option key={entry.studentId} value={entry.studentId}>{entry.name}</option>
                    })} 
                    <option>Mr. Smith</option>
                </select>
            </div>            
            {!different && <div className="position-relative mb-3">
                <label htmlFor="exampleSelect" className="form-label fw-bold">
                Category and Agreement
                </label>
                <div className="d-flex">
                  <div className="w-50 me-3">
                  <select name="category" role="button" id="categorySelect" className="form-select form-control d-inline p-2" value={form.category}
                    onChange={(e) => {
                      handleUpdateReasonWithChange(e.target.value)
                      updateForm({ category: e.target.value })
                    }}
                  >
                      <option value='category'>-- Select Category --</option>
                      {classInformation.agreements.map((agreement) => {
                        const match2 = colorMatch.filter(item5 => item5.color === student.color.current)[0]
                        if(agreement.category === 'Class Entry' && match2.level > 6) return
                          return <option key={agreement.id} value={agreement.category}>{agreement.category}</option>
                      })} 
                  </select>
                  </div>
                  <div className="w-50">
                  <select name="reason" role="button" id="reasonSelect"className="form-select form-controld-inline p-2"
                    value={form.reason} onChange={(e) => updateForm({ reason: e.target.value })}>
                      {categoryChosen && <option value='reason'>-- Select Reason --</option>}
                      {categoryChosen && categoryChosen.elements.map((element) => {
                          if(element.adminStatus) return
                          return <option key={element.id} value={element.entry}>{element.entry}</option>
                      })}
                  </select>
                  </div>
                </div>
            </div>}
            {/* {formType !== 'nominate' &&  */}
            {formType !== 'nominate' && (student.color.current === 'Black' && formType === 'raise') ? <OtherMessage message={`Congrats on reaching ⬛ Black! Is there anyone you could Shout Out?`} />: 
            (student.color.current === 'Gold' && formType === 'raise') ? <OtherMessage message={`To reach ⬛ Black, get a Shout Out from someone!`} /> : 
            (student.color.current === 'White' && formType === 'lower') ? <></> 
            : matchPending.length > 0 && <div className="d-flex justify-content-between align-items-center ms-1 me-1 mb-2">
              <div>
                <div className="fw-bold">Current Color:</div>
                <div className="d-flex">
                <div className={`card bg-${behaviorVariant} flex-fill border-0 shadow-none m-1 ms-0`} style={{minHeight:`15px`, maxWidth:"30px"}} />
                <span>{match[0].color}</span>
                </div>
              </div>
              <span>
                <FontAwesomeIcon className={`text-${color}`}icon={formType === 'raise' ? faCircleArrowUp : faCircleArrowDown} />
                <span className={`text-${color} fw-bold ms-1`}>1 Color</span>
              </span>
              <div>
                <div className="fw-bold">Pending Color:</div>
                <div className="d-flex justify-content-end">
                  <><div className={`card bg-${matchPending[0].variant} flex-fill border-0 shadow-none m-1 ms-0`} style={{minHeight:`15px`, maxWidth:"30px"}} />
                  <span>{matchPending[0].color}</span></>
                </div>
              </div>
            </div>}
            {(formType === 'raise' && (student.color.current === 'Black' || student.color.current === 'Gold') || (formType === 'lower' && student.color.current === 'White')) && formType !== 'nominate' ? <></> : <button type="submit" name={color} className={`mt-1 btn btn-${color} w-100 mt-3`}>
                {/* <FontAwesomeIcon style={{paddingRight: '5px' }} icon={icon} /> */}
                Submit
            </button>}
        </form>
    </div>
    )
}

function OtherMessage({message}) {
  return <div className="mt-4 mb-2">{message}</div>
}

import React, {useState} from 'react'

import AgreementModal from './AgreementModal'

export default function AgreementString({handleChange, string}) {

    const [norm, setNorm] = useState('')

    return (
        <div className="col-12">
            <div className="main-card mb-3 card">
                <div className="card-body">
                    <div className="title-container">
                      <div className="" style={{paddingBottom:"10px"}}>
                        <h1 style={{margin:"0px"}}>
                            <span >Proposed Agreement: </span> &nbsp; &nbsp;
                            <AgreementModal title={'Edit'} handleChange={handleChange} string={string} />
                        </h1>
                      </div>
                      <div className="">
                        <h1 style={{fontWeight:'bold'}}>{string}</h1>
                      </div>
                      <div style={{display:"block"}}>
                        
                      </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useContext, useState, useEffect } from 'react'
import { SiteContext } from '../../App'

export function useModal({setShow, setUpdate}) {

    const {
        socket, room, studentList, focusAgreements,
        studentDataFunctions: {
            updateMongo, setStudentList
        }
    } = useContext(SiteContext)

    const [mode, setMode] = useState(chooseMode())

    useEffect(() => {
        setMode(chooseMode(mode))
    }, [focusAgreements.goalSetting, focusAgreements.dayReview, focusAgreements.customModal])

    // useEffect(() => {
    //     setMode(prev => {return chooseMode(mode, false, prev)})
    // }, [focusAgreements && focusAgreements.dayReview, focusAgreements && focusAgreements.goalSetting, focusAgreements && focusAgreements.customModal])

    function chooseMode(modePrev) {
        if(focusAgreements && focusAgreements.goalSetting) return 'goalSetting'
        if(focusAgreements && focusAgreements.dayReview) return 'dayReview'
        if(focusAgreements && focusAgreements.customModal) return 'customModal'
        // if(focusAgreements && !focusAgreements.dayReview && !focusAgreements.goalSetting && modePrev) return context === 'dGoalExit' ? 'none' : 'goalSetting'
        // console.log(prev,'prev')
        if(modePrev) return modePrev
        return 'goalSetting'
    }

    const handleClose = (type, e) => {
      if(e) e.preventDefault()

      console.log('close', type, e)
      setShow(prev => {
          var index = prev.findIndex(entry => entry.type === type)
          prev[index].show = false
          return prev
      })
      setUpdate(prev => {return prev + 1})
  }

  const handleShow = (e, type, payload) => {
      if(e) e.preventDefault()

      setShow(prev => {
          var index = prev.findIndex(entry => entry.type === type)
          prev[index].show = true
          if(payload) { prev[index].payload = payload }
          return prev
      })
      setUpdate(prev => {return prev + 1})
  }

    function handleOverride(e, entry, outcome) {
        e.preventDefault()
        // console.log(entry,'entry----')
        updateMongo({modalOverride: outcome}, entry.dailyListId, 'dailyList')
        const editStudentList = [...studentList]
        var index = editStudentList.findIndex(item => item.studentId === entry.studentId)
        if(index === -1) return
        editStudentList[index].modalOverride = outcome
        socket.emit("send_modalOverride", { data: {studentId: entry.studentId, modalOverride: outcome}, room });
        setStudentList(editStudentList)
        // socket.emit("send_studentListUpdate", { studentList: editStudentList, room: 'admin' });
        socket.emit("send_updateSingleStudent", { student: {...editStudentList[index]}, room });
    }

    async function handleResetModal(type, studentEntry, mode) {
    
        const clearGoal = {
          display: 1,
          timeStamp: false,
          goal: { goal: false, matters: false, thing1: false, thing2: false, thing3: false, accomplish: false },
          review: {review: false, reviewComplete: false}
        }

        const reopenGoal = {
            display: 1,
            review: {review: false, reviewComplete: false}
        }

        const clearExitTicket = {
            display: 1,
            timeStamp: false,
            rating: { self: false, group: false, class: false},
            questions: {success: false, improve: false},
            module: { progress: false }
        }

        const reopenExitTicket = {
            display: 1,
            timeStamp: false,
        }

        if(mode === 'reset') {
            var payloadChange = type === 'goalSetting' ? clearGoal : clearExitTicket
        } else if(mode === 'reopen') {
            var payloadChange = type === 'goalSetting' ? reopenGoal : reopenExitTicket
        }        

        const payload = {...studentEntry[type], ...payloadChange}

        console.log(payload,'payload----')

        await updateMongo({[type]: payload}, studentEntry.dailyListId, 'dailyList')
        const editStudentList = [...studentList]
        var index = editStudentList.findIndex(item => item.studentId === studentEntry.studentId)
        if(index === -1) return
        editStudentList[index][type] = {...payload}
        // editStudentList[index][type] = {...editStudentList[index][type], ...payload}
        setStudentList(editStudentList)
        socket.emit("send_studentListUpdate", { studentList: editStudentList, room: 'admin' });
        socket.emit("send_modalOverride", { data: {studentId: studentEntry.studentId, payload, type}, room });
      }

    return {
      handleOverride, handleClose, handleShow, handleResetModal, mode, setMode, chooseMode
    }
}

import React from 'react'
import { baseUrl } from '../../App';

export function useMongoFunctions() {

    return {
        createMongo, getMongoList, getMongoListPeriod, getMongoByUniqueId, getMongoById, getMongoAllById, updateMongo, deleteMongo, getMongoAllByParam
    }
}

async function createMongo(studentObj, collection) {

    if(studentObj['_id']) {
       delete studentObj['_id']
    }

    await fetch(`${baseUrl}/create/${collection}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(studentObj),
    })
    .catch(error => {
      var message = error + 'create new entry ' + collection
      // window.alert(error + 'create new entry ' + collection);
      console.log(message)
      return;
    });

}

async function getMongoList(collection) {
    const response = await fetch(`${baseUrl}/readAll/${collection}`);

    if (!response.ok) {
        const message = `An error occurred ${collection}: ${response.statusText}`;
        // window.alert(message);
        console.log(message)
        return;
    }

    const obj = await response.json()    
    return obj
}

async function getMongoById(studentId, collection) {
    const response = await fetch(`${baseUrl}/readOne/${collection}/studentId/${studentId}`);

    if (!response.ok) {
      const message = `An error has occurred ${collection}: ${response.statusText}`;
      // window.alert(message);
      console.log(message)
      return;
    }

    const obj = await response.json();
    return obj
}

async function getMongoByUniqueId(id, collection) {
  // console.log(id, collection,'id, collection')
  const response = await fetch(`${baseUrl}/readOne/${collection}/${id}`);

  if (!response.ok) {
    const message = `An error has occurred ${collection}: ${response.statusText}`;
    // window.alert(message);
    console.log(message)
    return;
  }

  const obj = await response.json();
  return obj
}

async function getMongoAllById(studentId, collection) {
  const response = await fetch(`${baseUrl}/readAll/${collection}/studentId/${studentId}`);

  if (!response.ok) {
    const message = `An error has occurred ${collection}: ${response.statusText}`;
    // window.alert(message);
    console.log(message)
    return;
  }

  const obj = await response.json();
  return obj
}


async function getMongoAllByParam(param, collection, paramCat) {
  const response = await fetch(`${baseUrl}/readAll/${collection}/${paramCat}/${param}`);

  if (!response.ok) {
    const message = `An error has occurred ${collection}: ${response.statusText}`;
    // window.alert(message);
    console.log(message)
    return;
  }

  const obj = await response.json();
  return obj
}

async function getMongoListPeriod(collection, period) {
  const response = await fetch(`${baseUrl}/readAllPeriod/${collection}/${period}`);

  if (!response.ok) {
      const message = `An error occurred ${collection}: ${response.statusText}`;
      // window.alert(message);
      console.log(message)
      return;
  }

  const obj = await response.json()    
  return obj
}

async function updateMongo(editedObj, id, collection) {
         
    //This will send a post request to update the data in the database.
    await fetch(`${baseUrl}/update/${collection}/${id}`, {
      method: "POST",
      body: JSON.stringify(editedObj),
      headers: {
        'Content-Type': 'application/json'
      },
    });
}

async function deleteMongo(id, collection) {
    // console.log(id,'id')
    await fetch(`${baseUrl}/delete/${collection}/${id}`, {
        method: "DELETE"
    });
}
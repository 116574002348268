import React, {useContext, useState, useEffect} from 'react'
import { SiteContext } from '../../../App'

import Item from './Item'
import '../checklist.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLeft, faCheck, faCircleCheck, faCircleRight } from '@fortawesome/free-solid-svg-icons';

export default function Checklist({title}) {

    const {student, focusAgreements, socket, room, classInformation,
        studentDataFunctions: {setFocusAgreements, updateMongo, getMongoByUniqueId, getMongoList}
    } = useContext(SiteContext)

    // const [checklist, setChecklist] = useState(checklistArr)

    const [showTasks, setShowTasks] = useState(false)
    const [timer, setTimer] = useState(false)
    const [submitItem, setSubmitItem] = useState(false)

    useEffect(() => {

        async function updateAgreements() {
            const updated = await getMongoByUniqueId(classInformation.focusAgreementId, 'focusAgreements')
            console.log('on load - updated')
            setFocusAgreements(updated)
        }

        updateAgreements()
        
    }, [])

    async function updateChecklist(e, itemId, entryStatus, property, propValue) {
        if(e) e.preventDefault()

        const nowFocusAgreements = 
        // timer && (Date.now() - timer < 2000) ? focusAgreements : 
        focusAgreements
        // await getMongoByUniqueId(classInformation.focusAgreementId, 'focusAgreements')

        var editEntry = {...nowFocusAgreements}

        var index = editEntry.modules.findIndex(item => item.title === student.module)
            if(index === -1) return
        var index1 = editEntry.modules[index].checklists.findIndex(item => item.groupNumber === student.groupNumber)
            if(index1 === -1) return
        var index2 = editEntry.modules[index].checklists[index1].checklist.findIndex(item => item.itemId === itemId)
            if(index2 === -1) return

        var currentEntry = editEntry.modules[index].checklists[index1].checklist[index2]
        if(currentEntry.completeAdmin) return
        if(currentEntry.excused) return

        const records = await getMongoList('records')
        const matchingStudents = []

        records.map(entry => {
            
            if(entry.module !== student.module || entry.groupNumber !== student.groupNumber || entry.period !== student.period) return
            matchingStudents.push(entry.studentId)

        })

        var completeEvent = {
            ...currentEntry.completeEvent,
            date: Date.now(),
            name: student.name,
            studentId: student.studentId,
            period: student.period,
            groupNumber: student.groupNumber,
            matchingStudents,
        }

        // console.log(entryStatus,'entryStatus')
        if(entryStatus === 'completeEvent') {
            if(property === 'url') {
                currentEntry.completeEvent.url = 
                    currentEntry.completeEvent && propValue === 'delete' ? [] :
                    currentEntry.completeEvent && 
                    currentEntry.completeEvent.url && 
                    typeof currentEntry.completeEvent.url === 'string' ? [currentEntry.completeEvent.url, propValue] : 
                    currentEntry.completeEvent && 
                    currentEntry.completeEvent.url && 
                    currentEntry.completeEvent.url.length > 4 ? [...currentEntry.completeEvent.url] : 
                    currentEntry.completeEvent && 
                    currentEntry.completeEvent.url && 
                    currentEntry.completeEvent.url.length > 0 ? [...currentEntry.completeEvent.url, propValue] : [propValue]
            } else {
                currentEntry.completeEvent = currentEntry.completeEvent ? {...currentEntry.completeEvent, [property]: propValue} : {[property]: propValue}
            }
        } else if(entryStatus === 'active') {
            editEntry.modules[index].checklists[index1].checklist.map(entry => {
                entry.inProgress = false
            })
            currentEntry.inProgress = true
            currentEntry.complete = false
        } else if(entryStatus === 'inProgress') {
            currentEntry.inProgress = false
            currentEntry.complete = true
            currentEntry.completeEvent = {...completeEvent}
        } else if(entryStatus === 'pending') { 
            currentEntry.complete = false
            // currentEntry.completeEvent = false
            currentEntry.inProgress = false
        }

        console.log(editEntry,'editEntry', currentEntry)
        setFocusAgreements(editEntry)
        setTimer(Date.now())
        
        updateMongo({modules: [...editEntry.modules]}, focusAgreements._id, 'focusAgreements')
        // console.log(currentEntry,'currentEntry')

        var index = editEntry.modules.findIndex(item => item.title === student.module)
        if(index === -1) return
        socket.emit("send_updateProgressChecklist", { oneModule: {...editEntry.modules[index]}, room });
    }

    if(!focusAgreements || !focusAgreements.modules) return

    // console.log(student.module,'student.module', student)

    var index = focusAgreements.modules.findIndex(item => item.title === student.module)
        if(index === -1) return

        // console.log(focusAgreements.modules[index].checklists,'focusAgreements.modules[index].checklists')
    var index1 = focusAgreements.modules[index].checklists.findIndex(item => item.groupNumber === student.groupNumber)
        if(index1 === -1) return

    // console.log(student.module,'student.module')
    // var index2 = editEntry.modules[index].checklists[index1].checklist.findIndex(item => item.itemId === itemId)

    // var index = focusAgreements.modules.findIndex(entry => entry.title === student.module)
    // if(index === -1) return

    // var groupIndex = focusAgreements.modules[index].checklists.findIndex(entry => entry.groupNumber === student.groupNumber ? student.groupNumber : 1)
    // if(groupIndex === -1) return

    var checklist = focusAgreements.modules[index].checklists[index1].checklist
    checklist.sort( compare );
    // focusAgreements.modules[index].checklists[groupIndex].checklist

    // console.log(checklist,'checklist')

    var count = 0
    var designBriefCount = false
    var taskCount = 0

    var complete = 0
    var pending = 0
    var excused = 0
    var taskCountNum = 0
    var checklistLength = 0

    checklist.map(entry => {
        if(entry.type === 'Test') return
        if(entry.complete && entry.completeAdmin) {
            complete++
        }
        else if(entry.complete && !entry.completeAdmin) {
            pending++
        }
        if(entry.excused) {
            excused++
        }
        if(entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview') {
            taskCountNum++
        }
        if(entry.designBriefTask !== 'Overview') {
            checklistLength++
        }
    })

    const module = focusAgreements.modules[index]
    // console.log(focusAgreements.modules[index],'focusAgreements.modules[index].checklists[index1]')
    var percentComplete = (complete-excused)/(checklistLength-excused)*100
    var percentPending = (pending)/(checklistLength-excused)*100

    var percentCombined = percentComplete + percentPending

    var percentCompleteInt = parseInt(percentComplete)
    var percentPendingInt = parseInt(percentPending)
    var percentCombinedInt = parseInt(percentCombined)

    var mathComplete = percentCombinedInt > 98.9 ? 100 : percentCombinedInt

    const handleFunctions = {
        updateChecklist, showTasks, setShowTasks, taskCountNum, module, submitItem, setSubmitItem
    }

    if(!focusAgreements || !focusAgreements.modules) return

    const moduleCompletions = []
    // const pendingCompletions

    var index = focusAgreements.modules.findIndex(item => item.title === student.module)
        if(index === -1) return
    var index1 = focusAgreements.modules[index].checklists.findIndex(item => item.groupNumber === student.groupNumber)
        if(index1 === -1) return
    // var index2 = editEntry.modules[index].checklists[index1].checklist.findIndex(item => item.itemId === itemId)

    // var index = focusAgreements.modules.findIndex(entry => entry.title === student.module)
    // if(index === -1) return

    // var groupIndex = focusAgreements.modules[index].checklists.findIndex(entry => entry.groupNumber === student.groupNumber ? student.groupNumber : 1)
    // if(groupIndex === -1) return

    var checklist = focusAgreements.modules[index].checklists[index1].checklist

    checklist.map(entry => {
      if(!entry.completeAdmin) return
      moduleCompletions.push(entry)
    })
   
    const finalCompletions = moduleCompletions.filter(entry => entry.completeEvent && entry.completeEvent.date > getMonday(Date.now()))
    const pendingCompletions = checklist.filter(entry => entry.complete && entry.completeEvent && !entry.completeAdmin && entry.completeEvent.date > getMonday(Date.now()))
    // console.log(moduleCompletions,'moduleCompletions')

    function getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

      const newDate = new Date(d.setDate(diff));
      newDate.setMinutes(0)
      newDate.setHours(0)
      newDate.setSeconds(0)

      return newDate.getTime()
    }

    return (
    <div className="">
        <div className="col-12 d-flex">
        <div className="card flex-fill me-3">
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline">
                    <h1 className={`text-${mathComplete === 100 && percentPending === 0 ? 'success' : 'secondary'}`}>{title ? title : 'Progress Checklist'}</h1>
                    <span>
                        <h2 className={`text-secondary m-0 d-inline`}>
                        {mathComplete < 100 ? <><span className="me-3">{mathComplete}%</span><span className="ms-1">({complete+pending-excused}/{checklistLength-excused})</span></> :
                        <span className={`text-${percentPending === 0 ? 'success' : 'success'} d-flex align-items-center`} style={{fontSize: "2rem"}}>
                            {mathComplete}%
                            <FontAwesomeIcon className={`m-0 ms-1 card-title ${percentPending === 0 ? 'text-success' : 'text-success'}`} style={{fontSize: "1.5rem"}} icon={faCircleCheck} />
                        </span> 
                        }
                        </h2>  
                    </span>
                </div>
                <div className="progress-bar-xs progress mb-1 mt-1">
                    <div
                    className={`progress-bar bg-success`} 
                    role="progressbar" style={{ width: percentComplete+'%'}}
                    aria-valuemin={0} aria-valuemax={100}
                    />
                    <div
                    className={`progress-bar pending stripes`} 
                    role="progressbar" style={{ width: percentPending+'%'}}
                    aria-valuemin={0} aria-valuemax={100}
                    />
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                    <p className={`text-secondary m-0 d-inline`}>Activities This Week</p>
                </div>
                <h1 className="text-secondary d-flex align-items-center" style={{fontSize: "2rem"}}>
                        <span className="text-success">{finalCompletions.length}</span>
                        <FontAwesomeIcon className={`m-0 ms-2 card-title text-success`} style={{fontSize: "1.5rem"}} icon={faCircleCheck} /> 
                </h1>
                {pendingCompletions && pendingCompletions.length > 0 && <span className="text-secondary">+{pendingCompletions && pendingCompletions.length} Marked Complete</span>}
            </div>
        </div>
        </div>
 
        {checklist.map(entry => {
            if(entry.type === 'Test') return
            if(entry.type === 'Design Brief' && entry.designBriefTask === 'Overview') {
                count++
                designBriefCount = count
            } else if(entry.type === 'Design Brief' && entry.designBriefTask !== 'Overview') {
                taskCount++
            } else {
                count++
            }
            return <Item key={entry.itemId} entry={entry} count={count} handleFunctions={handleFunctions} />
        })}
    </div>
    )
}

// {/* <div className="col-12 d-flex mt-2">
// <div className="card flex-fill">
//     <div className="card-body p-1 d-flex align-items-center">
//         <div className={`badge bg-light d-flex align-items-center m-1 flex-fill text-secondary`} role={'button'} style={{height:"20px"}}><span className="w-100">To Be Completed</span></div>
//         <FontAwesomeIcon className={`m-1 card-title text-secondary`} style={{fontSize: "1rem"}} icon={faCircleRight} />
//         <div className={`badge bg-secondary d-flex align-items-center m-1 flex-fill`} role={'button'} style={{height:"20px"}}><span className="w-100">In Progress</span></div>
//         {/* <FontAwesomeIcon className={`m-1 card-title text-secondary`} style={{fontSize: "1rem"}} icon={faCircleRight} />
//         <div className={`badge text-white pending stripes d-flex align-items-center m-1 flex-fill`} role={'button'} style={{height:"20px"}}><span className="w-100">Marked Complete</span></div> */}
//         <FontAwesomeIcon className={`m-1 card-title text-secondary`} style={{fontSize: "1rem"}} icon={faCircleRight} />
//         <div className={`badge bg-success d-flex align-items-center m-1 flex-fill`} role={'button'} style={{height:"20px"}}><span className="w-100">Complete</span></div>
//     </div>
// </div>
// </div> */}


    // useEffect(() => {
    //     let interval;

    //     interval = setInterval(async () => {
    //         console.log(timer,'timer', Date.now() - timer)
    //         if(!timer || Date.now() - timer < 2000) return

    //         updateMongo({modules: [...focusAgreements.modules]}, focusAgreements._id, 'focusAgreements')

    //         var index = focusAgreements.modules.findIndex(item => item.title === student.module)
    //         if(index === -1) return
    //         socket.emit("send_updateProgressChecklist", { oneModule: {...focusAgreements.modules[index]}, room });
    //         console.log('updated')

    //         setTimer(false)

    //     }, 1000);

    //     return () => clearInterval(interval);

    // }, [timer])

    function compare( a, b ) {
        if ( a.index < b.index ){
          return -1;
        }
        if ( a.index > b.index ){
          return 1;
        }
        return 0;
    }
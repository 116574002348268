import React, { useContext, useState } from 'react'
import { SiteContext } from '../App'
import { ErrorBoundary } from '../other/utils/ErrorBoundary';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faFloppyDisk, faX } from '@fortawesome/free-solid-svg-icons';

export default function Profile({valuesOnly}) {

    const {profileElements} = useContext(SiteContext)

    const elements = profileElements

    if(valuesOnly) {
        return (
            <PropertyCard sizeClass="col-12" entry={elements[0]} />
        )
    }
    
    return (
        <div className="d-grid">
            <div className="row">
                <MainCard sizeClass="col-12 col-md-6" />
                <div className="col-12 col-md-6">
                    {elements.map(entry => {
                        return <PropertyCard key={entry.property} sizeClass="col-12" entry={entry} />
                    })}
                </div>
            </div>
        </div>
    )
}

function MainCard({sizeClass}) {

    const {student, classInformation} = useContext(SiteContext)

    const elements = [
        {property: 'Email', keyValue: student.email},
        {property: 'Student ID', keyValue: student.studentId},
        {property: 'Birthday', keyValue: student.birthday},
        {property: 'PowerSchool Username', keyValue: student.psUsername},
        {property: 'PowerSchool Password', keyValue: student.psPassword},
    ]

    if(classInformation.informationStrings.gcCode) {
        elements.push({property: 'Google Classroom Code', keyValue: classInformation.informationStrings.gcCode},)
    }

    // console.log(classInformation, 'classInformation')

    // console.log(student,'student prodlile')
    return (
        <div className={`${sizeClass}`}>
          <div className={`card m-1`}>
            <div className="card-header">{student.name}</div>
            <div className="card-body">
                {elements.map(entry => {
                    if(!entry.keyValue) return
                    return <CardData key={entry.property} property={entry.property} keyValue={entry.keyValue} stopDelete={true} />
                })}
            </div>
          </div>
        </div>
    )
}

function PropertyCard({entry, sizeClass}) {

    const {student, room, socket, studentDataFunctions: {updateMongo, setStudent}} = useContext(SiteContext)

    async function resetProperty(e) {
        if(e) e.preventDefault()

        const editStudent = {...student}
        const newArray = {[entry.property]: []}
        editStudent[entry.property] = []

        updateMongo(newArray, editStudent.dailyListId, 'dailyList')
        setStudent(editStudent)
        socket.emit("send_updateSingleStudent", { student: {...editStudent}, room });
    }

    return (
      <ErrorBoundary>
        <div className={`${sizeClass}`}>
          <div className={`card m-1 mb-3`}>
            <div className="card-header d-flex justify-content-between align-items-center">
                <span>{entry.title}</span>
                <button className="btn bg-light" onClick={e => resetProperty(e)}>Reset</button>
            </div>
            <div className="card-body">
                {entry.elements.map(item => {
                    var index = student[entry.property] ? student[entry.property].findIndex(itemMap => itemMap.property === item.property) : false
                    return <CardData key={item.property} property={item.property} keyValue={index > -1 && student[entry.property] ? student[entry.property][index].keyValue : false} mainProperty={entry.property} textArea={entry.textArea} resetProperty={resetProperty} elements={entry.elements} />
                })}
            </div>
            {entry.title === 'Top 5 Values' && <div className="card-footer d-flex justify-content-end">
                {/* <div role="button" className="btn btn-secondary m-2 me-0">Link to Choose Values</div> */}
                <Link className="btn btn-info m-2 me-0" to={"https://personalvalu.es/"} target="_blank">Online Values Quiz</Link>
            </div>}
          </div>
        </div>
      </ErrorBoundary>
    )
}

function CardData({property, keyValue, mainProperty, stopDelete, textArea, resetProperty, elements}) {
    
    const {student, socket, room, studentDataFunctions: {updateMongo, setStudent}} = useContext(SiteContext)

    const [newValue, setNewValue] = useState(keyValue)

    function handleUpdate(e) {
        e.preventDefault()
        setNewValue(e.target.value)
    }

    async function handleSave(e) {
        e.preventDefault()

        const editStudent = {...student}
        editStudent[mainProperty] = editStudent[mainProperty] ? [...editStudent[mainProperty], {property, keyValue: newValue}] : [{property, keyValue: newValue}]
        var newArray = {[mainProperty]: [...editStudent[mainProperty]]}

        if(newArray[mainProperty].length > elements.length) {
            resetProperty()
            return
        }
        updateMongo(newArray, editStudent.dailyListId, 'dailyList')
        setStudent(editStudent)
        socket.emit("send_updateSingleStudent", { student: {...editStudent}, room });

        console.log(newArray,'newArray', )
    }

    function handleDelete(e) {
        e.preventDefault()

        const editStudent = {...student}
        var filter = editStudent[mainProperty].filter(entry => entry.property !== property)
        
        var newArray = {[mainProperty]: [...filter]}
        editStudent[mainProperty] = [...filter]
        // console.log(newArray,'editStudent[mainProperty]', editStudent)

        setStudent(editStudent)
        setNewValue(false)
        updateMongo(newArray, editStudent.dailyListId, 'dailyList')
        socket.emit("send_updateSingleStudent", { student: {...editStudent}, room });
    }

    return (
        <div className="d-flex justify-content-between align-items-center mb-2">
            <span className="me-3" style={{minWidth:"45px"}}>{property}</span>
            {keyValue ? 
              <div className="d-flex justify-content-end align-items-center">
                <span className={`text-end`}>{keyValue}</span>
                {!stopDelete && <div className="btn btn-sm bg-light ms-4" onClick={e => handleDelete(e)}>
                <FontAwesomeIcon role="button" icon={faX} className="" />
                </div>
                }
              </div> 
              :
              <div className="d-flex justify-content-between align-items-center">
                <div>
                {textArea ? 
                <textarea cols={40} rows={2} type="text" className="form-control"
                  value={newValue === false ? '' : newValue} onChange={e => handleUpdate(e)} />
                : 
                <input type="text" className="form-control" value={newValue === false ? '' : newValue} onChange={e => handleUpdate(e)}/>}
                </div>
                <div className="btn bg-black text-white ms-1" onClick={e => handleSave(e)}>Save</div>
                {/* <FontAwesomeIcon icon={faFloppyDisk} className="ms-2" onClick={e => handleSave(e)} /> */}
              </div>}
        </div>
    )
}
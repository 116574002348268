import React, {useState, useEffect, useContext } from 'react'

import { SiteContext } from '../../App'

export const HallPassContext = React.createContext()

export function useSHallPass() {

    const {
        classInformation, student, socket,
        studentDataFunctions: {
            createMongo, getMongoList, getMongoById, updateMongo, deleteMongo, stringtoJSDate
        }
    } = useContext(SiteContext)

    const [hpEvents, setHpEvents] = useState([])
    // const [updateEvents, setUpdateEvents] = useState(false)

    useEffect(() => {

        function getHallPasses() {
            getMongoList('hallPassEvents').then(events => { 
            
                // console.log(events,'events')
                const activeEvents = []
                events.map(entry => {
                    if(entry.active && entry.period === classInformation.period) {
                        activeEvents.push(entry)
                    }
    
                    if(!entry.active && entry.approvedTimeStamp > 0 && !entry.closed && entry.period === classInformation.period) {
                        activeEvents.push(entry)
                    }
                })
                setHpEvents(activeEvents)
            })
        }

        getHallPasses()

        let interval;
        interval = setInterval(() => {

            var {date, milli: milliEnd} = stringtoJSDate(classInformation.classEnd)
            var {dateStart, milli: milliStart} = stringtoJSDate(classInformation.classStart)
  
            if(milliStart > Date.now() || milliEnd < Date.now() || classInformation.dayType === 'No School') return
            // console.log('update hall pass events')
            getHallPasses()
            
        }, 30000);

        return () => clearInterval(interval);

    }, [classInformation.classEnd, classInformation.classStart, classInformation.dayType])

    function getHallPasses() {
        getMongoList('hallPassEvents').then(events => { 
        
            // console.log(events,'events')
            const activeEvents = []
            events.map(entry => {
                if(entry.active && entry.period === classInformation.period) {
                    activeEvents.push(entry)
                }

                if(!entry.active && entry.approvedTimeStamp > 0 && !entry.closed && entry.period === classInformation.period) {
                    activeEvents.push(entry)
                }
            })
            setHpEvents(activeEvents)
        })
    }

    useEffect(() => {

        socket.on("receive_hallPass_addEvent", (data) => {
            console.log('receive_hallPass_addEvent', data, data.data.createObj) 
            setHpEvents([data.data.createObj])
        })

        socket.on("receive_hallPass_update", (data) => {
            console.log('receive_hallPass_update', data, data.updatedObj)
            updateExistingEvent(data.updatedObj, data.type, data.changeValue)
        })
    
        return () => {
          socket.off('receive_hallPass_update');
          socket.off('receive_hallPass_addEvent');
        };
    
    }, [socket, hpEvents])

    function updateExistingEvent(event, type, changeValue) {

        const editHpEvents = [...hpEvents]
        var index = editHpEvents.findIndex(entry => entry.timeStamp === event.timeStamp)
    
        if(index === -1 && type !== 'remove') {
            editHpEvents.push(event)
            setHpEvents(editHpEvents)
            return
        }
    
        if(type === 'remove') {
            const filter = editHpEvents.filter(entry => entry._id !== event._id)
            setHpEvents(filter)
            return
        }
    
        editHpEvents[index] = {...editHpEvents[index], ...changeValue}
        setHpEvents(editHpEvents)
    }
    
    var activePass = false
    hpEvents.map(entry => {
        if(entry.active && entry.approved && !entry.allowSimultaneous) {
            // console.log(entry,'entr')
            activePass = entry.approvedTimeStamp + entry.time*60000
        }
    })

    return {
        hpEvents, setHpEvents, updateExistingEvent, activePass, getHallPasses
    }

}

import React, { useContext, useState, useEffect } from 'react'
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import Select from 'react-select'

import { SiteContext } from '../App';
import { ErrorBoundary } from '../other/utils/ErrorBoundary';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Header({ title, subtitle, colorValue, sizeClass, periodBg, setFilterField, numEntries }) {

    const {loginState, reviewStudent, studentList, studentDataFunctions: {setReviewStudent, setRandomStudentIndex, updateRandomIndex}} = useContext(SiteContext)

    let location = useLocation();

    const handleChangeFilter = (e, newFilter) => {
        e.preventDefault()
        setFilterField(newFilter);
    }

    // function updateRandomIndex(e) {
    //   e.preventDefault()
    //   setRandomStudentIndex(
    //     parseInt(Math.random()*studentList.length)
    //   )
    // }

    var count = -1

    return (
    <ErrorBoundary>
      {loginState.admin && location.pathname !== '/colorReview' && <div className={`${sizeClass}`}>
        <div className="card mb-4">
          <div className="card-body">
            <AdminSearch/>
          </div>
        </div>
      </div>}
      <div className={`${sizeClass}`}>
        <div className="card mb-4">
          <div className="card-body pb-0">
            <div className={`pe-2 fsize-3 fw-bold text-${colorValue} d-flex justify-content-between align-items-center`}>
               <span>{title}</span>
               {loginState.admin && location.pathname !== '/colorReviewAdmin' && <div className="badge bg-light" role="button" style={{fontSize:"10px"}} onClick={e => updateRandomIndex(e)} role="button">Randomize</div>}
            </div>
          </div>
          <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title d-inline ms-1">Filter:</h5>
                <span className="ms-1">
                  {periodBg.map(entry => {
                    count++
                    return <div key={count} style={{fontSize:"11px"}} className={`badge rounded-pill bg-${periodBg[count].bg} m-1`}
                    onClick={e => handleChangeFilter(e, entry.period)} role="button"
                    >{entry.period}</div>
                  })}
                </span>
              </div>
              <h5 className="card-title m-1">Results: {numEntries}</h5>
          </div>
        </div>
      </div>
    </ErrorBoundary>
    )
}

function AdminSearch({}) {

  const {loginState, studentList, reviewStudent, classInformationArray, studentDataFunctions: {setReviewStudent}} = useContext(SiteContext)

  const [period, setPeriod] = useState('')

  var filtered = studentList.filter(studentEntry => studentEntry.period.includes(period))
  
  var options = []
  filtered.map(entry => {
    if(entry.period === 'Dropped') return
    options.push({value: entry.studentId, label: entry.name})
  })

  function handlePeriodSelect(e, period) {
    e.preventDefault()
    setPeriod(period)
  }

  function handleReviewStudent(value) {
    // e.preventDefault()

    var index = studentList.findIndex(entry => entry.studentId === value.value)
    if(index === -1) return
    // console.log(studentList[index],'studentList')
    setReviewStudent(studentList[index])
  }

  // const options = [
  //   { value: 'chocolate', label: 'Chocolate' },
  //   { value: 'strawberry', label: 'Strawberry' },
  //   { value: 'vanilla', label: 'Vanilla' }
  // ]

  return (
    <div className="d-flex justify-content-between align-items-center">
        <div className="w-25 me-4">
          <Select options={options} onChange={e => handleReviewStudent(e)} placeholder={'Choose Student'} />
        </div>
        <div>
          {classInformationArray.map(entry => {
            if(entry.archived) return
            return <div key={entry.period} role="button" className={`badge ${entry.period === period ? 'bg-primary' : 'bg-light'} m-1`} onClick={e => handlePeriodSelect(e, entry.period)}>{entry.period}</div>
          })}
        </div>
    </div>
  )
}

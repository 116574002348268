import React from 'react';
import { SketchPicker, SliderPicker, SwatchesPicker, CirclePicker } from 'react-color';

class ColorPicker extends React.Component {
  state = {
    background: this.props.drawArr.color,
  };

  handleChangeComplete = (color) => {
    this.setState({ background: color.hex });
    this.props.updateDrawArr({color: color.hex})
    // console.log(this.props,'props')
    // this.props.updateColor(color.hex)
  };

  render() {
    return (
      <>
      {this.props.showMore ? 
          <SwatchesPicker
            color={ this.state.background }
            onChangeComplete={ this.handleChangeComplete }
          />
          :
          <CirclePicker
          color={ this.state.background }
          onChangeComplete={ this.handleChangeComplete }
        />
      }
      </>
    );
  }
}

export default ColorPicker
import React,{ useState, useContext, useEffect } from 'react'

export function useSchedules({getMongoList}) {

    const [schedules, setSchedules] = useState([])
    const [calendarDays, setCalendarDays] = useState([])

    useEffect(() => {

        async function getSchedules() {
            const items = await getMongoList('schedules')
            setSchedules(items)
        }
        getSchedules()

    }, [])

    useEffect(() => {

        async function getCalendarDays() {
            const items = await getMongoList('calendarDays')
            // console.log(items,'items')
            setCalendarDays(items)
        }
        getCalendarDays()

    }, [])

    function getClassTimes(dayType, periodName) {

        // console.log(dayType,'dayType', periodName, 'name')
        var index = schedules.findIndex(entry => entry.item === dayType)
        if(index === -1) return false
        var index1 = schedules[index].periods.findIndex(entry => entry.class == periodName)
        // console.log('first map', schedules[index].periods, index1, periodName)
        if(index1 === -1) return false

        // console.log('second map')

        var start = schedules[index].periods[index1].startTime
        var end = schedules[index].periods[index1].endTime

        if(!end || !end.split(':')) return false
        // console.log(start, 'start', end, 'end')
        var endHours = end.split(':')[0]
        var hoursReadable = endHours > 12 ? endHours - 12 : endHours 
        // endHours > 12 ? endHours - 12 : endHours
        // ((end.split(':')[0] + 11) % 12 + 1);

        // console.log(end,'end', hoursReadable)

        return {
            classStart: start,
            classEnd: end,
            classEndReadable: `${parseInt(hoursReadable)}:${end.split(':')[1]}`,
            classLength: getClassLength(start, end)
        }
    }

    function getClassLength(start, end) {

        var startDate = new Date()
            startDate.setHours(start.split(':')[0])
            startDate.setMinutes(start.split(':')[1])

        var endDate = new Date()
            endDate.setHours(end.split(':')[0])
            endDate.setMinutes(end.split(':')[1])

        var startMilli = startDate.getTime()
        var endMilli = endDate.getTime()

        return (endMilli - startMilli)/60000
    }

    return {
        schedules, setSchedules, calendarDays, setCalendarDays, getClassTimes
    }
}
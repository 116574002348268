import React, { useContext } from 'react'
import { SiteContext, baseUrl } from '../../App'

export function useAttendance({updateDailyListMongo}) {

    const { 
        studentList, socket,
        classInformationArray, classInformation,
        studentDataFunctions: {
          setStudentList, updateMongo, individualAttendanceData,
        } 
    } = useContext(SiteContext)

    function handleAttendanceAll() {
        classInformationArray.map((entry) => {
            handleAttendance(entry)
        })
    }

    async function handleAttendance(classEntry, e, runAll) {
        if(e) { e.preventDefault() }

        try{

          const period = classEntry.period
          console.log(classEntry,'classEntry', classEntry.informationStrings.sectionId)
          const sectionId = classEntry.informationStrings.sectionId
  
          // console.log(runAll, 'runAll----', period, sectionId, classEntry)
  
          const attendanceOption = [
            {value: "Present", string: "P"},
            {value: "Tardy", string: "T"},
            {value: "Absent", string: "A"}
          ]
      
          const periodFilter = studentList.filter(entry => entry.period === period)
          const attendanceList = []
          periodFilter.map(entry => {
      
            console.log(entry.attendance,'entry.attendance', entry)
            var index = attendanceOption.findIndex(option => option.value === entry.attendance)
      
            // console.log(index,'index',entry)
            
            attendanceList.push({
              name: entry.name,
              studentId: entry.studentId,
              attendance: attendanceOption[index] ? attendanceOption[index].string : 'P',
              attendanceName: entry.attendanceName
            })
            
          })
  
          // console.log(runAll, 'runAll attendance')
  
          const allPeriodsArray = []
          classInformationArray.map(classEntry => {
            allPeriodsArray.push({period: classEntry.informationStrings.sectionId, complete: false})
          })
  
          const runAllObj = runAll && runAll.length > 0 ? runAll : runAll ? allPeriodsArray : false
      
          console.log(`${baseUrl}/powerschool/${period}/${sectionId}`, 'url')
          const response = await fetch(`${baseUrl}/powerschool/${sectionId}`, 
          // const response = await fetch(`${baseUrl}/powerschool/${period}/${sectionId}`, 
          { method: "POST",
            headers: { "Content-Type": "application/json",},
            body: JSON.stringify({attendanceList, runAll: runAllObj}),
          })
          .catch(error => {
            window.alert(error + ' send attendance'); 
            return;
          });
      
          const message = await response.json();
            console.log(message, 'message')

        } catch(error) {
          // alert(error)
          console.log(error)
        }
      
      }

      async function handleAttendanceStatus() {

        const allPeriodsArray = []
        classInformationArray.map(classEntry => {
          allPeriodsArray.push({period: classEntry.informationStrings.sectionId, complete: false, periodReal: classEntry.period})
        })

        
        const response = await fetch(`${baseUrl}/powerschool/attendanceCheck/${classInformation.informationStrings.sectionId}`, 
        // const response = await fetch(`${baseUrl}/powerschool/${period}/${sectionId}`, 
        { method: "POST",
          headers: { "Content-Type": "application/json",},
          body: JSON.stringify({runAll: allPeriodsArray}),
        })
        .catch(error => {
          window.alert(error + ' send attendance'); 
          return;
        });
    
        const message = await response.json();
          console.log(message, 'message')
      }

      function handleAttendanceClick(e, student, importAttendance) {
        e.preventDefault()
        
        var attendanceVal = importAttendance ? importAttendance : e.target.value
        const editList = [...studentList]
        var index = editList.findIndex(entry => entry.studentId === student.studentId)
            editList[index].attendance = attendanceVal
            if(attendanceVal === 'Tardy' && editList[index].color.warningsGiven === 0) {
              editList[index].color = {...editList[index].color, warningsGiven: 1}
            }

            const newItem = individualAttendanceData(editList[index], true)

            const namesListEntry = {
              name: newItem.name,
              studentId: newItem.studentId,
              period: newItem.period,
              chips: newItem.chips,
              attendance: newItem.attendance
            }

            setStudentList([...editList])
            socket.emit("send_updateSingleStudent", { student: {...newItem}, room: 'admin' });
            updateMongo({attendance: attendanceVal, attendanceTimeStamp: Date.now(), color: {...newItem.color}}, student.dailyListId, 'dailyList')
            socket.emit("send_updateNamesList", { student: namesListEntry, room: newItem.period });
      }

    return {
        handleAttendance, handleAttendanceAll, handleAttendanceClick, handleAttendanceStatus
    }
}

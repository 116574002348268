import React, {useEffect, useState, useContext} from 'react'
import { SiteContext } from '../../../App';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { v4 as uuidv4 } from 'uuid'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faBookmark, faX, faRotate, faShare, faPlus, faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function Footer({importVariables}) {

    const {focusAgreements, classInformation, classInformationArray, socket, studentList, room,
        studentDataFunctions: {createMongo, updateMongo, deleteMongo, getMongoList, setFocusAgreements, setStudentList}
    } = useContext(SiteContext)
    // const {customOption, handleClear, setVariables} = importVariables
    
    const activeAssignment = focusAgreements.activeAssignment

    const [assignments, setAssignments] = useState([])
    const [show, setShow] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [confirmAll, setConfirmAll] = useState(false)
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
  
      async function getClasses() {
        const assignmentsLoaded = await getMongoList('assignments')
        setAssignments(assignmentsLoaded)
      }
  
      getClasses()
  
    }, [])

    useEffect(() => {
        setConfirmDelete(false)
    }, [focusAgreements.activeAssignment])

    // [customOption.type, classTemplates.length, classInformation.period])
  
    // console.log(focusAgreements,'entry')
    function handleSaveTemplate(e) {
      e.preventDefault()
    //   createMongo({...customOption, period: classInformation.period, timestamp: Date.now()}, 'customModalTemplates')
      setAssignments([])
    }
  
    async function handleChooseAssignment(e) {
      e.preventDefault()
      const editFocusAgreements = {...focusAgreements, activeAssignment: e.target.value}
      setFocusAgreements(editFocusAgreements)
      classInformationArray.map(entry => {
        updateMongo({activeAssignment: e.target.value}, entry.focusAgreementId, 'focusAgreements')
      })
      socket.emit("send_focusAgreementsUpdate", { focusAgreements: editFocusAgreements, room: classInformation.period });
    }
  
    function handleCreateAssignment(title) {        

      const newAssignment = {
          title,
          dateMilli: Date.now(),
          itemId: uuidv4()
      }

      createMongo(newAssignment, 'assignments')
      const editAssignments = [...assignments, newAssignment]
      setAssignments(editAssignments)

      const editStudentList = [...studentList]
      editStudentList.map(entry => {
          if(entry.period === 'Dropped') return
          const studentEntry = {...newAssignment, complete: false}
          entry.assignments = entry.assignments ? [...entry.assignments, studentEntry] : [studentEntry]
          updateMongo({assignments: entry.assignments}, entry.dailyListId, 'dailyList')
          socket.emit("send_updateSingleStudent", { student: entry, room: entry.period });
      })
    //   console.log(editStudentList,'editStudentList')
      setStudentList(editStudentList)
    }
  
    function handleDeleteAssignment(e) {
      e.preventDefault()
  
      var index = assignments.findIndex(entry => entry.title === activeAssignment)
      if(index === -1) return
      deleteMongo(assignments[index]._id, 'assignments')

      const editAssignments = [...assignments]
      const trimmed = editAssignments.filter(entry => entry.itemId !== assignments[index].itemId)
      setAssignments(trimmed)
      setConfirmDelete(false)

      const editFocusAgreements = {...focusAgreements, activeAssignment: 'none'}
      setFocusAgreements(editFocusAgreements)
      classInformationArray.map(entry => {
        updateMongo({activeAssignment: 'none'}, entry.focusAgreementId, 'focusAgreements')
      })
      socket.emit("send_focusAgreementsUpdate", { focusAgreements: editFocusAgreements, room });

      const editStudentList = [...studentList]
      editStudentList.map(entry => {
        if(entry.period === 'Dropped') return
        entry.assignments = entry.assignments ? entry.assignments.filter(entry => entry.itemId !== assignments[index].itemId) : []
        updateMongo({assignments: entry.assignments}, entry.dailyListId, 'dailyList')
        socket.emit("send_updateSingleStudent", { student: entry, room: entry.period });
      })
    //   console.log(editStudentList,'editStudentList')
      setStudentList(editStudentList)
    
    }

    function handleConfirmDelete(e, action) {
        e.preventDefault()
        setConfirmDelete(action)
    }

    function handleConfirmAll(e, action) {
        e.preventDefault()
        setConfirmAll(action)
    }

    function handleChangeAllStatus(e, value) {
        e.preventDefault()

        const editStudentList = [...studentList]
        editStudentList.map(entry => {
            if(entry.period !== classInformation.period || !entry.assignments) return

            var index = entry.assignments ? entry.assignments.findIndex(item => item.title === focusAgreements.activeAssignment) : -1
            if(index === -1) return
            entry.assignments[index].complete = value

            updateMongo({assignments: entry.assignments}, entry.dailyListId, 'dailyList')
            socket.emit("send_updateSingleStudent", { student: entry, room: entry.period });
        })
    
        setStudentList(editStudentList)
        setConfirmAll(false)
    }

    const exportVariables = {handleShow, handleClose, show, handleCreateAssignment}

    var studentCount = 0
    var completeCount = 0
    var adminCount = 0
    studentList.map(entry => {
      if(entry.attendance === 'Absent') return
      if(entry.period !== classInformation.period) return
      if(!entry.assignments) return
      var index = entry.assignments ? entry.assignments.findIndex(entry => entry.title === focusAgreements.activeAssignment) : -1
      if(index === -1) return
      studentCount++
      if(entry.assignments[index].complete) {
        completeCount++
      }
      if(entry.assignments[index] && entry.assignments[index].completeAdmin) {
        adminCount++
      }
    })

    return (
    <>
    <CreateModal exportVariables={exportVariables} />
    <div className="d-flex justify-content-between flex-fill align-items-center">
      <div className="flex-fill m-2 ms-0" style={{width:"100%"}}>
        <select className="form-control" role="button" value={activeAssignment} onChange={e => handleChooseAssignment(e)}>
            <option value="none">Choose From Assignments</option>
            {assignments.map(entry => {
              return <option key={entry.itemId} value={entry.title}>{entry.title}</option>
            })}
        </select>
      </div>
      {confirmAll && <span className="text-nowrap">Mark All:</span>}
      <div className="btn-group m-2 flex-fill d-flex justify-content-end" role="group">
        {/* <div type="button" className={`btn btn-primary`} onClick={e => handleSaveTemplate(e)}>
          <FontAwesomeIcon color="white" icon={faFloppyDisk} />
        </div> */}
        {/* <div type="button" className={`btn btn-info`} onClick={handleClear}>
          <FontAwesomeIcon color="white" icon={faRotate} />
        </div> */}
        {!confirmDelete && !confirmAll ? <><div type="button" className={`btn btn-primary text-white border border-2 border-primary`} onClick={handleShow}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
        {focusAgreements.activeAssignment !== 'none' &&<div type="button" className={`btn btn-danger text-white border border-2 border-danger`} onClick={(e) => handleConfirmDelete(e, true)}>
          <FontAwesomeIcon icon={faX} />
        </div>}</> : !confirmAll && <DeleteButtons handleConfirmDelete={handleConfirmDelete} handleDeleteAssignment={handleDeleteAssignment} />}
        {!confirmAll && !confirmDelete ? <div type="button" className={`btn btn-success text-white`} onClick={(e) => handleConfirmAll(e, true)}>
          <FontAwesomeIcon icon={faCheck} />
        </div> : !confirmDelete && <AllButtons handleChangeAllStatus={handleChangeAllStatus} handleConfirmAll={handleConfirmAll} />}
      </div>
      <div className="d-flex flex-wrap flex-fill justify-content-center">
      {focusAgreements.activeAssignment !== 'none' && <span>{completeCount}/{studentCount} {Math.round(completeCount/studentCount*100)}%</span>}
      {focusAgreements.activeAssignment !== 'none' && <span className="text-success">{adminCount}/{studentCount} {Math.round(adminCount/studentCount*100)}%</span>}
      </div>
    </div>
    </>
    )
  }

  function getDateString(milliseconds) {
    var d1 = new Date(milliseconds),
      d = d1.getDate(),
      m = d1.getMonth() + 1,
      y = d1.getFullYear();
  
    return `${m}/${d}`
  }


function DeleteButtons({handleDeleteAssignment, handleConfirmDelete}) {
    return (
        <>
        <div type="button" className={`btn btn-danger text-white border border-2 border-danger flex-fill text-nowrap`} onClick={e => handleDeleteAssignment(e)}>
          Confirm Delete
        </div>
        <div type="button" className={`btn btn-secondary text-white border border-2 border-secondary flex-fill`} onClick={e => handleConfirmDelete(e, false)}>
          Cancel
        </div></>
    )
}

function AllButtons({handleChangeAllStatus, handleConfirmAll}) {
    return (
        <>
        <div type="button" className={`btn btn-success text-white border border-2 border-success flex-fill text-nowrap`} onClick={e => handleChangeAllStatus(e, 2)}>
          <FontAwesomeIcon icon={faCheck} className="me-1" />
          Complete
        </div>
        <div type="button" className={`btn btn-danger text-white border border-2 border-danger flex-fill text-nowrap`} onClick={e => handleChangeAllStatus(e, false)}>
          <FontAwesomeIcon icon={faX} className="me-1" />
          Incomplete
        </div>
        {/* <div type="button" className={`btn btn-warning text-white border border-2 border-warning flex-fill text-nowrap`} onClick={e => handleChangeAllStatus(e, 1)}>
          <FontAwesomeIcon icon={faSpinner} className="me-1" />
          Partially Complete
        </div> */}
        <div type="button" className={`btn btn-secondary text-white border border-2 border-secondary flex-fill`} onClick={e => handleConfirmAll(e, false)}>
          Cancel
        </div></>
    )
}

function CreateModal({exportVariables}) {
    
    const {handleShow, handleClose, show, handleCreateAssignment} = exportVariables

    const [title, setTitle] = useState('')

    function handleUpdateTitle(e) {
        e.preventDefault()
        setTitle(e.target.value)
    }

    function handleUpdateTitleTemplate(e) {
        e.preventDefault()
        setTitle(e.target.value)
    }

    function handleCloseLocal(e) {
        e.preventDefault()

        handleCreateAssignment(title)
        handleClose()
        setTitle('')
    }

    var today = new Date()
    var dateString = `${today.getMonth() + 1}/${today.getDate()}`

    return (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create Assignment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className="d-flex flex-wrap">
                <div className="d-flex align-items-center w-100 m-2 ms-0">
                    <label>Title</label>
                    <input
                    type="text"
                    className="form-control flex-fill ms-2"
                    id="position"
                    value={title}
                    onChange={(e) => handleUpdateTitle(e)}
                    />
                </div>
                <div className="flex-fill m-2 ms-0 w-100">
                    <select className="form-control" role="button" onChange={(e) => handleUpdateTitleTemplate(e)}>
                        <option value="">Choose From Templates</option>
                        <option>{dateString} Colors Review</option>
                        <option>{dateString}</option>
                        {/* {assignments.map(classEntry => {
                        //   return <option key={classEntry.id} value={classEntry.id}>{classEntry.active ? classEntry.period : getDateString(classEntry.timestamp)} - {(customOption.type === 'dailyLinks' && classEntry.bullets && classEntry.bullets.length > 0) ? classEntry.bullets[0].entry : classEntry.text}</option>
                        })} */}
                    </select>
                </div>
              </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={e => handleCloseLocal(e)}>
              Create
            </Button>
          </Modal.Footer>
        </Modal>
    );
  }
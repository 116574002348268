import React, { useContext, useState, useEffect } from 'react'
import { SiteContext } from '../../App'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

export function useChips({dailyList, addChips, setAddChips, handleAttendance, setDailyList, orders, setOrders}) {

    const {
        classInformation, socket, studentList, room, initialLoad, focusAgreements,
        studentDataFunctions: {
            setStudentList, updateMongo, calculateTimeElapsed, stringtoJSDate, getMongoList, deleteMongo, getMongoAllByParam, setFocusAgreements
        }
    } = useContext(SiteContext)

    const [showChipModal, setShowChipModal] = useState(false);
    const [updateAddChips, setUpdateAddChips] = useState(false)

    const handleChipClose = () => setShowChipModal(false);
    const handleChipShow = () => setShowChipModal(true);
 
    function handleChipModal(e, type, attendance) {
        e.preventDefault()

        if(type === 'add') {
            if(attendance) {
                handleAttendance(classInformation)
                updateDailyChips('info')
            } else {
                handleAddChips()
                updateDailyChips('success')
            }
        } else if(type === 'reject') {
            updateDailyChips(attendance ? 'primary' : 'flag')
            handleChipClose()
        }
    }

    // console.log(showChipModal,'showChipModal')

    async function handleUpdateOrders(e, item, orders) {
        e.preventDefault()
        
        const editOrders = [...orders]
        var filtered = editOrders.filter(entry => entry.orderId !== item.orderId)
        
        const matches = await getMongoAllByParam(item.orderId, 'orders', 'orderId')
        const match = matches[0]
        deleteMongo(match._id, 'orders')

        setOrders(filtered)
        socket.emit("send_updateOrders", { studentId: item.studentId, orderId: item.orderId, room });
    }
 
     const modalTriggers = useModalTriggers()

     useEffect(() => {

       const interval = setInterval(() => {
        if(isMobile) return
        var nowMilli = new Date().getTime()

        var schoolEnd = classInformation.classEnd
        // console.log(schoolEnd,'schoolEnd', classInformation.dayType, focusAgreements, classInformation)
        var {date, milli} = stringtoJSDate(schoolEnd)
            if(milli < nowMilli || classInformation.dayType === 'No School') return

        modalTriggers.map(entry => {
            if(nowMilli < entry.milli || entry.period !== classInformation.period) return
            // if(entry.period !== classInformation.period) return
            
            var index = focusAgreements.chips.findIndex(item => item.timeStamp === entry.min.toString())
            // console.log(focusAgreements.chips,'focusAgreements.chips', focusAgreements.chips[index])
            if(index === -1 || focusAgreements.chips[index].variant !== 'light') return

            console.log('showing')
            handleChipShow()
        })
        
      }, 4000);

      return () => {
         clearInterval(interval);
      };
     }, [modalTriggers, classInformation]);

    //  console.log(showChipModal,'showChipModal')

    function handleAddChips() {
        
        var filtered = studentList.filter(entry => entry.period === classInformation.period)
        var filteredOther = studentList.filter(entry => entry.period !== classInformation.period)
    
        filtered.map(entry => {
            var current = entry.color.current
            if(current !== 'Black' && current !== 'Gold' && current !== 'Purple' ) return
    
            var chipsAdded = 0
            if(current === 'Black') {
                chipsAdded = 6
            }
            if(current === 'Gold') {
                chipsAdded = 4
            }
            if(current === 'Purple') {
                chipsAdded = 2
            }
    
            const chipsArray = {
                balance: entry.chips.balance + chipsAdded,
                total: entry.chips.total + chipsAdded,
            }
    
            entry.chips = chipsArray

            // updateMongo({chips: chipsArray}, classInformation.focusAgreementId, 'focusAgreements')
            updateMongo({chips: chipsArray}, entry._id, 'records')
    
            var index = dailyList.findIndex(item => item.studentId === entry.studentId)
                const addedArray = {
                    added: dailyList[index].chipsToday.added + chipsAdded,
                    subtracted: dailyList[index].chipsToday.subtracted,
                }
                updateMongo({chipsToday: addedArray, chips: chipsArray}, dailyList[index]._id, 'dailyList')
                // updateMongo({chipsToday: addedArray, chips: chipsArray}, classInformation.focusAgreementId, 'focusAgreements')
                entry.chipsToday = addedArray
        })
        // console.log(filtered, 'filtered')
        const finalStudentList = [...filtered, ...filteredOther]
        setStudentList(finalStudentList)
        socket.emit("send_chipsAdded", { filtered, room });
    
        handleChipClose()
    }

    async function handleAddChipsManual(e, studentId, chipAdd, chipSubtract, type) {

        // console.log(studentId, chipAdd, chipSubtract, type)
        e.preventDefault()
        
        const editStudentList = [...studentList]
        const editDailyList = initialLoad ? [...dailyList] : await getMongoList('dailyList')
    
        var index = studentList.findIndex(entry => entry.studentId === studentId)
            editStudentList[index].chips[type] = editStudentList[index].chips[type] + chipAdd + chipSubtract
    
            updateMongo({chips: editStudentList[index].chips}, editStudentList[index]._id, 'records')
    
            var index2 = editDailyList.findIndex(item => item.studentId === studentId)
                editDailyList[index2].chipsToday.added += chipAdd
                editDailyList[index2].chipsToday.subtracted += chipSubtract
                const addedArray = {
                    added: editDailyList[index2].chipsToday.added + chipAdd,
                    subtracted: editDailyList[index2].chipsToday.subtracted + chipSubtract,
                }
    
                updateMongo({chipsToday: addedArray, chips: editStudentList[index].chips}, editDailyList[index2]._id, 'dailyList')
    
            setStudentList(editStudentList)
            setDailyList(editDailyList)
                
            socket.emit("send_orderComplete", { student: {studentId, chips: editStudentList[index].chips, chipsToday: addedArray}, room });
    }
    
    function updateDailyChips(newVariant) {

        // const {focusAgreements} = useContext(SiteContext)
        // const {classStart} = calculateTimeFunction(classInformation.dayType, classInformation.classStartNormal, classInformation.classStartMinimum)
        const elapsed = calculateTimeElapsed(classInformation.classStart)
    
        const editAddChips = [...focusAgreements.chips]
            // var index = editAddChips.findIndex(entry => entry.period === classInformation.period)
            for(let i = 0; i < editAddChips.length; i++) {
                if(editAddChips[i].variant === 'light') {
                    editAddChips[i].variant = newVariant
                    editAddChips[i].timeStamp = elapsed
                    break
                }
            }
        
        console.log(editAddChips,'editAddChips')
        // setAddChips(editAddChips)
        updateMongo({chips: editAddChips}, classInformation.focusAgreementId, 'focusAgreements')
        setFocusAgreements({...focusAgreements, chips: editAddChips})
        // updateMongo({addChips: editAddChips}, chipsId, 'addChips')
        // console.log(editAddChips,'editAddChips')
    }

    return {
        handleAddChips, handleAddChipsManual, handleUpdateOrders,
        showChipModal, handleChipModal, handleChipClose
    }
}


function futureTime(existingDate, minAdded, period) {
    return {
        date: new Date(existingDate.getTime() + minAdded * 60000), 
        milli: new Date(existingDate.getTime() + minAdded * 60000).getTime(),
        min: minAdded,
        period
    }
}

function useModalTriggers() {

    const {classInformationArray, classInformation} = useContext(SiteContext)

    var modalTriggers = []
    classInformationArray.map(entry => {
        // if(entry.dayType !== 'Normal Day' && entry.dayType !== 'Minimum Day') return
        var classStart = entry.classStart 
        // === 'Normal' ? entry.classStartNormal : entry.classStartMinimum

        if(!classStart) return modalTriggers
        
        var hours = parseInt(classStart.split(':')[0])
        var min = parseInt(classStart.split(':')[1])
            if(hours < 8) {hours = hours + 12}
    
        var classStartDate = new Date()
          classStartDate.setHours(hours, min, 0)
        
          modalTriggers.push(futureTime(classStartDate, 15, entry.period))
          modalTriggers.push(futureTime(classStartDate, 30, entry.period))
          if(entry.classLength == 50) {modalTriggers.push(futureTime(classStartDate, 45, entry.period))}            
    })

    // console.log(modalTriggers,'modalTriggers')
    return modalTriggers
}
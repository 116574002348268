import React, {useContext, useState, useEffect} from 'react'
import { SiteContext, baseUrl } from '../../../App'

export function useBehavior({}) {

    const { 
        classInformation, colorMatch, studentList,
        studentDataFunctions: { deleteMongo, getMongoList, createMongo, updateMongo }
    } = useContext(SiteContext)

    const [behaviorEvents, setBehaviorEvents] = useState([])
    const [lastUpdateTime, setLastUpdateTime] = useState(Date.now())
    const [pendingTime, setPendingTime] = useState(Date.now())
    const [pendingEvents, setPendingEvents] = useState([])
    const [updateBEvents, setUpdateBEvents] = useState(false)
  
    useEffect(() => {
        
        async function getBehaviorEvents() {
    
          const behaviorEventsMongo = await getMongoList('behaviorEvents')
          if(!behaviorEventsMongo) return
          behaviorEventsMongo.map(entry => {
              entry.id = entry._id
          })

          var activelyPending = behaviorEventsMongo.filter(entry => entry.activelyPending)
          const remainingEvents = handleDeleteDuplicateEvents(activelyPending)
          // console.log(remainingEvents,'remainingEvents')
          setBehaviorEvents(remainingEvents);
          console.log('behavior events loaded')
        }
    
        getBehaviorEvents();
      
    }, [classInformation.period, updateBEvents]);

    useEffect(() => {
      const remainingEvents = handleDeleteDuplicateEvents(behaviorEvents)
      // console.log(remainingEvents,'remainingEvents')
      setBehaviorEvents(remainingEvents);
    }, [behaviorEvents.length, studentList])
    // }, [behaviorEvents.length, classInformation.period, updateBEvents]);

      // This method will delete a record
    async function deleteBehaviorEvent(id) {
        deleteMongo(id, 'behaviorEvents')
    
        const newBehaviorEvents = behaviorEvents.filter((el) => el.id !== id);
        setBehaviorEvents(newBehaviorEvents);
    }

    function filterEvents(type) {

      var shoutoutType = type === 'raise' ? 'shoutOut' : 'callOut'

      var time = new Date()
        time.setHours(8)
        time.setMinutes(0)
        time.setSeconds(0)

      var timeMilli = time.getTime()

      // console.log(type, 'type', type === 'raise')
      const filtered = behaviorEvents.filter(behaviorEvent => 
        // self
        (behaviorEvent.activelyPending && behaviorEvent.type !== "nominate" && behaviorEvent.timeStamp > timeMilli && type === 'raise' ? behaviorEvent.levelChange > 0 : behaviorEvent.levelChange < 0) 
        || 
        // outs
        (behaviorEvent.activelyPending && behaviorEvent.type === 'nominate' && 
        behaviorEvent.timeStamp > timeMilli && behaviorEvent.nominateType === shoutoutType))

        // return filtered
        return filtered.filter(behaviorEvent => behaviorEvent.activelyPending && behaviorEvent.period === classInformation.period)
     }

    function handleDeleteDuplicateEvents(events) {

        var activelyPending = events.filter(entry => entry.activelyPending)
        
        const eventsToBeDeleted = []

        activelyPending.map(eventToMatch => {
          var filtered = activelyPending.filter(
              event =>
                  event.studentInitiatingId === eventToMatch.studentInitiatingId && 
                  eventToMatch.type === 'self' && 
                  event.type === 'self' && 
                  event.period === classInformation.period
          )
    
          var filtered2 = activelyPending.filter(
            event =>
                event.studentInitiatingName === eventToMatch.studentInitiatingName &&
                event.studentNominatedName === eventToMatch.studentNominatedName && 
                eventToMatch.type === 'nominate' && 
                event.type === 'nominate' && 
                event.period === classInformation.period
          )
    
          sliceDelete(filtered)
          sliceDelete(filtered2)
        })

        activelyPending.map(event => {
          // console.log(event,'event')
          if(event.type !== 'nominate') return
          var index = studentList.findIndex(entry => entry.studentId === event.studentNominatedId)
          if(index === -1) return
          if(studentList[index].color.current === 'Black') {
            activelyPending = activelyPending.filter(activeEvent => activeEvent.id !== event.id)
            deleteMongo(event.id, 'behaviorEvents')
          }
        })

        setBehaviorEvents(activelyPending)
        return activelyPending
    
        function sliceDelete (filtered) {
          filtered.sort( compare );
          var sliced = filtered.slice(1);
          sliced.map(filteredEvent => {
            // eventsToBeDeleted.push(filteredEvent)
            activelyPending = activelyPending.filter(activeEvent => activeEvent.id !== filteredEvent.id)
            deleteMongo(filteredEvent.id, 'behaviorEvents')
            // deleteBehaviorEvent(filteredEvent.id)
          })
        }
    
        setLastUpdateTime(Date.now())
    
      }


      async function handleColorClick(e, student, type) {
        if(e) e.preventDefault()
    
        var action = false
        if(type === 'warning4') {
          action = 'lower'
        } 
        else if(type === 'raise') {action = 'raise'}
        else if(e && e.target.classList.contains('showModal')) return
        else if(e && e.target.classList.contains('lower') || e.target.parentNode.classList.contains('lower')) {
          action = 'lower'

          // const match = colorMatch.filter(item => item.color == student.color.current)[0]    
          // var pendingMatch = colorMatch.filter(item => item.level == match.level - 1)[0]
          //   var colorPending = pendingMatch.color

          //   console.log(colorPending,'colorPending')

          updateMongo({color: {...student.color, pending: ""}}, student.dailyListId, 'dailyList')
        } else if(e && e.target.classList.contains('raise') || e.target.parentNode.classList.contains('raise')) {
          action = 'raise'
        } else return
    
  
        const currentColor = student.color.current
  
        const match = colorMatch.filter(item => item.color === currentColor)
          const behaviorLevel = match[0].level
          const behaviorString = match[0].string
  
        var raise1index = behaviorLevel === 9 ? colorMatch.findIndex(entry => entry.level === behaviorLevel) : colorMatch.findIndex(entry => entry.level === behaviorLevel + 1)
          const raise1 = colorMatch[raise1index]
        var lower1index = behaviorLevel === 1 ? colorMatch.findIndex(entry => entry.level === behaviorLevel) : colorMatch.findIndex(entry => entry.level === behaviorLevel - 1)
          const lower1 = colorMatch[lower1index]
  
        const newBehaviorEvent = { 
          activelyPending: false,
          studentInitiatingName: student.name,
          studentInitiatingId: student.studentId,
          result: 'approved',
          type: 'self',
          studentNominatedName: '',
          studentNominatedId: '',
          category: '',
          reason: '',
          pendingColor: action === 'raise' ? raise1.color : lower1.color,
          currentColor: currentColor,
          timeStamp: Date.now(),
          period: student.period,
          manual: true
        };
  
        console.log(newBehaviorEvent,'newBehaviorEvent')
        createMongo(newBehaviorEvent, 'behaviorEvents')
      }
  

    return {
        behaviorEvents, setBehaviorEvents, deleteBehaviorEvent,
        lastUpdateTime, setLastUpdateTime,
        pendingTime, setPendingTime,
        pendingEvents, setPendingEvents,
        handleDeleteDuplicateEvents, updateBEvents, setUpdateBEvents,
        handleColorClick, filterEvents
    }
}



function compare( a, b ) {
    if ( a.timeStamp > b.timeStamp){
      return -1;
    }
    if ( a.timeStamp < b.timeStamp ){
      return 1;
    }
    return 0;
}
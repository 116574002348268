import React, { useContext, useState, useEffect } from 'react'
import { useNavigate, NavLink } from "react-router-dom";

import { SiteContext } from '../App'
import { AdminContext } from '../pages/AdminContainer'

import DAdminPages from './DAdminPages';

import Dropdown from 'react-bootstrap/Dropdown';
import MessageCenter from '../other/messages/MessageCenter';
import DailyEventTooltip from '../other/utils/DailyEventTooltip';
import ChipsTooltip from '../other/utils/ChipsTooltip';

import PSModal from '../admin/seatingChart/PSModal';
import CreateAgModal from '../modals/customModal/admin/AEdit';
import SeatModal from '../admin/seatingChart/SeatModalNew';
import HPModal from '../hallPass/admin/createModal/HPModal';
import HPBoxModal from '../hallPass/admin/mainModal/HPBoxModal';
import ACustomModal from '../modals/customModal/admin/ACustomModal';
import ActiveEvent from '../hallPass/admin/mainModal/ActiveEvent';

import { ErrorBoundary } from '../other/utils/ErrorBoundary';

import './header.css'

export default function D_Header({type, handleAddDailyRecord, handleAddChips, handlePeriodSelect, handleShow, handleClose, show}) {

    const navigate = useNavigate();

    const { 
        classInformation, adminPages, socket, initialLoad, widthWindow, studentList, room, classInformationArray, focusAgreements, loginState,
        studentDataFunctions: { 
            handleResetOtherLists, createMongo, getMongoById, getMongoList, updateMongo, handleLogout, setStudentList, setInitialLoad, clearFocusAgreements
        }
    } = useContext(SiteContext)
    
    const {clearDailyList, handleAttendanceAll, handleAttendance, refreshAllStudents, connected, handleAttendanceStatus} = useContext(AdminContext)
    
    const blankBg = () => {

        const blankBg = []
        classInformationArray.map(entry => {
            blankBg.push({period: entry.period, bg: 'light'})
        })
        blankBg.push({period: 'P', bg: 'light'})

        return blankBg
    }

    const [periodBg, setPeriodBg] = useState(blankBg)

    const [month, setMonth] = useState(() => {
        var date = new Date()
        return date.getMonth() + 1
    })
    const [date, setDate] = useState(() => {
        var date = new Date()
        return date.getDate()
    })

    useEffect(() => {
        const editPeriodBg = blankBg()
        editPeriodBg.map(entry => {
          entry.bg = 'light'
        })
        var index = editPeriodBg.findIndex(entry => entry.period === classInformation.period)
          if(index === -1) return
          editPeriodBg[index].bg = 'primary'
          setPeriodBg(editPeriodBg)

    }, [classInformation.period])

    function updateTime(e, type) {
        e.preventDefault()
        if(type === 'month') {
            setMonth(e.target.value)
            return
        }
        setDate(e.target.value)
    }

    function findShow(type, query) {
        var index = show.findIndex(entry => entry.type === type)
        // console.log(show,'show', index)
        return show[index][query]
    }

    async function addResetCombined(e, dateString) {
        e.preventDefault()
        const updatedDailyList = await handleAddDailyRecord(dateString, true)
        await clearDailyList(updatedDailyList)
        await handleResetOtherLists()
        await clearFocusAgreements()
        
        classInformationArray.map(entry => {
            socket.emit("send_refreshAll", { refresh: true, room: entry.period });
        })
        socket.emit("send_refreshAll", { refresh: true, room: 'admin' });

        alert('Add & Reset Complete - Loading New Data Now')
        // window.location.reload();
    }

    function handleAddRecord(e, dateString) {
        e.preventDefault()
        handleAddDailyRecord(dateString)
    }

    function handleNavigate(item) {
        navigate(`/${item}`, { replace: true });
    }

    function handlePeriodSelectLocal(period) {
        handlePeriodSelect(period, true)
        socket.emit("send_changePeriod", { data: period, room: 'admin' });
    }

    function clearDailyListLocal(e) {
        e.preventDefault()
        clearDailyList()
    }
    
    function handleAttendanceLocal() {
        handleAttendance(classInformation, undefined, true)
    }

    function handleBackupStudent(e) {
        e.preventDefault()

        studentList.map(async(entry) => {
            const item = await getMongoById(entry.studentId, 'studentBackup')
            console.log(item,'item')
            if(item && item._id) {
                var entryEdit = {...entry}
                delete entryEdit._id
                updateMongo(entryEdit, item._id, 'studentBackup')
            } else {
                createMongo(entry,'studentBackup')
            }
        })
    }

    async function resetGoals(e, runAll) {
        e.preventDefault()

        const records = await getMongoList('dailyRecordRoster')
        const editStudentList = [...studentList]

        records.map(entry => {
            var index = studentList.findIndex(item => item.studentId === entry.studentId)
            if(index === -1) return
            if(!runAll && studentList[index].period !== classInformation.period) return
            const addedGoal = {...studentList[index].goalSetting, module: studentList[index].module}
            const clearGoal = {
                display: 1,
                timeStamp: false,
                goal: { goal: false, matters: false, thing1: false, thing2: false, thing3: false, accomplish: false },
                review: {review: false}
            }
            const updatedGoalSetting = entry.goalSetting ? [...entry.goalSetting, addedGoal] : [addedGoal]
            updateMongo({goalSetting: updatedGoalSetting}, entry._id, 'dailyRecordRoster')
            updateMongo({goalSetting: clearGoal}, studentList[index].dailyListId, 'dailyList')
            editStudentList[index].goalSetting = clearGoal
            socket.emit("send_adminUpdateSingleStudent", { studentId: studentList[index].studentId, param: 'goalSetting', goalSetting: clearGoal, room });
        })

        setStudentList(editStudentList)
    }

    function AddDropdowns({classVal}) {
        return (
            <ErrorBoundary>
            <div className={classVal}>
            {/* <Dropdown.Item onClick={e => handleAddRecord(e, `${month}/${date}`)}>
                <DailyEventTooltip addDaily={addDaily} />
            </Dropdown.Item>   */}
            <Dropdown.Item onClick={e => addResetCombined(e, `${month}/${date}`)}>
                Add & Reset
            </Dropdown.Item>  
            <Dropdown.Item onClick={(e) => {e.stopPropagation()}}>
                <RecordDate month={month} date={date} updateTime={updateTime} />
            </Dropdown.Item> 
            <Dropdown.Item onClick={handleAddChips}>
                <ChipsTooltip addChips={focusAgreements.chips} />
            </Dropdown.Item>  
            <div className="dropdown-divider"></div>
            {/* <Dropdown.Item onClick={handleUpdateList}>
                Update Login Data
            </Dropdown.Item> 
            <Dropdown.Item onClick={refreshAllStudents}>
                Refresh All Students
            </Dropdown.Item>     */}
            <Dropdown.Item onClick={handleAttendanceLocal}>
                Submit All Attendance
            </Dropdown.Item>     
            <Dropdown.Item onClick={handleAttendanceStatus}>
                Check Attendance Status
            </Dropdown.Item>  
            </div>
            </ErrorBoundary>
        )
    }

    function ResetDropdowns({classVal}) {
        return (
            <div className={classVal}>
            <Dropdown.Item onClick={handleResetOtherLists}>
                Reset Lists
            </Dropdown.Item>  
            <Dropdown.Item onClick={e => clearDailyListLocal(e)}>
                Clear Daily List
            </Dropdown.Item> 
            <Dropdown.Item onClick={clearFocusAgreements}>
                Reset Focus Agreements
            </Dropdown.Item> 
            <div className="dropdown-divider"></div> 
            <Dropdown.Item onClick={e => resetGoals(e, false)}>
                Reset Goals for {classInformation.period} 
            </Dropdown.Item> 
            <Dropdown.Item onClick={e => resetGoals(e, true)}>
                Reset Goals for All Period
            </Dropdown.Item> 
            <div className="dropdown-divider"></div>
            <Dropdown.Item onClick={handleLogout}>
                Log Out
            </Dropdown.Item>   
            </div>
        )
    }

    var count = -1
    
    var activeModalBg = false
    var activeModalText = false
        if(focusAgreements.customModal) {
            activeModalBg = 'primary'
            activeModalText = focusAgreements.customModalText
            // activeModalText = 'Custom Modal'
        }
        if(focusAgreements.dayReview) {
            activeModalBg = 'purple'
            activeModalText = 'Exit Ticket'
        }
        if(focusAgreements.goalSetting) {
            activeModalBg = 'info'
            activeModalText = 'Goal Setting'
        }

    return (    
    <ErrorBoundary>
        {type === 'dashboard' && <PSModal handleClose={handleClose} handleShow={handleShow} show={findShow('powerSchool','show')} payload={findShow('powerSchool','payload')} type={'powerSchool'} />}
        {/* <ACustomModal handleClose={handleClose} handleShow={handleShow} show={findShow('customModal','show')} payload={findShow('customModal','payload')} type={'customModal'} /> */}
        <SeatModal handleClose={handleClose} handleShow={handleShow} show={findShow('seatModal','show')} payload={findShow('seatModal','payload')} type={'seatModal'} />
        <HPModal handleClose={handleClose} handleShow={handleShow} show={findShow('hallPassModal','show')} payload={findShow('hallPassModal','payload')} type={'hallPassModal'} />
        <HPBoxModal handleClose={handleClose} handleShow={handleShow} show={findShow('hallPassBoxModal','show')} payload={findShow('hallPassBoxModal','payload')} type={'hallPassBoxModal'} />
        <div className="app-header header-shadow">
            <div className="app-header__content">
                <div className="app-header-left">
                    <Dropdown>
                        <Dropdown.Toggle variant="white" id="dropdown-basic">
                            Pages
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <DAdminPages/>
                            {/* <div className="dropdown-divider d-block d-sm-none"></div>
                            <AddDropdowns classVal={'d-block d-sm-none'} /> */}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle variant="white" id="dropdown-basic">
                            {widthWindow >= 768 ? 'Add' : 'Other'}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <AddDropdowns/>
                            <div className="dropdown-divider d-block d-sm-none"></div>
                            <ResetDropdowns classVal={'d-block d-sm-none'} />
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="d-none d-sm-block">
                        <Dropdown.Toggle variant="white" id="dropdown-basic">
                            Reset
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <ResetDropdowns/>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="app-header-right">
                    {useNetwork() === false && <NoConnection/>}
                    <div className="">
                      <div className="page-title-head center-elem">
                        {activeModalBg && type === 'dashboard' && <div style={{paddingTop:"12px", paddingBottom:"12px"}} className={`badge m-3 ps-3 pe-3 bg-${activeModalBg}`}>
                            {activeModalText}
                        </div>}
                        {type === 'records' && <div className="badge bg-success" role="button" onClick={e => handleBackupStudent(e)}>
                            Backup Students
                        </div>}
                        {type === 'records' && <NavLink className="nav-link" to="/create">
                            Create Record
                        </NavLink>}
                        {type === 'createRecord' && <NavLink className="nav-link" to="/records">
                            Return to Records
                        </NavLink>}
                        {initialLoad && !connected && <button style={{padding:"8px", margin: "10px", width:"50%"}} className="btn btn-danger">
                            No Connection
                        </button>}
                        {/* {type === 'dashboard' && <AHallPass/>} */}
                        {type === 'dashboard' && <div className="btn bg-light me-2" onClick={e => handleShow(undefined, 'hallPassBoxModal', {title: "Hall Passes"})} role="button">
                            <ActiveEvent/>
                        </div>}
                        {type === 'events' && <MessageCenter />}
                        {(type === 'dashboard' || type === 'colorGrid' || type === 'events') && 
                        <>
                        {classInformationArray.map(entry => {
                            count++
                            if(entry.archived) return
                            return <div key={entry._id} role="button"
                                        className={`badge bg-${entry.period === classInformation.period ? 'primary' : 'light'} p-2 m-1`}
                                        onClick={e => handlePeriodSelectLocal(entry.period)}
                                    >{entry.informationStrings ? entry.informationStrings.periodAbbreviation : entry.period}</div>
                        })}
                        </>}
                        <span className="d-none d-md-inline-block pe-2 ps-2">{classInformation.period}</span>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </ErrorBoundary>
    )
}


function RecordDate({month, date, updateTime}) {

    const monthString = ["", "Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    const datesString = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    var dateItem = new Date()
    dateItem.setMonth(month - 1)
    dateItem.setDate(date)

    // console.log(month,'month', date, 'date', dateItem, )

    var dateVar = new Date()
    var dateString = month === dateVar.getMonth() + 1 && date === dateVar.getDate() ? 'Today' : `${datesString[dateItem.getDay()]} ${monthString[month]} ${date}`

    return (
        <div className="d-flex align-items-center" style={{whiteSpace:"initial"}}>
        <span className="me-2">{`Date: ${dateString}`}</span>
        <input
          type="number" className={`form-control completedWatch m-1 p-1`}  min={1}  max={12}
          value={month} style={{width:"40px"}}
          onChange={e => updateTime(e, 'month')}
        />{` / `}
        <input
          type="number" className={`form-control completedWatch m-1 p-1`} min={1}  max={31}
          value={date} style={{width:"40px"}}
          onChange={e => updateTime(e, 'date')}
        />
        
        </div> 
    )
}

function useNetwork() {
    const [isOnline, setNetwork] = useState(window.navigator.onLine);
  
    const updateNetwork = () => {
      setNetwork(window.navigator.onLine);
    };
  
    useEffect(() => {
      window.addEventListener("offline", updateNetwork);
  
      window.addEventListener("online", updateNetwork);
  
      return () => {
        window.removeEventListener("offline", updateNetwork);
  
        window.removeEventListener("online", updateNetwork);
      };
    });
  
    return isOnline;
  }

  function NoConnection({}) {
     
    const {initialLoad, studentDataFunctions: {setInitialLoad}} = useContext(SiteContext)

    function changeOfflineMode(e, boolean) {
        e.preventDefault()
        // const currentLoad = initialLoad
        setInitialLoad(!initialLoad)
        // console.log(boolean,'boolean')
    }

    // return <button className="btn btn-primary">Click for Offline Mode</button>
    return <div className={`btn bg-${initialLoad ? 'success' : 'danger'} text-white me-2`} onClick={changeOfflineMode}>
        Offline Mode
        <div role="group" className="btn-group-sm btn-group">
          <div className={`badge bg-white text-${initialLoad ? 'success' : 'danger'}`}>{initialLoad ? 'On' : 'Off'}</div>
          {/* <div className="badge bg-light">Off</div> */}
        </div>
    </div>
  }
import React, { useState, useContext, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import InProgressOverlay from './InProgressOverlay';
import CompleteEvent from '../../../modules/checklist/dashboard/CompleteEvent';

import { SiteContext } from '../../../App';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLeft, faCheck, faCircleCheck, faCircleRight } from '@fortawesome/free-solid-svg-icons';
import ModalDialog from 'react-bootstrap/esm/ModalDialog';

export default function ChecklistModal({entryImport, activeChart, setActiveChart, grades, checklistCount, firstDate, secondDate, student, setTableCopied}) {
  
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const {classInformation, classInformationArray, studentList, focusAgreements, socket, room, studentDataFunctions: {setFocusAgreements, updateMongo, getMongoByUniqueId}} = useContext(SiteContext)

  const [localFocus, setLocalFocus] = useState({})
  const [activeEntry, setActiveEntry] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  
  async function handleShow2Local(e, item) {
    e.preventDefault()
    const output = await getFocus(true)
    console.log(item,'item local', output)
    setActiveEntry(item)
    setShow2(true);
    if(grades) {
      setTableCopied(false)
    }
  }
  // console.log('I am here 1st', student, activeEntry)

  async function getFocus(local) {
    // console.log('1', student, show)
    if(!local && (!student || !show)) return
    console.log('2', student.period, local, show)
    var index = classInformationArray.findIndex(item => item.period === ((student && student.period) ? student.period : classInformation.period))
    // console.log(classInformationArray[index],'classInformationArray[index]')
    const editEntry = await getMongoByUniqueId(classInformationArray[index].focusAgreementId, 'focusAgreements')

    var index0 = editEntry.modules.findIndex(item => item.title === student.module)
        if(index0 === -1) return
    var index1 = editEntry.modules[index0].checklists.findIndex(item => item.groupNumber === student.groupNumber)
      if(index1 === -1) return

    const combined = {...editEntry.modules[index0], period: student.period, groupNumber: student.groupNumber, checklist: editEntry.modules[index0].checklists[index1].checklist}
    setLocalFocus(combined)
    return combined
  }

  useEffect(() => {

    getFocus()

  }, [show, student])

  var entry = student ? localFocus : entryImport

  if(entry.period) {
    // console.log(entry, 'entry', entry.period)
  }

  async function handleChangeStatus(e, stringStatus, selectedEntry, skipComplete) {
      e.preventDefault()

      console.log(stringStatus,'stringStatus')
      // var completeEvent = {
      //   date: Date.now(),
      //   name: 'Smith',
      //   studentId: '123456',
      //   period: classInformation.period
      // }
      var index = classInformationArray.findIndex(entry => entry.period === ((student && student.period) ? student.period : classInformation.period))
      var localLoad = await getMongoByUniqueId(classInformationArray[index].focusAgreementId, 'focusAgreements')
      // if(!editFocusAgreements && !student) {
      //   var index = classInformationArray.findIndex(entry => entry.period === ((student && student.period) ? student.period : classInformation.period))
      //   console.log(classInformationArray[index],'classInformationArray[index]')
      //   localLoad = await getMongoByUniqueId(classInformationArray[index].focusAgreementId, 'focusAgreements')

      //   // editFocusAgreements = localLoad
      // } else {
      //   localLoad = false
      // }

      var editFocusAgreements = !student ? {...focusAgreements} : {...localLoad}

      console.log(editFocusAgreements,'editFocusAgreements', localFocus)

      var index = editFocusAgreements.modules.findIndex(item => item.title === entry.title)
        if(index === -1) return
        console.log('1', student)
      var index1 = editFocusAgreements.modules[index].checklists.findIndex(item => item.groupNumber == (student && student.groupNumber ? student.groupNumber : entry.groupNumber))
        if(index1 === -1) return
        console.log('2', editFocusAgreements.modules[index].checklists)
      var index2 = editFocusAgreements.modules[index].checklists[index1].checklist.findIndex(item => item.itemId === selectedEntry.itemId)
      // editFocusAgreements.modules[index].checklist.findIndex(item => item.itemId === selectedEntry.itemId)
        if(index2 === -1) return
        console.log('3')

      const editEntry = {...editFocusAgreements.modules[index].checklists[index1].checklist[index2], ...selectedEntry}

      if(stringStatus === 'Not Complete') { // --> in progress
        editFocusAgreements.modules[index].checklists[index1].checklist.map(entry => {
          entry.inProgress = false
        })
        editEntry.inProgress = true
      } else if(stringStatus === 'In Progress') { // --> pending
        editEntry.complete = true
        // editEntry.completeEvent = {...completeEvent}
      } else if(stringStatus === 'Pending' && !skipComplete) { // --> complete
        editEntry.completeAdmin = true
        editEntry.complete = true
        editEntry.completeEvent = editEntry.completeEvent && editEntry.completeEvent.date && (editEntry.completeEvent.date > getMonday(Date.now() - 86400000*7)) ? editEntry.completeEvent : {
          date: Date.now(),
          name: 'Mr. Smith',
          studentId: '123456',
          period: entry.period ? entry.period : classInformation.period,
          groupNumber: (student && student.groupNumber) ? student.groupNumber : entry.groupNumber,
          // ...editEntry.completeEvent
        }
        updateStudents(e, editEntry, entry)
      } else if(stringStatus === 'Complete' || (stringStatus === 'Pending' && skipComplete)) { // --> not complete
        editEntry.excused = true
        // editEntry.completeAdmin = false
        // editEntry.complete = false
      } else if(stringStatus === 'Excused') {
        editEntry.excused = false
        editEntry.inProgress = false
        editEntry.complete = false
        // editEntry.completeEvent = false
        editEntry.completeAdmin = false
      }

      editFocusAgreements.modules[index].checklists[index1].checklist[index2] = {...editEntry}

      console.log('hello', editFocusAgreements, editFocusAgreements.modules[index], editEntry, editFocusAgreements.modules[index].checklists[index1], student)
      updateMongo({modules: [...editFocusAgreements.modules]}, editFocusAgreements._id, 'focusAgreements')
      student ? 
        setLocalFocus({...editFocusAgreements.modules[index], period: student.period, groupNumber: student && student.groupNumber , checklist: editFocusAgreements.modules[index].checklists[index1].checklist}) : 
        setFocusAgreements(editFocusAgreements)
      console.log(editFocusAgreements,'editFocusAgreements')
      socket.emit("send_updateProgressChecklist", { focusAgreements: editFocusAgreements, admin: true, room: entry.period ? entry.period : classInformation.period });
  }

  function handleShowLocal(e, item) {
    e.preventDefault()
    handleShow()
    setActiveEntry(item)
  }

  function updateStudents(e, editEntry, selectedEntry) {

    e.preventDefault()

    // console.log('here we are')
    studentList.map(entry2 => {
      
      // console.log(entry2,'entry2')
      if(entry2.module !== entry.title) return
      // console.log('2')
      if(entry2.groupNumber !== (student && student.groupNumber ? student.groupNumber : entry.groupNumber)) return
      // console.log('3',entry2.groupNumber, entry2.name)
      if(entry2.period !== (entry.period ? entry.period : classInformation.period)) return
      // console.log('4')

      if(entry2.moduleCompletions) {
        entry2.moduleCompletions = entry2.moduleCompletions.filter(item2 => item2.itemId !== editEntry.itemId)
        // if(length > 0) return
      }

      entry2.moduleCompletions = entry2.moduleCompletions && typeof entry2.moduleCompletions === 'object' ? [...entry2.moduleCompletions, editEntry] : [editEntry]
      // console.log(entry2.moduleCompletions,'entry.moduleCompletions', entry2.name)

      updateMongo({moduleCompletions: entry2.moduleCompletions}, entry2.dailyListId, 'dailyList')
      socket.emit("send_updateSingleStudent", { student: {...entry2}, room: entry2.period });
    })
  }
  
  var complete = 0
  var pending = 0
  var excused = 0
  var checklistLength = 0

  if(!entry.checklist && show2) return
  // if(!entry.checklist && !show) return

  // console.log(entry,'entry', student,'student')

  entry && entry.checklist && entry.checklist.map(item => {

    if(item.type === 'Test') return
    // console.log(item,'item')

    if(item.complete && item.completeAdmin) {
      complete++
    } else if(item.complete) {
      pending++
    }

    if(item.excused) {
      excused++
    }

    if(item.designBriefTask !== 'Overview') {
      checklistLength++
    }

  })

  checklistLength = checklistLength - excused < 1 ? 1 : checklistLength - excused
  var total = pending+complete-excused
  var percent = Math.round((pending+complete-excused)/(checklistLength)*100)

  var percentComplete = (complete)/(checklistLength)*100
  var percentPending = (pending)/(checklistLength)*100
  
  var checklist = entry.checklist
  // entry.checklists && entry.checklists[0].checklist

  // if(!checklist || !entry.checklists) return
  checklist && checklist.sort( compare );

  // console.log(checklist,'checklist',entry.checklists[0].checklist, entry.fullTitle)
  // console.log(focusAgreements.modules, entry, 'entry')

  const moduleCompletions = []

  // console.log(entry.checklists,'entry.checklists')
  var checklist2 = entry.checklist

  checklist2 && checklist2.map(entry => {
    if(!entry.completeAdmin) return
    if(entry.type === 'Test') return
    moduleCompletions.push(entry)
  })

  var finalCompletions = moduleCompletions.filter(entry => entry.completeEvent.date > getMonday(Date.now())).length

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

    const newDate = new Date(d.setDate(diff));
    newDate.setMinutes(0)
    newDate.setHours(0)
    newDate.setSeconds(0)

    // return newDate.getTime() - 7 * 24 * 60 * 60 * 1000
    return newDate.getTime() 
    // - 86400000*7
  }

  var color = finalCompletions > 2 ? 'success' : finalCompletions > 0 ? 'info' : 'secondary'
  const pendingCompletions = checklist && checklist.filter(entry => entry.complete && entry.completeEvent && !entry.completeAdmin && entry.completeEvent.date > getMonday(Date.now()))

  const reversed = student && student.moduleCompletions ? [...student.moduleCompletions].reverse() : []
  // console.log(reversed,'reversed', student.moduleCompletions)

  const absences = [
    {absences: 5, score: 100, variant: 'danger'},
    {absences: 4, score: 85, variant: 'flag'},
    {absences: 3, score: 70, variant: 'warning'},
    {absences: 2, score: 40, variant: 'secondary'},
    {absences: 1, score: 40, variant: 'light'},
    {absences: 0, score: 40, variant: 'light'},
  ]

  if(student && student.absentCount) {
    // console.log(student.absentCount,'student.absentCount', student.name)
    var index = absences.findIndex(item2 => item2.absences === student.absentCount)
    var absentVariant = index === -1 ? 'info' : absences[index].variant
    if(student.absentCount > 5) {
      absentVariant = 'danger'
    }
  }

  var count = 0

  // console.log('I am here', acti)
  return (
    <>
      {activeEntry && <CompleteEvent item={activeEntry} period={entry.period} entry={entry} setActiveEntry={setActiveEntry} show={show} handleShow={handleShow} handleClose={handleClose} grades={true} />}
      {grades ? <div onClick={e => handleShow2Local(e, entry)} role="button" className={`badge ${student.absentCount > 0 && 'border border-4'} border-${absentVariant} ${checklistCount > 85 ? 'fw-bolder fst-italic bg-success' : checklistCount > 70 ? 'bg-success' : checklistCount > 40 ? 'bg-warning' : 'bg-danger'}`}>{checklistCount}</div> :
      <div>
        <div className="d-flex justify-content-between flex-wrap">
          <InProgressOverlay entry={entry} handleShow={handleShow2} setActiveChart={setActiveChart} />
          <span className="d-flex align-items-center flex-wrap" style={{fontSize:"12px"}}>
            <span className="text-black m-2 mt-0 mb-0 ms-auto">{percent}%</span>
            <span className="text-black m-2 mt-0 mb-0 ms-auto">{total}/{checklistLength}</span>
            <div className={`badge bg-${color} text-white m-2 mt-0 mb-0 me-0 ms-auto`} onClick={handleShow2} role="button">
              {finalCompletions}
            </div>
            {pendingCompletions && pendingCompletions.length > 0 && <div className={`badge text-success bg-white ms-1 border border-2 border-success`} style={{fontSize:"7px"}} onClick={handleShow} role="button">+{pendingCompletions && pendingCompletions.length}</div>}
          </span>
        </div>
        <div className="progress-bar-xs progress mb-2 mt-1 bg-white" onClick={handleShow2} role="button">
          <div
            className={`progress-bar bg-success`} 
            role="progressbar" style={{ width: percentComplete+'%'}}
            // aria-valuenow={percentCompleteInt} 
            aria-valuemin={0} aria-valuemax={100}
          />
          <div
            className={`progress-bar bg-info`} 
            role="progressbar" style={{ width: percentPending+'%'}}
            // aria-valuenow={40} 
            aria-valuemin={0} aria-valuemax={100}
          />
        </div>
      </div>}

      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{entry.title} - {entry.groupNumber}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
        
        <div className="d-flex row m-1 flex-fill mt-3 mb-3">
          <h3 className="">Checklist</h3>
          {checklist && checklist.map(item => {
            // console.log(item,'item')
              if(item.type === 'Test') return
              return <CardEntry key={item.itemId} item={item} firstDate={firstDate} secondDate={secondDate} grades={grades} handleChangeStatus={handleChangeStatus} getMonday={getMonday} entry={entry} handleShowLocal={handleShowLocal} />
          })}
        </div>
        </Modal.Body>
        {grades && <Modal.Body>
          <h3 className="">Module Completions</h3>
            {reversed.map(item => {
              count++
              return (
                <CardEntry key={item.itemId + count} item={item} firstDate={firstDate} secondDate={secondDate} grades={grades} handleChangeStatus={handleChangeStatus} getMonday={getMonday} entry={entry} />
              )
            })}
        </Modal.Body>}
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function CardEntry({item, firstDate, secondDate, grades, getMonday, handleChangeStatus, handleShowLocal}) {

  var bgColor = item.excused  ? 'bg-black text-white' :
    item.completeAdmin  ? 'bg-success text-white' :
    item.complete ? 'bg-info text-white' :
    item.inProgress ? 'bg-secondary text-white' : 'bg-light text-secondary'

  var stringStatus = item.excused  ? 'Excused' :
        item.completeAdmin  ? 'Complete' :
        item.complete ? 'Pending' :
        item.inProgress ? 'In Progress' : 'Not Complete'

  var typeBg = item.type === 'Test' ? 'bg-black' :
    item.type === 'Activity' ? 'bg-purple' :
    item.type === 'Design Brief' ? 'bg-primary' : 'bg-secondary'

  var showCheck = false
  if(grades) {
    showCheck = item.completeEvent && item.completeEvent.date > firstDate && item.completeEvent.date < secondDate && true
  } else {
    showCheck = item.completeEvent && item.completeEvent.date > getMonday(Date.now()) && true
  }

  function getTypeBg(type) {
    return type === 'Test' ? 'bg-black' :
           type === 'Activity' ? 'bg-purple' :
           type === 'Design Brief' ? 'bg-primary' : 'bg-secondary'
  }    

  function prettyDate(dateInput) {
    var date = new Date(dateInput)
    // console.log(date, 'inside---')
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    var hours = ((date.getHours() + 11) % 12 + 1);
    var minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()

    return `${days[date.getDay()]} ${months[date.getMonth()]} ${date.getDate()}`
    // ${hours}:${minutes}`

  }

  // console.log(item, 'item')
  return (
    <div className="" key={item.item}>
    {/* <div className="row"> */}
      <div className="card shadow-none">
        <div className="card-body">
            <div className="d-flex justify-content-between">
              <span className={`text-wrap ${item.type === 'Design Brief' && item.designBriefTask !== 'Overview' && 'ms-3'}`}>
                {/* {item.completeAdmin || item.complete ? 
                : */}
                <div className={`me-2 badge ${getTypeBg(item.type)}`} onClick={e => {
                  if(item.completeAdmin || item.complete) {handleShowLocal(e, item)} 
                }} role={`${(item.completeAdmin || item.complete) && `button`}`}>{item.day}: {item.type}</div>
                <span className="text text-wrap me-2">
                    {item.item}
                </span> 
                {item.completeEvent && item.completeAdmin && <div className="badge bg-secondary">{prettyDate(item.completeEvent.date)} - {item.completeEvent.name}</div>}
              </span>
              <div className="d-flex align-items-center ms-3 me-0">
              {(item.type !== 'Design Brief' || (item.type === 'Design Brief' && item.designBriefTask !== 'Overview')) && 
              <>
              <FontAwesomeIcon icon={faCircleRight} onClick={e=>handleChangeStatus(e, stringStatus, item, true)} className="fa-lg m-1" role="button" />
              <div className={`badge ${bgColor} d-inline d-flex align-items-center`}>
                <span className="m-1 me-0 ms-0">{stringStatus}</span>
                {item.completeEvent && item.completeAdmin && showCheck && <FontAwesomeIcon className={`text-white m-1 me-0`} icon={faCircleCheck} />}
              </div>
              {!item.completeAdmin && <div className={`badge bg-success d-inline d-flex align-items-center ms-1`} onClick={e=>handleChangeStatus(e, 'Pending', item)} role="button">
                <span className="m-1 me-0 ms-0 text-success">{`R`}</span>
              </div>}
              </>}
              </div> 
            </div> 
        </div> 
      </div> 
    </div>
  )
}

function compare( a, b ) {
  if ( a.index < b.index ){
    return -1;
  }
  if ( a.index > b.index ){
    return 1;
  }
  return 0;
}

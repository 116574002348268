import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpLong, faCircleUp, faCircleDown } from '@fortawesome/free-solid-svg-icons';

export default function ColorTitle({icon, text, variant}) {
    return (
      <div className="d-flex align-items-stretch">
        <div className={`badge p-2 bg-${variant} text-white d-flex align-items-center`}>
            <p className="m-1 d-inline">{text}</p>
            <FontAwesomeIcon className="m-0 ms-1 me-1 card-title d-inline text-white" icon={icon} />
        </div>
      </div>
    )
}

import React, { Component } from "react";
import { render } from "react-dom";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

import { ReactSketchCanvas } from 'react-sketch-canvas';

// import CanvasDraw from "react-canvas-draw";
import ColorPicker from "./ColorPicker";
import SliderPicker from './SliderPicker'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faX, faPlus, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

// import CanvasDraw from "../../src";
// import classNames from "./index.css";

class DrawCanvas extends Component {

  state = {
    ...this.props,
    // color: "#ffc600",
    // width: 400,
    // height: 400,
    // brushRadius: 10,
    // lazyRadius: 12,
    // backgroundImg: "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg",
    // imgs: [
    //   "https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg",
    //   "https://i.imgur.com/a0CGGVC.jpg"
    // ]
  };

  constructor(props) {
    super(props);
    this.state = {dimensions: {}, showMore: false, ...this.state};
    this.onImgLoad = this.onImgLoad.bind(this);
    this.handleMore = this.handleMore.bind(this);
    this.canvas = React.createRef();
   }


  onImgLoad({target:img}) {
    this.setState({dimensions:{height:img.offsetHeight,
                               width:img.offsetWidth}});


                               console.log(img.offsetHeight, 'height', img.offsetHeight)
  }

  handleMore() {
    //   e.preventDefault()
    //   console.log(e.target.value,'e.target.value', e.target)
    var currentVal = this.state.showMore
    this.setState({showMore: !currentVal});
  }

  componentDidMount() {
    // let's change the color randomly every 2 seconds. fun!
    // window.setInterval(() => {
    //   this.setState({
    //     color: "#" + Math.floor(Math.random() * 16777215).toString(16)
    //   });
    // }, 2000);

    // let's change the background image every 2 seconds. fun!
    // window.setInterval(() => {
    //   if (
    //     this.state.imgs &&
    //     this.state.imgs.length &&
    //     this.state.backgroundImg
    //   ) {
    //     let img = '';
    //     let imgs = this.state.imgs;
    //     for (let i = 0; i < imgs.length; i++) {
    //       if (this.state.backgroundImg !== imgs[i]) {
    //         img = imgs[i];
    //       }
    //     }

    //     this.setState({
    //       backgroundImg: img,
    //     });
    //   }
    // }, 2000);

  }

  
  render() {
    // console.log()
    // console.log(this.canvas,'this.canvas.current')
    return (
      <ErrorBoundary>
      <div className="w-100">
        <div className="d-flex justify-content-between align-items-center">
          <span className="m-2 fsize-3">{this.props.customArray.text}</span>
          {/* <h1 className="m-2">Hi</h1> */}
          <FontAwesomeIcon className="fsize-3 me-2" role="button" icon={faX} onClick={this.props.handleClose} />
        </div>
        
        {!this.state.dimensions.width && <img src={`${this.props.url}?${new Date().getTime()}`} onLoad={this.onImgLoad} className="m-0 viewModalImage"/>}
        {/* {this.state.dimensions.width && <img src={`${this.props.url}?${new Date().getTime()}`} className="m-0 viewModalImage"/>} */}
        {/* <p>Try it out! Draw on this white canvas:</p> */}
        {/* <CanvasDraw brushColor={this.props.drawArr.color} 
        onChange={() => {
            // localStorage.setItem(
            //     "savedDrawing",
            //     this.saveableCanvas.getSaveData()
            //   );
        }} 
        brushRadius={this.props.drawArr.brushRadius} 
        imgSrc={this.props.url} enablePanAndZoom clampLinesToDocument
        ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
        saveData={localStorage.getItem("savedDrawing")}
        canvasWidth={this.state.dimensions.width ? this.state.dimensions.width : 400}
        canvasHeight={this.state.dimensions.height ? this.state.dimensions.height : 400}
        // imgSrc="https://upload.wikimedia.org/wikipedia/commons/a/a1/Nepalese_Mhapuja_Mandala.jpg"
        /> */}
        <ReactSketchCanvas
          // style={styles}
          ref={this.canvas}
          width={'100%'}
          height={'500px'}
          strokeWidth={this.props.drawArr.brushRadius}
          strokeColor={this.props.drawArr.color}
          backgroundImage={this.props.url}
          preserveBackgroundImageAspectRatio={'xMidYMid'}
        />
        <div className="d-flex justify-content-start align-items-start p-3">
          <div className="me-4">
            <ColorPicker drawArr={this.props.drawArr} updateDrawArr={this.props.updateDrawArr} updateColor={this.updateColor} showMore={this.state.showMore} />
          </div>
          <div>
            <div role="group" className="btn-group-sm btn-group">
                {/* <button className="btn bg-secondary text-white"
                    onClick={() => {
                    localStorage.setItem(
                        "savedDrawing",
                        this.saveableCanvas.getSaveData()
                    );
                    //   console.log(this.saveableCanvas.getSaveData(),'this.saveableCanvas.getSaveData()')
                    }}
                >
                    Save
                </button> */}
                <button className="btn bg-secondary text-white"
                    onClick={() => {
                      this.canvas.current.clearCanvas();
                    }}
                >
                    Clear
                </button>
                <button className="btn bg-secondary text-white"
                    onClick={() => {
                      this.canvas.current.undo();
                    }}
                >
                    Undo
                </button>
                <button className="btn bg-secondary text-white"
                    onClick={() => {
                      this.canvas.current.redo();
                    }}
                >
                    Redo
                </button>
                <button className="btn bg-secondary text-white"
                    onClick={() => {
                      this.canvas.current.eraseMode(true);
                    }}
                >
                    Eraser
                </button>
                <button className="btn bg-secondary text-white"
                    onClick={() => {
                      this.canvas.current.eraseMode(false);
                    }}
                >
                    Pen
                </button>
                {/* <button className="btn bg-secondary text-white"
                    onClick={() => {
                    console.log(this.saveableCanvas.getDataURL());
                    alert("DataURL written to console")
                    }}
                >
                    GetDataURL
                </button> */}
                <button className="btn bg-secondary text-white"
                    onClick={e => {this.handleMore(e)}} value={this.state.showMore}
                >
                    {this.state.showMore ? 'Less Colors' : 'More Colors'}
                </button>
            </div>
            <div>
                {/* <label className="me-1 mt-2">Brush Size:</label>
                <input
                type="number" min={1} max={20}
                value={this.props.drawArr.brushRadius}
                onChange={e => {
                    this.setState({ brushRadius: parseInt(e.target.value, 10) })
                    this.props.updateDrawArr({brushRadius: parseInt(e.target.value, 10)})
                }}
                /> */}
                <SliderPicker brushRadius={this.props.drawArr.brushRadius} setState={this.setState} updateDrawArr={this.props.updateDrawArr} />
            </div>
          </div>
            {/* <h2 className="p-3 pb-0">Custom Brush</h2> */}
        </div>        
      </div>
      </ErrorBoundary>
    );
  }
}

export default DrawCanvas
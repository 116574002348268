import React from 'react';
import Card from './Card';

function SearchList({ filteredPersons }) {
  const filtered = filteredPersons.map( person =>  <Card key={person._id} person={person} />); 
  return (
    <div className="">
      {filtered}
    </div>
  );
}

export default SearchList;
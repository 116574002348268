import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../../../App'
import { AdminContext } from '../../../pages/AdminContainer'
import { ErrorBoundary } from '../../../other/utils/ErrorBoundary'

// import './d_behavior.css'

export default function AuctionEntry({item}) {

    const { 
        colorMatch, studentList, socket, 
        studentDataFunctions: {updateMongo, setStudentList}
    } = useContext(SiteContext)

    const {handleAddChipsManual} = useContext(AdminContext)

    function handleBalance(e, amount) {
        e.preventDefault()

        console.log()
        const editStudentList = [...studentList]
            var index = editStudentList.findIndex(entry => entry.studentId === item.studentId)
            console.log(editStudentList[index].auctionBalance, amount,'editStudentList[index].auctionBalance + amount')
            editStudentList[index].auctionBalance = typeof item.auctionBalance !== 'undefined' ? editStudentList[index].auctionBalance + amount : 200 + amount
            console.log(editStudentList[index].auctionBalance, amount,'editStudentList[index].auctionBalance + amount')
            setStudentList(editStudentList)
            updateMongo({auctionBalance: editStudentList[index].auctionBalance}, editStudentList[index].dailyListId, 'dailyList')
            socket.emit("send_updateSingleStudent", { student: editStudentList[index], room: editStudentList[index].period })
    }

    const change = [
        {amount: -50, variant: 'danger'},
        {amount: -25, variant: 'flag'},
        {amount: -10, variant: 'warning'},
        {amount: -5, variant: 'light'},
        {amount: -1, variant: 'white'},
        {amount: 1, variant: 'light'},
        {amount: 5, variant: 'info'},
        {amount: 10, variant: 'primary'},
    ]

    var count = 0

    console.log(item.auctionBalance,'item.auctionBalance', item.name)
    return (
        <ErrorBoundary>
        <tr>
            <td className="text-center">
                <div className="box">
                    <span className="text">{item.name}</span>
                </div>
            </td>
            <td className="text-center">
                <div className="box">
                    <span className="text">${typeof item.auctionBalance !== 'undefined' ? parseInt(item.auctionBalance) : 200}</span>
                </div>
            </td>
            <td className="text-center">
                <div role="group" className="btn-group-sm btn-group">
                    {change.map(entry => {
                        count++
                        return (
                        <button key={count} onClick={(e) => handleBalance(e, entry.amount)} className={`btn-shadow btn btn-${entry.variant}`}>
                        {entry.amount}
                        </button>
                        )
                    })}
                </div>
            </td>
        </tr>
        </ErrorBoundary>
    )
}

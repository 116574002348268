import React, { useState, useEffect, useContext } from 'react';
import Scroll from '../Scroll';
import SearchList from '../SearchList';
import BalanceList from './BalanceList';
import Orders from '../Orders';
import Auction from './Auction'

import { ErrorBoundary } from '../../../other/utils/ErrorBoundary';
import { SiteContext, baseUrl } from '../../../App';

export default function Search({sizeClass, colorValue, title}) {

  const {  
    studentList, classInformationArray,
    studentDataFunctions: {
        setRecords
    }
  } = useContext(SiteContext)

  const blankBg = () => {
    const array = []
    classInformationArray.map(entry => {
      array.push({period: entry.period, bg: 'light'})
    })
    array.push({period: 'P', bg: 'light'})
    return array
  }

  const [searchField, setSearchField] = useState("");
  const [periodField, setPeriodField] = useState("P");
  const [periodBg, setPeriodBg] = useState(blankBg)
  const [auctionBalance, setAuctionBalance] = useState(false)

  useEffect(() => {
    const editPeriodBg = blankBg()
    editPeriodBg.map(entry => {
      entry.bg = 'light'
    })
    var index = editPeriodBg.findIndex(entry => entry.period === periodField)
      if(index === -1) return
      editPeriodBg[index].bg = 'primary'
      setPeriodBg(editPeriodBg)
  }, [periodField, classInformationArray.length])

  const filteredPersons = studentList.filter(
    person => {
      return (
        person
        .name
        .toLowerCase()
        .includes(searchField.toLowerCase()) 
        &&
        person
        .period
        .toLowerCase()
        .includes(periodField.toLowerCase())
      );
    }
  );

  const handleChange = e => {
    setSearchField(e.target.value);
  };

  // const handleChangePeriod = e => {
  //   if(e.target.value === '-- Select Period --') {
  //     setPeriodField("");
  //     return
  //   }
  //   setPeriodField(e.target.value);
  // }

  const handleChangePeriod = (newPeriod) => {
    setPeriodField(newPeriod);
  }

  function handleToggle(e, action) {
    e.preventDefault()
    setAuctionBalance(action)
  }

  var alphaPersons = filteredPersons.sort((a, b) => a.name.localeCompare(b.name))

  // console.log(filteredPersons,'filteredPersons')
  return (
        <div className={sizeClass}>  
          <div className="main-card mb-3 card">
            <div className="card-header d-flex justify-content-between align-items-center flex-wrap">
              <div className="card-header-title font-size-lg text-capitalize fw-normal d-flex">
                <div className={`badge bg-${auctionBalance ? 'light' : 'secondary'} m-1`} onClick={e => handleToggle(e, false)} role="button">Chips</div>
                <div className={`badge bg-${auctionBalance ? 'secondary' : 'light'} m-1`} onClick={e => handleToggle(e, true)} role="button">Auction</div>
              </div>
              <div className="">
                  {classInformationArray.map(entry => {
                    var index = periodBg.findIndex(item => item.period === entry.period)

                    if(index === -1) return
                    return <div key={entry.period} className={`m-1 p-2 badge bg-${periodBg[index].bg}`} onClick={e => handleChangePeriod(entry.period)} role="button">
                      {entry.informationStrings.periodAbbreviation}</div>
                  })}
              </div>
            </div>
            <ErrorBoundary>
              {auctionBalance ? 
              <Auction handleChange={handleChange} filteredPersons={alphaPersons} />
              : <BalanceList handleChange={handleChange} filteredPersons={alphaPersons} />}
            </ErrorBoundary>
          </div>
        </div>
  );
}



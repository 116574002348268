import React, { useContext, useState, useEffect } from 'react'
import { SiteContext, baseUrl } from '../App';
import { AdminContext } from './AdminContainer';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Behavior from '../behavior/dashboard/Behavior';
import Warnings from '../admin/grid/GridContainer';
import Card from '../admin/adminTopCards/Card'
import CardPercent from '../admin/adminTopCards/CardPercent'
import CardLoggedIn from '../admin/adminTopCards/CardLoggedIn'
import HPCard from '../admin/adminTopCards/HPCard';
import Orders from '../store/admin/Orders';
import DModals from './DModals';
import Checkouts from '../modules/inventory/admin/checkouts/Checkouts';
import CustomModal from '../modals/customModal/admin/AEdit'
import ChecklistBox from '../modules/checklist/dashboard/AChecklistDash';
import ClassPoints from '../admin/adminTopCards/ClassPoints';
import FocusCategories from '../behavior/dashboard/FocusCategories';

import readingZone from '../other/json/readingZone.json'

export default function Dashboard() {

    const {
        classInformation, focusAgreements,
        socket,
        studentList,
        loginState, initialLoad,
        studentDataFunctions: {
            setStudentList,
            checkToday, updateMongo
        }
    } = useContext(SiteContext)

    const { hpMessage, orders, modes, mode } = useContext(AdminContext)

    const [expand, setExpand] = useState(isBrowser ? true : false)

    useEffect(() => {
        
        function sendResponse_Login (studentObj, roomNumber) {
            socket.emit("send_response_Login", { studentObj, room: roomNumber });
        };

        socket.on("receive_message_Login", (data) => {
          if(!loginState.admin) return
    
          const filtered = studentList.filter(entry => entry.studentId === data.studentObj.studentId)[0]
            const checkinObj = data.studentObj.checkIn
    
            if(checkinObj.complete) {
              filtered.checkIn = checkinObj
              filtered.color.pending = data.studentObj.color.pending
            }
            
            var modifiedStudentList = [...studentList]
                const filteredStudentList = modifiedStudentList.filter(entry => entry.studentId !== filtered.studentId)
                filteredStudentList.push(filtered)

                var alphaStudentList = filteredStudentList.sort((a, b) => a.name.localeCompare(b.name))
            
            // setStudentList(alphaStudentList)            
            sendResponse_Login(filtered, data.room)
    
        })
    
        return () => {
          socket.off('receive_message_Login');
        };
    
    }, [socket, studentList])

    var editStudentList = [...studentList]

    var filterBonus = editStudentList.filter(student => 
        student.period === classInformation.period &&
        student.color.current !== 'Purple' && 
        student.color.current !== 'Gold' &&
        student.color.current !== 'Black' && student.attendance !== 'Absent')

    var filterLoggedIn = editStudentList.filter(student => 
        student.period === classInformation.period && student.loggedIn && student.attendance !== 'Absent')

    var filterExcludeAbsent = editStudentList.filter(student => 
        student.period === classInformation.period && student.attendance !== 'Absent')
        
    // var eligiblePercent = Math.round(((nonExcludedLength-filterBonus.length)/nonExcludedLength)*100) + "%"
    var eligiblePercent = Math.round(((filterExcludeAbsent.length-filterBonus.length)/filterExcludeAbsent.length)*100) + "%"
    var loggedInPercent = Math.round((filterLoggedIn.length/filterExcludeAbsent.length)*100) + "%"

    function addRZdata(e) {
      e.preventDefault()
  
      readingZone.map(entry => {
          var index = studentList.findIndex(student => student.email === entry.email)
          if(index === -1) return
          const dateItem = new Date(entry.dateString)
          console.log({readingZoneMin: entry.minNuM, readingZoneDate: dateItem.getTime()},'dateItem')
          updateMongo({readingZoneMin: entry.minNuM, readingZoneDate: dateItem.getTime()}, studentList[index].dailyListId, 'dailyList')
      })
    }

    // console.log(focusAgreements,'focus')

    return (
      <>
        <div className="row">
            <CardLoggedIn percent={loggedInPercent} subtitle={'Logged In'} colorValue={'success'} sizeClass={'col-6 col-sm-4 col-lg-2'}/>
            {/* <CardPercent percent={eligiblePercent} subtitle={'Bonus Eligible'} colorValue={'alt'} sizeClass={'col-6 col-sm-4 col-lg-2'}/> */}
            <ClassPoints percent={eligiblePercent} subtitle={'Class Points'} colorValue={'alt'} sizeClass={'col-6 col-sm-4 col-lg-2'}/>
            <Card subtitle={'Time Elapsed'} colorValue={'warning'} cardType={'elapsed'} sizeClass={'col-6 col-sm-4 col-lg-2'} />
            <Card subtitle={'Time Remaining'} colorValue={'info'} cardType={'remaining'} sizeClass={'col-6 col-sm-4 col-lg-2'} />
            <Card subtitle={'Class End'} colorValue={'primary'} sizeClass={'col-6 col-sm-4 col-lg-2'} />
            <HPCard message={hpMessage.message} subtitle={'Hall Pass'} colorValue={hpMessage.variant} sizeClass={'col-6 col-sm-4 col-lg-2'} />
          <div className="col-12 col-lg-12">
            <Warnings sizeClass={'col-12'} mode={mode} />
          </div>
        </div>
        <div className="row">
            <div className="col-12 col-md-6">
                {expand && mode === 'customModal' && <CustomModal sizeClass={'col-12'} />}
                {!expand && initialLoad && <Behavior />}
                {!expand && initialLoad && <FocusCategories showStopwatch={false} />}
                {!expand && <Checkouts title={'Checkouts'} sizeClass={'col-12'} />}
                {!expand && <Orders title={'Cups'} sizeClass={'col-12'} orders={orders} type={'cups'} context={'dashboard'} />}
                {!expand && <Orders title={'Orders'} sizeClass={'col-12'} orders={orders} context={'dashboard'} />}
                {!expand && <ChecklistBox title={'Module Completions'} sizeClass={'col-12'} />}
            </div>
            <div className="col-12 col-md-6">
                {(!expand || mode === 'customModal') && <DModals setExpand={setExpand} expand={expand} />}
                {/* {!expand && <CustomModal />} */}
                {!expand && mode === 'customModal' && <CustomModal />}
            </div>
        </div>
        <div className="row">
            {expand && mode !== 'customModal' && <DModals setExpand={setExpand} expand={expand} />}
            {expand && initialLoad && <Behavior />}
            {/* {expand && mode === 'customModal' && <CustomModal sizeClass={'col-12 col-md-6'} />} */}
            {/* {expand && <Checkouts title={'Checkouts'} sizeClass={'col-12 col-md-6'} />}
            {expand && <Orders title={'Cups'} sizeClass={'col-12 col-md-6'} orders={orders} type={'cups'} context={'dashboard'} />}
            {expand && <Orders title={'Orders'} sizeClass={'col-12 col-md-6'} orders={orders} context={'dashboard'} />} */}
        </div>
      </>
    )
}

import React, { useContext } from 'react'

import { SiteContext } from '../../App'
import { AdminContext } from '../../pages/AdminContainer'

import Stopwatch from '../../other/utils/Stopwatch'
import calculateTime from '../../other/utils/calculateTime'

export default function Settings( { sizeValue, importFunctions }) {

  const {standings, setStandings, showTimer, handleToggleTimer, timerLength, 
    setTimerLength, startTime, handleStart, handleReset, showEmojis, setShowEmojis} = importFunctions
  const {classInformation} = useContext(SiteContext)
  const {handleUpdateList} = useContext(AdminContext)

  function handleTimerLength(e, value) {
    e.preventDefault()
    setTimerLength(value)
  }

  function handleEmojis(e) {
    e.preventDefault()
    setShowEmojis(prev => {return !prev})
  }

    return (
        <div className={`${sizeValue}`}>
          <div className={`card`}>
            <div className="card-body d-flex flex-wrap">
              <button onClick={e => {
                  e.preventDefault()
                  setStandings(prev => {return !prev})
              }} className={`btn btn-light m-1`}>{standings ? 'Hide Standings' : 'Show Standings'}</button>
              {/* <button onClick={handleUpdateList} className={`btn btn-light m-1`}>Update</button> */}
              <button onClick={handleToggleTimer} className={`btn btn-light m-1`}>{showTimer ? 'Hide Timer' : 'Show Timer'}</button>
              <button onClick={e => handleEmojis(e)} className={`btn btn-light m-1`}>{showEmojis ? 'Hide Emoji' : 'Show Emojis'}</button>
              {showTimer && <div className="mt-3 d-flex flex-wrap">
                <div>
                  <button onClick={e => handleStart(e)} className={`btn btn-secondary m-1`}>{startTime ? 'Restart' : 'Start'}</button>
                  <input 
                    type="number" className={`btn text-center m-auto ms-0 me-0 border border-1 border-secondary`}
                    name="day" min="1" max="300" value={timerLength} 
                    onChange={e => {
                      e.preventDefault()
                      setTimerLength(e.target.value)
                    }}
                  />
                  <button onClick={handleReset} className={`btn btn-secondary m-1`}>Reset</button>
                </div>
                <div className="mt-1">
                {showTimer && presets.map(entry => {
                  return <button key={entry} onClick={e => handleTimerLength(e,entry)} className={`btn btn-light m-1`} style={{fontSize:"12px"}}>{entry} min</button>
                })}
                </div>
              </div>}
              
            </div>
            
          </div>
        </div>
    )
}

const presets = [1,2,3,5,7,10,15,20]
import React from 'react'

export default function useStatus({key, prop}) {

    const hpStatus = [
        {
            message: 'Out',
            variant: 'danger'
        },
        {
            message: '10 Min Rule',
            variant: 'flag'
        },
        {
            message: 'Available',
            variant: 'success'
        },
        {
            message: 'Wait',
            variant: 'warning'
        },
    ]

    var index = hpStatus.findIndex(entry => entry[key] === prop)

    // return hpStatus[index]

    return {
        message: hpStatus[index].message,
        variant: hpStatus[index].variant
    }
}

import React, {Component, useContext} from 'react'

import Rating from 'react-rating'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

class RatingComponent extends Component {

  constructor(props) {
    super(props)
    this.state = {
      value: this.props.reviewRating ? this.props.reviewRating : 0,
    }
  }

  handleRatingChange = (rating) => {
    this.setState({...this.state, value: rating});
    this.props.updateChecklist(false, this.props.itemId, 'completeEvent', 'rating', rating)
  }

  render() {
    var count = 0
    var elements = [1,2,3,4,5]
    // var match = this.props.colorMatch.filter(entry => entry.level === this.state.value)[0]
    
    return (
        <div className="d-flex align-items-center">
        <Rating 
        onChange={this.handleRatingChange} 
        initialRating={this.state.value}
        stop={5}
        emptySymbol={elements.map(entry => {
          count++
        //   if(entry.color === 'White' && this.props.type !== 'moduleDay') return
          return <FontAwesomeIcon value={entry} style={{color: 'white', marginLeft:"3px", marginRight:"3px"}} icon={faSquare} className="fa-lg" />
        })}
        fullSymbol={elements.map(entry => {
          return <FontAwesomeIcon style={{color: "#3ac47d", marginLeft:"3px", marginRight:"3px"}} icon={faSquare} className="fa-lg" />
        })}
      />
      {this.state.value > 0 && <div className="badge bg-white text-success ms-3 border border-success border-2 p-1" style={{fontSize:"0.9rem"}}>{this.state.value}</div>}
      {/* {this.props.type !== 'moduleDay' && match.color !== 'White' && <div className="d-block mt-2 ms-1" style={{color: match.hex, display:"inline",fontWeight:"bold", fontSize:"12px"}}>{`${match.color}`}</div>} */}
      </div>
    );
    
  }
}

export default RatingComponent
import React, { useContext } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { ErrorBoundary } from '../other/utils/ErrorBoundary';
import { SiteContext } from '../App';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export default function SummaryChart({title, colorValue, sizeClass, filteredReviewEntries, type, variants}) {

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: variants.length > 10 ? 1 : 2,
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
      x: {
        max: 140,
        min: 0,
        ticks: {
          stepSize: 20,
          autoSkip: false,
          display: true,
          callback: function(value, index, ticks) {
            if(value > 100) return ''
            return `${value}%`;
          }
        },
        grid: {
          display: false,
          drawBorder: false, 
        },
      },
      y: {
        // position: 'right',
        ticks: {
          color: function (context, value) {
            // console.log(context, 'context.dataset.label', context.tick.label)
            var selection = variants
            var key = 'category'
            var labelVal = context.tick.label
            // console.log(labelVal,'labelVal')
            var index = selection.findIndex(entry => entry[key] === labelVal)
              if(labelVal === 'White') return '#343a40'
              return selection[index].hex
          },
          crossAlign: "far",
          font: function (context) {
            var size =  type === 'colors' ? context.chart.height/28 : context.chart.height/10
            size = size < 12 ? 12 : size; // setting max limit to 12
            size = size > 16 ? 16 : size; 
            // console.log(size, 'size')
            return {
              weight: 'bold',
              size,
              color: 'red'
            }
          },
        },
        grid: {
          display: false,
          drawBorder: false, 
        },
      }
    },
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false // <-- this option disables tooltips
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        borderRadius: 10,
        // color: 'black',
        padding: function (context) {
          var size = context.chart.height/24
          size = size < 4 ? 4 : size; // setting max limit to 12
          // console.log(context.chart.height, 'context.chart.height')
          return size
        },
        font: function (context) {
          var size = type === 'colors' ? context.chart.height/30 : context.chart.height/15

          size = size < 12 ? 12 : size; // setting max limit to 12
          size = size > 16 ? 16 : size;
          return {
            weight: 'bold',
            size,
          }
        },
        formatter: function (value, context) {
          // console.log(context, 'context')
          // var index = colorMatch.findIndex(entry => entry.string === context.dataset.label)
            const matcher = variants
            var string = matcher[context.dataIndex].string 
          if(value === 0) {return ''} else if(matcher === colorMatch) {
            // return `${string}     ${parseInt(value/filteredReviewEntries.length*100)}% (${value})`
            var number = Math.round(value/100*filteredReviewEntries.length)
            return `${value}% (${number} time${number > 1 ? 's' : ''})`
          } else {
            var number = Math.round(value/100*filteredReviewEntries.length)
            return `${value}% (${number} time${number > 1 ? 's' : ''})`
          }
        },
        offset: function (context) {
          var size = type === 'colors' ? -context.chart.height/20 : -context.chart.height/10
          size = size < -2 ? -2 : size; // setting max limit to 12
          return size
        },
        color: function (context, value) {
          if(value > 100) return
          // console.log(context, 'context.dataset.label')
          // var selection = (context.tick.label === 'Present' || context.tick.label === 'Tardy' || context.tick.label === 'Absent') ? attendance : colorMatch
          // var key = selection === attendance ? 'attendance' : 'color'
          // var labelVal = (context.tick.label === 'Present' || context.tick.label === 'Tardy' || context.tick.label === 'Absent') ? context.tick.label : context.tick.label.split(' ')[1]
          // var index = selection.findIndex(entry => entry[key] === labelVal)
          //   if(labelVal === 'White') return '#343a40'
          //   return selection[index].hex
        },
    },
    },
  };
  

  const {colorMatch} = useContext(SiteContext)

  const attendance = [
    {attendance: 'Present', hex: '#3ac47d', string: 'Present'},
    {attendance: 'Tardy', hex: '#fd7e14', string: 'Tardy'},
    {attendance: 'Absent', hex: '#d92550', string: 'Absent'},
  ]

  const border = []
  const bgColor = []
  const dataVal = []
  const labels = []

  function createData(obj, key) {
    const opacity = percentToHex(80)

    // console.log(obj, key,'obj, key', filteredReviewEntries)

    
    obj.map(entry => {

      // var filter2 = filteredRefviewEntries.filter(item => item.category === entry.category)
      // console.log(entry.category, 'cat', filter2)
      border.push(entry.hex)
      bgColor.push(entry.hex + opacity)
      labels.push(entry.category)
      
      const filter = filteredReviewEntries.filter(item => item[key] === entry[key])
      dataVal.push(parseInt(filter.length/filteredReviewEntries.length*100))
    })
  }

//   console.log(dataVal,'dataVal')
  const dataColors = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        // data: [1,2,3,4],
        data: dataVal,
        borderColor: border,
        backgroundColor: bgColor,
        datalabels: {
          align: 'right',
          anchor: 'end',
        },
      },
    ],
  };

  const dataAttendance = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        // data: [1,2,3,4],
        data: dataVal,
        borderColor: border,
        backgroundColor: bgColor,
        datalabels: {
          align: 'right',
          anchor: 'end',
        },
      },
    ],
  };
  
//   createData(variants, 'color')
  createData(variants, 'category')
//   type === 'colors' ? createData(colorMatch, 'color') : createData(attendance, 'attendance')

  const data = type === 'colors' ? dataColors : dataAttendance

  return (
    <ErrorBoundary>
      <div className={`${sizeClass}`}>
        <div className={`card-shadow-${colorValue} mb-3 widget-chart widget-chart2 text-start card`}>
          <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className={`widget-content-left pe-2 fsize-1 text-${colorValue}`}>
                {/* <div className="widget-numbers mt-0 fsize-3 text-white"> */}
                  <div className="widget-numbers mt-0 fsize-3">
                      {title}
                  </div>
                </div>
              </div>
              <div className="widget-content-left fsize-1 text-muted">
                <Bar options={options} data={data} plugins={[ChartDataLabels]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
  
}


const percentToHex = (p) => {
  const percent = Math.max(0, Math.min(100, p)); // bound percent from 0 to 100
  const intValue = Math.round(p / 100 * 255); // map percent to nearest integer (0 - 255)
  const hexValue = intValue.toString(16); // get hexadecimal representation
  return hexValue.padStart(2, '0').toUpperCase(); // format with leading 0 and upper case characters
}
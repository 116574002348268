import React, {useState, useContext} from 'react'

import DeleteModal from './DeleteModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faX, faPlus } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid'

import { SiteContext } from '../../../App';

export default function ArrayItems({entry, dataFunctions, arrayCategory}) {

  const {modifiedPeriod, setModifiedPeriod, editHeading, setEditHeading, arrays} = dataFunctions

  const { classInformation, studentDataFunctions: { updateMongo } } 
  = useContext(SiteContext)

  const [show, setShow] = useState(false)
  const [modalInfo, setModalInfo] = useState({})

  function handleChange(e, type, category, categoryEntry) {
    // e.preventDefault()

    const modifyHeading = [...modifiedPeriod[editHeading.key]]
    var index = modifyHeading.findIndex(entry => entry.category === category)
    if(type === 'categoryEntry') {
        var entryIndex = modifyHeading[index].elements.findIndex(entry => entry.entry === categoryEntry)
        modifyHeading[index].elements[entryIndex] = {...modifyHeading[index].elements[entryIndex], entry: e.target.value}
    } else if(type === 'adminStatus') {
      var entryIndex = modifyHeading[index].elements.findIndex(entry => entry.entry === categoryEntry)
      modifyHeading[index].elements[entryIndex].adminStatus = !modifyHeading[index].elements[entryIndex].adminStatus
      console.log(modifyHeading[index].elements[entryIndex],'modifyHeading[index].elements[entryIndex]')
    } else if(type === 'category') {
        modifyHeading[index] = {...modifyHeading[index], category: e.target.value}
    }
    // console.log(modifyHeading,'modifyHeading')
    setVariables(modifyHeading)
    // console.log(modifyHeading,'modifyHeading-----', {...modifiedPeriod, [editHeading.key]: [...modifyHeading]})
  }

  function handleIndex(e, action, index) {
    // e.preventDefault()
      
    var modifyHeading = [...modifiedPeriod[editHeading.key]]
    // console.log(index,'index', modifiedPeriod[editHeading.key],'modifiedPeriod[editHeading.key]', modifyHeading[index], action)
    var element = modifyHeading[index]
    var change = action === 'raise' ? -1 : 1
      modifyHeading.splice(index, 1)
      modifyHeading.splice(index + change, 0, element)
    // console.log(index,'index', modifiedPeriod[editHeading.key],'modifiedPeriod[editHeading.key] AFTRE',index + change)
    setVariables(modifyHeading)
  }

  function handleCreate(e, type, category) {
    console.log('creating', modifiedPeriod)
    var modifyHeading = [...modifiedPeriod[editHeading.key]]

    if(type === 'categoryEntry') {
      var index = modifyHeading.findIndex(entry => entry.category === category)
      modifyHeading[index].elements.splice(modifyHeading[index].elements.length, 0, newCategoryEntry())

    } else if(type === 'category') {
      modifyHeading.splice(modifyHeading.length, 0, newCategory(modifyHeading.length))
    }
    console.log(modifyHeading,'modifyHeading creaye')
    setVariables(modifyHeading)
  }

  function handleDeleteFinal(type, category, categoryEntry) {
    const modifyHeading = [...modifiedPeriod[editHeading.key]]
    var index = modifyHeading.findIndex(entry => entry.category === category)
    if(type === 'categoryEntry') {
        var entryIndex = modifyHeading[index].elements.findIndex(entry => entry.entry === categoryEntry)
        modifyHeading[index].elements.splice(entryIndex, 1)
    } else if(type === 'category') {
        modifyHeading.splice(index, 1)
    }
    setVariables(modifyHeading)
  }

  function setVariables(modifyHeading) {
    setModifiedPeriod(prev => {return {...prev, [editHeading.key]: [...modifyHeading]}})
    setEditHeading(prev => {return {...prev, value: [...modifyHeading]}})
    // delete modifiedPeriod._id
    // console.log(classInformation,'classInformation', {classRoles: newCategory(), focusSchedule: newCategory()})
    // updateMongo({focusSchedule}, modifiedPeriod.id, 'classPeriods')
  }

  function handleDelete(e, type, category, categoryEntry) {
    e.preventDefault()
    console.log('lciked---',e, type, category, categoryEntry)
    setModalInfo({type, category, categoryEntry})
    setShow(true)
  }


  var editFunctions = { handleChange, handleIndex, handleDelete, handleCreate, editHeading, arrayCategory: editHeading.text  }

    var indexCount = -1
    return (
        <div className="">
            <DeleteModal show={show} setShow={setShow} modalInfo={modalInfo} handleDeleteFinal={handleDeleteFinal} />
            <div className={`me-2 badge bg-white text-primary mt-2`}>{editHeading.text} Categories</div>
            <div className="ms-2 mb-3 mt-0 d-flex align-items-center flex-wrap">
              {entry.map(item => {
                  return <div key={item.id} className={`me-2 badge bg-light mt-1`} role="none">{item.category}</div>
              })}
              <FontAwesomeIcon className="ms-1 mt-1" icon={faPlus} onClick={e => handleCreate(e, 'category')} role="button" />
            </div>
            <div className="ms-2 pt-3">
            {entry.map(item => {
                indexCount++
                return <Category key={item.id + indexCount} item={item} editFunctions={editFunctions} indexCount={indexCount} length={entry.length} />
            })}
            </div>
        </div>   
    )    
}

function Category({item, editFunctions, indexCount, length}) {
    
    const {handleChange, handleIndex, handleDelete, handleCreate, editHeading, arrayCategory} = editFunctions

    const {classInformation} = useContext(SiteContext)

    var type = editHeading.key === 'focusSchedule' ? 'dropdown' : ''
    var dropdownArray = editHeading.key === 'focusSchedule' ? getCategories() : []

    function getCategories() {
        const categories = []
        classInformation.agreements.map(entry => {categories.push(entry.category)})
        return categories
    }

      return (
      <div key={item.id + indexCount} className="mt-0 d-flex flex-wrap">
        <div className="d-flex justify-content-between align-items-center w-100">
          {/* <div className=""> */}
          <div>
          <input
          type="text" className="bg-light rounded d-inline p-1 ps-2 fw-bold border-0 flex-shrink-1"
          value={item.category}
          onChange={(e) => handleChange(e, 'category', item.category)}
          />
          </div>
          {/* </div> */}
          <div className="w-100 ms-2">
          {indexCount + 1 !== length && <FontAwesomeIcon className="ms-2" icon={faArrowDown} onClick={e => handleIndex(e,'lower', indexCount)} role="button" />}
          {indexCount !== 0 && <FontAwesomeIcon className="ms-2" icon={faArrowUp} onClick={e => handleIndex(e,'raise', indexCount)} role="button" />}
          <FontAwesomeIcon className="ms-4" icon={faX} onClick={e => handleDelete(e, 'category', item.category)} role="button" />
          </div>
        </div>
        <div className="w-100 mt-2">
            {item.elements.map(element => {
            return <CategoryEntry key={element.id + indexCount} element={element} item={item} editFunctions={editFunctions} type={type} dropdownArray={dropdownArray} arrayCategory={arrayCategory} />
            })}
          {editHeading.key !== 'focusSchedule' && 
          <div className="d-flex justify-content-end mt-2">
          <FontAwesomeIcon className="m-2" style={{}} icon={faPlus} onClick={e => handleCreate(e, 'categoryEntry', item.category)} role="button" />
          </div>}
        </div>
      </div>
      )
  }

  function CategoryEntry({element, item, editFunctions, type, dropdownArray, arrayCategory}) {
    const {handleChange, handleDelete} = editFunctions

    if(type === 'dropdown') {
      return (
        <div className="d-flex">
          <select
            className="w-50 form-select form-control" key={element.id}
            value={element.entry}
            onChange={(e) => handleChange(e, 'categoryEntry', item.category, element.entry)}
          >
              <option value={'select'}>-- Select Category --</option>
              { dropdownArray.map(entry => {
                  return <option key={entry}>{entry}</option>
              })} 
          </select>
          <FontAwesomeIcon key={element.id + 'fa'} className="m-2" onClick={e => handleDelete(e, 'categoryEntry', item.category, element.entry)} icon={faX} role="button" />
        </div>
      )
    }
    return (
      <div className="d-flex align-items-center">
        {/* <div className="d-flex flex-wrap justify-content-center me-2">
          <span className="">Admin</span>
          <input key={element.id} type="checkbox" className=""
            value={element.entry} onChange={(e) => {}} />
        </div> */}
        {arrayCategory === 'Agreements' && 
          <div className={`border border-1 rounded border-primary text-primary p-1 me-2 text-center ${element.adminStatus ? 'bg-primary text-white' : ''}`} style={{width:"55px"}} role="button" 
            onClick={e => handleChange(e, 'adminStatus', item.category, element.entry)}>{element.adminStatus ? 'Admin' : 'All'}</div>}
        <input key={element.id} type="text" className="form-control"
          value={element.entry} onChange={(e) => handleChange(e, 'categoryEntry', item.category, element.entry)} />
        <FontAwesomeIcon key={element.id + 'fa'} className="ms-3 m-2" onClick={e => handleDelete(e, 'categoryEntry', item.category, element.entry)} icon={faX} role="button" />
      </div>
    )
  }

function newCategoryEntry () {
  return {
    id: uuidv4(),
    entry: ''
  }
}

function newCategory(length) {
  return {
    id: uuidv4(),
    category: `New Category (${length})`,
    elements: [
      newCategoryEntry()
    ]
  }
}

const focusSchedule = [
  {
    id: uuidv4(),
    category: 'Monday',
    elements: [
      newCategoryEntry()
    ]
  },
  {
    id: uuidv4(),
    category: 'Tuesday',
    elements: [
      newCategoryEntry()
    ]
  },
  {
    id: uuidv4(),
    category: 'Wednesday',
    elements: [
      newCategoryEntry()
    ]
  },
  {
    id: uuidv4(),
    category: 'Thursday',
    elements: [
      newCategoryEntry()
    ]
  },
  {
    id: uuidv4(),
    category: 'Friday',
    elements: [
      newCategoryEntry()
    ]
  },
]
import React, { useContext, useState } from 'react'

import { SiteContext, baseUrl } from '../../App'
import { ErrorBoundary } from '../../other/utils/ErrorBoundary';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faCirclePlus, faSquareCheck, faSquareXmark, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import TimerCountUp from '../../other/utils/TimerCountUp';

export default function D_OutEntry({event, handleUpdateBehaviorEvents}) {

    const { 
        colorMatch,
        studentList,
        room,
        studentDataFunctions: {
            levelChangeCalc
        }
    } = useContext(SiteContext)

    const {
        studentInitiatingName,
        studentInitiatingId,
        category,
        reason,
        currentColor: currentColorVal,
        type,
        nominateType,
        studentNominatedName,
        studentNominatedId,
        _id,
        result,
        id
    } = event

    const [change, setChange] = useState(nominateType === 'shoutOut' ? 1 : -1)

    function handleUpdateChange(e) {
        setChange(parseInt(e.target.value))
    }

    // console.log(event,'event')
    async function updateBehaviorEvent(e, result) {

        e.preventDefault();

        console.log(e.target.value,'e.target.value')

        var editedBehaviorEvent = {
            ...event,
            activelyPending: false,
            result,
            pendingColor: pendingColor
        };
        
        console.log(editedBehaviorEvent,'editedBehaviorEvent')

        if(editedBehaviorEvent._id) {delete editedBehaviorEvent._id}
        // This will send a post request to update the data in the database.
        fetch(`${baseUrl}/update/behaviorEvents/${id}`, {
          method: "POST",
          body: JSON.stringify(editedBehaviorEvent),
          headers: {
            'Content-Type': 'application/json'
          },
        });

        handleUpdateBehaviorEvents(editedBehaviorEvent)
    }

    if(studentNominatedName === 'Mr. Smith') {
        var emojiColor = '⬛️'
    } else {
        var index = studentList.findIndex(student => student.studentId == studentNominatedId)
        var nominatedStudent = studentList[index]

        if(!nominatedStudent) return
        var matchNominated = colorMatch.filter(item => item.color == nominatedStudent.color.current)[0]
            var variant = matchNominated.variant
            var string = matchNominated.string
            var level = matchNominated.level
            var emojiColor = matchNominated.string.split(' ')[0]
            var currentString = matchNominated.string.split(' ')[1]
            var colorNominated = matchNominated.variant

            var newLevel = level + change
            if(newLevel > 9) {newLevel = 9}
            var index = colorMatch.findIndex(entry => entry.level === newLevel)
            if(index === -1) return
                var pendingColor = colorMatch[index].color
                var pendingVariant = colorMatch[index].variant
                var pendingEmoji = colorMatch[index].string.split(' ')[0]
                var pendingString = colorMatch[index].string.split(' ')[1]
    }

    var opacity = ''
        if(result === 'approved') { opacity = 'approved' }
        if(result === 'rejected') { opacity = 'rejected' }

    var badgeVariant = nominateType === 'shoutOut' ? 'secondary' : 'secondary'
    // if(nominateType === 'shoutOut') {var badgeVariant = 'success'} else{
    //     badgeVariant = 'danger'
    // }

    const categoryCount = nominatedStudent && nominatedStudent.behaviorEvents ? nominatedStudent.behaviorEvents.filter(entry => entry.category === category).length : 0
    const agreementCount = nominatedStudent && nominatedStudent.behaviorEvents ? nominatedStudent.behaviorEvents.filter(entry => entry.reason === reason).length : 0

    return (
        <ErrorBoundary>
        <tr className={`bg-light`}>
            <td className="text-center" style={{verticalAlign:"top"}}>
            <div className="box">
                <span className="text">{studentNominatedName}</span>
                {/* <span className="text">{studentNominatedName.slice(0,20)}</span> */}
            </div>
            <div>
                <div className={`mb-2 me-2 badge bg-${badgeVariant}`}>
                By: {studentInitiatingName.split(' ')[0] && `${studentInitiatingName.split(' ')[0]} `} 
                {studentInitiatingName.split(' ')[0] && studentInitiatingName.split(' ')[1].substring(0, 1)}                
                </div>
                <br/>
            </div>
            </td>
            <td className="text-center" style={{verticalAlign:"top"}}>
                <div className="ms-auto me-auto" style={{maxWidth:"75px"}}>
                    <div className={`badge rounded-pill bg-${colorNominated} w-100 d-flex justify-content-center mb-1`}>
                        <span className="">{currentString}</span>
                        <span className="ms-1">→</span>
                    </div>
                    <div className={`badge rounded-pill bg-${pendingVariant} w-100 d-flex justify-content-center`}>
                        <span className="me-1">→</span>
                        <span className="">{pendingString}</span>
                    </div>
                    <div className="badge mt-2 bg-info">
                        {nominatedStudent && nominatedStudent.color.lastUpdate ? <TimerCountUp startTime={nominatedStudent.color.lastUpdate} showBadge={true} /> : 'New'}
                    </div>
                    {agreementCount > 0 ? <div className={`badge mt-2 ms-1 bg-purple`}>{agreementCount}</div> 
                    : categoryCount > 0 ? <div className={`badge mt-2 ms-1 bg-${categoryCount === 1 ? 'warning' : categoryCount === 2 ? 'flag' : 'danger'}`}>{categoryCount}</div> : <></>}
                </div>
            </td>
            <td className="text-center" style={{verticalAlign:"top"}}>
                <div className="box d-flex flex-wrap">
                    <div className={`mb-2 me-2 badge bg-${badgeVariant}`}>{category}</div>
                    <span className="text text-start">{reason.slice(0,50)}</span>
                </div>
            </td>
            <td className="">
                {/* <div className={`mb-2 me-2 badge bg-${badgeVariant}`}>{category}</div> */}
            </td>
            <td className="" style={{width:"90px", verticalAlign:"top"}}>
                <div role="group" className="btn-group-sm btn-group d-flex align-top justify-content-center pt-auto ms-auto me-auto" 
                // style={{minWidth:"70px", maxWidth:"70px"}}
                >
                    <button value="approved" onClick={(e) => updateBehaviorEvent(e, 'approved')} 
                    className={`btn-shadow btn btn-black text-white ${opacity}`}>
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                    <button onClick={(e) => updateBehaviorEvent(e, 'rejected')} 
                    className={`btn-shadow btn btn-white bg-white text-black ${opacity}`}>
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
                <div className="d-flex align-items-start justify-content-center mt-2 d-block">
                <input 
                  type="number" style={{textAlign:"center"}}
                  name="day" 
                  min="-5" 
                  max="5"
                  value={change} className={``}
                  onChange={e => handleUpdateChange(e)}
                />
                </div>
            </td>
        </tr>
        </ErrorBoundary>
    )
}

import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { SiteContext } from "../../../App";

export default function SubmitModal({note, setNote}) {
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([
      {category: 'General', active: ''},
      {category: 'Goal', active: ''},
      {category: 'Personal Agreement', active: ''},
      {category: 'Value', active: ''},
  ])

  const {  
    studentDataFunctions: {
      setRecords
    },
    records
  } = useContext(SiteContext)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleCategoryClick (e) {
      e.preventDefault()

      const editCategories = [
        {category: 'General', active: ''},
        {category: 'Goal', active: ''},
        {category: 'Personal Agreement', active: ''},
        {category: 'Value', active: ''},
      ]         

      var index = editCategories.findIndex(entry => entry.category === e.target.value)
      editCategories[index].active = 'active'

      setCategories(editCategories)
  }

  function handleAddNote(e) {
      e.preventDefault()

      var filtered = categories.filter(entry => entry.active === 'active')[0]
      var category = filtered.category
      
      

      setCategories([
        {category: 'General', active: ''},
        {category: 'Goal', active: ''},
        {category: 'Personal Agreement', active: ''},
        {category: 'Value', active: ''},
      ])
      setNote('')
  }

  return (
    <>
      <Button 
       variant="secondary" 
       onClick={handleShow}
       className="btn btn-secondary"
       style={{width:"15%", display:"inline", fontSize:"12px", marginLeft:"10px"}}
      >
        Submit
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p className="bold" style={{display:"inline"}}>Note: </p><p>{note}</p>
            <p>Which Category?</p>
            <div className="position-relative mb-3">
                {categories.map(category => {
                    return (
                        <button
                        type="button"
                        key={category.category}
                        style={{fontSize:"10px"}}
                        className={`mb-2 me-2 btn-pill btn btn-outline-2x btn-outline-primary ${category.active}`}
                        value={category.category}
                        onClick={(e) => { handleCategoryClick(e)}}
                        >{category.category}</button>
                    )
                })}

            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" 
            onClick={(e) => {
                handleAddNote(e)
                handleClose()
            }}>
            Add Note
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
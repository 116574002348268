import React, { useContext, useEffect, useState } from 'react'

import { SiteContext, baseUrl } from '../App';
import { StudentContext } from './StudentContainer';
import { ErrorBoundary } from '../other/utils/ErrorBoundary';
import { useNavigate } from "react-router-dom";

import ColorCard from '../student/studentTopCards/colorCard/ColorCard';
import StudentCard from '../student/studentTopCards/StudentCard';
import StudentBehavior from '../behavior/student/S_Behavior'
import Agreements from '../student/studentCards/Agreements';
import Information from '../student/studentCards/Information';
import MessageCard from '../student/studentTopCards/MessageCard';
import SHallPass from '../hallPass/student/SHallPass';
import FocusCard from '../student/studentCards/focus/FocusCard';
import Goal from '../student/studentCards/Goal';
import ReadingZone from '../student/studentCards/ReadingZone';

import calculateTimeFunction from '../other/utils/calculateTime.js'

export default function Student() {

    const {
        classInformation, focusAgreements, student,
        studentDataFunctions: { calculateCompletion}
    } = useContext(SiteContext)

    // const {calculateCompletion} = useContext(StudentContext)
    const navigate = useNavigate();
    const {classEnd, classEndReadable} = calculateTimeFunction(classInformation.dayType, classInformation.classStartNormal, classInformation.classStartMinimum)
    
    if(!focusAgreements) return
    var render = false
    focusAgreements.categories.map(entry => {
        if(entry.category === 'Reading Zone' && entry.adminFocus) {
          render = true
        }
    })

    const outcome = calculateCompletion(focusAgreements, student)
    var percentage = outcome ? `${outcome.mathComplete}` : ''

    // console.log(classInformation, 'classInformation')

    try{
        return (
            <ErrorBoundary>
                <div className="row">
                </div>
                <div className="row">
                <ErrorBoundary>
                    <ColorCard sizeClass={'col-sm-12 col-md-6 col-lg-6'}/>
                    <StudentCard title={percentage} subtitle={'Progress Checklist'} colorValue={'success'} sizeClass={'col-sm-6 col-md-3 col-lg-3'}/>
                    {/* <StudentCard title={'Smiley'} subtitle={'Check-In'} colorValue={'primary'} sizeClass={'col-sm-6 col-md-2 col-lg-2'}/> */}
                    <StudentCard title={classInformation.classEndReadable} subtitle={'Class End'} colorValue={'info'} sizeClass={'col-sm-6 col-md-3 col-lg-3'}/>
                    {/* <MessageCard title={"Message"} subtitle={'Send Message'} colorValue={'primary'} sizeClass={'col-sm-6 col-md-2 col-lg-2'}></MessageCard> */}
                </ErrorBoundary>                
                </div> 
                <div className="row">
                    <ErrorBoundary>
                    <div className="col-sm-12 col-md-6">
                        <StudentBehavior sizeClass={'col-sm-12'} />
                        {/* <ColorsCards/> */}
                        {focusAgreements.focusCategory === 'Reading Zone' ? <ReadingZone sizeClass={'col-sm-12'} /> : 
                        <Goal sizeClass={'col-sm-12'} />}
                        {/* <Information sizeClass={'col-sm-12'}/> */}
                    </div>
                    </ErrorBoundary>
                    <div className="col-sm-12 col-md-6">
                    <ErrorBoundary>
                        {/* <FocusCard sizeClass={'col-sm-12'} /> */}
                        <Agreements sizeClass={'col-sm-12'}/>
                    </ErrorBoundary>
                    </div>
                </div> 
            </ErrorBoundary>                        
        )
    }
    catch(error){
        console.error(error);
        // navigate(`/`, { replace: true });
    }
    
}


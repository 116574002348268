import React from 'react'

export function useGoal() {
    
    const goalQuestions = [
        {type: 'goal', questions: [
          {question: 'What is your goal during this module? What do you want to accomplish? Why?', sentenceStem: 'In this module, I want to _______ because _______', reviewText: 'Goal', sizeClass: 'col-12', sizeReview: 'col-12', category: 'goal', item: 'goal', sizeStatic: 'col-12'}
        ]},
        {type: 'matters', questions: [
          {question: 'Why does this goal matter to you?', sentenceStem: 'This goal matters to me because _______', reviewText: 'Why it matters', sizeClass: 'col-12', sizeReview: 'col-4', sizeStatic: 'col-12', category: 'goal', item: 'matters'}
        ]},
        {type: 'things', questions: [
          {question: '1st thing:', sentenceStem: '1st thing I can do is _________', reviewText: 'Things to do #1', sizeClass: 'col-4', sizeReview: 'col-4', sizeStatic: 'col-4',category: 'goal', item: 'thing1'},
          {question: '2nd thing:', sentenceStem: '2nd thing I can do is _________', reviewText: 'Things to do #2', sizeClass: 'col-4', sizeReview: 'col-4', sizeStatic: 'col-4',category: 'goal', item: 'thing2'},
          {question: '3rd thing:', sentenceStem: '3rd thing I can do is _________', reviewText: 'Things to do #3', sizeClass: 'col-4', sizeReview: 'col-4', sizeStatic: 'col-4',category: 'goal', item: 'thing3'},
          
        ]},  
        {type: 'accomplish', questions: [
          {question: 'Will you be able to accomplish this goal this module?', sentenceStem: 'During this module, I think I can / cannot accomplish this goal because _______',  reviewText: 'Accomplishing the goal', sizeClass: 'col-12', sizeReview: 'col-4', sizeStatic: 'col-12', category: 'goal', item: 'accomplish'}
        ]},
      ]
    
      return { goalQuestions }
}
import React, {useContext, useState, useEffect} from 'react'
import { SiteContext } from '../../../App';
import { AdminContext } from '../../../pages/AdminContainer';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

export default function CompleteEvent({item, period, setActiveEntry, show, setShow, handleShow, handleClose, grades}) {

    const {focusAgreements, classInformation, socket, classInformationArray, studentDataFunctions: {setFocusAgreements, updateMongo, getMongoByUniqueId}} = useContext(SiteContext)
    const {handleDate} = useContext(AdminContext)
    
    const event = item.completeEvent

    // const [show, setShow] = useState(false);
    const [itemLocal, setItemLocal] = useState({...item})
    const [eventLocal, setEventLocal] = useState({...event})
  
    // useEffect(() => {
    //   setItemLocal(item)
    // }, [item])

    var typeBg = item.type === 'Test' ? 'bg-black' :
    item.type === 'Activity' ? 'bg-purple' :
    item.type === 'Design Brief' ? 'bg-primary' : 'bg-secondary'

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    // console.log(event,'event', item)
  
    function prettyDate(dateInput) {
      var date = new Date(dateInput)
      // console.log(date, 'inside---')
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      var hours = ((date.getHours() + 11) % 12 + 1);
      var minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
      
      return `${days[date.getDay()]} ${months[date.getMonth()]} ${date.getDate()}`
      // ${hours}:${minutes}`

    }

    // async function handleDate(e, dayChange) {
    //   e.preventDefault()

    //   var index = classInformationArray.findIndex(entry => entry.period === (period ? period : ''))
    //   var localLoad = period && await getMongoByUniqueId(classInformationArray[index].focusAgreementId, 'focusAgreements')

    //   var editEntry = !period ? {...focusAgreements} : {...localLoad}

    //   // var editEntry = {...focusAgreements}

    //   var groupNumber = itemLocal.completeEvent.groupNumber
    //   var index = editEntry.modules.findIndex(entry => entry.title === itemLocal.module)
    //       if(index === -1) return
    //   var index1 = editEntry.modules[index].checklists.findIndex(entry => entry.groupNumber === groupNumber)
    //       if(index1 === -1) return
    //       // console.log(editEntry.modules[index],'editEntry.modules[index]')
    //   var index2 = editEntry.modules[index].checklists[index1].checklist.findIndex(entry => entry.itemId == itemLocal.itemId)

    //   editEntry.modules[index].checklists[index1].checklist[index2].completeEvent.date = editEntry.modules[index].checklists[index1].checklist[index2].completeEvent.date + (dayChange)*86400000

    //   // console.log(editEntry.modules[index].checklists[index1].checklist[index2],'editEntry.modules[index].checklists[index1].checklist[index2]', item, period)
    //   updateMongo({modules: [...editEntry.modules]}, editEntry._id, 'focusAgreements')
    //   socket.emit("send_updateProgressChecklist", { oneModule: {...editEntry.modules[index]}, room: period ? period : classInformation.period });
    //   if(!period || period === classInformation.period) {
    //     setFocusAgreements(prev => {return {...prev, ...editEntry}})
    //   } 
    //   setItemLocal({...editEntry.modules[index].checklists[index1].checklist[index2]})
    //   setEventLocal({...editEntry.modules[index].checklists[index1].checklist[index2].completeEvent})
    //   // console.log(editEntry.modules[index].checklists[index1].checklist[index2].completeEvent.date,'editEntry.modules[index].checklists[index1].checklist[index2].completeEvent.date')
    // }

    function handleCloseLocal() {
      handleClose()
      setActiveEntry(false)
    }

    function handleShowLocal() {
      handleShow()
      setActiveEntry(item)
    }

    console.log(item,'item')
    var count = 0
    return (
      <>
        {/* <Button variant="primary" >
          Launch demo modal
        </Button> */}
        {!grades && <div onClick={handleShowLocal} className={`me-2 badge ${typeBg ? typeBg : 'bg-secondary'} word-wrap`} role="button">{itemLocal.day}: {itemLocal.type}</div>}
  
        <Modal show={show} onHide={handleCloseLocal} dialogClassName={`my-modal4`}>
          <Modal.Header closeButton>
            <Modal.Title>
                <span className="fw-bold">{itemLocal.day} - {itemLocal.type}</span>
                <div style={{fontSize:"1rem"}}>{itemLocal.item}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
             <div className="d-flex mb-2 align-items-center justify-content-between">
                <div>{eventLocal.name} - {eventLocal.period}</div>
                <div className="d-flex align-items-center">
                  <div className="badge bg-secondary me-1">{prettyDate(eventLocal.date)}</div>
                  <FontAwesomeIcon icon={faArrowUp} onClick={e => handleDate(e, 1, period, false, itemLocal, setItemLocal, setEventLocal)} className="fa-lg m-1" role="button" />
                  <FontAwesomeIcon icon={faArrowDown} onClick={e => handleDate(e, -1, period, false, itemLocal, setItemLocal, setEventLocal)} className="fa-lg m-1" role="button" />
                </div>
             </div>
             {typeof eventLocal.url === 'string' ? <img src={event.url} className="m-0 viewModalImage"/> :
              <>
              {eventLocal && eventLocal.url && typeof eventLocal.url !== 'string' && eventLocal.url.map(entry => {
                count++
                return <img key={count} src={entry} className="m-0 mb-2 viewModalImage"/>
              })}
              </>}
             <div className="d-flex align-items-center mt-2">
                <div className="badge bg-secondary text-white me-2">{eventLocal.rating}</div>
                <div className="">{eventLocal.activity}</div>
             </div>             
          </Modal.Body>
        </Modal>
      </>
    );
  }
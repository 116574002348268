import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from "react-router";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CartItems from './CartItems';
import { v4 as uuidv4 } from 'uuid'

import { SiteContext, baseUrl } from "../../App";
import { StudentContext } from '../../pages/StudentContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Orders from '../admin/Orders';

export default function Checkout({colorValue, cart, total, setCart}) {
  const [show, setShow] = useState(false);

 const { 
    socket, classInformation,
    room,
    student,
    roomJoined,
    studentDataFunctions: {
      setRoom,
      setRoomJoined,
      joinRoom, sendMessage,
      createMongo, getMongoList, getMongoById, updateMongo, deleteMongo
    }
 } = useContext(SiteContext)

  const { orders, setOrders } = useContext(StudentContext)
 
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setRoom(student.period)
  }, [student.name, student])

  useEffect(() => {
    joinRoom(student.name, room)
    setRoomJoined(true)
  }, [room])

  function handleClick(e) {
    e.preventDefault()

    var cost = 0
    cart.map(entry => {
      if(entry.quantity === 0) {return}
      // items.push({item: entry.name, price: entry.price, quantity: entry.quantity})
      cost = cost + entry.price*entry.quantity

      const order = {
        name: student.name,
        studentId: student.studentId,
        period: student.period,
        item: entry.item,
        price: entry.price,
        quantity: entry.quantity,
        timeStamp: Date.now(),
        completed: false, 
        orderId: uuidv4(),
      }

      var splitItem = entry.item.split(' (') ? entry.item.split(' (')[0] : entry.item
      console.log(order,'order')
      createMongo(order, 'orders')
      sendMessage({
        name: student.name,
        reason: 'Store',
        message: `${splitItem} (${entry.quantity} / ${entry.price}€)`,
        period: student.period,
        studentId: student.studentId,
      })

      if(orders.length > 0) {
        setOrders([])
      } else {
        setOrders([...orders, order])
      }
      socket.emit("send_order", { order, room });
      getMongoById(student.studentId, 'dailyList').then(record => {
        const chipsToday = {...record.chipsToday}
        chipsToday.subtracted = chipsToday.subtracted - cost

        updateMongo({chipsToday:chipsToday}, record._id, 'dailyList')
      })
    })



    // console.log(order,'order')

    

    const editCart = [...cart]
      editCart.map(entry => {
        entry.quantity = 0
      })
    setCart(editCart)
    handleClose()
  }

  return (

    <>
      <Button 
        className={`widget-numbers fsize-1 btn btn-info flex-fill`} 
        // className={` widget-numbers fsize-1 btn btn-${colorValue}`} 
        // style={{margin:"10px", float:"left", width:"55%", margin:"0"}}     
        // onClick={classInformation.period === 'Period 7' ? () => {} : handleShow}
        onClick={handleShow}
      >
        Checkout
        {/* {classInformation.period === 'Period 7' ? 'Store Closed' : 'Checkout'} */}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Mr. Smith's Chip Emporium Checkout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="fsize-1">
                <div className="ingredient-grid2 text-muted">
                    <span className="bold">Price</span>
                    <span className="bold">Item</span>
                    {cart.map(entry => {
                        if(entry.quantity === 0) {return}
                        return (
                            <CartItems entry={entry} key={entry.name} colorValue={colorValue} />
                        )
                    })}
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
        <div className="d-flex justify-content-between align-items-center">
          <button className={`widget-numbers fsize-1 btn btn-${'colorValue'} text-${colorValue} w-100`} style={{margin:"15px", float:"left", width:"20%", margin:"0"}}>
            Total: {total}€
          </button>
          <button className={`widget-numbers fsize-1 btn btn-${colorValue} w-100`}
          onClick={(e) => {handleClick(e)}}
          >Complete Order</button>
          {/* <Button 
            // variant="info"
            
            // style={{margin:"10px", width:"40%", margin:"0", padding:"3px"}}
            >
            
          </Button> */}
        </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
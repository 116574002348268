import React, { useContext } from 'react'
import { SiteContext } from '../../App';

import Accordion from 'react-bootstrap/Accordion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';

export default function RZAccordion({sizeClass, title}) {

  const { 
    classInformation: {
    }
  } = useContext(SiteContext)

    return (
      <div className={sizeClass}>
        <div className="main-card mb-3 card">
          <div className="card-header">
            <FontAwesomeIcon style={{ marginRight: 10 }} icon={faCalendar} />
            {title}
            <a 
              href="https://wccusdhelmsms.beanstack.org/"
              style={{marginLeft:"auto"}}
              target="_blank"
            >Beanstack</a>
          </div>
          <div className="card-body">
            <Accordion>
                  <Accordion.Item eventKey={'normal'}>
                    <Accordion.Header>Reading Zone Schedule</Accordion.Header>
                    <Accordion.Body>
                    <div className="recipe__row">
                        <div className="recipe__value recipe__value--indented">
                            <div className="ingredient-grid2">
                                <span className="bold">Weekday</span>
                                <span className="bold">Reading Zone Class</span>
                                <span className="">Monday</span><span className="">History</span>
                                <span className="">Tuesday</span><span className="">Science</span>
                                <span className="">Wednesday</span><span className="">Math</span>
                                <span className="">Thursday</span><span className="">Enrichment (CCR Lab)</span>
                                <span className="">Friday</span><span className="">English</span>
                            </div>
                        </div>
                    </div>  
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey={'clubs'}>
                    <Accordion.Header>Clubs Schedule</Accordion.Header>
                    <Accordion.Body>
                    <a 
                      href="https://docs.google.com/document/d/1hgtyTBKH3j2Cfg89pIy3C9OcEwDt9Xke7L5BekkbQzI/edit"
                      style={{marginLeft:"auto"}}
                      target="_blank"
                    >Link</a>
                    </Accordion.Body>
                  </Accordion.Item> */}
            </Accordion>
          </div>
        </div>
      </div>
    )
}

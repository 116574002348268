import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from "react-router";
import { SiteContext, baseUrl } from "../App";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import './modal.css'

import Edit from './Edit3';
import More from "./More";
import ModalDelete from './ModalDelete'

export default function ModalBox({person, title, edit, variant, type}) {

  const {studentList, socket, studentDataFunctions: {setStudentList}} = useContext(SiteContext)

  const [show, setShow] = useState(false);
  const [form, setForm] = useState(person.dailyListId ? {dailyListId: person.dailyListId, studentId: person.studentId} : {studentId: person.studentId})

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {  
     studentDataFunctions: {
       setRecords, updateMongo, getRecords
     },
     records
   } = useContext(SiteContext)
 
  const navigate = useNavigate();
  
  // These methods will update the state properties.
  function updateForm(value) {
    const editForm = {...form, ...value}
    console.log(editForm,'editForm modalBox')
    return setForm({...editForm})
    //   (prev) => {
    //   console.log(prev,'prev')
    //   return { ...prev, ...value };
    // });
  }
 
  async function onSubmit(e) {
    e.preventDefault();
    const editedPerson = {...form};

    console.log(form,'form')
    if(!editedPerson.dailyListId) {
      alert('No Daily List Id', editedPerson)
      return
    }
    console.log(editedPerson,'editedPerson')
    const editStudentList = [...studentList]
    var index = editStudentList.findIndex(entry => entry.studentId === editedPerson.studentId)
    if(index === -1) return

    if(editStudentList[index].period !== editedPerson.period && editStudentList[index] && editStudentList[index].moduleHistory && typeof editStudentList[index].moduleHistory === 'object') {

      editedPerson.moduleHistory = [
        ...editStudentList[index].moduleHistory, 
        {
          module: editStudentList[index].module, 
          date: Date.now(), 
          groupNumber: editStudentList[index].groupNumber, 
          period: editedPerson.period
        }
      ]
    }
    updateMongo(editedPerson, person._id, 'records')
    updateMongo(editedPerson, editedPerson.dailyListId, 'dailyList')

    editStudentList[index] = {...editStudentList[index], ...editedPerson}

    setStudentList(editStudentList)
    socket.emit("send_updateSingleStudent", {student: {...editStudentList[index]}, room: editStudentList[index].period});

    handleClose()
  }

  const personCombined = {...person, ...form}
  
  return (
    <>
      {type !== 'status' && <div className="flex-fill d-inline m-1 ms-0">
      <Button variant={variant} onClick={handleShow} className="">
        {title}
      </Button>
      </div>}

      {type === 'status' && <Button onClick={handleShow} style={{color:"white", fontSize:"10px"}}
          className={`mb-2 btn-pill btn btn-outline-2x btn-outline-${variant} active me-2`}
      >
        {title}
      </Button>}



      <Modal 
        show={show} 
        onHide={handleClose}
        // style={{width:"80%"}}
        // dialogClassName="modal-height"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {edit && <Edit 
                id={person._id}
                onSubmit={onSubmit}
                form={personCombined}
                updateForm={updateForm}
            />}
            {!edit && <More
                person={personCombined}
            />}
        </Modal.Body>
        <Modal.Footer>
          {edit && <ModalDelete person={personCombined} handleCloseOriginal={handleClose}/>}
          {edit && <Button variant="warning"onClick={(e) => {
              handleClose()
              onSubmit(e)
          }}>
            Update Student
          </Button>}
        </Modal.Footer>
      </Modal>
    </>
  );
}
import React, { useContext, useState, useEffect } from 'react'

export function useFocus({classInformation, getMongoList, getMongoByUniqueId, updateMongo, socket, room, stringtoJSDate}) {

 const [focusAgreements, setFocusAgreements] = useState({
    categories: [], agreements: []
 })

 useEffect(() => {

    if(!classInformation || !classInformation.period) return

    async function getFocusAgreements() {
      var entriesPeriod =  await getMongoByUniqueId(classInformation.focusAgreementId,'focusAgreements')
      
      if(!entriesPeriod) return
      focusScheduleSet(entriesPeriod)

      entriesPeriod.modules.map(moduleEntry => {
        // if(!moduleEntry || !moduleEntry.checklists) return
        moduleEntry.checklists.map(checklistEntry => {
          // console.log(checklistEntry,'checklistEntry')
          checklistEntry.checklist.sort((a,b) => a.index - b.index)
        })
      })

      setFocusAgreements(entriesPeriod)
    }

    getFocusAgreements()

    let interval;
    interval = setInterval(() => {

        var {date, milli: milliEnd} = stringtoJSDate(classInformation.classEnd)
        var {dateStart, milli: milliStart} = stringtoJSDate(classInformation.classStart)

        if(milliStart > Date.now() || milliEnd < Date.now() || classInformation.dayType === 'No School') return
        console.log('update focusAgreements')
        getFocusAgreements()
        
    }, 60000);

    return () => clearInterval(interval);
 
 }, [classInformation.period, classInformation.focusSchedule, classInformation.dayType, classInformation.classEnd, classInformation.classStart]) 

 function focusScheduleSet(focusAgreements) {

  const foci = focusAgreements.categories.filter(entry => entry.adminFocus)
   if(foci.length > 0) return 

   var midnight = new Date()
      midnight.setHours(5)
      midnight.setMinutes(0)
      midnight.setSeconds(0)
    
   var midnightMilli = midnight.getTime()

   if(focusAgreements.lastUpdate && focusAgreements.lastUpdate > midnightMilli) return

    const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
    var dateToday = new Date()
      var weekDay = dateToday.getDay()
      var index = classInformation.focusSchedule.findIndex(entry => entry.category === days[weekDay])
      if(index === -1) return
    var focusCategory = classInformation.focusSchedule[index].elements[0].entry
      if(focusCategory.length === 0) return
      var index2 = focusAgreements.categories.findIndex(entry => entry.category === focusCategory)
    
    if(index2 === -1) return
    updateFocus('', focusAgreements.categories[index2], 'categories', true, 'adminFocus', focusAgreements)
 }

  useEffect(() => {

        socket.on("receive_update_focus", (data) => {
            console.log('receive_update_focus', data)
            // setUpdateFocusAgreements(prev => {return !prev})
            setFocusAgreements({...data.focusAgreements})
        })
    
        return () => {
          socket.off('receive_update_focus');
        };
    
  }, [socket, focusAgreements])

  function updateFocus(e, selection, dataType, value, context, importAgreements) {

    value === 'true' ? value = true : value = false

    var editFocusAgreements = importAgreements ? {...importAgreements} : {...focusAgreements}
    var index = editFocusAgreements[dataType].findIndex(entry => entry.id === selection.id)
      editFocusAgreements[dataType][index][context] = !value

      if(value && dataType === 'categories') {
        editFocusAgreements['agreements'].map(entry => {
          if(entry.category === selection.category) {
            entry.adminFocus = false
            entry.studentFocus = false
          }
        })
      }

      // console.log(editFocusAgreements[dataType],'editFocusAgreements[dataType]', value, dataType, selection)
    
    editFocusAgreements.lastUpdate = Date.now()

    if(context === 'adminFocus') {
      updateMongo({[dataType]: editFocusAgreements[dataType], lastUpdate: Date.now()}, classInformation.focusAgreementId, 'focusAgreements') 
    }

    setFocusAgreements(editFocusAgreements)
    socket.emit("send_focusAgreementsUpdate", { focusAgreements: editFocusAgreements, room: 'admin' });
    socket.emit("send_update_focus", { focusAgreements: editFocusAgreements, room });
  }

  function updateFocusSelection(selected) {

    var editFocusAgreements = {...focusAgreements}
    // console.log(selected,'selected')
    
    const newCategory = {focusCategory: selected ? selected.value : ''}
    console.log(newCategory,'newCategory')
    editFocusAgreements = {...editFocusAgreements, ...newCategory}
    setFocusAgreements(editFocusAgreements)

    updateMongo(newCategory, classInformation.focusAgreementId, 'focusAgreements') 
    socket.emit("send_focusAgreementsUpdate", { focusAgreements: newCategory, room: 'admin' });
    socket.emit("send_focusAgreementsUpdate", { focusAgreements: newCategory, room: classInformation.period });
  }


  function checkFocus(selection, dataType, context) {
    var index = focusAgreements[dataType].findIndex(entry => entry.id === selection.id)
    if(focusAgreements[dataType][index] && focusAgreements[dataType][index][context]) {return true} else {return false}
  }

  return {
      updateFocus, checkFocus, updateFocusSelection,
      focusAgreements, setFocusAgreements,
  }
}

import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { SiteContext, baseUrl } from "../../../App";

export default function DeleteModal({show, handleClose, item, setNewItems, setModuleItems}) {

  const {  
    studentDataFunctions: {
      setRecords, deleteMongo
    },
    records
  } = useContext(SiteContext)

  function deleteFunction(id) {
    if(item.newId) {
      setNewItems((prev) => {return prev.filter(entry => entry.newId !== item.newId)})
    } else{
      deleteMongo(id, 'inventoryItems')
      setModuleItems(prev => {return prev.filter(entry => entry._id !== item._id)})
    }
    
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete item named {item.item}{item.displayTitle ? ` (${item.displayTitle})`: ``}?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" 
            onClick={() => {
                handleClose()
                deleteFunction(item._id)
            }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
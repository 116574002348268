import React, { useContext } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { ErrorBoundary } from '../../other/utils/ErrorBoundary';
import { SiteContext } from '../../App';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie, Doughnut } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export default function ColorChart({title, colorValue, sizeClass, filteredReviewEntries, type}) {

  const options = {
    rotation: -90,
    responsive: true,
    maintainAspectRatio: true,
    // aspectRatio: 1.1,
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
    //   x: {
    //     // max: 140,
    //     // min: 0,
    //     // ticks: {
    //     //   stepSize: 20,
    //     //   autoSkip: false,
    //     //   display: true,
    //     //   callback: function(value, index, ticks) {
    //     //     if(value > 100) return ''
    //     //     return `${value}%`;
    //     //   }
    //     // },
        grid: {
          display: false,
          drawBorder: false, 
        },
    //   },
    //   y: {
    //     // position: 'right',
    //     ticks: {
    //     //   color: function (context, value) {
    //     //     // console.log(context, 'context.dataset.label', context.tick.label)
    //     //     var selection = (context.tick.label === 'Present' || context.tick.label === 'Tardy' || context.tick.label === 'Absent') ? attendance : colorMatch
    //     //     var key = selection === attendance ? 'attendance' : 'color'
    //     //     var labelVal = (context.tick.label === 'Present' || context.tick.label === 'Tardy' || context.tick.label === 'Absent') ? context.tick.label : context.tick.label.split(' ')[1]
    //     //     var index = selection.findIndex(entry => entry[key] === labelVal)
    //     //       if(labelVal === 'White') return '#343a40'
    //     //       return selection[index].hex
    //     //   },
    //     //   crossAlign: "far",
    //     //   font: function (context) {
    //     //     var size =  type === 'colors' ? context.chart.height/28 : context.chart.height/10
    //     //     size = size < 12 ? 12 : size; // setting max limit to 12
    //     //     size = size > 16 ? 16 : size; 
    //     //     // console.log(size, 'size')
    //     //     return {
    //     //       weight: 'bold',
    //     //       size,
    //     //       color: 'red'
    //     //     }
    //     //   },
    //     },
    //     // grid: {
    //     //   display: false,
    //     //   drawBorder: false, 
    //     // },
    //   }
    },
    // responsive: true,
    plugins: {
      tooltip: {
        enabled: false // <-- this option disables tooltips
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        borderRadius: 10,
        color: 'white',
        borderColor: 'white',
        // borderWidth: 3,
        padding: 3,
        font: {
            size: "24px",
            weight: 'bold'
        }
        // padding: function (context) {
        //   var size = context.chart.height/24
        //   size = size < 4 ? 4 : size; // setting max limit to 12
        //   // console.log(context.chart.height, 'context.chart.height')
        //   return size
        // },
        // font: function (context) {
        //   var size = type === 'colors' ? context.chart.height/30 : context.chart.height/15

        //   size = size < 12 ? 12 : size; // setting max limit to 12
        //   size = size > 16 ? 16 : size;
        //   return {
        //     weight: 'bold',
        //     size,
        //   }
        // },
        // offset: function (context) {
        //   var size = type === 'colors' ? -context.chart.height/20 : -context.chart.height/10
        //   size = size < -2 ? -2 : size; // setting max limit to 12
        //   return size
        // },
    },
    },
  };
  

  const {colorMatch} = useContext(SiteContext)

  const border = []
  const bgColor = []
  const dataVal = []
  const labels = []
    for(let i = 9; i >= 4; i--) {
        const match = colorMatch.filter(entry => entry.level === i)[0]
        var filter = filteredReviewEntries.filter(item => item.color.current === match.color)
        if(i === 4) {
            var count = 0
            filteredReviewEntries.map(item4 => {
                const match2 = colorMatch.filter(item5 => item5.color === item4.color.current)[0]
                if(match2.level <= 4) {count++}
            })
            if(count === 0) continue
            dataVal.push(count)
            labels.push('Other')
            bgColor.push(match.hex === '#f7b924' ? '#6c757d' : match.hex)
        } else {
            if(filter.length === 0) continue
            dataVal.push(filter.length)
            labels.push(match.color)
            bgColor.push(match.hex === '#f7b924' ? '#6c757d' : match.hex)
        }
    }

  const dataColors = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: dataVal,
        borderColor: '#ffffff',
        borderWidth: 3,
        backgroundColor: bgColor,
        datalabels: {
          align: 'start',
          anchor: 'end',
        },
      },
    ],
  };

  const data = dataColors

  // console.log(filteredReviewEntries.length,'filteredReviewEntries.length')

  return (
    <ErrorBoundary>
      <div className={`${sizeClass}`}>
        <div className={`card-shadow-${colorValue} mb-3 widget-chart widget-chart2 text-start card`}>
          <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className={`widget-content-left pe-2 fsize-1 text-${colorValue}`}>
                  <div className="widget-numbers mt-0 fsize-3">
                      {title}
                  </div>
                </div>
                <div className={`widget-content-right pe-2 fsize-1 text-${`primary`}`}>
                  <div className="widget-numbers mt-0 fsize-1">
                      Students: {filteredReviewEntries.length}
                  </div>
                </div>
              </div>
              <div className="widget-content-left fsize-1 text-muted">
                <Doughnut options={options} data={data} plugins={[ChartDataLabels]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faX, faPlus, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

export default function AItem({entry, count, designBriefCount, taskCount, handleFunctions, types, length}) {

    const { updateChecklist, handleSave, handleAddDelete, handleChangeIndex } = handleFunctions

    const [form, setForm] = useState({...entry})

    function handleUpdateForm(e, param) {
        e.preventDefault()

        const editForm = {...form}
        if(param === 'type' && e.target.value === 'Design Brief') { editForm.day = 'Day 7' }
        if(param === 'item' && e.target.value === 'Day 5 Checkpoint Test') { 
            editForm.day = 'Day 5'
            editForm.type = 'Test' 
        }
        if(param === 'item' && e.target.value === 'Day 10 Checkpoint Test') { 
            editForm.day = 'Day 10'
            editForm.type = 'Test' 
        }
        editForm[param] = e.target.value
        setForm(editForm)
    }

    var entryStatus = entry.complete && !entry.adminComplete ? 'pending' :
                      entry.complete && entry.adminComplete ? 'complete' : 'active'

    const days = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7', 'Day 8', 'Day 9', 'Day 10']
    const tasks = ['Overview', 'Task 1', 'Task 2', 'Task 3', 'Task 4', 'Task 5', 'Task 6', 'Task 7', 'Task 8', 'Task 9', 'Task 10']
    const items = ['Day 5 Checkpoint Test', 'Day 10 Checkpoint Test']

    const actions = ['Complete Worksheet', 'Answer Questions on PaxPat']
    
    var bgColor = form.type === 'Test' ? 'bg-black' : 
                  form.type === 'Activity' ? 'bg-purple' : 
                  form.type === 'Design Brief' ? 'bg-primary' : 'bg-secondary'
    
    // console.log(count,'count',length, 'length', taskCount, entry)
    return (
    <div className="row mt-3">
        <div className={`col-1 d-flex align-items-stretch`}>
            <div className={`card ${bgColor} text-white w-100`}>
            <div className="d-flex align-items-center justify-content-center" style={{height:"100%"}}>
                <h2 className="m-0">{entry.type === 'Design Brief' && taskCount === 0 ? `${designBriefCount}` : entry.type === 'Design Brief' ? `${designBriefCount}.${taskCount}` : `${count}`}</h2>
            </div>
            </div>
        </div>
        <div className={`col-10 d-flex justify-content-between align-items-stretch`}>
            <div className="card w-100 shadow-none">
                <div className="m-2 me-3 ms-3 p-2 d-flex flex-wrap">
                    <span className={`me-2 ms-2 mb-2 w-50`}>
                    Item: 
                    <input value={form.item} onChange={(e) => handleUpdateForm(e, 'item')} className={`form-control d-inline ${entry.item === form.item ? 'bg-white' : 'bg-secondary text-white'}`}
                        list="items" />  
                    <datalist id="items">
                        <option value={''}/>
                        {items.map(entry => {
                            return (
                                <option value={entry} key={entry} />
                            )
                        })}
                    </datalist>
                    {/* <input type="text" className={`form-control d-inline w-100 ${entry.item === form.item ? 'bg-white' : 'bg-secondary text-white'}`} value={form.item} onChange={(e) => handleUpdateForm(e, 'item')} /> */}
                    </span>
                    <span className="me-2 ms-2 mb-2">
                    Link:
                    <input type="text" className={`form-control d-inline w-100 ${entry.link === form.link ? 'bg-white' : 'bg-secondary text-white'}`} value={form.link} onChange={(e) => handleUpdateForm(e, 'link')} />
                    </span>
                    <span className="me-2 ms-2 mb-2">
                    Type:
                    {/* <input list="storage1" className={`form-control d-block`}  
                    value={form.type} onChange={(e) => handleUpdateForm(e, 'type')}
                    />  
                    <datalist id="storage1">
                        <option value={''}/>
                        {types.map(entry => {
                            return (
                                <option value={entry.type} key={entry.type} />
                            )
                        })}
                    </datalist> */}
                    <select
                    // className="form-control"
                    className={`form-control d-inline ${entry.type === form.type ? 'bg-white' : 'bg-secondary text-white'}`}
                    value={form.type} onChange={(e) => handleUpdateForm(e, 'type')}
                    >
                        <option value={''}></option>
                        {types.map(entry => {
                            return <option value={entry.type} key={entry.type}>{entry.type}</option>
                        })}
                    </select>
                    </span>
                    {form.type === 'Design Brief' && <span className="me-2 ms-2 mb-2">
                    Task:
                    <select
                    // className="form-control"
                    className={`form-control d-inline ${entry.designBriefTask === form.designBriefTask ? 'bg-white' : 'bg-secondary text-white'}`}
                    value={form.designBriefTask}
                    onChange={(e) => handleUpdateForm(e, 'designBriefTask')}
                    >
                        <option value={''}></option>
                        {tasks.map(entry => {
                            return <option value={entry} key={entry}>{entry}</option>
                        })}
                    </select>
                    </span>}
                    <span className="me-2 ms-2 mb-2">
                    Day:
                    <select
                    // className="form-control"
                    className={`form-control d-inline ${entry.day === form.day ? 'bg-white' : 'bg-secondary text-white'}`}
                    value={form.day}
                    onChange={(e) => handleUpdateForm(e, 'day')}
                    >
                        <option value={''}></option>
                        {days.map(entry => {
                            return <option value={entry} key={entry}>{entry}</option>
                        })}
                    </select>
                    </span>
                    <div className={`me-2 ms-2 mb-2 w-50`}>
                    Action: 
                    <input value={form.action} onChange={(e) => handleUpdateForm(e, 'action')} className={`form-control d-inline ${entry.item === form.item ? 'bg-white' : 'bg-secondary text-white'}`}
                        list="actions" />  
                    <datalist id="actions">
                        <option value={''}/>
                        {actions.map(entry => {
                            return (
                                <option value={entry} key={entry} />
                            )
                        })}
                    </datalist>
                    {/* <input type="text" className={`form-control d-inline w-100 ${entry.item === form.item ? 'bg-white' : 'bg-secondary text-white'}`} value={form.item} onChange={(e) => handleUpdateForm(e, 'item')} /> */}
                    </div>
                </div>
            </div>
        </div>
        <div className={`col-1 d-flex align-items-stretch flex-grow`}>
          <div className="card w-100 shadow-none">
            <div className="card-body d-flex justify-content-center align-items-center flex-wrap">
                {/* <div className="d-flex justify-content-center align-items-center flex-wrap w-100"> */}
                    <FontAwesomeIcon className="m-1" onClick={e => handleSave(e, entry, form)} icon={faFloppyDisk} role="button" />
                    <FontAwesomeIcon className="m-1" onClick={e => handleAddDelete(e, 'delete', entry)} icon={faX} role="button" />
                {/* </div> */}
                {/* <div className="d-flex justify-content-center align-items-center flex-wrap w-100"> */}
                    {count > 1 && length > 1 && <FontAwesomeIcon className="m-1" onClick={e => handleChangeIndex('raise', entry)} icon={faArrowUp} role="button" />}
                    {count <= length - 1  && <FontAwesomeIcon className="m-1" onClick={e => handleChangeIndex('lower', entry)} icon={faArrowDown} role="button" />}
                    {/* length - taskCount - 1 */}
                {/* </div> */}
            </div>
          </div>
        </div>
    </div>
    )
}



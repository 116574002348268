import React, { useState, useEffect } from "react";

export const useStopwatchLocal = (milliImport, running) => { 

    const [time, setTime] = useState(0);
    const [newLoad, setNewLoad] = useState(true)

    // console.log(milliImport, running, 'milliImport, running')
    useEffect(() => {

      var dateNow = new Date()
      var dateNowMilli = dateNow.getTime()

        setTime(dateNowMilli-milliImport)
        setNewLoad(false)

    }, [])

    useEffect(() => {

      let interval;

      if (newLoad) {
        return
      } else if (running && !newLoad) {
        interval = setInterval(() => {
          var dateNow = new Date()
          var dateNowMilli = dateNow.getTime()
          setTime(dateNowMilli - milliImport + 10);
        }, 10);
      } else if (!running) {
        clearInterval(interval);
      }

      return () => clearInterval(interval);
    }, [running, newLoad]);

    return {
      minutes: ("0" + Math.floor((time / 60000) % 60)).slice(-2),
      seconds: ("0" + Math.floor((time / 1000) % 60)).slice(-2)
    }
};

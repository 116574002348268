import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faX, faPlus, faFloppyDisk, faCamera } from '@fortawesome/free-solid-svg-icons';
import { usePhotoGallery } from '../inventory/admin/upload/usePhotoGallery.ts';
import CreatableSelect, { useCreatable } from 'react-select/creatable';

export default function AItem({entry, count, handleFunctions, length}) {

    const { updateChecklist, handleSave, handleAddDelete, handleChangeIndex } = handleFunctions
    const { takePhoto } = usePhotoGallery();

    const [form, setForm] = useState({...entry})

    //   console.log(value,'value utside')


    // console.log(form, 'form', entry, 'entry')

    function handleUpdateForm(e, param, inputValue) {
        if(e) e.preventDefault()

        // console.log(inputValue,'inputValue')
        const editForm = {...form}
        editForm[param] = inputValue ? inputValue : e.target.value
        // console.log(editForm,'editForm')
        setForm(editForm)
        console.log(editForm,'editForm')
    }

    async function handleAddImage(e) {
        e.preventDefault()
    
        const timeMilli = new Date().getTime()
        var url = `https://ccr-items.s3.us-west-1.amazonaws.com/${timeMilli}.jpeg`
        await takePhoto(timeMilli, 100)
        // handleChange(e, 'image', '', url)
        handleUpdateForm(e, 'imageURL', url)
    }
    
    // console.log(entry,'entry')

    // var bgColor = entry === form ? 'bg-primary text-white' : ''
    var bgColor = 'bg-white'

    const moduleInfo = [
        {type: 'imageURL', text: 'Image URL', inputType: 'input'},
        {type: 'emoji', text: 'Module Emoji', inputType: 'input'},
        {type: 'description', text: 'Description', inputType: 'text'},
        {type: 'designBriefTask', text: 'Design Brief Task', inputType: 'text', rows: 5},
        // {type: 'jobs', text: 'Jobs', inputType: 'text'},
    ]

    // console.log(entry.jobs, 'entry.hobs', form.jobs, 'form')

    return (
    <div className="d-flex align-items-stretch mb-3">
        <div className={`card bg-primary text-white p-2 me-2 shadow-none`} style={{minWidth:"40px"}}>
            <div className="d-flex align-items-center justify-content-center" style={{height:"100%"}}>
                <h2 className="m-0">{count}</h2>
            </div>
        </div>
        <div className="card flex-grow-1 shadow-none bg-light">
            <div className="m-2 me-3 ms-3 p-2 d-flex flex-wrap">
                <span className={`flex-fill me-2 ms-2 mb-2`}>
                    Module: 
                    <input value={form.title} onChange={(e) => handleUpdateForm(e, 'title')} className={`form-control d-inline ${entry.title === form.title ? 'bg-white' : 'bg-secondary text-white'}`} list="items" />  
                </span>
                {moduleInfo.map(item => {
                    // console.log(item,'item')
                    return (
                        <span key={item.type} className={`flex-fill me-2 ms-2 mb-2 ${item.inputType !== 'input' && 'w-100'}`}>
                        {item.text}:
                        {item.inputType === 'input' ?
                            <input className={`form-control ${entry[item.type] === form[item.type] ? 'bg-white' : 'bg-secondary text-white'}`}
                            value={form[item.type]} onChange={(e) => handleUpdateForm(e, item.type)}/>
                         :
                            <textarea className={`form-control ${entry[item.type] === form[item.type] ? 'bg-white' : 'bg-secondary text-white'}`} cols={40} rows={item.rows ? item.rows : 1} value={form[item.type]}
                            onChange={(e) => handleUpdateForm(e, item.type)} />
                         }
                        </span>
                    )
                })}
                <span className={`flex-fill me-2 ms-2 mb-2 w-100 p-2 border rounded ${entry.jobs === form.jobs ? '' : 'bg-secondary'}`} style={{minWidth:"500px"}}>
                    Jobs:
                    <JobsSelect form={form} handleUpdateForm={handleUpdateForm} />
                </span>
            </div>
            {/* </div> */}
        </div>
        <div className={`card ms-2 shadow-none ${bgColor}`} style={{maxWidth:"100px"}}>
            <div className="card-body d-flex flex-shrink-1 align-content-center justify-content-center flex-wrap">
                <div className="d-flex justify-content-center flex-wrap">
                <FontAwesomeIcon className="m-2" onClick={e => handleSave(e, entry, form)} icon={faFloppyDisk} role="button"  />
                {/* <FontAwesomeIcon className="m-2" onClick={e => handleAddDelete(e, 'delete', entry)} icon={faX} /> */}
                </div>
                <div className="d-flex justify-content-center flex-wrap">
                {/* {count > 1 && length > 1 && <FontAwesomeIcon className="m-2" onClick={e => handleChangeIndex('raise', entry, count-1)} icon={faArrowUp} />}
                {count <= length - 1 && <FontAwesomeIcon className="m-2" onClick={e => handleChangeIndex('lower', entry, count-1)} icon={faArrowDown} />} */}
                <FontAwesomeIcon className="m-2" onClick={async (e) => handleAddImage(e)} icon={faCamera} role="button" />
                </div>
            </div>
        </div>
    </div>
    )
}


function JobsSelect({form, handleUpdateForm}) {

    // console.log(form,'form2')
    
    const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
      });

    // const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState(form.jobs ? [...form.jobs] : []);
    const [value, setValue] = useState(form.jobs ? [...form.jobs] : [])

      const handleCreate = (inputValue) => {
          const newOption = createOption(inputValue);
          setOptions((prev) => [...prev, newOption]);
          const editValue = value ? [...value, newOption] : [newOption]
          setValue(editValue);
          console.log(editValue,'value', newOption, options)
          handleUpdateForm(undefined, 'jobs', editValue)
      };

      const handleChange = (newValue) => {
        console.log(newValue,'newValue')
        setValue(newValue)
        handleUpdateForm(undefined, 'jobs', newValue ? newValue : [])
      }

    return (
        <CreatableSelect 
        isMulti 
        isClearable
        // isDisabled={isLoading}
        // isLoading={isLoading}
        onChange={(newValue) => handleChange(newValue)}
        onCreateOption={handleCreate}
        options={options}
        value={value}
        />
    )
}
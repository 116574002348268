import React, {useState, useContext} from 'react'

import studentJSobj from '../json/calendar.json'
// import goalsJSON from '../other/utils/goals.json'

export function useCalendar({calendarDays, schedules}) {

    return {checkCalendar}

    function checkCalendar(timeStamp){

        if(!calendarDays || calendarDays.length === 0 || schedules.length === 0) return 
        const calendarEvents = calendarDays[0].events
    
        // console.log('here 1')
        const inputDate = timeStamp ? new Date(timeStamp) : new Date()
            inputDate.setHours(0)
            inputDate.setMinutes(0)
            inputDate.setSeconds(0)
            inputDate.setMilliseconds(0)
    
        const inputMilli = inputDate.getTime()

        // console.log(inputDate,'inputDate')
        
        var index = calendarEvents.findIndex(entry => entry.milli === inputMilli)
        if(index === -1) {
            // console.log('day not found 1')
            if(inputDate.getDay() === 0 || inputDate.getDay() === 6) return 'No School'
            return schedules[0].item
        }

        var index2 = schedules.findIndex(entry => entry.index == calendarEvents[index].index)
        // console.log(calendarEvents[index], index, 'calendarEvents[index]', schedules, index2)
        if(calendarEvents[index].index === schedules.length) {
            // console.log('no school')
            return 'No School'
        }
        else if(index2 > -1) {
            // if(review) {
            //     console.log(schedules[index2].item, 'hereeee', new Date(timeStamp))
            // }
            // console.log(schedules[index2].item,'dayType hereeeee')
            return schedules[index2].item
        } else {
            // console.log('day not found 2')
            return schedules[0].item
        }
    }
}



        // const studentJSobj = JSON.parse(JSON.stringify(jsonItem))
        // const studentJSobj = JSON.parse(JSON.stringify(jsonItem))
        
        // var dateObj = timeStamp ? new Date(timeStamp) : new Date()
        //     var weekDay = dateObj.getDay()
    
        //     // console.log(weekDay, 'weekDay')
    
        // if(weekDay === 6 || weekDay === 0) {
        //     return 'No School'
        // }
    
        // var d = timeStamp ? new Date(timeStamp) : new Date()
        //     var mondayTime = d.setDate(d.getDate()-(weekDay - 1))
        //     var mondayDate = new Date(mondayTime)
    
        //     var month = mondayDate.getMonth() + 1; //months from 1-12
        //     var day = mondayDate.getDate();
    
        //     var mondayString = month + "/" + day;
    
        //     var weeklyValues = studentJSobj.filter(entry => entry.week === mondayString)[0]
            
        //     if(!weeklyValues) {
        //         return "Normal"
        //     }
    
        //     var dayType = weeklyValues['values'][weekDay-1]
    
        //     if(dayType.length === 0) {
        //         return "Normal"
        //     }
            
        //     return dayType

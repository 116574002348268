import React, { useContext, useState, useEffect } from 'react'

import { SiteContext } from '../../App';
import { StudentContext } from '../../pages/StudentContainer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsToDot, faBullseye } from '@fortawesome/free-solid-svg-icons';
import {  } from '@fortawesome/free-regular-svg-icons';

import { ErrorBoundary } from '../../other/utils/ErrorBoundary';

export default function Goal({sizeClass}) {

  const {
      classInformation, focusAgreements, student, colorMatch,
      studentDataFunctions: {
        createMongo, getMongoList, getMongoById, updateMongo, deleteMongo,
        updateFocus, checkFocus
      }
  } = useContext(SiteContext)

  const {handleShow} = useContext(StudentContext)

    return (
      <ErrorBoundary>
      <div className={sizeClass}>
        <div className="main-card mb-3 card" role="button" onClick={e => handleShow(undefined, 'goalSettingStatic')}>
          <div className="card-header">
            {/* <div className="d-flex justify-content-between align-items-center w-100"> */}
              <span>
                <FontAwesomeIcon className="me-2" icon={faBullseye} />
                Goal - {student.moduleFull} 
              </span>
              {/* <div className={`ms-2 badge bg-secondary`} style={{fontSize:"12px"}}>Class</div> */}
              {/* <div className={`badge bg-secondary p-2 ms-2`} role="button" onClick={e => handleShow(undefined, 'goalSettingStatic')}>Expand Goal</div> */}
            {/* </div> */}
          </div>
          <div className="card-body">
                {student.goalSetting && student.goalSetting.goal.goal ? student.goalSetting.goal.goal : 'No Goal Yet'} 
          </div>
        </div>
      </div>
      </ErrorBoundary>
    )
}


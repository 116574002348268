import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';

import { SiteContext } from "../../App";
import { StudentContext } from "../../pages/StudentContainer";
import { ErrorBoundary } from "../../other/utils/ErrorBoundary";

import birthdayFunction from "../../other/utils/birthday";

export default function PSModal({handleShow, show, type, payload}) {

    const {attendanceOutcome} = payload

    const {
      student, socket, room, classInformationArray, studentList, classInformation,
      studentDataFunctions: {createMongo, handleClose}
    } = useContext(SiteContext)

    function handleCloseLocal() {
      handleClose(type)
    }

    function handleButton(e, open) {
        e.preventDefault()
        handleClose(type)
    }
  
 if(!attendanceOutcome) return

 var matchingPeriod = classInformationArray.filter(entry => entry.informationStrings.sectionId === attendanceOutcome.period)[0]

 var count = 0

 const birthdays = []
 studentList.map(entry => {
   // console.log(birthdayFunction(entry.birthday),'birthdayFunction(student.birthday)')
  if(!birthdayFunction(entry.birthday) || (matchingPeriod && entry.period !== matchingPeriod.period)) return
  birthdays.push({name: entry.name, birthday: birthdayFunction(entry.birthday)}) 
 })

 var count1 = 0

 return (
  <ErrorBoundary>    
    <div
      onKeyDown={e => e.stopPropagation()}
    >
    <Modal 
      show={show} 
      onHide={handleCloseLocal}
    >
      <Modal.Header>
        <Modal.Title>{matchingPeriod ? `${matchingPeriod && matchingPeriod.period} Attendance` : 'Attendance Check'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="m-1">
        {attendanceOutcome.failures.length > 0 ? 
        attendanceOutcome.failures.map(entry => {
          count++
          return (
              <div key={count} className="d-flex justify-content-start align-items-center">
                <span>{entry.name}</span>
                <span className="ms-3" style={{fontWeight:"bold"}}>{entry.message}</span>
              </div>
          )
        })
        :
        'Success!'}
      </div>
      {birthdays.length > 0 && <div className="mt-3 m-1">
        <div className="fw-bold">Birthdays:</div>
        {birthdays.map(entry => {
          count1++
          return (<div key={count1} className="d-flex justify-content-start align-items-center">
            <span>{entry.name}</span>
            <span className="ms-3">{entry.birthday}</span>
          </div>)
        })}
      </div>}
      </Modal.Body>
      <Modal.Footer>
          <button onClick={e=>handleButton(e, true)} className={`btn btn-info`}>Close</button>
      </Modal.Footer>
    </Modal>
    </div>
  </ErrorBoundary>
  
);
}




import React, {useContext} from 'react'
import { SiteContext } from '../App'
import { useNavigate, NavLink } from "react-router-dom";

import Dropdown from 'react-bootstrap/Dropdown';

export default function DAdminPages() {

    const {adminPages, loginState, studentDataFunctions: {setLoginState}} = useContext(SiteContext)
    const navigate = useNavigate();

    function handleNavigate(item) {
        navigate(`/${item}`, { replace: true });

        const editLoginState = {...loginState}
        if(item === 'student') {
            setLoginState({...editLoginState, studentView: true})
        } else if(editLoginState.studentView) {
            setLoginState({...editLoginState, studentView: false})
        }
    }

    function handleStudentView(e) {
        e.preventDefault()
        handleNavigate('student')

        const editLoginState = {...loginState}
        setLoginState({...editLoginState, studentView: true})
    }
    
    return (
        <>
        <Dropdown.Item onClick={() => handleNavigate('dashboard')}>
        Dashboard
        </Dropdown.Item>
        {adminPages.map(entry => {
            if(entry.priority !== 1 || entry.type === 'dashboard') return
            return (
            <Dropdown.Item key={entry.type} onClick={() => handleNavigate(entry.type)}>
                {entry.text}
            </Dropdown.Item>
            )
        })}
        <div className="dropdown-divider"></div>
        {adminPages.map(entry => {
            if(entry.priority !== 2 || entry.type === 'dashboard') return
            return (
            <Dropdown.Item key={entry.type} onClick={() => handleNavigate(entry.type)}>
                {entry.text}
            </Dropdown.Item>
            )
        })}
        <Dropdown.Item onClick={e => handleStudentView(e)}>
            Student Dashboard
        </Dropdown.Item>
        <div className="dropdown-divider"></div>
        {adminPages.map(entry => {
            if(entry.priority || entry.type === 'dashboard') return
            return (
            <Dropdown.Item key={entry.type} onClick={() => handleNavigate(entry.type)}>
                {entry.text}
            </Dropdown.Item>
            )
        })} 
        </>
    )
}

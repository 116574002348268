import React, {useState, useContext, useEffect} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faArrowUp, faX, faPlus } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid'
import { usePhotoGallery } from '../../../../modules/inventory/admin/upload/usePhotoGallery.ts';
import useKeyPress from '../../../../other/utils/useKeyPress';

import { SiteContext } from '../../../../App';

import '../../customModal.css'

export default function Body({customOption, setVariables, update, setUpdate}) {

//   const {modifiedPeriod, setModifiedPeriod, editHeading, setEditHeading, arrays} = dataFunctions

  const { socket, room, studentDataFunctions: { updateMongo, getMongoList, } } 
  = useContext(SiteContext)

  const [show, setShow] = useState(false)
  const [imageHash, setImageHash] = useState(Date.now())

  const { takePhoto } = usePhotoGallery();
  
  function handleChange(e, type, categoryEntry, url) {
    e.preventDefault()

    const modifyHeading = {...customOption}
    if(type === 'categoryEntry') {
        var entryIndex = modifyHeading.bullets.findIndex(entry => entry.entry === categoryEntry)
        modifyHeading.bullets[entryIndex] = {...modifyHeading.bullets[entryIndex], entry: e.target.value}
    } else if(type === 'image') {
      modifyHeading.url = url
    } else if(type === 'category') {
      modifyHeading.text = e.target.value
    } else if(type === 'responseType') {
      modifyHeading.responseType = e.target.value
    }

    setVariables(modifyHeading)
    setImageHash(Date.now())
    // socket.emit("send_modalDisplay", { data: {attendanceQuestion: {...modifyHeading}}, room });
  }

  function handleCreate() {
    var modifyHeading = {...customOption}
    modifyHeading.bullets.push(newCategoryEntry())

    setVariables(modifyHeading)
    socket.emit("send_modalDisplay", { data: {attendanceQuestion: {...modifyHeading}}, room });
  }

  const onKeyPress = (event) => {
    // console.log(`key pressed: ${event.key}`);
    if(event.shiftKey === true && event.ctrlKey === true) {
        if(event.key === 'N') handleCreate()
    }
  };

  useKeyPress(['N'], onKeyPress);

  function handleDelete(e, entry){
    var modifyHeading = {...customOption}
    modifyHeading.bullets = modifyHeading.bullets.filter(item => item.id !== entry.id)
    setVariables(modifyHeading)
    socket.emit("send_modalDisplay", { data: {attendanceQuestion: {...modifyHeading}}, room });
  }

  function handleDeleteImage(e) {
    e.preventDefault()

    handleChange(e, 'image', '', '')
  }

  async function handleAddImage(e) {
    e.preventDefault()

    const timeMilli = new Date().getTime()
    var url = `https://ccr-items.s3.us-west-1.amazonaws.com/${timeMilli}.jpeg`
    handleChange(e, 'image', false, url)
    await takePhoto(timeMilli, 100)
  }

    var editFunctions = { handleChange, handleDelete, handleCreate, customOption }
    var indexCount = -1

    // console.log(customOption,'customOption')
    if(!customOption) return

    return (
        <>
        <ResponseTypes editFunctions={editFunctions} />
        <div className="mb-2">
          <div className={`d-flex ${customOption.url.length > 0 ? `justify-content-between` : `justify-content-end align-items-center`}`}>
              {customOption.url.length > 0 ? 
              <div><img src={`${customOption.url}?${new Date().getTime()}`} className="flex-fill modalImage"/></div> 
              : <span className="me-3">No Image</span>
              }
              <div className={`d-flex flex-column`} style={{borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px"}}>
              <button className={`btn btn-primary text-white flex-fill ${customOption.url.length > 0 && `addButton`}`} 
              // style={{borderBottomLeftRadius:"0px", borderBottomRightRadius:"0px", borderTopLeftRadius:"0px", height: customOption.url.length > 0 && '200px'}}
                  onClick={async (e) => handleAddImage(e)}>
                      <FontAwesomeIcon color="white" icon={faCamera} />
              </button>
              {customOption.url.length > 0 && <button className="btn btn-danger text-white d-block" style={{borderTopLeftRadius:"0px", borderTopRightRadius:"0px", borderBottomLeftRadius:"0px"}} onClick={e => handleDeleteImage(e)}>
                <FontAwesomeIcon color="white" icon={faX} />
              </button>}
              </div>
          </div>
        </div>
        <Category key={indexCount} item={customOption} editFunctions={editFunctions} />
        </>
    )
}

function Category({item, editFunctions, indexCount, length}) {
    
    const {handleChange, handleDelete, handleCreate} = editFunctions

    const {classInformation} = useContext(SiteContext)

      return (
      <div key={item.id} className="">
        <div className="mb-2">
        <div className="d-block">Heading:</div>
        <textarea cols={40} rows={1}
        type="text" className="form-control bg-light w-100 mb-2"
        value={item.text}
        onChange={(e) => handleChange(e, 'category')}
        />
        </div>
        <div className="">
            <div className="d-block">Prompts:</div>
            {item.bullets && item.bullets.map(element => {
            return <CategoryEntry key={element.id} element={element} item={item} editFunctions={editFunctions} />
            })}
          <div className="d-flex justify-content-end">
          <FontAwesomeIcon className="m-2 mt-3 me-0" role="button" style={{minWidth:"15px"}} icon={faPlus} onClick={e => handleCreate(e, 'categoryEntry', item.category)} />
          </div>
        </div>
      </div>
      )
  }

  function CategoryEntry({element, item, editFunctions}) {
    const {handleChange, handleDelete} = editFunctions
    // console.log(element,'element')
    return (
      <div className="d-flex justify-content-between align-items-center mb-2">
        <textarea key={element.id} type="text" className="form-control flex-fill" cols={40} rows={1}
          value={element.entry} onChange={(e) => handleChange(e, 'categoryEntry', element.entry)} />
        <FontAwesomeIcon key={element.id + 'fa'} style={{minWidth:"15px"}} className="m-2 ms-3 me-0" role="button" onClick={e => handleDelete(e, element)} icon={faX} />
      </div>
    )
  }

  function ResponseTypes({editFunctions}) {

    const {handleChange, handleDelete, handleCreate, customOption} = editFunctions

    function updateResponseType(e) {
      e.preventDefault()

      handleChange(e, 'responseType')
    }

    return (
      <div className="mb-2">
        <div className="d-block">
          Student Response Type:
        </div>
        <select
        className="form-control" role="button"
        onChange={e => updateResponseType(e)}
        value={customOption.responseType}
        >
          <option>No Response</option>
          <option>Text Response</option>
          <option>Poll Response</option>
          <option>Poll Response and Explain</option>
          <option>Full Screen, No Response</option>
        </select>
      </div>
    )

  }
  
  function newCategoryEntry () {
      return {
        id: uuidv4(),
        entry: ''
      }
  }

import React, { useContext } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

import { SiteContext } from '../../App'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend); 

export default function RChart({levelByColor, added, subtracted, dayType, entriesLength}) {

    const { colorMatch } = useContext(SiteContext)

    const options = {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 8, 
      plugins: {
        legend: {
            display:false,
          },
          chartAreaBorder: {
            borderWidth: 0,
          },
        datalabels: {
            backgroundColor: function(context) {
                if((context.dataset.label === 'Add' || context.dataset.label === 'Subtract') && context.dataset.data[context.dataIndex] !== null) {
                    var bgColor = context.dataset.label === 'Add' ? '#3ac47d' : '#d92550'
                    return bgColor
                } else return ''
            },
            color: 'white',
            display: true,
            borderRadius: 10,
            color: 'white',
            padding: function (context) {
              var size = context.chart.height/24
              size = size < 4 ? 4 : size; // setting max limit to 12
              // console.log(context.chart.height, 'context.chart.height')
              return size
            },
            font: function (context) {
              // if(context.dataset.label !== 'Add' && context.dataset.label !== 'Subtract') {
              //   return {
              //     weight: 'bold',
              //     size: "14px"
              //   }
              // }
              var size = context.chart.height/9
              size = size < 10 ? 10 : size; // setting max limit to 12
              // console.log(context.chart.height, 'context.chart.height')
              return {
                weight: 'bold',
                size: size
              }
            },
            formatter: function (value, context) {
                    if(context.dataset.label === 'Add') {
                        if(value === null) return '' 
                        else {return '+1' }
                    }
                    if(context.dataset.label === 'Subtract') {
                        if(value === null) return '' 
                        else {return '-1' }
                    }
                    if(context.datasetIndex > 9) {
                        if(value === 0) return '' 
                        else {return value }
                    }
                    if(context.datasetIndex <= 9 && context.datasetIndex > 0) {
                        return ''
                    }
                    if(value === 0) {return ''} else {
                        return context.chart.data.labels[context.dataIndex]
                    }
            },
            offset: function (context) {
              if(context.dataset.label !== 'Add' && context.dataset.label !== 'Subtract') {return}
              // console.log(context.chart.height, 'context.chart.height')
              if(context.dataset.label === 'Add') {
                return -.97*context.chart.height;
              } else if(context.dataset.label === 'Subtract') {
                return -.45*context.chart.height;
              }
              
            },
    
        },
        tooltip: {
            display: false,
            filter: function (context, data) {
                return context.datasetIndex > 20
            },
        },
        title: {
        //   display: true,
        //   text: 'Chart.js Bar Chart - Stacked',
        },
        elements: {
            line: {
              fill: false
            },
            point: {
              hoverRadius: 7,
              radius: 5
            }
        },
      },
      scales: {
        y: {
            ticks: {
                display: false
            },
            stacked: true,
            min: -.3,
            max: .6,
            labels: {
                display: false
            }, 
            grid: {
                display: false,
                drawBorder: false, 
            }, 
        },
        x: {
            stacked: true,
            min: 0,
            max: entriesLength,
            ticks: {
                display: false,
            },
            grid: {
                display: false,
                drawBorder: false, 
            }
        },
      },
    };
    
    const labels = ['','Start'];
    const axisData = []

    // console.log(levelByColor,'levelByColor', entriesLength)
    for(let i = 2; i < entriesLength + 1; i++) {
        if(i === entriesLength - 3) {
            labels.push('End')
        } else if((i === 5 || i === 10 || i === 15 || i === 20 || i === 25 || i === 30 || i === 35 || i === 40 || i === 45) && i < entriesLength -3) {
            // labels.push('End')
            labels.push(i + ' min')
        } else {
            labels.push('')
        }
    
        axisData.push(0.2)
    }

    const data = {
        labels,
        datasets: [
          {
            label: 'Dataset 1',
            data: axisData,
            backgroundColor: '#6c757d',
            stack: 'Stack 0',
            barPercentage: 1.0,
            categoryPercentage: 1.0,
            datalabels: {
              align: 'center',
              anchor: 'left',
            },
            // borderWidth: 2,
            // borderRadius: 5,
            // borderSkipped: false,
          },
          createDataSetObj(levelByColor, 1),
          createDataSetObj(levelByColor, 2),
          createDataSetObj(levelByColor, 3),
          createDataSetObj(levelByColor, 4),
          createDataSetObj(levelByColor, 5),
          createDataSetObj(levelByColor, 6),
          createDataSetObj(levelByColor, 7),
          createDataSetObj(levelByColor, 8),
          createDataSetObj(levelByColor, 9),
          {
            label: 'Add',
            data: added,
            // backgroundColor: '#3ac47d',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            stack: 'Stack 0',
            barPercentage: 1.0,
            categoryPercentage: 1.0,
            datalabels: {
              align: 'top',
              anchor: 'end',
              // offset: -163
            },
            skipNull: true
          },
          {
            label: 'Subtract',
            data: subtracted,
            // backgroundColor: '#d92550',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            stack: 'Stack 0',
            barPercentage: 1.0,
            categoryPercentage: 1.0,
            datalabels: {
              align: 'bottom',
              anchor: 'center',
            //   offset: -3
            }, 
            skipNull: true,
          },
        ],
    };

    function createDataSetObj(levelByColor, level) {

        var index = colorMatch.findIndex(entry => entry.level === level)
            var levelHex = colorMatch[index].hex

        return {
            label: 'Dataset 1',
            data: levelByColor['level' + level],
            backgroundColor: levelHex,
            stack: 'Stack 0',
            barPercentage: 1.0,
            categoryPercentage: 1.0,
            datalabels: {
              align: 'right',
              anchor: 'center',
            },
            skipNull: true,
            // borderWidth: 2,
            // borderRadius: 5,
            // borderSkipped: false,
        }
    }

  return (
    <Bar options={options} data={data} plugins={[ChartDataLabels]} 
    //   style={{backgroundColor:"black"}}
    />
  )
  
}




import React, {useEffect, useState} from "react";
import GridLayout, {WidthProvider} from "react-grid-layout";

import '../../../node_modules/react-grid-layout/css/styles.css'
import '../../../node_modules/react-resizable/css/styles.css'
import '../../admin/grid/utils/grid.css'
import blankLayout from '../../other/json/blankLayout.json'

import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";

const DecoratedReactGridLayout = WidthProvider(GridLayout);
// const originalLayout = this.props.layout ? this.props.layout : getFromLS("layout") || [];

function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-7")) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls[key];
}

export default class SeatGrid extends React.Component {

  static defaultProps = {
    className: "layout",
    cols: 50,
    rows: 50,
    // rowHeight: 30,
    // width: "100px",
    isBounded: true,
    compactType: null,
    // allowOverlap: true,
    // verticalCompact: false,
    preventCollision: true,
    onLayoutChange: function() {},
    // isDraggable: false,
    // isResizable: false
  }; 

  constructor(props) {
    super(props);

    this.state = {
      layout: JSON.parse(JSON.stringify(
        getFromLS("layout") || []
      ))
    };

    // this.isDraggable = false
    // this.isResizable = false

    // this.onLayoutChange = this.onLayoutChange.bind(this);
    // this.resetLayout = this.resetLayout.bind(this);
    // this.saveLayout = this.saveLayout.bind(this);
  }

  
  render() {


    function compare( a, b ) {
      if ( a.index < b.index ){
        return -1;
      }
      if ( a.index > b.index ){
        return 1;
      }
      return 0;
    }

    const sorted = this.props.modules.sort( compare );


    var modulesCombined = []
    sorted.map(entry => {
      modulesCombined.push(entry)
      // console.log('looping')
    })
    modulesCombined.push({title: 'No Group', variant: 'secondary', fullTitle: 'No Group'})

    modulesCombined.map(entry => {
      entry.students = []
      entry.checkoutItems = []
    })

    var index = modulesCombined.findIndex(item => item.fullTitle === 'No Group')
    // console.log(modulesCombined[index],'index', this.props.layout)

    // var studentsLength = modulesCombined[index].students.length
    // this.props.layout[18].h = studentsLength > 32 ? 12 : studentsLength > 24 ? 11 : studentsLength > 16 ? 8 : studentsLength > 8 ? 6 : 5
    // this.props.layout[18].w = 50

    var count = 0
    const currentLayout = this.props.layout ? this.props.layout : JSON.parse(JSON.stringify(blankLayout))
    // console.log(JSON.stringify(currentLayout),'currentLayout', this.props.period)
    // currentLayout.sort((a, b) => a.x.localeCompare(b.x) || b.y - a.y);

    currentLayout.sort(
      function(a, b) {          
         if (a.x === b.x) {
            // Price is only important when cities are the same
            return b.x - a.x;
         }
         return a.y > b.y ? 1 : -1;
      });

    return (
    <div>
      <DecoratedReactGridLayout
          {...this.props}
          // layout={this.props.layout ? this.props.layout : this.state.layout}
          onLayoutChange={this.onLayoutChange}
      >
        {modulesCombined.map(entry => {
            // console.log(entry,'entry', this.props.moduleCurrent)
            count++

            const tooltip = (
                <Tooltip id="tooltip">
                  <strong>{entry.fullTitle}:</strong> {entry.description}
                </Tooltip>
              );

            if(entry.students.length === 0 && entry.title === 'No Group' && this.props.gridMode !== 'Edit') return
            return (
            
            <div className={`gridBox pt-1 bg-${this.props.moduleCurrent === entry.title ? 'secondary' : 'light'}`} key={count} data-grid={{ w: 36, h: 3, x: 0, y: 0 }} role="button" 
                 onClick={e => this.props.handleClick(e, entry.title)} style={{position:"absolute"}}>
                {/* {this.props.gridMode === 'Modal Completion' && entry.checkoutItems.length > 0 && <CheckoutOverlay entry={entry} />} */}
                <div className={`d-flex justify-content-between align-items-center mb-1 ${this.props.moduleCurrent === entry.title ? 'text-white' : ''} fw-bold`}>
                <OverlayTrigger placement="top" overlay={tooltip}>
                <span className="ms-2 flex-shrink-1" style={{fontSize:"0.8rem"}} role="button">
                  {entry.emoji} 
                  {/* {entry.fullTitle} */}
                </span>
                </OverlayTrigger>
                </div>
                {/* <div>
                    {entry.description}
                </div> */}
                <br/>
                
            </div>
            
            )
        })}
      </DecoratedReactGridLayout>
      {/* {this.props.gridMode === 'Edit' && 
      <EditButtons editFunctions={{resetLayout: this.resetLayout, resetStudents: this.props.resetStudents, editMode: this.props.editMode,
        resetPendingStudents: this.props.resetPendingStudents, handleAllowEdit: this.props.handleAllowEdit, handleSimplifySeats: this.props.handleSimplifySeats, allowEdit: this.props.allowEdit, showSeats: this.props.showSeats, setShowSeats: this.props.setShowSeats, saveLayout: this.saveLayout}}/>} */}
    </div>
    );
  }
}


import React, {useState} from 'react'
import TimePicker from 'react-time-picker';

import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

export default function InformationItems({entry, handleChangeInfo}) {

    const [value, setValue] = useState(entry.value);

    function updateTime(e) {
        // console.log(e, 'value')
        setValue(e)
        handleChangeInfo(e, entry.key)
    }

    if(entry.key === '_id' || entry.key === 'id' || entry.key === 'dailyEventSubmitted' || entry.key === 'dayType' || entry.key === 'focusAgreementId') return

    var s = entry.key.replace(/([A-Z])/g, ' $1').trim()
    return (
        <>
        <div className="form-group edit-row">
            <label htmlFor="position">{capitalizeFirstLetter(s)} {entry.key === 'periodAbbreviation' && '(Max 3 Characters)'}</label>
            {(entry.key === 'classStartNormal' || entry.key === 'classStartMinimum') ? 
            <div>
              <TimePicker onChange={updateTime} value={value} disableClock={true} clearIcon={null} />
            </div> 
            :
            <input
            type="text" className="form-control" id="position"
            value={entry.value}
            onChange={e => handleChangeInfo(e, entry.key)}
            />}
        </div> 
         
        </>
    )

}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

import React, { useContext } from 'react'
import { SiteContext } from '../../App';
import { ErrorBoundary } from '../utils/ErrorBoundary';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Insructions({ title, subtitle, colorValue, sizeClass }) {

    // const { student } = useContext(SiteContext)

    return (
    <ErrorBoundary>
      <div className={`${sizeClass}`}>
        <div className={`card-shadow-${colorValue} mb-3 widget-chart widget-chart2 text-start card`}>
          <div className="widget-content p-0 w-100">
            <div className="widget-content-outer">
              <div className="widget-content-wrapper">
                <div className={`widget-content-left pe-2 fsize-1 text-${colorValue}`}>
                {/* <div className="widget-numbers mt-0 fsize-3 text-white"> */}
                  <div className="widget-numbers mt-0 fsize-3">
                      {title}
                  </div>
                </div>
              </div>
              <div className="widget-content-left fsize-1">
                <div className="text-muted">
                    {title === 'Instructions' && <div className="recipe__row">
                        <div className="recipe__value recipe__value--indented">
                            <div className="ingredient-grid2">
                                <span className="">Step 1.</span><span className="">Start in the center and choose a word that describes how you're feeling.</span>
                                <span className="">Step 2.</span><span className="">The words touching this word on the next ring of words are similar words.</span>
                                <span className="">Step 3.</span><span className="">From this next ring, choose a word that describes how you're feeling a bit better.</span>
                                <span className="">Step 4.</span><span className="">When a word feels right: stop and click on this word. Then, go back to the center and repeat two more times.</span>
                            </div>
                        </div>
                    </div>}  
                    {title !== 'Instructions' && <div>{`The Feelings Wheel exists to offer us better, more specific words to describe how we're feeling.`}</div>}
                    {title !== 'Instructions' && <br/>}
                    {title !== 'Instructions' && 
                      <div style={{marginTop:"15x"}}>
                        <span>On the feelings wheel below, you will choose 3 words to describe</span>
                        <span className="fw-bold ms-1">how you feel right now.</span>
                      </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
    )
}

// export default function Balance({ title, subtitle, colorValue, sizeClass }) {

//     const { student } = useContext(SiteContext)

//     return (

//       <div className={`${sizeClass}`}>
//         <div className={`card-shadow-${colorValue} mb-3 widget-chart widget-chart2 text-start card`}>
//           <div className="widget-content p-0 w-100">
//             <div className="widget-content-outer">
//               <div className="widget-content-wrapper">
//                 <div className={`widget-content-left pe-2 fsize-1 text-${colorValue}`}>
//                   <div className="widget-numbers mt-0" style={{fontSize:"50px"}}>
//                     {title}€
//                   </div>
//                 </div>
//                 <div className={`widget-content-right pe-2 fsize-1 text-${colorValue}`}>
//                   <div className="widget-numbers mt-0 fsize-3">{subtitle}</div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//     )
// }
import React, { useState, useEffect, useContext } from "react";

import Modal from 'react-bootstrap/Modal';
import CompleteCard from "./SCompleteCard";

import { SiteContext } from "../../../App";
import { StudentContext } from "../../../pages/StudentContainer";
import { ErrorBoundary } from "../../../other/utils/ErrorBoundary";

import { usePhotoGallery }  from '../../../modules/inventory/admin/upload/usePhotoGallery.ts';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsToDot, faBook, faSheetPlastic, faX } from '@fortawesome/free-solid-svg-icons';

export default function Questions({activeAssignment, setActiveAssignment}) {
//   const {submitItem, entry, setSubmitItem, typeBg, count, updateChecklist} = importVariables

  const {student, socket, studentDataFunctions: {setStudent, updateMongo}} = useContext(SiteContext)

  const { takePhoto } = usePhotoGallery();

  const [localActivity, setLocalActivity] = useState('')
  const [localWhat, setLocalWhat] = useState(activeAssignment.what)

  useEffect(() => {

    let interval;

    interval = setInterval(async () => {
      console.log(activeAssignment.what, localWhat === activeAssignment.what, localWhat)
      if((activeAssignment.what && localWhat === activeAssignment.what) || !localWhat || localWhat.length === 0) return
      console.log('continue')
      updateAssignment(false, 'what', localWhat)
      // updateChecklist(false, entry.itemId, 'completeEvent', 'activity', localActivity)
    }, 3000);

    return () => clearInterval(interval);

  }, [localWhat])

  useEffect(() => {
    setLocalWhat(activeAssignment.what ? activeAssignment.what : '')
  }, [activeAssignment.title])

  console.log(activeAssignment,'activeAssignment')
  // useEffect(() => {
  //   setLocalWhat(activeAssignment.what ? activeAssignment.what : '')
  // }, [activeAssignment])

  async function handleAddImage(e) {
    if(e) e.preventDefault()

    if(e.target.classList.contains('delete')) {
      console.log('here')
      updateAssignment(e, 'url', 'delete')
      return
    }
    const timeMilli = new Date().getTime()
    var url = `https://ccr-items.s3.us-west-1.amazonaws.com/${timeMilli}.jpeg`
    await takePhoto(timeMilli, 100)
    // updateChecklist(e, entry.itemId, 'completeEvent', 'url', url)
    // handleChange(e, 'image', '', url)
    updateAssignment(e, 'url', url)
  }

  async function updateAssignment(e, param, value) {
    if(e) e.preventDefault()

    const editAssignments = [...student.assignments]
    var index = editAssignments.findIndex(entry => entry.itemId === activeAssignment.itemId)

    console.log(editAssignments[index],'editAssignments[index]', param, value)
    if(param === 'url'){
      if(value === 'delete') {
        editAssignments[index].url = []
      } else {
        editAssignments[index].url = editAssignments[index].url ? [...editAssignments[index].url, value] : [value]
      }
    } else if(param === 'complete') {
      editAssignments[index].complete = value
      if(value === true) {
        editAssignments[index].returned = false
        editAssignments[index].timestamp = Date.now()
      } else {
        editAssignments[index].returned = false
        editAssignments[index].timestamp = Date.now()
        editAssignments[index].completeAdmin = false
      }
    } else {
      editAssignments[index][param] = value
    }

    console.log(editAssignments,'editAssignments')
    setStudent({...student, assignments: editAssignments})
    updateMongo({assignments: editAssignments}, student.dailyListId, 'dailyList')
    setActiveAssignment(editAssignments[index])
    socket.emit("send_updateSingleStudent", {student: {...student, assignments: editAssignments}, room: student.period});
  }

  function localUpdate(e) {
    e.preventDefault()
    console.log(e.target.value,'val')
    setLocalWhat(e.target.value)
  }

  function handleClose() {
    // setSubmitItem(false)
  }

  // function localUpdate(e) {
  //   e.preventDefault()
  //   console.log(e.target.value,'val')
  //   setLocalActivity(e.target.value)
  // }

  function updateChecklistLocal(e, outcome) {
    console.log(outcome,'outcome')
    updateAssignment(e, 'complete', outcome)
    // setSubmitItem(false)
  }

  const questions = [
    {question: 'In a couple sentences, what did you do in this activity?', param: 'what', type: 'text'},
    // {question: 'How much did you enjoy the activity? (On a scale of 1 to 5)', type: 'rating'},
  ]

  var countQ = 0
  // console.log(activeAssignment, 'activeAssignment')

  var variantColor = activeAssignment.complete ? 'success' : 'info'

  if(activeAssignment.complete && !activeAssignment.returned) {
    return <CompleteCard questions={questions} activeAssignment={activeAssignment} variantColor={variantColor} updateChecklistLocal={updateChecklistLocal} />
  }

  console.log(activeAssignment.url,'activeAssignment.url')
  return (
    <div className="mt-3 mb-3 d-flex align-items-stretch">
      <div className={`card flex-grow-1 border border-3 border-${variantColor}`}>
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="">
                <h3 className="fsize-2 fw-bold mb-0">{activeAssignment.title}</h3>
              </div>
              {activeAssignment.returned && <div className="badge bg-secondary d-flex align-items-center ps-3 pe-3 pt-2 pb-2">
                <h3 style={{fontSize:"18px"}} className="mb-0 fw-bold">Returned</h3>
              </div>}
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="mb-2">To submit evidence of your work & learning, please complete the following:</div>
          <div className="d-flex justify-content-start align-items-start">
            <div className="me-2">1.</div>
            <div>Answer the following questions.</div>
          </div>
          <div className="d-flex justify-content-start align-items-start">
            <div className="me-2">2.</div>
            <div>Submit an artifact. Either by taking a photo of your work or uploading a photo.</div>
          </div>
        </div>
        <div className="card-body">
          {/* <div className="card bg-secondary border border-3 border-success">
            <div className="card-body"> */}
              {questions.map(item => {
                countQ++  
                return (
                  <div key={countQ} className={`card bg-secondary border border-3 border-${variantColor} mb-3`}>
                    <div className="card-body">
                      <div className="text-white fw-bold">{countQ}. {item.question}</div>
                      <div className="mt-2 p-1">
                      {item.type === 'text' ?          
                        <textarea className="form-control" onChange={e => localUpdate(e)} cols={40} rows={2} value={!localWhat ? '' : localWhat}
                          id="textAreaSpace" placeholder={'Type here...'} 
                          // defaultValue={!localWhat ? '' : localWhat} 
                          /> : <></>}
                       </div>
                      {item.type === 'text' && localWhat && localWhat.length < 40 && localWhat.length > 10 && <div className="d-flex justify-content-end text-light p-1">
                        {40-localWhat.length} Characters More Required
                      </div>}
                    </div>
                  </div>
                )
              })}
            {/* </div>
          </div> */}
          <div className={`card bg-black text-white mb-3 border border-3 border-${variantColor}`} role="button" onClick={async (e) => handleAddImage(e)}>
            <div className={`card-body fw-bold d-flex justify-content-between flex-wrap`}>
              <span>Click here to upload artifact. Either take or upload photo.</span>
              <div className="d-flex align-items-center">
                <div className={`badge ${activeAssignment.url && activeAssignment.url.length > 0 ? `bg-${variantColor} text-white` : 'bg-light text-black'} d-flex align-items-center`}>
                  {!activeAssignment.url || !activeAssignment.url.length === 0 ? 'No File Uploaded' : 
                  activeAssignment.url && activeAssignment.url.length === 1 ? '1 Image Uploaded' : 
                  `${activeAssignment.url && activeAssignment.url.length} Images Uploaded ${(activeAssignment.url && activeAssignment.url.length > 1) ? `(Max 5)` :
                   ''}`}
                </div>
                {activeAssignment.url && activeAssignment.url.length > 0 && <div className={`badge ms-2 bg-light text-black d-flex align-items-center`}>+ Image</div>}
                {activeAssignment.url && activeAssignment.url.length > 0 && <div className={`badge ms-2 bg-light text-black d-flex align-items-center delete`}>
                <FontAwesomeIcon className={`delete`} icon={faX} />
              </div>}
              </div>
            </div>
          </div>
          {activeAssignment.url && activeAssignment.url.length > 0 && localWhat && localWhat.length > 39 && !activeAssignment.complete && <div className="d-flex justify-content-end">
            <div className={`badge bg-${variantColor} p-3`} style={{fontSize:"1rem"}} 
              role="button" onClick={e => updateChecklistLocal(e, true)}>
              Submit
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}
import React, { useContext, useState, useEffect, createContext } from 'react'

import { SiteContext, baseUrl } from '../../App'

import EventEntries from './EventEntries'
import AllEntries from './AllEntries'

export default function AllEventCard( { title, subtitle, colorValue, sizeClass, colorChanges }) {

    const { 
      classInformation, 
      studentDataFunctions: {
      }
    } = useContext(SiteContext)

    var count = 0
    var bgCount = 0
    var currentDay = ''

    return (
      <div className={sizeClass}>
      <div className={`card-shadow-secondary mb-3 widget-chart widget-chart2 text-start card`}>
        <div className="widget-content p-0 w-100">
          
          <div className="widget-content-outer">
            <div className="widget-content-wrapper">
              <div className="widget-content-left pe-2 fsize-1">
                <div className="widget-numbers mt-0 fsize-3 text-info">
                  All Color Changes
                </div>      
              </div>
            </div>
            
            <div className="widget-content-left fsize-1">
            {colorChanges.length === 0 && <span>No Color Changes</span>}
            {colorChanges.length > 0 && <div className="table-responsive">
              <table className="align-middle text-truncate mb-0 table table-borderless table-hover text-secondary">
                <thead>
                  <tr>
                    <th className="text-center">Date</th>
                    {/* <th className="text-center">Type</th> */}
                    <th className="">Agreement Category</th>
                    <th className="">Agreement</th>
                    {/* <th className="text-center">Colors</th> */}
                  </tr>
                </thead>
                <tbody>
                    
                    {colorChanges.map(entry => {
                        if(count == 0) {
                          currentDay = entry.dateString
                        } else {
                          // console.log(entry.dateString,'entry.dateString',currentDay)
                          if(entry.dateString !== currentDay) {
                            bgCount++
                            currentDay = entry.dateString
                          }
                        }
                        count++
                        // console.log(entry,'entry')
                        return (
                            <AllEntries entry={entry} key={count} bgCount={bgCount} />
                        )
                    })}
                </tbody>
              </table>
            </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

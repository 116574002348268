import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner, faRotate} from '@fortawesome/free-solid-svg-icons';

export default function InProgressOverlay({entry, handleShow, setActiveChart}) {
  const renderTooltip = (props) => (
    <Tooltip id="" {...props}>
      <div className="d-flex flex-wrap text-wrap justify-content-start">
        <div className="d-flex badge bg-black mb-1 d-inline">{inProgressItem.day}</div>
        <span className="d-flex d-inline">{inProgressItem.item}</span>
      </div>
    </Tooltip>
  );

  var inProgressItem = false
  if(!entry || !entry.checklist) return
  for(let i = 0; i < entry.checklist.length; i++) {
      var item = entry.checklist[i]

      if(!item.inProgress || item.complete) continue
      inProgressItem = item
      break
  }

  function handleGroupClick(e, groupNumber) {
    e.preventDefault()
    var index = entry.checklists.findIndex(item => item.groupNumber === groupNumber)
    if(index === -1) return
    setActiveChart(entry.checklists[index])
  }

  // console.log(entry.checklists,'entry.checklists')
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={inProgressItem ? renderTooltip : <></>}
    > 
    <div className="text-wrap word-break d-flex align-items-center flex-wrap" role="button"
    // style={{wordBreak:"break-all"}}
    >
        <span className={`${inProgressItem ? 'text-black' : 'text-black'} me-2`} style={{fontWeight:"bold", fontSize:"12px"}} onClick={handleShow}>
          {/* <div className={`badge ${inProgressItem ? 'bg-black' : 'bg-secondary'} p-1 text-white`}> */}
          {entry.title.substring(0,12)}
          {/* <span className="ms-1">{entry.checklists.length > 1 && entry.groupNumber}</span> */}
        </span>
        {entry.checklists.length > 1 && <span className="d-flex align-items-center me-2">
          {entry.checklists.map(checklistEntry => {
            return <div key={checklistEntry.groupNumber} className={`badge ${entry.groupNumber === checklistEntry.groupNumber ? 'bg-black text-white' : 'bg-white text-black'} me-1`} onClick={e => handleGroupClick(e, checklistEntry.groupNumber)}>{checklistEntry.groupNumber}</div>
          })}
          {/* <span className="ms-1">{entry.checklists.length > 1 && entry.groupNumber}</span> */}
          {/* </div> */}
        </span>}
        {inProgressItem && <FontAwesomeIcon className="card-title d-inline text-black mb-0" icon={faRotate} style={{fontSize:"12px"}} />}
    </div>

    </OverlayTrigger>
  );
}

function calculateTime(dayType, normalStart, minimumStart) {

    // console.log(dayType,normalStart,minimumStart,'minimumStart')

    var startTime = ''
    var classLength = 0
    if(dayType === 'Normal') {
        classLength = 50
        startTime = normalStart
    } else if (dayType === 'Minimum') {
        classLength = 40
        startTime = minimumStart
    } else {
        return {
          classStart: 'No School',
          classEnd: 'No School'
        }
    }

    if(!startTime) return {
        classStart: 'No School',
        classEnd: 'No School'
    }
    startTime = startTime.split(':')

    var hours = parseInt(startTime[0])
    var mins = parseInt(startTime[1])

    const start = `${hours}:${mins}`

    // console.log(hours, mins, classLength, startTime, start, 'fewjoewfjew;klfejwlkf;')
    if(mins + classLength >= 60) { 
        mins = ((mins + classLength) - 60).toString()
        hours++
        // if(hours < 8) {hours = hours + 12}
    } else {mins = (mins + classLength).toString()}

    if(mins < 10) { mins = `0${mins}`}
    // console.log(`${hours}:${mins}`)

    var hoursReadable = ((hours + 11) % 12 + 1);

    return {
        classStart: start,
        classEnd: `${hours}:${mins}`,
        classEndReadable: `${hoursReadable}:${mins}`,
    }
    
}

module.exports = calculateTime
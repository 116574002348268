import React, { useState, useContext } from 'react'
import { SiteContext } from '../../App';

import TimePicker from 'react-time-picker';

import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import './schedules.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faX, faPlus, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

export default function ScheduleItem({entry, count, handleFunctions, length}) {

    const {classInformationArray, colorMatch} = useContext(SiteContext)

    const { updateChecklist, handleSave, activeSchedule, handleChangeIndex, handleAddDeletePeriod, updateVariables, schedules } = handleFunctions

    // const [form, setForm] = useState({...entry})

    function handleUpdateForm(e, param, newValue) {
        if(e) e.preventDefault()

        const editEntry = {...entry}
        // editEntry[param] = e.target.value
        // setForm(editForm)

        const editActiveSchedule = {...activeSchedule}
        var index = editActiveSchedule.periods.findIndex(periodEntry => periodEntry.itemId === editEntry.itemId)

        editActiveSchedule.periods[index][param] = newValue ? newValue : e.target.value

        const editSchedules = [...schedules]
        var index = editSchedules.findIndex(scheduleEntry => scheduleEntry.itemId === editActiveSchedule.itemId)
        if(index === -1) return

        editSchedules[index] = {...editActiveSchedule}
        updateVariables(editSchedules, editSchedules[index])
    }

    function updateTime(e) {
        // console.log(e, 'value')
        // setValue(e)
        // handleChangeInfo(e, entry.key)
        handleUpdateForm(e, 'class')
    }

    var bgColor = 'bg-primary'

    return (
    <div className="d-flex align-items-stretch mb-3">
        <div className={`card bg-${colorMatch[2 + activeSchedule.index].variant} text-white p-2 me-2 shadow-none`}>
            <div className="d-flex align-items-center justify-content-center" style={{height:"100%", width:"30px"}}>
                <h2 className="m-0">{count}</h2>
            </div>
        </div>
        <div className="card flex-grow-1 shadow-none bg-light">
            <div className="m-2 me-3 ms-3 p-2 d-flex flex-wrap align-items-center justify-content-between">
                <span className={`flex-fill me-2 ms-2 mb-2`}>
                    Select Class: 
                    <select className={`form-control d-inline`}
                      value={entry.class} onChange={(e) => handleUpdateForm(e, 'class')}>
                      {classInformationArray.map(classEntry => {
                          if(classEntry.archived) return
                          return <option key={classEntry._id} value={classEntry.period}>{classEntry.period}</option>
                      })}
                      <option value={'Lunch'}>Lunch</option>
                      <option value={'Prep'}>Prep</option>
                    </select>
                </span>
                <div className="d-flex">
                    <span className="flex-fill me-2 ms-2 mb-2">
                        Class Start:
                        <TimePickerContainer inputTime={entry.startTime} timeType={'startTime'} handleUpdateForm={handleUpdateForm} />
                    </span>
                    <span className="flex-fill me-2 ms-2 mb-2">
                        Class End:
                        <TimePickerContainer inputTime={entry.endTime} timeType={'endTime'} handleUpdateForm={handleUpdateForm} />
                    </span>
                </div>
            </div>
            {/* </div> */}
        </div>
        <div className="card ms-2 shadow-none bg-light" style={{maxWidth:"110px", minWidth:"110px"}}>
            <div className="card-body d-flex flex-shrink-1 align-content-center justify-content-center flex-wrap">
                <div className="d-flex justify-content-center flex-wrap">
                {/* <FontAwesomeIcon className="m-2" onClick={e => handleSave(e, entry, form)} icon={faFloppyDisk} role="button" /> */}
                <FontAwesomeIcon className="m-2" onClick={e => handleAddDeletePeriod(e, 'delete', entry)} icon={faX} role="button" />
                </div>
                <div className="d-flex justify-content-center flex-wrap">
                {count > 1 && length > 1 && <FontAwesomeIcon className="m-2" onClick={e => handleChangeIndex('raise', entry, count-1)} icon={faArrowUp} role="button" />}
                {count <= length && <FontAwesomeIcon className="m-2" onClick={e => handleChangeIndex('lower', entry, count-1)} icon={faArrowDown} role="button" />}
                </div>
            </div>
        </div>
    </div>
    )
}

function TimePickerContainer({inputTime, timeType, handleUpdateForm}) {

    const [value, setValue] = useState(inputTime);

    function updateTime(e) {
        // console.log(e, 'value')
        handleUpdateForm(undefined, timeType, e)
        setValue(e)
    }

    return <div>
        <TimePicker onChange={updateTime} value={value} disableClock={true} clearIcon={null} />
        </div>
}

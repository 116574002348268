export function useColorMatch() {
    return [
        {
            color: 'Black',
            string: '⬛ Black',
            variant: 'black',
            level: 9,
            grade: 100,
            gradeLetter: 'A+',
            hex: '#343a40'
        },
        {
            color: 'Gold',
            string: '🏅 Gold',
            variant: 'gold',
            level: 8,
            grade: 95,
            gradeLetter: 'A',
            hex: '#d3ac1e'
        },
        {
            color: 'Purple',
            string: '🟪 Purple',
            variant: 'alt',
            level: 7,
            grade: 90,
            gradeLetter: 'A-',
            hex: '#794c8a'
        },
        {
            color: 'Blue',
            string: '🟦 Blue',
            variant: 'primary',
            level: 6,
            grade: 85,
            gradeLetter: 'B',
            hex: '#3f6ad8'
        },
        {
            color: 'Green',
            string: '🟩 Green',
            variant: 'success',
            level: 5,
            grade: 80,
            gradeLetter: 'B-',
            hex: '#3ac47d'
        },
        {
            color: 'Yellow',
            string: '🟨 Yellow',
            variant: 'warning',
            level: 4,
            grade: 70,
            gradeLetter: 'C',
            hex: '#f7b924'
        },
        {
            color: 'Orange',
            string: '🟧 Orange',
            variant: 'flag',
            level: 3,
            grade: 62,
            gradeLetter: 'D',
            hex: '#fd7e14'
        },
        {
            color: 'Red',
            string: '🟥 Red',
            variant: 'danger',
            level: 2,
            grade: 60,
            gradeLetter: 'D-',
            hex: '#d92550'
        },
        {
            color: 'White',
            string: '⬜ White',
            variant: 'light',
            level: 1,
            grade: 50,
            gradeLetter: 'F',
            hex: '#eeeeee'
        },
    ]
}
import React, { useContext, useState, useEffect, createContext } from 'react'

import ChartJs from './ChartJs'

import { SiteContext, baseUrl } from '../../App'
import { ErrorBoundary } from '../../other/utils/ErrorBoundary'

import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

const data01 = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];
const data02 = [
  { name: 'A1', value: 100 },
  { name: 'A2', value: 300 },
  { name: 'B1', value: 100 },
  { name: 'B2', value: 80 },
  { name: 'B3', value: 40 },
  { name: 'B4', value: 30 },
  { name: 'B5', value: 50 },
  { name: 'C1', value: 100 },
  { name: 'C2', value: 200 },
  { name: 'D1', value: 150 },
  { name: 'D2', value: 50 },
];

export default function FeelingsWheel( { title, sizeClass, words, setWords }) {

    const { 
      studentDataFunctions: {
      }
    } = useContext(SiteContext)

    function handleReset() {
      setWords(['','',''])
    }

    // static demoUrl = 'https://codesandbox.io/s/pie-chart-of-two-levels-gor24';

    return (
        <ErrorBoundary>
        <div className={sizeClass}>  
          <div className="main-card mb-3 card">
            <div className="card-header">
              {/* <div className="card-header-title font-size-lg text-capitalize fw-normal"> */}
                <button onClick={handleReset} className="btn btn-secondary">Reset</button>
                {/* </div> */}
              {/* </div> */}
            </div>
            <div className="card-body">
                  <div  style={{position: "relative", height:"85vh", width: "60vw"}}>
                  <ChartJs words={words} setWords={setWords} />
                  </div>
            </div>
          </div>
        </div>
        </ErrorBoundary>
    )
}

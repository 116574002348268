import React, { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';

import AgEntry from './AgEntry';

import { SiteContext, baseUrl } from "../../../App";
import { AdminContext } from '../../../pages/AdminContainer';

export default function AgreementsModal({title, activeModal}) {

  const [show, setShow] = useState(false);
  const [focusAdded, setFocusAdded] = useState(false)

  const {
      classInformation, focusAgreements, setFocusAgreements, initialLoad,
      studentDataFunctions: {
        createMongo, getMongoList, getMongoById, updateMongo, deleteMongo,
        updateFocus, checkFocus, clearFocusAgreements
      }
  } = useContext(SiteContext)

  const { agreements, setAgreements } = useContext(AdminContext)

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => setShow(true);

  function handleReset(e) {
    e.preventDefault()

    clearFocusAgreements()
  }

  const keys = []
  for(let i = 0; i < classInformation.agreements.length; i++) {
    keys.push(i)
  }

  if(!focusAgreements) return
  focusAgreements.categories = focusAgreements.categories.sort((a, b) => a.category.localeCompare(b.category))

  var count = 0

  const focusElements = []
  const nonFocusElements = []

  classInformation.agreements.map(entry => {
    const index = focusAgreements.categories.findIndex(item => item.id === entry.id)
    
    if(focusAgreements.categories[index] && focusAgreements.categories[index].adminFocus) {
      focusElements.push(entry)
    } else {
      nonFocusElements.push(entry)
    } 
  })

  const combined = [...focusElements, ...nonFocusElements]

  const foci = focusAgreements.categories.filter(entry => entry.adminFocus)
  var string = foci.length > 0 ? `Focus: ${foci[0].category}` : 'Agreements'
  string = foci.length > 1 ? string += ` +${foci.length - 1}` : string

  return (
    <>
      <Button 
        onClick={handleShow}
        className={`btn-icon btn-wide btn-outline-2x btn ${activeModal ? `btn-outline-light` : `btn-outline-focus`} m-1 p-1 ps-2 ms-0 pe-2`}
        variant="outline-light"
        // style={{height:"40px"}}
      >
        {/* {string} */}
        Agreements
      </Button>

      <Modal 
        show={show} 
        onHide={handleClose}
        dialogClassName="my-modal2"
      >
        <Modal.Header closeButton>
          <Modal.Title>
          <button onClick={e => handleReset(e)} className="btn btn-secondary btn-sm"
           style={{fontSize:"10px", margin:"5px"}}
          >Reset Focus</button>
            {/* {focusAgreements.categories.map(entry => {
              var hidden = entry.adminFocus ? 'hideCategory' : ''
              return (
                <div key={entry.id} style={{display:"inline", margin:"5px"}}>
                  <div key={entry.id}
                    style={{fontSize:"10px"}}
                    className={`mb-2 me-2 badge bg-light ${hidden}`}
                    onClick={e => updateFocus(e, entry, 'categories', true, 'adminFocus')}
                  >{entry.category}</div>
                </div>
              )
            })} */}
            {focusAgreements.categories && focusAgreements.categories.map(entry => {
              if(!entry.adminFocus) {return}
              return (
                <div key={entry.id + count + 'div'} style={{margin:"10px", display:"inline"}}>
                  {/* <input
                    className={`form-check-input`}
                    type="checkbox"
                    name="positionOptions"
                    style={{fontSize:"16px", marginTop:"11.5px"}}
                    checked={checkFocus(entry, 'categories', 'adminFocus')}
                    value={checkFocus(entry, 'categories', 'adminFocus')}
                    onChange={e => updateFocus(e, entry, 'categories', e.target.value, 'adminFocus')}
                  /> */}
                  <div key={entry.id + count} className={`badge bg-primary`} style={{fontSize:"10px", margin:"5px"}}>{entry.category}</div>

                  {focusAgreements.agreements && focusAgreements.agreements.map(entry2 => {
                    if(!entry.adminFocus){return}
                    count++
                    return (
                      <div key={entry2.id}>
                        {entry2.adminFocus && entry2.category === entry.category &&
                          <div key={entry2.id + count} className={`badge bg-light`} 
                            style={{fontSize:"10px", padding:"5px", margin:"5px", marginLeft:"20px", fontSize:"10px", whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}
                          >{entry2.entry}</div>}
                      </div>
                    )
                  })}
                  
                 </div>
              )
            })}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Accordion defaultActiveKey={keys} alwaysOpen>
              {combined.map((agreement) => {
                var count = 0
                return (
                  <Accordion.Item key={agreement.id} eventKey={count}>
                    <Accordion.Header>
                      {agreement.category}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="recipe__row" style={{marginBottom:"25px"}}>
                        <div className="recipe__value recipe__value--indented2">
                          <div className="ingredient-grid3close">
                          {agreement.elements.map((element) => {
                            count++
                            return (
                              <AgEntry key={element.id} count={count} category={agreement} element={element} updateFocus={updateFocus} checkFocus={checkFocus} />)
                          })}
                          </div> 
                        </div>  
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })}
            </Accordion>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
} 
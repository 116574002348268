import React, { useContext, useState, useEffect, createContext } from 'react'

import { SiteContext, baseUrl } from '../../App'
import ListingEntry from './ListingEntry'
import Cart from './Cart'

export default function StoreCard( { cart, setCart, editCart }) {

    const { 
      classInformation, storeItems,
      studentDataFunctions: {
      }
    } = useContext(SiteContext)

    storeItems.sort( compare );

    return (
        <div className="col-sm-12">  
          <div className="main-card mb-3 card">
            <div className="card-header">
              <div className="card-header-title font-size-lg text-capitalize fw-normal">
                Chip Emporium Items
              </div>
              <div className="btn-actions-pane-right">
              </div>
            </div>
            <div className="table-responsive m-2">
              <table className="align-middle text-truncate mb-0 table table-borderless table-hover">
                <thead>
                  <tr>
                    <th className="">Item</th>
                    <th className="">Cost</th>
                    {/* <th className="text-center">Reason</th> */}
                    <th className="text-center">Add / Remove</th>
                  </tr>
                </thead>
                <tbody>
                  { storeItems.map(item => {
                      if(item.outOfStock === 'Yes') return
                      return (
                          <ListingEntry item={item} editCart={editCart} key={item.item}/>
                      )
                  })}
                </tbody>
              </table>
            </div>
            {/* <div className="d-block p-4 text-center card-footer">
              <button className="btn-pill btn-shadow btn-wide fsize-1 btn btn-dark btn-lg">
                <span className="me-1">View More</span>
              </button>
            </div> */}
          </div>
        </div>
    )
}

function compare( a, b ) {
  if ( a.index < b.index ){
    return -1;
  }
  if ( a.index > b.index ){
    return 1;
  }
  return 0;
}
import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../../App'
import { AdminContext } from '../../pages/AdminContainer'
import { ErrorBoundary } from '../../other/utils/ErrorBoundary'

import D_Behavior from './D_Behavior'

export default function Behavior({}) {

  const { 
    classInformation, 
    socket,
    room,
    studentList,
    studentDataFunctions: {
      setStudentList, getMongoById, updateMongo
    }
  } = useContext(SiteContext)

  const {
    dailyList, updateDailyListMongo,
    behaviorEvents, setBehaviorEvents, lastUpdateTime, setLastUpdateTime, 
    handleUpdateList, updateBEvents, setUpdateBEvents,
        pendingTime, setPendingTime, pendingEvents, setPendingEvents, handleDeleteDuplicateEvents
  } = useContext(AdminContext)

  useEffect(() => {

    socket.on("receive_createBEvent", (data) => {
        data.createObj.id = data.res.insertedId
        handleCreateBehaviorEvent(data.createObj,true)

        if(data.createObj.manual) {
          handleUpdateBehaviorEvents(data.createObj)
        }
    })

    return () => {
      socket.off('receive_createBEvent');
    };
  }, [socket, dailyList.length, behaviorEvents.length, pendingEvents.length, studentList]) 

  function handleCreateBehaviorEvent(behaviorEvent, manual) {

      // const remaining =  handleDeleteDuplicateEvents(behaviorEvents)
      // setBehaviorEvents(remaining)
      const behaviorEventsEdit = handlePending(behaviorEvents, pendingEvents, behaviorEvent, manual)

      if(behaviorEvent.type === 'self') {
        handleBehaviorEventResponse({studentId: behaviorEvent.studentInitiatingId, currentColor: false, pendingColor: behaviorEvent.pendingColor, eventType: false, behaviorEvent: false})
        // updateDailyList(behaviorEvent.studentInitiatingId, false, behaviorEvent.pendingColor)
      }
  }

  function handlePending(behaviorEvents, pendingEvents, behaviorEvent, manual) {

    // console.log(pendingEvents,'pendingEvents start')
    var activelyPending = behaviorEvents.filter(entry => entry.activelyPending)

    if(behaviorEvent && behaviorEvent.type === 'self') {
      var index = pendingEvents.findIndex(entry => entry.type === behaviorEvent.type && entry.studentInitiatingId === behaviorEvent.studentInitiatingId)
      if(index !== -1) {pendingEvents[index] = behaviorEvent} 
      else{pendingEvents.push(behaviorEvent)}
    }
  
    pendingEvents.map(entry => {
      if(entry && entry.type === 'self') {
        var index = activelyPending.findIndex(entry => entry.type === behaviorEvent.type && entry.studentInitiatingId === behaviorEvent.studentInitiatingId)
        if(index !== -1) {activelyPending[index] = behaviorEvent} 
        else{activelyPending.push(behaviorEvent)}
      } else {
        activelyPending.push(behaviorEvent)
      }
    })
    
    const remaining = handleDeleteDuplicateEvents(activelyPending) 
    setBehaviorEvents(remaining)
    socket.emit("send_updateBehaviorEvents", {behaviorEvents: remaining, room: 'admin'});
    setPendingTime(Date.now())

    return activelyPending
  }

  function handleUpdateBehaviorEvents(behaviorEvent) {

      const editedBehaviorEvents = [...behaviorEvents]
      
      if(!behaviorEvent.activelyPending && !behaviorEvent.manual) {
        var index = editedBehaviorEvents.findIndex(entry => entry.id === behaviorEvent.id)
        editedBehaviorEvents[index].activelyPending = false
      } else {
        var index = editedBehaviorEvents.findIndex(entry => entry.id === behaviorEvent.id)
        editedBehaviorEvents[index] = behaviorEvent
      }

      setBehaviorEvents(editedBehaviorEvents)
      // console.log(editedBehaviorEvents,'editedBehaviorEvents')
      socket.emit("send_updateBehaviorEvents", {behaviorEvents: editedBehaviorEvents, room: 'admin'});
      sendBehaviorEventOutcome(behaviorEvent, editedBehaviorEvents)
  }

  function sendBehaviorEventOutcome(behaviorEvent) {

    // SELF
    if(behaviorEvent.type === 'self') {
      if(behaviorEvent.result === 'approved') {
        handleBehaviorEventResponse({studentId: behaviorEvent.studentInitiatingId, currentColor: behaviorEvent.pendingColor, pendingColor: '', eventType: 'self', behaviorEvent})
        // updateDailyList(behaviorEvent.studentInitiatingId, behaviorEvent.pendingColor, '', behaviorEvent)
      } else {
        handleBehaviorEventResponse({studentId: behaviorEvent.studentInitiatingId, currentColor: false, pendingColor: '', eventType: 'self', behaviorEvent: false})
        // updateDailyList(behaviorEvent.studentInitiatingId, false, '', behaviorEvent)
      }

    // NOMINATE
    } else if(behaviorEvent.type !== 'self' && behaviorEvent.result === 'approved') {
        var index = studentList.findIndex(entry => entry.name === behaviorEvent.studentNominatedName)
        handleBehaviorEventResponse({studentId: studentList[index].studentId, currentColor: behaviorEvent.pendingColor, pendingColor: '', eventType: 'nominate', behaviorEvent})
        // updateDailyList(studentList[index].studentId, behaviorEvent.pendingColor, '', behaviorEvent)
    }
  };

  async function handleBehaviorEventResponse({studentId, currentColor, pendingColor, eventType, behaviorEvent}) {

    const outcomeData = { studentId, currentColor, pendingColor, eventType, behaviorEvent }
      socket.emit("send_updateBehaviorEventsResponse", {outcomeData: outcomeData, room});

    const editStudentList = [...studentList]
    var index = editStudentList.findIndex(entry => entry.studentId === studentId)
      if(typeof currentColor === 'string') {
        editStudentList[index].color.current = currentColor
        editStudentList[index].color.lastUpdate = Date.now()
      }
      if(typeof pendingColor === 'string') {
        editStudentList[index].color.pending = pendingColor
      }

      if(behaviorEvent && typeof currentColor === 'string') {
        editStudentList[index].behaviorEvents.push(behaviorEvent)
      }

      var alphaStudentList = editStudentList.sort((a, b) => a.name.localeCompare(b.name))
      setStudentList(alphaStudentList)
      socket.emit("send_updateSingleStudent", {student: {...editStudentList[index]}, room: 'admin'});
      // socket.emit("send_adminBehaviorUpdate", {behaviorEvents: behaviorEvents ? behaviorEvents : false, room: "admin"});

      // const studentDaily = await getMongoById(studentId, 'dailyList')

      const updateArray = {
        color : editStudentList[index].color,
        behaviorEvents: editStudentList[index].behaviorEvents
        // studentDaily.behaviorEvents
      }

      // if(behaviorEvent && typeof currentColor === 'string') {
      //   updateArray.behaviorEvents.push(behaviorEvent)
      // }
      updateMongo({...updateArray}, editStudentList[index].dailyListId, 'dailyList')
  };

  return (
    <ErrorBoundary>
      {/* <div className="row"> */}
          <D_Behavior
            behaviorEvents={behaviorEvents}
            handleUpdateBehaviorEvents={handleUpdateBehaviorEvents}
            type={'raise'}
          />
          <D_Behavior
            behaviorEvents={behaviorEvents}
            handleUpdateBehaviorEvents={handleUpdateBehaviorEvents}
            type={'lower'}
          />
      {/* </div> */}
    </ErrorBoundary>
    )
}

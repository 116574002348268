import React, { useContext, useState, useEffect } from 'react'

import { SiteContext, baseUrl } from '../../../App'
import { AdminContext } from '../../../pages/AdminContainer'
import { ErrorBoundary } from '../../../other/utils/ErrorBoundary'
import { v4 as uuidv4 } from 'uuid'

import ChecklistEntry from './ADashEntry'
import ColorTitle from '../../../other/utils/ColorTitle'
import CompleteEvent from './CompleteEvent'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLeft, faCheck } from '@fortawesome/free-solid-svg-icons';

export default function ChecklistBox( { title, sizeClass }) {

    const { 
      classInformation, initialLoad, focusAgreements,
      studentList,
      studentDataFunctions: {
        updateMongo, setFocusAgreements
      }
    } = useContext(SiteContext)

    const {orders, checkoutItems} = useContext(AdminContext)

    const [lastUpdate, setLastUpdate] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [localFocusAgreements, setLocalFocusAgreements] = useState(false)

    const [show, setShow] = useState(false);
    const [activeEntry, setActiveEntry] = useState(false)

    useEffect(() => {

      let interval;
      interval = setInterval(async () => {
          // console.log(lastUpdate,'lastUpdate',localFocusAgreements,'localFocusAgreements')
          if(!lastUpdate) return
          if(!localFocusAgreements || !localFocusAgreements.modules || localFocusAgreements.modules.length === 0) return
          if(Date.now() - lastUpdate < 5000) return
          setUpdating(true)
          await updateMongo({modules: [...localFocusAgreements.modules]}, localFocusAgreements._id, 'focusAgreements')
          setUpdating(false)
          setLastUpdate(false)
          setFocusAgreements(prev => {return {...prev, modules: localFocusAgreements.modules}})
          console.log('updated 1')
      }, 5000);
      return () => clearInterval(interval)

    }, [localFocusAgreements, lastUpdate])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if(!focusAgreements || !focusAgreements.modules) return
    const completionsPending = []
    const completionsComplete = []

    focusAgreements.modules.map(moduleEntry => {

      moduleEntry.checklists.map(checklistEntry => {
        checklistEntry.checklist.map(entry => {

          // if(!entry.complete) return
          if(!entry.complete || entry.completeEvent.period !== classInformation.period) return
          entry.module = moduleEntry.title

          var inputDate = new Date(entry.completeEvent.date);
          var todaysDate = new Date();

          // if(entry.module === 'Digital Manu') {
          //   // console.log(entry,'entry')
          // }
          if(entry.completeAdmin && inputDate.setHours(0,0,0,0) === todaysDate.setHours(0,0,0,0)) {
            completionsComplete.push(entry)
          } else if(!entry.completeAdmin) {
            completionsPending.push(entry)
          }    

        })
      })
    })

    const filteredCompletions = [...completionsPending, ...completionsComplete]
    if(filteredCompletions.length === 0) return
    // console.log(filteredCompletions,'filteredCompletions')
    var count = 0

    var variant = 'purple'

    const exportFunctions = {handleShow, handleClose, activeEntry, setActiveEntry, lastUpdate, setLastUpdate, localFocusAgreements, setLocalFocusAgreements, updating, setUpdating}

    return (
        <ErrorBoundary>
        {activeEntry && <CompleteEvent item={activeEntry} setActiveEntry={setActiveEntry} show={show} setShow={setShow} handleShow={handleShow} handleClose={handleClose} />}
        <div className={sizeClass}>  
          <div className="main-card mb-3 card">
            <div className="card-header h-auto mt-2">
              <h5 className="card-title d-flex justify-content-between w-100">
                <ColorTitle icon={faCheck} text={title} variant={variant} />
                {updating && <div className={`badge p-2 bg-purple text-white d-flex align-items-center`}>Updating Database</div>}
                {/* {title}
                <FontAwesomeIcon className="m-0 ms-2 card-title" icon={faCheck} /> */}
              </h5>
              <div className="btn-actions-pane-right">
              </div>
            </div>
            {filteredCompletions.length === 0 && <div className="card-body">
              <p>No Completions</p>
            </div>}
            {filteredCompletions.length > 0 && <div className="m-2 table-responsive">
              <table className="align-middle text-truncate mb-3 table table-borderless table-hover">
                <thead>
                  <tr>
                    <th className="text-center">Module</th>
                    <th className="text-center">Student</th>
                    {/* <th className="text-center">Date</th> */}
                    <th className="">Item</th>
                    {/* <th className="text-center">Type / Day</th> */}
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCompletions.map(entry => {
                    count++
                    if(count > 8) return
                    return <ChecklistEntry key={entry.itemId + uuidv4()} item={entry} variant={variant} exportFunctions={exportFunctions} />
                  })}
                </tbody>
              </table>
            </div>}
            {filteredCompletions.length > 8 && <div className="d-block text-center card-footer" style={{minHeight:"30px"}}>
              {count > 8 ? `+${parseInt(count) - 8} More` : ' '}
            </div>}
          </div>
        </div>
        </ErrorBoundary>
    )
}
